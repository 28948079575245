import {
  ActionDetails,
  actionsList,
  Condition,
  DamageType,
  TechDetails,
  techList,
} from "./tech";

export interface Tech {
  personalCount: number;
  equippedCount: number;
  storageCount: number;
}

export interface Attitude {
  x: number;
  y: number;
  z: number;
}

export interface CharacterData {
  owner: string;
  npc: boolean;
  attitude: Attitude;
  situations?: Record<string, boolean>;
  createdAt: string;
  displayName: string;
  avatar: number | string;
  description: string;
  notes: string;
  credits: number;
  campaignId?: string;
  attributes: {
    strength: number;
    coordination: number;
    intelligence: number;
    sensitivity: number;
  };
  published?: boolean;
  healthDamage: number;
  shieldDamage: number;
  droneDamage: number;
  tech: Record<string, Tech>;
  tier: number;
  statModifiers?: Partial<Stats>;
}

export interface Stats {
  maxHeadCapacity: number;
  currentHeadCapacity: number;
  maxTorsoCapacity: number;
  currentTorsoCapacity: number;
  maxLegsCapacity: number;
  currentLegsCapacity: number;
  maxShellCapacity: number;
  currentShellCapacity: number;
  maxArmsCapacity: number;
  currentArmsCapacity: number;
  maxCarryingCapacity: number;
  currentCarryingCapacity: number;
  currentHealth: number;
  maxHealth: number;
  currentShield: number;
  maxShield: number;
  currentDroneShield: number;
  maxDroneShield: number;
  droneMovementSpeed: number;
  droneKineticDamage: number;
  combatMovementSpeed: number;
  firewall: number;
  firewallRegen: number;
  unarmedDamage: number;
  unarmedElectricalDamage: number;
  size: number;
  // resistances
  [DamageType.kinetic]: number;
  [DamageType.thermal]: number;
  [DamageType.electrical]: number;
  [Condition.stunned]: number;
  [Condition.confused]: number;
  [Condition.crippled]: number;
  [Condition.clumsy]: number;
  [Condition.burning]: number;
  // Unused
  [DamageType.organic]: number;
  [Condition.frozenFirewall]: number;
  [Condition.jammed]: number;
  [Condition.focused]: number;
  [Condition.amped]: number;
  [Condition.stimulated]: number;
  [Condition.camouflaged]: number;
  [Condition.unstable]: number;
  [Condition.invigorated]: number;
  [Condition.doused]: number;
  // skills
  engineering: number;
  hacking: number;
  medical: number;
  empathy: number;
  performance: number;
  persuasion: number;
  deception: number;
  intimidation: number;
  perception: number;
  environmentalAnalysis: number;
  research: number;
  stealth: number;
  security: number;
  athletics: number;
  handToHand: number;
  heavyGuns: number;
  lightGuns: number;
  physicalPower: number;
  driving: number;
  demolition: number;
}

export type Stat = keyof Stats;

export type FullCharacter = Omit<CharacterData, "tech"> & {
  id: string;
  stats: Stats;
  actions: ActionDetails[];
  tech: Record<string, TechDetails & Tech>;
};

export function getFullCharacter(
  id: string,
  character: CharacterData
): FullCharacter {
  // const tech = keyBy(character.tech, "id");
  const { strength, coordination, intelligence, sensitivity } =
    character.attributes;
  const { statModifiers } = character;

  const baseStats: Stats = {
    maxCarryingCapacity: 0,
    currentCarryingCapacity: 0,
    maxHeadCapacity: 0,
    currentHeadCapacity: 0,
    maxTorsoCapacity: 0,
    currentTorsoCapacity: 0,
    maxLegsCapacity: 0,
    currentLegsCapacity: 0,
    maxShellCapacity: 0,
    currentShellCapacity: 0,
    maxArmsCapacity: 0,
    currentArmsCapacity: 0,
    currentHealth: 0,
    maxHealth: 20,
    currentShield: 0,
    maxShield: 0,
    currentDroneShield: 0,
    maxDroneShield: 0,
    droneMovementSpeed: 0,
    droneKineticDamage: 0,
    firewall: 3,
    firewallRegen: 1,
    combatMovementSpeed: 1.0, // 2500 / 60 / 60,
    unarmedDamage: 1,
    unarmedElectricalDamage: 0,
    size: 1,
    // resistances
    [DamageType.kinetic]: 0,
    [DamageType.thermal]: 0,
    [DamageType.electrical]: 0,
    [Condition.stunned]: 0,
    [Condition.confused]: 0,
    [Condition.crippled]: 0,
    [Condition.clumsy]: 0,
    [Condition.burning]: 0,
    //unused
    [DamageType.organic]: 0,
    [Condition.frozenFirewall]: 0,
    [Condition.jammed]: 0,
    [Condition.focused]: 0,
    [Condition.amped]: 0,
    [Condition.stimulated]: 0,
    [Condition.camouflaged]: 0,
    [Condition.unstable]: 0,
    [Condition.invigorated]: 0,
    [Condition.doused]: 0,
    // skills
    // engineering: Math.round(strength / 2 + intelligence / 2),
    // hacking: intelligence,
    // medical: Math.round(sensitivity / 2 + intelligence / 2),
    // empathy: sensitivity,
    // performance: Math.round(sensitivity / 2 + coordination / 2),
    // persuasion: Math.round(sensitivity / 2 + intelligence / 2),
    // deception: Math.round(sensitivity / 2 + coordination / 2),
    // intimidation: Math.round(sensitivity / 2 + strength / 2),
    // perception: sensitivity,
    // environmentalAnalysis: Math.round(sensitivity / 2 + intelligence / 2),
    // research: intelligence,
    // stealth: coordination,
    // security: Math.round(coordination / 2 + intelligence / 2),
    // athletics: Math.round(coordination / 2 + strength / 2),
    // handToHand: Math.round(coordination / 2 + strength / 2),
    // heavyGuns: Math.round(coordination / 2 + strength / 2),
    // lightGuns: coordination,
    // physicalPower: strength,
    // driving: Math.round(coordination / 2 + intelligence / 2),
    // demolition: Math.round(strength / 2 + intelligence / 2),
    engineering: intelligence,
    hacking: intelligence,
    medical: sensitivity,
    empathy: sensitivity,
    performance: coordination,
    persuasion: intelligence,
    deception: sensitivity,
    intimidation: strength,
    perception: sensitivity,
    environmentalAnalysis: intelligence,
    research: intelligence,
    stealth: coordination,
    security: sensitivity,
    athletics: coordination,
    handToHand: strength,
    heavyGuns: strength,
    lightGuns: coordination,
    physicalPower: strength,
    driving: coordination,
    demolition: strength,
  };

  if (statModifiers) {
    for (const [key, value] of Object.entries(statModifiers)) {
      baseStats[key as Stat] += value;
    }
  }

  const characterTech = Object.keys(character.tech)
    .map((id) => Object.values(techList).filter((tech) => tech.id === id))
    .reduce((a, b) => [...a, ...b], []);

  const modifiers = characterTech
    .map((tech) =>
      tech.statModifiers
        ?.map((mod) => ({
          [mod.stat]:
            mod.modifier *
            (tech.isEquippable
              ? character.tech[tech.id].equippedCount
              : character.tech[tech.id].personalCount),
        }))
        .reduce((a, b) => ({ ...a, ...b }), {})
    )
    .filter((e) => e !== undefined);

  const techActions = characterTech
    .map((tech) =>
      character.tech[tech.id].equippedCount > 0 ? tech.actions : undefined
    )
    .filter((e) => e !== undefined)
    .reduce((a, b) => [...a!, ...b!], [])
    ?.filter(
      (action) =>
        !action?.requirements ||
        action.requirements.every(
          (req) =>
            character.tech[req.id] && character.tech[req.id].equippedCount > 0
        )
    );

  const stats: Stats = modifiers.reduce((a, b) => {
    a = a!;
    b = b!;
    for (const [key, value] of Object.entries(a)) {
      const k = key as Stat;
      const v = value as number;
      if (!b[k]) {
        b[k] = 0;
      }

      b[k] += v;
    }

    return b;
  }, baseStats as any) as Stats;

  stats.currentShield = stats.maxShield - character.shieldDamage;
  stats.currentHealth = stats.maxHealth - character.healthDamage;
  stats.currentDroneShield = stats.maxDroneShield - character.droneDamage;
  stats.combatMovementSpeed += Math.round(stats.athletics * 0.75) * 0.5; //(stats.athletics * 2500) / 60 / 60;
  stats.maxCarryingCapacity = stats.physicalPower * 10;
  stats.currentCarryingCapacity = characterTech
    .map(
      (tech) =>
        (tech.weight ?? 0) *
          ((tech.isEquipment ? character.tech[tech.id].equippedCount : 0) +
            character.tech[tech.id].personalCount) || 0
    )
    .reduce((a, b) => a + b, 0);

  if (stats.currentCarryingCapacity > stats.maxCarryingCapacity) {
    stats.combatMovementSpeed = 0.5;
    // stats.athletics = Math.round(stats.athletics * 0.5);
    // stats.handToHand = Math.round(stats.handToHand * 0.5);
    // stats.heavyGuns = Math.round(stats.heavyGuns * 0.5);
    // stats.lightGuns = Math.round(stats.lightGuns * 0.5);
    // stats.stealth = Math.round(stats.stealth * 0.5);
    // stats.physicalPower = Math.round(stats.physicalPower * 0.5);
    // stats.demolition = Math.round(stats.demolition * 0.5);
  }

  return {
    ...character,
    id,
    stats,
    actions: [
      ...(new Set(techActions) || []),
      actionsList.attack,
      actionsList.grapple,
      actionsList.move,
      actionsList.dash,
      actionsList.chase,
      actionsList.defend,
      actionsList.dodge,
      actionsList.unreadyWeapon,
      actionsList.sneakAttack,
      actionsList.hide,
      actionsList.search,
      // actionsList.shiftDisposition,
    ],
    tech: characterTech
      .map((tech) => ({
        [tech.id]: {
          ...tech,
          ...character.tech[tech.id],
          // Backwards capatibility
          ...(character.tech[tech.id].personalCount === undefined
            ? {
                storageCount: 0,
                personalCount: 0,
              }
            : {}),
        },
      }))
      .reduce((a, b) => ({ ...a, ...b }), {}),
  };
}

export function getDrone(character: FullCharacter): FullCharacter {
  return {
    ...character,
    id: `drone-${character.id}`,
    displayName: `${character.displayName}'s Drone`,
    healthDamage: character.droneDamage,
    shieldDamage: 0,
    stats: {
      ...character.stats,
      maxHealth: character.stats.maxDroneShield,
      currentHealth: character.stats.currentDroneShield,
      maxShield: 0,
      currentShield: 0,
    },
  };
}

export enum AttitudeType {
  friendly,
  hostile,
  intimidated,
  awed,
  dismissive,
  protective,
}

export function attitudeDistance(a: Attitude, b: Attitude) {
  return (Math.abs(a.x - b.x) + Math.abs(a.y - b.y) + Math.abs(a.z - b.z)) / 2;
}

export function attitudeDistanceFromAttitudeType(
  attitude: Attitude,
  attitudeType: AttitudeType
) {
  return attitudeDistance(attitude, attitudeTypeToAttitude(attitudeType));
}

export function lerp(a: number, b: number, t: number): number {
  return a + (b - a) * t;
}

export function lerpAttitude(a: Attitude, b: Attitude, t: number): Attitude {
  return {
    x: lerp(a.x, b.x, t),
    y: lerp(a.y, b.y, t),
    z: lerp(a.z, b.z, t),
  };
}

export function roundAttitude(disposition: Attitude): Attitude {
  let x = Math.round(disposition.x);
  let y = Math.round(disposition.y);
  let z = Math.round(disposition.z);
  let xDiff = Math.abs(x - disposition.x);
  let yDiff = Math.abs(y - disposition.y);
  let zDiff = Math.abs(z - disposition.z);
  if (xDiff > yDiff && xDiff > zDiff) {
    x = -y - z;
  } else if (yDiff > zDiff) {
    y = -x - z;
  } else {
    z = -x - y;
  }
  return {
    x,
    y,
    z,
  };
}

export function attitudeTypeToAttitude(attitude: AttitudeType) {
  switch (attitude) {
    case AttitudeType.friendly:
      return { x: 3, y: 0, z: -3 };
    case AttitudeType.protective:
      return { x: 0, y: 3, z: -3 };
    case AttitudeType.dismissive:
      return { x: -3, y: 3, z: 0 };
    case AttitudeType.hostile:
      return { x: -3, y: 0, z: 3 };
    case AttitudeType.intimidated:
      return { x: 0, y: -3, z: 3 };
    case AttitudeType.awed:
      return { x: 3, y: -3, z: 0 };
  }
}

export function moveAttitudeTowardsAttitudeType(
  attitude: Attitude,
  attitudeType: AttitudeType,
  steps: number
) {
  const dDisposition = attitudeTypeToAttitude(attitudeType);
  const distance = attitudeDistance(attitude, dDisposition);

  if (distance === 0) {
    return attitude;
  }

  const newDisposition = roundAttitude(
    lerpAttitude(
      attitude,
      dDisposition,
      Math.min(1.0, (1.0 / distance) * steps)
    )
  );

  return newDisposition;
}
