import React from "react";
import { avatars } from "./avatars";
import styled from "styled-components";
import { Button, Text } from "@arwes/core";
import { FrameContent } from "../layout";
const { FrameCorners, FrameBox } = require("@arwes/core");

interface AvatarProps {
  id: number | string;
  onClick?: any;
}

const StyledImage = styled.img`
  vertical-align: middle;
  width: 124px;
  height: 124px;
  overflow: hidden;

  animation: fadeIn linear 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const AvatarContainer = styled.div`
  width: 128px;
  height: 128px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export function Avatar(props: AvatarProps) {
  return (
    <AvatarContainer>
      <FrameCorners
        style={{
          position: "absolute",
          padding: "2px",
          width: "128px",
        }}
        showContentLines
      >
        <StyledImage src={avatars[props.id]} />
      </FrameCorners>
      {props.onClick && (
        <Button
          style={{ background: "#01101B", opacity: "80%" }}
          FrameComponent={FrameBox}
          animator={{ root: true }}
          onClick={props.onClick}
        >
          <Text>Change</Text>
        </Button>
      )}
    </AvatarContainer>
  );
}
