import { Animator } from "@arwes/animation";
import { Button, Card, Text } from "@arwes/core";
import { DialogActions, Grid, MenuItem, Select } from "@material-ui/core";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAccount, useSubscription } from "../account";
import { useModuleList } from "../campaign";
import { useCampaignList, useCreateCampaign } from "../campaign/useCampaign";
import { CampaignData } from "../campaign/utils";
import { LoadingButton } from "../components";
import { CardText, FrameContent, Modal, PageLayout } from "../layout";
import { Link } from "../navigation";
import { AnimatedRouteChildrenProps } from "../navigation/AnimatedRoute";

export const ModulesPage: React.FC<
  AnimatedRouteChildrenProps<{ id: string }>
> = (props) => {
  const { activate } = props;
  const [firstRenderTime] = useState(() => Date.now());
  const modules = useModuleList();

  const [module, setModule] = useState(
    undefined as
      | undefined
      | firebase.firestore.QueryDocumentSnapshot<CampaignData>
  );

  useEffect(() => {
    document.title = `Modules`;
  }, []);

  return (
    <PageLayout>
      <FrameContent style={{ padding: "16px" }} animator={{ activate }}>
        <Grid container direction="column">
          <Grid item xs>
            <Text as="h3">Modules</Text>
          </Grid>
          <Grid item xs style={{ marginBottom: "16px" }}>
            <Text>
              Modules are pre-made adventures, containing all the characters,
              locations and situations you need to run a campaign.
            </Text>
          </Grid>
          <Grid item container direction="row" spacing={2}>
            {modules.docs.map((moduleRef, index) => {
              const module = moduleRef.data();
              return (
                <Grid key={moduleRef.id} item xs={12} md={6}>
                  <Card
                    animator={{
                      root: true,
                      activate,
                      duration: {
                        delay: Math.max(
                          100 * index - (Date.now() - firstRenderTime),
                          0
                        ),
                      },
                    }}
                    image={
                      module.displayImage
                        ? { src: module.displayImage }
                        : undefined
                    }
                    title={module.displayName}
                    options={
                      <Button onClick={() => setModule(moduleRef)}>
                        <Text>Play</Text>
                      </Button>
                    }
                  >
                    <Text style={{ whiteSpace: "pre-wrap" }}>
                      {module.description}
                    </Text>
                  </Card>
                </Grid>
              );
            })}
            {/* <Grid item xs={12} md={6}>
              <Card
                animator={{
                  root: true,
                  activate,
                  duration: {
                    delay: Math.max(
                      100 * modules.docs.length -
                        (Date.now() - firstRenderTime),
                      0
                    ),
                  },
                }}
                image={{ src: "/images/alone-on-busy-street.jpg" }}
                title="Stay tuned"
              >
                <Text style={{ whiteSpace: "pre-wrap" }}>
                  More coming soon...
                </Text>
              </Card>
            </Grid> */}
          </Grid>
        </Grid>
      </FrameContent>
      <ImportModal
        moduleDoc={module}
        handleClose={() => setModule(undefined)}
      />
    </PageLayout>
  );
};

const ImportModal: React.FC<{
  handleClose: any;
  moduleDoc?: firebase.firestore.QueryDocumentSnapshot<CampaignData>;
}> = (props) => {
  const { handleClose, moduleDoc } = props;
  const history = useHistory();

  const module = moduleDoc?.data();

  const campaigns = useCampaignList();
  const createCampaign = useCreateCampaign();
  const importModule = firebase.functions().httpsCallable("importModule");

  const subscription = useSubscription();
  const account = useAccount();

  const [selectedCampaign, setSelectedCampaign] = useState(undefined);

  const hasSpace =
    module &&
    (subscription.hasSubscription() ||
      (10 - (account?.totalCharacters || 0) >=
        Object.keys(module.npcs).length &&
        5 - (account?.totalMaps || 0) >= Object.keys(module.maps).length &&
        5 - (account?.totalSituations || 0) >=
          Object.keys(module.situations).length));

  return (
    <Modal isOpen={!!moduleDoc} onClose={handleClose} maxWidth={600}>
      <Grid container xs={12} direction="column">
        <Grid item>
          <Text as="h4">Import Module</Text>
        </Grid>
        {!hasSpace ? (
          <Grid item style={{ marginBottom: "8px" }}>
            <Text>
              You don't have enough free character, location or situation slots
              to import this module. Either free up some space or purchase a
              subscription.
            </Text>
          </Grid>
        ) : null}
        <Grid item style={{ marginBottom: "8px" }}>
          <Text>Select a campaign to import the module into:</Text>
        </Grid>
        <Grid container item xs={12} justify="center">
          <Grid item xs={6}>
            <Select
              id="campaign-select"
              value={selectedCampaign}
              fullWidth
              onChange={(event) =>
                setSelectedCampaign(event.target.value as any)
              }
            >
              {subscription.hasSubscription() ||
              (account?.totalCampaigns || 0) < 1 ? (
                <MenuItem key="create-new" value="create-new">
                  {"[Create New]"}
                </MenuItem>
              ) : null}
              {campaigns.docs
                .map((campaignDoc) => ({
                  ...campaignDoc.data(),
                  id: campaignDoc.id,
                }))
                .sort((a, b) => {
                  if (a.displayName === b.displayName) {
                    return 0;
                  } else if (a.displayName < b.displayName) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((campaign) => {
                  return (
                    <MenuItem key={campaign.id} value={campaign.id}>
                      {campaign.displayName}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
        </Grid>
        <Grid>
          <DialogActions>
            <LoadingButton
              disabled={!selectedCampaign || !hasSpace}
              onClick={async () => {
                if (!moduleDoc) {
                  return;
                }
                const campaignId =
                  selectedCampaign === "create-new"
                    ? (await createCampaign()).id
                    : selectedCampaign;
                await importModule({
                  moduleId: moduleDoc.id,
                  campaignId,
                });
                handleClose();
                history.push(`/campaigns/${campaignId}/overview`);
              }}
            >
              <Text>Import</Text>
            </LoadingButton>
            <Button onClick={handleClose}>
              <Text>Cancel</Text>
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Modal>
  );
};
