import React from "react";
import { useAccount, useSubscription } from "../account";

export const SituationLimit: React.FC = () => {
  const subscription = useSubscription();
  const account = useAccount();
  return (
    <>
      {!subscription.hasSubscription() &&
        `${account.totalSituations || 0}/5 free situation slots used`}
    </>
  );
};
