import { Grid, TextField } from "@material-ui/core";
import { Button, Text } from "@arwes/core";
import React, { useEffect, useRef, useState } from "react";
import { EditableCharacter } from "./useCharacter";
import {
  LoadingBars,
  SuspenseWithLoadingBars,
  WideFrameLines,
} from "../components";
import firebase, { User } from "firebase";
import { useEditableCampaign } from "../campaign/useCampaign";
import { SVG } from "@svgdotjs/svg.js";
import { createHexPrototype, Grid as HexGrid, spiral } from "honeycomb-grid";
import { useUser } from "reactfire";

// const Hex = extendHex({ size: 13 });
// const HexGrid = defineGrid(Hex);
// const corners = Hex().corners();
// const grid = HexGrid.hexagon({ radius: 3, center: [3, 3] });

const hexPrototype = createHexPrototype({ dimensions: 13 });
const grid = new HexGrid(hexPrototype, spiral({ start: [0, 0], radius: 3 }));

interface CharacterOverviewProps {
  activate: boolean;
  editMode: boolean;
  loading: boolean;
  character: EditableCharacter;
}
export const CharacterOverview: React.FC<CharacterOverviewProps> = (props) => {
  const { editMode, loading, character, activate } = props;

  const gridRef: any = useRef();

  useEffect(() => {
    function clickHex({ offsetX, offsetY }: any) {
      if (!editMode) return;

      const hexCoordinates = grid.pointToHex({
        x: offsetX - 85,
        y: offsetY - 73,
      });

      const clickedHex = grid.getHex(hexCoordinates);
      if (clickedHex) {
        character.setAttitude({
          x: clickedHex.q,
          y: clickedHex.r,
          z: clickedHex.s,
        });
      }
    }

    if (gridRef && gridRef.current) {
      gridRef.current.addEventListener("click", clickHex);
      return function cleanup() {
        if (gridRef && gridRef.current) {
          gridRef.current.removeEventListener("click", clickHex);
        }
      };
    }
  }, [editMode]);

  useEffect(() => {
    if (gridRef && gridRef.current) {
      gridRef.current.innerHTML = "";
      const draw = SVG().addTo(gridRef.current).size("175px", "145px");

      const unselectedHex = draw
        .symbol()
        .polygon(hexPrototype.corners.map(({ x, y }) => [x, y]) as any)
        .fill("none")
        .stroke({ width: 1, color: "#999" });

      const selectedHex = draw
        .symbol()
        .polygon(hexPrototype.corners.map(({ x, y }) => [x, y]) as any)
        .fill("blue")
        .stroke({ width: 1, color: "#999" });

      grid
        .each((hex) => {
          const { x, y } = hex.center;
          // const currentAttitude = { x: 2, y: 3, z: -5 };
          if (
            character.state.attitude.x === hex.q &&
            character.state.attitude.y === hex.r &&
            character.state.attitude.z === hex.s
          ) {
            draw
              .use(selectedHex)
              .translate(-x, -y)
              .translate(
                85 + hexPrototype.center.x,
                73 + hexPrototype.center.y
              );
          } else {
            draw
              .use(unselectedHex)
              .translate(-x, -y)
              .translate(
                85 + hexPrototype.center.x,
                73 + hexPrototype.center.y
              );
          }
        })
        .run();
    }
  }, [character.state.attitude]);

  return (
    <>
      {character.state.campaignId && (
        <SuspenseWithLoadingBars traceId={"character-campaign-details"}>
          <CampaignDetails {...props} />
        </SuspenseWithLoadingBars>
      )}
      {character.state.npc && (
        <>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Text as="h4">Attitude</Text>
            </Grid>
            <Grid container direction="column" item>
              <Grid container direction="row" item>
                <Grid item style={{ marginLeft: "16px" }}>
                  <Text>Intimidated</Text>
                </Grid>
                <Grid item style={{ marginLeft: "100px" }}>
                  <Text>Awed</Text>
                </Grid>
              </Grid>
              <Grid container direction="row" item alignItems="center">
                <Grid item>
                  <Text>Hostile</Text>
                </Grid>
                <Grid item>
                  <div ref={gridRef} />
                </Grid>
                <Grid item>
                  <Text>Friendly</Text>
                </Grid>
              </Grid>
              <Grid container direction="row" item>
                <Grid item style={{ marginLeft: "16px" }}>
                  <Text>Dismissive</Text>
                </Grid>
                <Grid item style={{ marginLeft: "100px" }}>
                  <Text>Protective</Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <WideFrameLines
            hideTopLines
            hideShapes
            style={{ marginBottom: "16px" }}
          />
        </>
      )}
      <Grid container direction="column">
        <Grid item xs={12}>
          <Text as="h4">Description</Text>
        </Grid>
        <Grid item style={{ marginLeft: "16px", marginRight: "16px" }}>
          {editMode ? (
            <TextField
              label="Character Description"
              variant="filled"
              multiline
              rows={5}
              fullWidth
              value={character.state.description}
              disabled={loading}
              onChange={(e) => {
                character.setDescription(e.target.value);
              }}
            />
          ) : (
            <Text style={{ whiteSpace: "pre-wrap" }}>
              {character.state.description}
            </Text>
          )}
        </Grid>
      </Grid>
      <WideFrameLines
        hideTopLines
        hideShapes
        style={{ marginBottom: "16px" }}
      />
      <Grid container direction="column">
        <Grid item>
          <Text as="h4">Notes</Text>
        </Grid>
        <Grid item style={{ marginLeft: "16px", marginRight: "16px" }}>
          {editMode ? (
            <TextField
              label="Character Notes"
              variant="filled"
              multiline
              rows={20}
              fullWidth
              value={character.state.notes}
              disabled={loading}
              onChange={(e) => {
                character.setNotes(e.target.value);
              }}
            />
          ) : (
            <Text style={{ whiteSpace: "pre-wrap" }}>
              {character.state.notes}
            </Text>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const CampaignDetails: React.FC<CharacterOverviewProps> = (props) => {
  const { editMode, loading, character, activate } = props;
  const campaign = useEditableCampaign(character.state.campaignId!);

  const [leavingCampaign, setLeavingCampaign] = useState(false);
  const leaveCampaign = firebase.functions().httpsCallable("leaveCampaign");

  const currentUser = useUser<User>();

  return (
    <Grid container direction="column">
      <Grid item container justify="space-between">
        <Grid item>
          <Text as="h4">Campaign: {campaign.state.displayName}</Text>
        </Grid>
        <Grid item>
          {!editMode &&
            (character.state.owner === currentUser.uid ||
              campaign.state.owner === currentUser.uid) &&
            (leavingCampaign ? (
              <LoadingBars />
            ) : (
              <Button
                disabled={loading}
                onClick={async () => {
                  setLeavingCampaign(true);
                  try {
                    await leaveCampaign({ characterId: character.id });
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setLeavingCampaign(false);
                  }
                }}
              >
                <Text>Leave</Text>
              </Button>
            ))}
        </Grid>
      </Grid>
      <Grid item style={{ marginLeft: "16px", marginRight: "16px" }}>
        <Text style={{ whiteSpace: "pre-wrap" }}>
          {campaign.state.description}
        </Text>
      </Grid>
      <WideFrameLines
        hideTopLines
        hideShapes
        style={{
          marginBottom: "16px",
        }}
      ></WideFrameLines>
    </Grid>
  );
};
