import Markdown from "markdown-to-jsx";
import raw from "raw.macro";
import React from "react";
import { FrameContent, PageLayout } from "../layout";

export const PrivacyPolicyPage: React.FC = (props) => {
  return (
    <PageLayout>
      <FrameContent animator={{ animate: false }} style={{ padding: "16px" }}>
        <Markdown>{raw("./privacy.md")}</Markdown>
      </FrameContent>
    </PageLayout>
  );
};
