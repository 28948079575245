import { FullCharacter, Stat, Stats } from "../utils";

function techImage(tech: string) {
  return `/images/tech/${tech}.png`;
}

interface StatModifier {
  stat: Stat;
  modifier: number;
}

export enum Role {
  bruiser,
  gunner,
  hacker,
  socialite,
  infiltrator,
  engineer,
  medic,
}

export interface TechDetails {
  id: string;
  displayName: string;
  image?: string;
  description: string;
  tier: number;
  roles: Role[];
  weight?: number;
  slots?: string[];
  requirements?: TechDetails[];
  isEquippable?: boolean;
  isEquipment?: boolean;
  maxEquippable?: number;
  price: number;
  weaponSkill?: Stat;
  range?: number;
  maxEffectiveRange?: number;
  minEffectiveRange?: number;
  statModifiers?: StatModifier[];
  actions?: ActionDetails[];
  damage?: Partial<Record<DamageType, EffectDetails>>;
  ammo?: number;
  ammoType?: TechDetails;
  roundsPerSecond?: number;
  recoil?: number;
  poolModifier?: EffectDetails;
  melee?: boolean;
}

export type ActionFunction = (stats: Stats) => ActionDetails;

export enum ActionType {
  roll,
  ready,
  reload,
}

export enum TargetType {
  self,
  character,
  skill,
  path,
  point,
}

export enum Condition {
  stunned = "stunned",
  clumsy = "clumsy",
  confused = "confused",
  crippled = "crippled",
  burning = "burning",
  doused = "doused",
  frozenFirewall = "frozenFirewall",
  jammed = "jammed",
  focused = "focused",
  stimulated = "stimulated",
  amped = "amped",
  camouflaged = "camouflaged",
  unstable = "unstable",
  invigorated = "invigorated",
}

export const conditionNames = {
  [Condition.stunned]: "Stunned",
  [Condition.clumsy]: "Clumsy",
  [Condition.confused]: "Confused",
  [Condition.crippled]: "Crippled",
  [Condition.burning]: "Burning",
  [Condition.doused]: "Doused",
  [Condition.frozenFirewall]: "Frozen Firewall",
  [Condition.jammed]: "Jammed",
  [Condition.focused]: "Focused",
  [Condition.stimulated]: "Stimulated",
  [Condition.amped]: "Amped",
  [Condition.camouflaged]: "Camouflaged",
  [Condition.unstable]: "Unstable",
  [Condition.invigorated]: "Invigorated",
};

export interface ConditionDetails {
  multiplier?: number;
  flat?: number;
  duration?: number;
  durationMultiplier?: number;
}

export interface EffectDetails {
  multiplier: number;
  flat?: number;
}

export enum DamageType {
  kinetic = "kinetic",
  thermal = "thermal",
  electrical = "electrical",
  organic = "organic",
}

export const damageNames = {
  [DamageType.kinetic]: "Kinetic",
  [DamageType.thermal]: "Thermal",
  [DamageType.electrical]: "Electrical",
  [DamageType.organic]: "Organic",
};

export interface ActionUtilization {
  movement?: boolean;
  interaction?: boolean;
  attention?: boolean;
}

export enum DroneStatus {
  deployed = "deployed",
  undeployed = "undeployed",
}

export interface ActionDetails {
  id: string;
  displayName: string;
  description: string;
  targetType: TargetType;
  targetDrone?: number;
  movementMultiplier?: number;
  droneMovementMultiplier?: number;
  minTargets?: number;
  maxTargets?: number;
  utilizes: ActionUtilization;
  duration: number;
  cooldown: number;
  stealth?: EffectDetails;
  search?: EffectDetails;
  shieldHealing?: number;
  droneHealing?: number;
  breachFirewall?: number;
  boostFirewall?: number;
  isFirewallable?: boolean;
  sharedCooldown?: string;
  maxHelpers?: number;
  readyWeapon?: TechDetails | null;
  requiredWeapon?: TechDetails | null;
  reloadWeapon?: string;
  skill?: Stat;
  poolModifier?: EffectDetails;
  damage?: Partial<Record<DamageType, EffectDetails>>;
  grapple?: EffectDetails;
  defense?: EffectDetails;
  engage?: EffectDetails;
  mark?: EffectDetails;
  cooldownReduction?: Record<string, EffectDetails>;
  price?: number;
  conditions?: Partial<Record<Condition, ConditionDetails>>;
  clearConditions?: Condition[];
  melee?: boolean;
  range?: number;
  radius?: number;
  consumes?: TechDetails;
  isAttack?: boolean;
  isDefendable?: boolean;
  droneRequired?: boolean;
  lineOfSight?: boolean;
  requirements?: TechDetails[];
  logFormat?: {
    base: string;
    success?: string;
    failure?: string;
  };
  setDroneStatus?: DroneStatus;
  requiresStealth?: boolean;
  recoilSuppression?: number;
}

const attack: ActionDetails = {
  id: "db863d8c-b80b-4c23-8911-96835146baed",
  displayName: "Attack",
  description: `Attack with your currently readied weapon.`,
  targetType: TargetType.character,
  targetDrone: 1,
  maxTargets: 1,
  minTargets: 1,
  utilizes: {
    interaction: true,
  },
  duration: Infinity,
  cooldown: 0,
  maxHelpers: 0,
  isAttack: true,
  isDefendable: true,
  lineOfSight: true,
  logFormat: {
    base: "Attack ",
    success: "{targetDisplayName} for a total of {damage} damage",
    failure: "{targetDisplayName} but missed",
  },
};

const sneakAttack: ActionDetails = {
  id: "6a4b3556-026a-4a4d-a5ce-10ebc91f1822",
  displayName: "Sneak Attack",
  description: `Make a stealth challenge contested by perception against an unaware target within your currently readied weapon's effective range, if successful then make an attack with your currently readied weapon. This attack will do double damage and can't be affected by dodge or defend.`,
  targetType: TargetType.character,
  targetDrone: 1,
  maxTargets: 1,
  minTargets: 1,
  utilizes: {
    interaction: true,
    movement: true,
  },
  duration: 1,
  cooldown: 1,
  maxHelpers: 0,
  isAttack: true,
  lineOfSight: true,
  requiresStealth: true,
  poolModifier: {
    multiplier: 2,
  },
  logFormat: {
    base: "Sneak Attack ",
    success: "{targetDisplayName} for a total of {damage} damage",
    failure: "{targetDisplayName} but missed",
  },
};

const markTarget: ActionDetails = {
  id: "b8a5097e-1269-42e7-909d-fdeed0149c37",
  displayName: "Mark Target",
  description: `Carefully track the target of your attack to negate their attempts at defense. This will cause you to ignore X / 2 points of their defense.`,
  targetType: TargetType.self,
  maxTargets: 1,
  minTargets: 1,
  utilizes: {
    attention: true,
  },
  skill: "perception",
  duration: Infinity,
  cooldown: 0,
  maxHelpers: 0,
  price: 500,
  lineOfSight: true,
  logFormat: {
    base: "Mark their target ",
    success: "for a total of {rollResult} successes",
    failure: "but failed",
  },
};

const droneAmmoRound: TechDetails = {
  id: "4a9c5adb-67c0-4332-b062-63c7b3d67d62",
  tier: 1,
  roles: [Role.engineer],
  displayName: "Drone Ammo Round",
  image: techImage("bullet"),
  isEquippable: true,
  isEquipment: true,
  description: "A round of ammo for use by a drone.",
  price: 1,
  weight: 0.02,
};

const droneAttack: ActionDetails = {
  id: "ff0bfca7-ab0a-4864-acbb-3ff2f23987f1",
  displayName: "Drone Attack",
  description: `Attack with your drone.`,
  targetType: TargetType.character,
  targetDrone: 1,
  maxTargets: 1,
  minTargets: 1,
  utilizes: {
    attention: true,
  },
  duration: Infinity,
  cooldown: 0,
  maxHelpers: 0,
  droneRequired: true,
  isAttack: true,
  isDefendable: true,
  lineOfSight: true,
  consumes: droneAmmoRound,
  logFormat: {
    base: "Attack",
    success: " {targetDisplayName} for {damage} damage",
    failure: " but missed",
  },
};

const droneMove: ActionDetails = {
  id: "340c235f-aded-4455-a450-8a143af8c00a",
  displayName: "Drone Move",
  description: `Move your drone up to its movement speed per second.`,
  targetType: TargetType.path,
  droneMovementMultiplier: 1,
  skill: "engineering",
  utilizes: {
    attention: true,
  },
  duration: Infinity,
  cooldown: 0,
  maxHelpers: 0,
  droneRequired: true,
  logFormat: {
    base: "move {droneMovementSpeed} metres",
  },
};

const deployDrone: ActionDetails = {
  id: "dd529036-f4b6-4281-8bf3-f946cefebdc6",
  displayName: "Drone Deploy",
  description: `Deploys your drone.`,
  targetType: TargetType.self,
  utilizes: {
    attention: true,
  },
  duration: 3,
  cooldown: 0,
  maxHelpers: 0,
  setDroneStatus: DroneStatus.deployed,
  logFormat: {
    base: "deploy their drone",
  },
};

const undeployDrone: ActionDetails = {
  id: "2e418170-53bf-487f-a1b2-26a5eaa3bbc7",
  displayName: "Drone Undeploy",
  description: `Undeploys your drone.`,
  targetType: TargetType.self,
  utilizes: {
    attention: true,
  },
  duration: 3,
  cooldown: 0,
  maxHelpers: 0,
  setDroneStatus: DroneStatus.undeployed,
  logFormat: { base: "undeploy their drone" },
};

const grapple: ActionDetails = {
  id: "e5e871eb-580d-4013-8e77-4864ca6a1da1",
  displayName: "Grapple",
  description: `Attempt to grapple a target. This action uses your hand to hand skill. Successes will cancel out the target's defense points, and excess successes will be applied as points of grapple. Points of grapple add to the difficulty of any movement or interaction actions the target is attempting to perform this second. Additionally, all characters involved in a grapple make a contested physical power challenge and the movement of the whole group is the average movement of all the participants weighted by their physical power rolls, i.e. a strong character can drag a weak character around.`,
  targetType: TargetType.character,
  skill: "handToHand",
  grapple: {
    multiplier: 1,
  },
  maxTargets: 1,
  minTargets: 1,
  utilizes: {
    interaction: true,
  },
  melee: true,
  range: 0.5,
  duration: Infinity,
  cooldown: 0,
  maxHelpers: 100,
  lineOfSight: true,
  logFormat: {
    base: "Grapple ",
    success: "{targetDisplayName} for {rollResult} successes",
    failure: "{targetDisplayName} but failed",
  },
};

const move: ActionDetails = {
  id: "61cd1f41-d1d7-4fbf-b4c1-08634d3e8a2a",
  displayName: "Move",
  description: `Move up to your combat speed per second.`,
  targetType: TargetType.path,
  movementMultiplier: 1,
  maxTargets: 10,
  skill: "athletics",
  utilizes: {
    movement: true,
  },
  duration: Infinity,
  cooldown: 0,
  maxHelpers: 0,
  logFormat: {
    base: "move {movementSpeed} metres",
  },
};

const chase: ActionDetails = {
  id: "483b77cf-8bcd-47e2-9c56-2f2f428b1c8b",
  displayName: "Chase",
  description: `Chase a target at up to your combat speed per second.`,
  targetType: TargetType.character,
  targetDrone: 1,
  movementMultiplier: 2,
  maxTargets: 1,
  minTargets: 1,
  skill: "athletics",
  utilizes: {
    attention: true,
    interaction: true,
    movement: true,
  },
  duration: Infinity,
  cooldown: 0,
  maxHelpers: 0,
  lineOfSight: true,
  logFormat: {
    base: "chase {targetDisplayNames}",
  },
};

const suppressRecoil: ActionDetails = {
  id: "fb9b76d7-840a-487b-985b-3fe0ef177a5f",
  displayName: "Suppress Recoil",
  description: `Suppress recoil of heavier weapons.`,
  targetType: TargetType.self,
  skill: "physicalPower",
  utilizes: {
    movement: true,
  },
  duration: Infinity,
  cooldown: 0,
  maxHelpers: 0,
  recoilSuppression: 1,
  logFormat: {
    base: "Suppress Recoil",
    success: " for {rollResult} successes",
    failure: " but failed",
  },
};

const dash: ActionDetails = {
  id: "4b776a65-7b83-48b1-abe6-84a364371858",
  displayName: "Dash",
  description: `Dash up to twice your combat speed per second.`,
  targetType: TargetType.path,
  movementMultiplier: 2,
  skill: "athletics",
  utilizes: {
    movement: true,
    interaction: true,
  },
  duration: Infinity,
  cooldown: 0,
  maxHelpers: 0,
  logFormat: {
    base: "dash {movementSpeed} metres",
  },
};

const breachFirewall: ActionDetails = {
  id: "facd5a3d-7763-4d00-975a-d6fcd9891611",
  displayName: "Breach Firewall",
  description: `Reduce target's firewall, making them more vulnerable to hacking programs.`,
  targetType: TargetType.character,
  targetDrone: 1,
  skill: "hacking",
  breachFirewall: 1,
  minTargets: 1,
  maxTargets: 1,
  utilizes: {
    attention: true,
  },
  duration: Infinity,
  cooldown: 0,
  price: 100,
  logFormat: {
    base: "Breach Firewall against ",
    success: "{targetDisplayName} with {rollResult} successes",
    failure: "{targetDisplayName} but failed",
  },
};

const boostFirewall: ActionDetails = {
  id: "15df5f80-6be7-40c4-a1f7-b939fdec7ff9",
  displayName: "Boost Firewall",
  description: `Boosts target's firewall.`,
  targetType: TargetType.character,
  targetDrone: 1,
  skill: "hacking",
  boostFirewall: 1,
  minTargets: 1,
  maxTargets: 1,
  utilizes: {
    attention: true,
  },
  duration: Infinity,
  cooldown: 0,
  price: 100,
  logFormat: {
    base: "Boost Firewall for ",
    success: "{targetDisplayName} with {rollResult} successes",
    failure: "{targetDisplayName} but failed",
  },
};

const skillChallenge: ActionDetails = {
  id: "e4658b0a-a74e-479f-9612-f7611ab42e3c",
  displayName: "Skill Challenge",
  description: `Perform a skill challenge.`,
  targetType: TargetType.skill,
  utilizes: {},
  duration: 0,
  cooldown: 0,
  maxHelpers: 0,
  logFormat: {
    base: "{skillChallengeDisplayName}",
    success: " with {rollResult} successes",
    failure: " but failed",
  },
};

const dodge: ActionDetails = {
  id: "39f39dcf-d78a-42dc-8f44-b4ab6b02cd4f",
  displayName: "Dodge",
  description: `Use your athletics skill to dodge attacks.`,
  targetType: TargetType.self,
  skill: "athletics",
  defense: {
    multiplier: 0.5,
  },
  utilizes: {
    movement: true,
  },
  duration: Infinity,
  cooldown: 0,
  maxHelpers: 0,
  logFormat: {
    base: "Dodge ",
    success: "for {rollResult} successes",
    failure: "but failed",
  },
};

const defend: ActionDetails = {
  id: "0f481c3b-647d-4174-9e00-c0063e162d42",
  displayName: "Block",
  description: `Use your physical power skill to block incoming attacks.`,
  targetType: TargetType.self,
  skill: "physicalPower",
  defense: {
    multiplier: 0.5,
  },
  utilizes: {
    interaction: true,
  },
  duration: Infinity,
  cooldown: 0,
  maxHelpers: 0,
  logFormat: {
    base: "Block ",
    success: "for {rollResult} successes",
    failure: "but failed",
  },
};

const unreadyWeapon: ActionDetails = {
  id: "dfefd926-1a9c-4434-b821-d9ece4c04525",
  readyWeapon: null,
  displayName: "Unready Weapon",
  description: `Put away your current weapon.`,
  targetType: TargetType.self,
  utilizes: {
    interaction: true,
  },
  duration: 1,
  cooldown: 0,
  maxHelpers: 0,
};

const lightAmmoClip: TechDetails = {
  id: "12a8ce12-b09f-4745-b28b-574a01ee2376",
  tier: 1,
  roles: [
    Role.gunner,
    Role.infiltrator,
    Role.hacker,
    Role.medic,
    Role.socialite,
  ],
  displayName: "Light Ammo Round",
  image: techImage("bullet"),
  isEquippable: true,
  isEquipment: true,
  description: "A round of ammo for use with a light gun.",
  price: 1,
  weight: 0.01,
};

const heavyAmmoClip: TechDetails = {
  id: "6d212627-d610-402a-ac11-4d590652f27a",
  tier: 1,
  roles: [Role.gunner],
  displayName: "Heavy Ammo Round",
  image: techImage("bullet"),
  isEquippable: true,
  isEquipment: true,
  description: "A round of ammo for use with a heavy gun.",
  price: 1,
  weight: 0.025,
};

const pistol1: TechDetails = {
  id: "cd001910-1b08-46fa-9660-21c8ad025e1e",
  tier: 1,
  roles: [
    Role.gunner,
    Role.infiltrator,
    Role.socialite,
    Role.hacker,
    Role.medic,
  ],
  weaponSkill: "lightGuns",
  displayName: "Semi-automatic Pistol",
  image: techImage("pistol"),
  isEquippable: true,
  isEquipment: true,
  description:
    "Semi-automatic pistols are small, light, and easy to use. They can be concealed when not readied.",
  maxEffectiveRange: 25,
  minEffectiveRange: 2,
  price: 500,
  actions: [],
  damage: {
    [DamageType.kinetic]: {
      multiplier: 3,
    },
  },
  ammo: 8,
  roundsPerSecond: 2,
  melee: false,
  weight: 1,
  ammoType: lightAmmoClip,
};

const readyPistol1: ActionDetails = {
  id: "14ecb1fa-fd66-43e8-967f-f89fe17e347b",
  readyWeapon: pistol1,
  displayName: "Ready/Reload Semi-automatic Pistol",
  description: `Readies your equipped semi-automatic pistol.`,
  targetType: TargetType.self,
  utilizes: {
    interaction: true,
  },
  duration: 2,
  cooldown: 0,
  maxHelpers: 0,
};
pistol1.actions?.push(readyPistol1);

const pdw: TechDetails = {
  id: "3ae9fda3-d30c-4895-a97a-3791ebfd38d7",
  tier: 2,
  roles: [
    Role.gunner,
    Role.infiltrator,
    Role.socialite,
    Role.hacker,
    Role.medic,
  ],
  weaponSkill: "lightGuns",
  displayName: "PDW",
  isEquippable: true,
  isEquipment: true,
  description:
    "PDW's are larger, fully automatic light guns. They can be concealed when not readied at a penalty of -2.",
  maxEffectiveRange: 40,
  minEffectiveRange: 3,
  price: 2500,
  actions: [],
  damage: {
    [DamageType.kinetic]: {
      multiplier: 4,
    },
  },
  ammo: 3,
  poolModifier: {
    multiplier: 1.1,
  },
  melee: false,
  weight: 3,
  ammoType: lightAmmoClip,
  roundsPerSecond: 5,
};

const readyPdw: ActionDetails = {
  id: "44fe57a6-0a67-414b-b3ab-2cb003d0edbc",
  readyWeapon: pdw,
  displayName: "Ready/Reload PDW",
  description: `Readies your equipped PDW.`,
  targetType: TargetType.self,
  utilizes: {
    interaction: true,
  },
  duration: 3,
  cooldown: 0,
  maxHelpers: 0,
};
pdw.actions?.push(readyPdw);

const rifle1: TechDetails = {
  id: "b1ba283b-fde9-4422-9eaa-3d40f0cf9f0f",
  tier: 1,
  roles: [Role.gunner],
  weaponSkill: "heavyGuns",
  isEquipment: true,
  damage: {
    [DamageType.kinetic]: {
      multiplier: 4,
    },
  },
  ammo: 4,
  recoil: 0.4,
  poolModifier: {
    multiplier: 1.25,
  },
  maxEffectiveRange: 60,
  minEffectiveRange: 4,
  displayName: "Automatic Rifle",
  image: techImage("automatic-rifle"),
  isEquippable: true,
  description:
    "Automatic rifles are heavy and more cumbersome to use than light guns, but deal more damage. They have recoil that needs to be suppressed to achieve maximum effectiveness. They can't be concealed while equipped.",
  price: 1000,
  actions: [suppressRecoil],
  weight: 5,
  ammoType: heavyAmmoClip,
  roundsPerSecond: 5,
};

const readyRifle1: ActionDetails = {
  id: "7ef184cc-8395-43ab-81e4-99fa17f907e9",
  readyWeapon: rifle1,
  displayName: "Ready/Reload Automatic Rifle",
  description: `Readies your equipped automatic rifle.`,
  targetType: TargetType.self,
  utilizes: {
    interaction: true,
  },
  duration: 4,
  cooldown: 0,
  maxHelpers: 0,
};
rifle1.actions?.push(readyRifle1);

const machineGun: TechDetails = {
  id: "3d34828c-ed2b-4610-9bc3-cb4f6c83a096",
  tier: 2,
  roles: [Role.gunner],
  weaponSkill: "heavyGuns",
  isEquipment: true,
  damage: {
    [DamageType.kinetic]: {
      multiplier: 5,
    },
  },
  ammo: 8,
  recoil: 0.6,
  poolModifier: {
    multiplier: 1.2,
  },
  maxEffectiveRange: 80,
  minEffectiveRange: 6,
  displayName: "Machine Gun",
  isEquippable: true,
  description:
    "Machine guns are very heavy, cumbersome and slow to start, but deal huge amounts of damage for long periods of time. They have huge recoil that needs to be suppressed to achieve maximum effectiveness. They can't be concealed.",
  price: 5000,
  actions: [suppressRecoil],
  weight: 15,
  ammoType: heavyAmmoClip,
  roundsPerSecond: 10,
};

const readyMachineGun: ActionDetails = {
  id: "748c8558-eb90-4ab4-8b38-683ced49faf2",
  readyWeapon: machineGun,
  displayName: "Ready/Reload Machine Gun",
  description: `Readies your equipped machine gun.`,
  targetType: TargetType.self,
  utilizes: {
    interaction: true,
  },
  duration: 6,
  cooldown: 0,
  maxHelpers: 0,
};
machineGun.actions?.push(readyMachineGun);

const knife1: TechDetails = {
  id: "926adb6a-a0fe-4c11-8404-a18eb0ed7470",
  tier: 1,
  roles: [Role.bruiser],
  weaponSkill: "handToHand",
  displayName: "Knife",
  image: techImage("knife"),
  isEquippable: true,
  isEquipment: true,
  range: 0.5,
  weight: 0.25,
  description:
    "Carbon composite combat knife. Knives are small melee weapons that can be concealed when not readied.",
  price: 100,
  damage: {
    [DamageType.kinetic]: {
      multiplier: 3,
    },
  },
  melee: true,
  actions: [],
};

const readyKnife1: ActionDetails = {
  id: "f5b8853d-995a-47f6-a71c-f4f93662a70b",
  readyWeapon: knife1,
  displayName: "Ready Knife",
  description: `Readies your equipped knife.`,
  targetType: TargetType.self,
  utilizes: {
    interaction: true,
  },
  duration: 1,
  cooldown: 0,
  maxHelpers: 0,
};
knife1.actions?.push(readyKnife1);

const sword: TechDetails = {
  id: "9a4aea9f-1069-4c43-b9a2-3e81aefb786d",
  tier: 1,
  roles: [Role.bruiser],
  weaponSkill: "handToHand",
  displayName: "Sword",
  image: techImage("sword"),
  isEquippable: true,
  isEquipment: true,
  range: 1,
  description:
    "Carbon composite sword. Swords are large melee weapons that can't be concealed while equipped.",
  price: 500,
  weight: 2,
  damage: {
    [DamageType.kinetic]: {
      multiplier: 4,
    },
  },
  melee: true,
  actions: [],
};

const readySword: ActionDetails = {
  id: "476b1d7c-bd96-42d4-882c-384e87899dd2",
  readyWeapon: sword,
  displayName: "Ready Sword",
  description: `Readies your equipped sword.`,
  targetType: TargetType.self,
  utilizes: {
    interaction: true,
  },
  duration: 2,
  cooldown: 0,
  maxHelpers: 0,
};
sword.actions?.push(readySword);

const flameSword: TechDetails = {
  id: "d9f9b675-e169-4ecd-b1ec-d7c4356bbc81",
  tier: 2,
  roles: [Role.bruiser],
  image: techImage("sword"),
  weaponSkill: "handToHand",
  displayName: "Flame Sword",
  isEquippable: true,
  isEquipment: true,
  range: 1,
  description:
    "Flame swords channel a thin stream of plasma into an edge which deals thermal damage. They can't be concealed while equipped.",
  price: 3000,
  weight: 2,
  damage: {
    [DamageType.kinetic]: {
      multiplier: 1,
    },
    [DamageType.thermal]: {
      multiplier: 3,
    },
  },
  melee: true,
  actions: [],
};

const readyFlameSword: ActionDetails = {
  id: "7ee84fe5-46df-42c3-8237-8b0fa3096080",
  readyWeapon: flameSword,
  displayName: "Ready Flame Sword",
  description: `Readies your equipped flame sword.`,
  targetType: TargetType.self,
  utilizes: {
    interaction: true,
  },
  duration: 3,
  cooldown: 0,
  maxHelpers: 0,
};
flameSword.actions?.push(readyFlameSword);

const stunBaton: TechDetails = {
  id: "8bb03893-9ea0-4ad5-a36e-eb56373243e1",
  tier: 1,
  roles: [Role.bruiser],
  weaponSkill: "handToHand",
  displayName: "Stun Baton",
  image: techImage("stun-baton"),
  isEquippable: true,
  isEquipment: true,
  range: 1,
  description:
    "An electrified baton that deals electrical and kinetic damage. It also grants that taze ability that can stun an opponent.",
  price: 200,
  damage: {
    [DamageType.kinetic]: {
      multiplier: 1,
    },
    [DamageType.electrical]: {
      multiplier: 1,
    },
  },
  melee: true,
  actions: [],
};

const readyStunBaton: ActionDetails = {
  id: "0f6737ab-918e-4e3b-9c73-05084a86753c",
  readyWeapon: stunBaton,
  displayName: "Ready Stun Baton",
  description: `Readies your equipped stun baton.`,
  targetType: TargetType.self,
  utilizes: {
    interaction: true,
  },
  duration: 1,
  cooldown: 0,
  maxHelpers: 0,
};
stunBaton.actions?.push(readyStunBaton);

const taze: ActionDetails = {
  id: "2a68ba42-a32b-4bcb-8899-1692c134e32d",
  displayName: "Taze",
  description: `Taze your target.`,
  requiredWeapon: stunBaton,
  melee: true,
  skill: "handToHand",
  targetType: TargetType.character,
  maxTargets: 1,
  conditions: {
    [Condition.stunned]: {
      multiplier: 1,
      duration: 10,
    },
  },
  damage: {
    [DamageType.electrical]: {
      multiplier: 1,
    },
  },
  utilizes: {
    interaction: true,
  },
  duration: 2,
  cooldown: 60,
  maxHelpers: 0,
  lineOfSight: true,
  logFormat: {
    base: "Taze ",
    success:
      "{targetDisplayName} for a total of {rollResult} points of stun and {damage} damage",
    failure: "{targetDisplayName} but failed",
  },
};
stunBaton.actions?.push(taze);

const firstAid: ActionDetails = {
  id: "0c1e2ab7-9755-4526-8dba-d017e9049111",
  skill: "medical",
  displayName: "First Aid",
  description: "Stabilizes a critically injured person for X hours.",
  targetType: TargetType.character,
  melee: true,
  maxTargets: 1,
  utilizes: {
    interaction: true,
  },
  duration: 10,
  cooldown: 0,
  price: 100,
  lineOfSight: true,
  clearConditions: [Condition.unstable],
  logFormat: {
    base: "apply First Aid to ",
    success: "{targetDisplayName} for a total of {rollResult} successes",
    failure: "{targetDisplayName} but failed",
  },
};
const firstAidAttention: ActionDetails = {
  ...firstAid,
  id: "829fa4c3-15bb-4ce4-9cb7-08391f3334ef",
  utilizes: {
    attention: true,
  },
  price: 0,
};

const repairDrone: ActionDetails = {
  id: "3edbfd1c-e1ea-4cac-9922-d41361baa0c1",
  skill: "engineering",
  displayName: "Repair Drone",
  description: "Repairs X * 10 drone shielding.",
  targetType: TargetType.character,
  targetDrone: 2,
  melee: true,
  droneHealing: 10,
  maxTargets: 1,
  utilizes: {
    interaction: true,
  },
  duration: 5,
  cooldown: 5,
  price: 0,
  lineOfSight: true,
  logFormat: {
    base: "Repair ",
    success:
      "{targetDisplayName} for a total of {droneHealing} points of shield repair",
    failure: "{targetDisplayName} but failed",
  },
};

const selfDestruct: ActionDetails = {
  id: "0b67eeab-71b3-402f-927e-2da83bdbfa34",
  displayName: "Self Destruct",
  description: "Self destruct.",
  targetType: TargetType.character,
  melee: true,
  maxTargets: 1,
  maxHelpers: 0,
  utilizes: {
    interaction: true,
  },
  duration: 1,
  cooldown: 3,
  poolModifier: {
    multiplier: 1,
    flat: 2,
  },
  skill: "engineering",
  damage: {
    [DamageType.kinetic]: {
      multiplier: 5,
    },
  },
};

const scan: ActionDetails = {
  id: "6387148b-e7e3-43c9-845d-1dc965ce06d6",
  displayName: "Scan",
  description: `Reveal technological capabilities of the target.`,
  targetType: TargetType.character,
  targetDrone: 1,
  minTargets: 1,
  maxTargets: 1,
  utilizes: {
    attention: true,
  },
  duration: 1,
  cooldown: 3,
  skill: "hacking",
  isFirewallable: true,
  price: 200,
  logFormat: {
    base: "Scan ",
    success: "{targetDisplayName} for a total of {rollResult} successes",
    failure: "{targetDisplayName} but failed",
  },
};

const hackCommunications: ActionDetails = {
  id: "8874f528-5025-46f9-83ce-79ff731f0ff7",
  displayName: "Jam Communications",
  description: `Prevent up to 10 targets from being able to communicate via a network.`,
  targetType: TargetType.character,
  targetDrone: 1,
  minTargets: 0,
  maxTargets: 10,
  utilizes: {
    attention: true,
  },
  duration: 1,
  cooldown: 45,
  skill: "hacking",
  isFirewallable: true,
  price: 1000,
  conditions: {
    [Condition.jammed]: {
      duration: 30,
      multiplier: 1,
    },
  },
  logFormat: {
    base: "Hack communications of ",
    success: "{targetDisplayName} for a total of {rollResult} successes",
    failure: "{targetDisplayName} but failed",
  },
};

const camouflage: ActionDetails = {
  id: "56184315-0ea6-4ded-8fd6-89c0d3addecc",
  displayName: "Active Camouflage",
  description: `While active you can only be detected by characters who roll more than X successes on a Perception skill challenge.`,
  targetType: TargetType.self,
  utilizes: {
    attention: true,
    interaction: true,
  },
  duration: Infinity,
  cooldown: 10,
  stealth: {
    multiplier: 1,
  },
  skill: "stealth",
  price: 200,
  logFormat: {
    base: "Active Camouflage ",
    success: "for a total of {rollResult} successes",
    failure: "but failed",
  },
};

const hide: ActionDetails = {
  id: "2c94d9a1-a073-4bbb-a4ae-5ee18fcca8af",
  displayName: "Hide",
  description: ``,
  targetType: TargetType.self,
  utilizes: {
    attention: true,
    movement: true,
    interaction: true,
  },
  duration: Infinity,
  cooldown: 0,
  stealth: {
    multiplier: 1,
  },
  skill: "stealth",
  price: 200,
  logFormat: {
    base: "Hide ",
    success: "for a total of {rollResult} successes",
    failure: "but failed",
  },
};

const search: ActionDetails = {
  id: "8ec9e1c9-66bc-444a-a7c7-d5dbb5e9760e",
  displayName: "Search",
  description: ``,
  targetType: TargetType.self,
  utilizes: {
    attention: true,
  },
  duration: Infinity,
  cooldown: 0,
  search: {
    multiplier: 1,
  },
  skill: "perception",
  price: 200,
  logFormat: {
    base: "Search ",
    success: "for a total of {rollResult} successes",
    failure: "but failed",
  },
};

const interferenceConfused: ActionDetails = {
  id: "e5e38f12-8ba1-40f5-8d73-d12c8ec52ff9",
  displayName: "Interference (Confused)",
  description: `X points of confusion damage for 5 seconds.`,
  targetType: TargetType.character,
  targetDrone: 1,
  minTargets: 1,
  maxTargets: 1,
  utilizes: {
    attention: true,
  },
  duration: 1,
  cooldown: 4,
  sharedCooldown: "interference",
  skill: "hacking",
  isFirewallable: true,
  price: 0,
  conditions: {
    [Condition.confused]: {
      duration: 5,
      multiplier: 1,
    },
  },
  logFormat: {
    base: "run Interference (Confused) against ",
    success:
      "{targetDisplayName} for a total of {rollResult} points of Confused",
    failure: "{targetDisplayName} but failed",
  },
};

const interferenceDazed: ActionDetails = {
  id: "9c898699-9b03-4aa0-a833-ba62bb1b51d6",
  displayName: "Interference (Crippled)",
  description: `X points of cripple damage for 5 seconds.`,
  targetType: TargetType.character,
  targetDrone: 1,
  minTargets: 1,
  maxTargets: 1,
  utilizes: {
    attention: true,
  },
  duration: 1,
  cooldown: 4,
  sharedCooldown: "interference",
  skill: "hacking",
  isFirewallable: true,
  price: 0,
  conditions: {
    [Condition.crippled]: {
      duration: 5,
      multiplier: 1,
    },
  },
  logFormat: {
    base: "run Interference (Crippled) against ",
    success:
      "{targetDisplayName} for a total of {rollResult} points of Crippled",
    failure: "{targetDisplayName} but failed",
  },
};

const interferenceParalyzed: ActionDetails = {
  id: "cdbd9778-ec76-4e34-a2cc-1905d077975c",
  displayName: "Interference (Clumsy)",
  description: `X points of clumsy damage for 5 seconds.`,
  targetType: TargetType.character,
  targetDrone: 1,
  minTargets: 1,
  maxTargets: 1,
  utilizes: {
    attention: true,
  },
  duration: 1,
  cooldown: 4,
  sharedCooldown: "interference",
  skill: "hacking",
  isFirewallable: true,
  price: 0,
  conditions: {
    [Condition.clumsy]: {
      duration: 5,
      multiplier: 1,
    },
  },
  logFormat: {
    base: "run Interference (Clumsy) against ",
    success: "{targetDisplayName} for a total of {rollResult} points of Clumsy",
    failure: "{targetDisplayName} but failed",
  },
};

const shutdown: ActionDetails = {
  id: "7db3e48e-e496-4001-b9fb-c304cc7a2313",
  displayName: "Shutdown",
  description: `X points of stun damage for 5 seconds.`,
  targetType: TargetType.character,
  targetDrone: 1,
  minTargets: 1,
  maxTargets: 1,
  utilizes: {
    attention: true,
  },
  duration: 1,
  cooldown: 10,
  skill: "hacking",
  isFirewallable: true,
  price: 1000,
  conditions: {
    [Condition.stunned]: {
      duration: 5,
      multiplier: 1,
    },
  },
  logFormat: {
    base: "run Shutdown against ",
    success: "{targetDisplayName} for a total of {rollResult} points of Stun",
    failure: "{targetDisplayName} but failed",
  },
};

const overheat: ActionDetails = {
  id: "c2891bdd-8b85-4b79-aeee-2c0e9e82e1f8",
  displayName: "Overheat",
  description: `X points of burning damage for 5 seconds.`,
  targetType: TargetType.character,
  targetDrone: 1,
  minTargets: 1,
  maxTargets: 1,
  utilizes: {
    attention: true,
  },
  duration: 1,
  cooldown: 7,
  skill: "hacking",
  isFirewallable: true,
  price: 200,
  conditions: {
    [Condition.burning]: {
      duration: 5,
      multiplier: 1,
    },
  },
  logFormat: {
    base: "run Overheat against",
    success:
      "{targetDisplayName} for a total of {rollResult} points of Burning",
    failure: "{targetDisplayName} but failed",
  },
};

const engage: ActionDetails = {
  id: "4b582102-927c-43f3-987c-16b0f5a40897",
  displayName: "Engage",
  description: `Engage a target in melee combat, watching closely for any opportunity to strike. If the target attempts to move away while engaged you will gain X successes on your melee attack.`,
  skill: "perception",
  melee: true,
  engage: {
    multiplier: 1,
  },
  targetType: TargetType.self,
  maxTargets: 1,
  maxHelpers: 0,
  utilizes: {
    attention: true,
  },
  duration: Infinity,
  cooldown: 0,
  price: 500,
  logFormat: {
    base: "Engage their target ",
    success: "for a total of {rollResult} successes",
    failure: "but failed",
  },
};

const charge: ActionDetails = {
  id: "852bec76-63f9-4f54-866b-d62d6a8bd85a",
  displayName: "Charge",
  description: `Move up to 3 times your combat movement speed towards a target and hit them for X kinetic damage.`,
  targetType: TargetType.character,
  targetDrone: 1,
  movementMultiplier: 3,
  skill: "athletics",
  damage: {
    [DamageType.kinetic]: {
      multiplier: 1,
    },
  },
  maxTargets: 1,
  minTargets: 1,
  maxHelpers: 0,
  utilizes: {
    movement: true,
    interaction: true,
  },
  duration: 1,
  cooldown: 20,
  price: 1000,
  lineOfSight: true,
  logFormat: {
    base: "charge at ",
    success: "{targetDisplayName} for a total of {rollResult} successes",
    failure: "{targetDisplayName} but failed",
  },
};

export function hasSufficientFreeCapacity(
  character: FullCharacter,
  tech: TechDetails
) {
  const modifiers = tech.statModifiers
    ?.map((mod) => ({
      [mod.stat]: mod.modifier,
    }))
    .reduce((a, b) => ({ ...a, ...b }), {});

  if (!modifiers) {
    return true;
  }

  return (
    [
      "HeadCapacity",
      "TorsoCapacity",
      "LegsCapacity",
      "ShellCapacity",
      "ArmsCapacity",
    ].every((key) => {
      if (!modifiers[`current${key}` as Stat]) {
        return true;
      }
      return (
        character.stats[`max${key}` as Stat] -
          character.stats[`current${key}` as Stat] >=
        modifiers[`current${key}` as Stat]
      );
    }) &&
    (character.tech[tech.id]?.equippedCount || 0) <
      (tech.maxEquippable ?? Infinity)
  );
}

const headTier1: TechDetails = {
  id: "667e0a65-de34-43c2-bad2-ddb7740d6bc1",
  tier: 1,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Head Cybernetics Platform (Tier 1)",
  image: techImage("platforms"),
  description: `This procedure replaces part of your biology with a cybernetics platform for head-based augmentation. These augmentations typically improve your senses, cognitive capability or ability to interface with computer networks. This augmentation also allows you to communicate with anyone you know wirelessly and non-verbally.`,
  statModifiers: [{ stat: "maxHeadCapacity", modifier: 20 }],
  price: 0,
};

const armsTier1: TechDetails = {
  id: "7668e92f-dc7a-4fa2-bc69-e33adea77876",
  tier: 1,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Arms Cybernetics Platform (Tier 1)",
  image: techImage("platforms"),
  description: `This procedure replaces part of your biology with a cybernetics platform for arms-based augmentation. These augmentations typically improve your strength, dexterity or ability to interact with the physical world.`,
  statModifiers: [{ stat: "maxArmsCapacity", modifier: 20 }],
  price: 0,
};

const legsTier1: TechDetails = {
  id: "7449968d-35a3-418b-a30f-5457bedceb00",
  tier: 1,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Legs Cybernetics Platform (Tier 1)",
  image: techImage("platforms"),
  description: `This procedure replaces part of your biology with a cybernetics platform for legs-based augmentation. These augmentations typically improve your strength, agility or ability to move through the physical world.`,
  statModifiers: [{ stat: "maxLegsCapacity", modifier: 20 }],
  price: 0,
};

const torsoTier1: TechDetails = {
  id: "516efe6d-92cc-46d4-8469-9958b0844f1f",
  tier: 1,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Torso Cybernetics Platform (Tier 1)",
  image: techImage("platforms"),
  description: `This procedure replaces part of your biology with a cybernetics platform for torso-based augmentation. These augmentations typically improve your stamina or survivability.`,
  statModifiers: [{ stat: "maxTorsoCapacity", modifier: 20 }],
  price: 0,
};

const shellTier1: TechDetails = {
  id: "571d4bec-4297-49ee-81c4-31f9da3c58ca",
  tier: 1,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Shell Cybernetics Platform (Tier 1)",
  image: techImage("platforms"),
  description: `This procedure replaces part of your biology with a cybernetics platform for skin-based augmentation. These augmentations typically improve your appearance, senses or protection from the outside world.`,
  statModifiers: [{ stat: "maxShellCapacity", modifier: 20 }],
  price: 0,
};

// const pneumaticLegs1: TechDetails = {
//   id: "73e15dbc-42e5-4448-928a-649614e8cf4f",
//   tier: 1,
//   roles: [
//     Role.bruiser,
//     Role.gunner,
//     Role.infiltrator,
//     Role.engineer,
//     Role.medic,
//   ],
//   displayName: "Pneumatic Legs",
//   maxEquippable: 1,
//   isEquippable: true,
//   description:
//     "Adds powerful pneumatics to your legs to better propel your movements.",
//   statModifiers: [
//     { stat: "currentLegsCapacity", modifier: 10 },
//     { stat: "athletics", modifier: 3 },
//   ],
//   price: 0,
//   weight: 5,
// };
const cardiovascularBoost: TechDetails = {
  id: "73e15dbc-42e5-4448-928a-649614e8cf4f",
  tier: 1,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Synthetic Vascular System (Tier 1)",
  image: techImage("cardiovascular-system"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "A synthetic support network for your vascular system that improves your endurance and power.",
  statModifiers: [
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: "currentLegsCapacity", modifier: 5 },
    { stat: "athletics", modifier: 2 },
    { stat: "physicalPower", modifier: 1 },
  ],
  price: 0,
  weight: 3,
};

const reinforcedMuscles: TechDetails = {
  id: "a5867f8b-1ec1-4090-ae1e-50a0cae04264",
  tier: 1,
  roles: [Role.bruiser, Role.gunner, Role.socialite],
  displayName: "Hulking Form (Tier 1)",
  image: techImage("hulking-form"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Modify your body to add bulk and/or height, increasing your power and intimidating appearance.",
  statModifiers: [
    { stat: "currentLegsCapacity", modifier: 5 },
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "physicalPower", modifier: 2 },
    { stat: "intimidation", modifier: 1 },
  ],
  price: 0,
  weight: 5,
};

const threatSubroutines: TechDetails = {
  id: "75d32f73-87c2-493c-b81c-65834bd5b267",
  tier: 1,
  roles: [Role.socialite, Role.gunner],
  displayName: "Ferocity Hormones Amplifier (Tier 1)",
  image: techImage("hormone-module"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "A module that increases the strength of your aggression hormones, spurring you to behave more ferociously.",
  statModifiers: [
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "intimidation", modifier: 2 },
    { stat: "heavyGuns", modifier: 1 },
  ],
  price: 0,
  weight: 0.5,
};

const heavyRecoilSuppression1: TechDetails = {
  id: "43c43135-2eea-451e-a4ee-f372c076b071",
  tier: 1,
  roles: [Role.gunner],
  displayName: "Advanced Weapon Targeting System (Tier 1)",
  image: techImage("targeting-reticle"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "A targeting and coordination system that supports the operation of complex firearms.",
  statModifiers: [
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "heavyGuns", modifier: 2 },
    { stat: "lightGuns", modifier: 1 },
  ],
  price: 0,
  weight: 3,
};

const lightAimAssist1: TechDetails = {
  id: "ed325d95-4a0f-4352-9038-99ef5d4281b1",
  tier: 1,
  roles: [Role.gunner, Role.infiltrator, Role.hacker, Role.medic],
  displayName: "Precision Movement Actuators (Tier 1)",
  image: techImage("joint"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "A set of precision actuators that allow for greater control over movement.",
  statModifiers: [
    { stat: "currentLegsCapacity", modifier: 5 },
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "lightGuns", modifier: 2 },
    { stat: "stealth", modifier: 1 },
  ],
  price: 0,
  weight: 3,
};

const shockAbsorbers1: TechDetails = {
  id: "28ea8085-984e-4db2-9531-f661e87211d7",
  tier: 1,
  roles: [Role.infiltrator],
  displayName: "Active Camouflage (Tier 1)",
  image: techImage("cloaking"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Allows your shell to project different colours and patterns, giving you the ability to camouflage yourself within your environment.",
  statModifiers: [
    { stat: "currentShellCapacity", modifier: 10 },
    { stat: "currentLegsCapacity", modifier: 5 },
    { stat: "stealth", modifier: 2 },
    { stat: "deception", modifier: 1 },
  ],
  price: 0,
  weight: 5,
  actions: [camouflage],
};

const sinceritySubroutines: TechDetails = {
  id: "2c309d95-7371-402f-8e51-eaad6af787ee",
  tier: 1,
  roles: [Role.socialite],
  displayName: "Emotional Feedback Loop (Tier 1)",
  image: techImage("emotional"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Subconscious microprocessor array that monitors target emotions and makes microadjustments to your delivery for maximum effect.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "deception", modifier: 2 },
    { stat: "performance", modifier: 1 },
  ],
  price: 0,
  weight: 0.5,
};

const striking1: TechDetails = {
  id: "2ee8f67d-d1fe-473b-bf42-9ae98fc4c844",
  tier: 1,
  roles: [Role.socialite],
  displayName: "Coordination Acceleration Implant (Tier 1)",
  image: techImage("nervous-system"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Fiber optic implants that accelerate your nervous system's ability to coordinate your body.",
  statModifiers: [
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: "currentLegsCapacity", modifier: 5 },
    { stat: "performance", modifier: 2 },
    { stat: "driving", modifier: 1 },
  ],
  price: 0,
  weight: 3,
};

const autoLink1: TechDetails = {
  id: "2001781a-2e67-4d15-a5e7-9d938cc9cb50",
  tier: 1,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Auto Link (Tier 1)",
  image: techImage("holographic-road"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "A high speed link with the vehicle you're driving that allows you to better take advantage of the vehicle's sensors and automatically coordinate with the vehicle's controls.",
  statModifiers: [
    { stat: "currentLegsCapacity", modifier: 5 },
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "driving", modifier: 2 },
    { stat: "engineering", modifier: 1 },
  ],
  price: 0,
  weight: 0.5,
};

const droneLink1: TechDetails = {
  id: "2d36573c-076a-44e2-914d-1ff78f6888c4",
  tier: 1,
  roles: [Role.engineer],
  displayName: "Drone Link (Tier 1)",
  image: techImage("drone-link"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Gives you the ability to pilot drones and an understanding of machinery's inner workings.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "currentTorsoCapacity", modifier: 10 },
    { stat: "engineering", modifier: 2 },
    { stat: "demolition", modifier: 1 },
  ],
  price: 0,
  weight: 0.5,
};

const explosivesTargeting1: TechDetails = {
  id: "c96fece2-f7eb-4597-98c6-d923e6ac27fe",
  tier: 1,
  roles: [Role.engineer, Role.infiltrator],
  displayName: "Explosives Launcher System (Tier 1)",
  image: techImage("explosion"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Adds a system to your body capable of launching explosive rounds.",
  statModifiers: [
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: "currentArmsCapacity", modifier: 10 },
    { stat: "demolition", modifier: 2 },
    { stat: "security", modifier: 1 },
  ],
  price: 200,
  weight: 0.5,
};

const securityTooling1: TechDetails = {
  id: "eff2bc03-bc3e-4d94-b443-2d9ca43939a8",
  tier: 1,
  roles: [Role.hacker, Role.infiltrator],
  displayName: "Security Tools (Tier 1)",
  image: techImage("security-tools"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Adds tools and a code breaking module for overcoming physical and digital security.",
  statModifiers: [
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "security", modifier: 2 },
    { stat: "hacking", modifier: 1 },
  ],
  price: 0,
  weight: 1,
};

const processingCore1: TechDetails = {
  id: "88e77f2c-616d-415d-83b9-3b72b2821c67",
  tier: 1,
  roles: [Role.hacker],
  displayName: "Hacking Processor (Tier 1)",
  image: techImage("processor"),
  isEquippable: true,
  maxEquippable: 1,
  description: "A processor that allows you to hack networks and systems.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 10 },
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "hacking", modifier: 2 },
    { stat: "research", modifier: 1 },
  ],
  actions: [boostFirewall, breachFirewall],
  price: 0,
  weight: 1,
};

const dataLink1: TechDetails = {
  id: "b1dc1781-ce4b-4cb5-acb7-832459d4403a",
  tier: 1,
  roles: [Role.socialite, Role.infiltrator],
  displayName: "Data Link (Tier 1)",
  image: techImage("data-link"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Gives you the capability to automatically scan through vast amounts of information mentally to find the facts you're looking for.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: "research", modifier: 2 },
    { stat: "persuasion", modifier: 1 },
  ],
  price: 0,
  weight: 0.5,
};

const dialecticSubroutines: TechDetails = {
  id: "fd8e684f-3a8e-43e6-bc1e-cfbb6eb92b8c",
  tier: 1,
  roles: [Role.socialite, Role.infiltrator],
  displayName: "Logic Modeling Subsystem (Tier 1)",
  image: techImage("logic"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "A computational module specifically designed to model logic, useful for both rhetoric and investigation.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: "persuasion", modifier: 2 },
    { stat: "environmentalAnalysis", modifier: 1 },
  ],
  price: 0,
  weight: 0.5,
};

const forensicAnalyser1: TechDetails = {
  id: "9ca31ebf-642f-40ed-ba57-1a801aa2e580",
  tier: 1,
  roles: [Role.infiltrator, Role.socialite],
  displayName: "Forensic Analyser (Tier 1)",
  image: techImage("forensic"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Adds forensic mapping equipment that allows you to model your environment.",
  statModifiers: [
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "environmentalAnalysis", modifier: 2 },
    { stat: "perception", modifier: 1 },
  ],
  price: 0,
  weight: 3,
};

// const cyberEyes1: TechDetails = {
//   id: "65301031-a31f-499d-b9d4-182f9907c7ee",
//   tier: 1,
//   roles: [Role.infiltrator],
//   displayName: "Cybernetic Eyes",
//   maxEquippable: 1,
//   isEquippable: true,
//   description:
//     "Replaces your organic eyes with better performing cybernetics. These allow you to stream your vision over the network and provide night-vision in low light.",
//   statModifiers: [
//     { stat: "currentHeadCapacity", modifier: 10 },
//     { stat: "perception", modifier: 3 },
//   ],
//   price: 0,
//   weight: 0.5,
// };

const cyberEyes1: TechDetails = {
  id: "65301031-a31f-499d-b9d4-182f9907c7ee",
  tier: 1,
  roles: [Role.infiltrator, Role.socialite],
  displayName: "Extrasensory Array (Tier 1)",
  image: techImage("sensors"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Adds an array of electromagnetic and chemical sensors that allow you to detect things beyond normal human senses.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "perception", modifier: 2 },
    { stat: "empathy", modifier: 1 },
  ],
  price: 0,
  weight: 0.5,
};

const theoryOfMind1: TechDetails = {
  id: "4045cead-784d-427f-82ab-80edd9552bbd",
  tier: 1,
  roles: [Role.medic, Role.socialite],
  displayName: "Biological Scanner (Tier 1)",
  image: techImage("biological-scanner"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Adds a biological scanner and a real-time medical diagnostic info feed.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "empathy", modifier: 2 },
    { stat: "medical", modifier: 1 },
  ],
  price: 0,
  weight: 1,
};

const surgicalTooling1: TechDetails = {
  id: "3e965308-6e23-4e00-ae65-1f9727c04471",
  tier: 1,
  roles: [Role.medic, Role.bruiser],
  displayName: "Automatic Medical Tools (Tier 1)",
  image: techImage("medical-tools"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Cutting-edge medical technology that allows you to perform higher quality field care using your attention instead of your interaction. Knowing how to heal can also be used to hurt.",
  statModifiers: [
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "medical", modifier: 2 },
    { stat: "handToHand", modifier: 1 },
  ],
  price: 0,
  actions: [firstAid, firstAidAttention],
  weight: 1,
};

const repairCybernetics: ActionDetails = {
  id: "9e1bcecb-5afe-4a50-977c-282e76cd89ee",
  skill: "medical",
  displayName: "Repair Cybernetics",
  description: "Repairs X * 10 shielding.",
  targetType: TargetType.character,
  melee: true,
  shieldHealing: 10,
  maxTargets: 1,
  utilizes: {
    interaction: true,
  },
  duration: 5,
  cooldown: 5,
  price: 0,
  lineOfSight: true,
  logFormat: {
    base: "Repair ",
    success:
      "{targetDisplayName} for a total of {shieldHealing} points of shield repair",
    failure: "{targetDisplayName} but failed",
  },
};

const repairCyberneticsAttention: ActionDetails = {
  ...repairCybernetics,
  id: "394004d8-f4e4-4723-8d85-52399a8611a0",
  utilizes: {
    attention: true,
  },
  requirements: [surgicalTooling1],
};
const cyberneticsRepairKit: TechDetails = {
  id: "504f5ee4-3932-414d-8ac7-a2204ca0e6a9",
  tier: 1,
  roles: [Role.medic],
  displayName: "Cybernetics Repair Kit",
  image: techImage("cybernetics-repair-kit"),
  isEquippable: true,
  isEquipment: true,
  maxEquippable: 4,
  description:
    "A kit of repair supplies that allows medics to perform field repairs on human cybernetics. Consumed on use.",
  price: 100,
  actions: [repairCybernetics, repairCyberneticsAttention],
  weight: 1,
};

const combatDrugs: TechDetails = {
  id: "f8bafe84-9b66-4d2c-90eb-b908361fdd50",
  tier: 1,
  roles: [Role.medic],
  displayName: "Combat Drugs Kit",
  image: techImage("drugs-kit"),
  isEquippable: true,
  isEquipment: true,
  description: `This kit contains a variety of combat drugs and the tools necessary to apply them. Consumed on use.`,
  actions: [],
  price: 20,
  weight: 0.5,
};

const combatDrugsFocused: ActionDetails = {
  id: "ab587efa-0e40-41dc-add0-d2374bfdbdac",
  displayName: "Combat Drugs (Focused)",
  description: `Gives a willing target X points of Focused for 30 seconds, followed by 3 points of Confused for 30 seconds.`,
  targetType: TargetType.character,
  minTargets: 1,
  maxTargets: 1,
  melee: true,
  lineOfSight: true,
  utilizes: {
    interaction: true,
  },
  duration: 1,
  cooldown: 10,
  sharedCooldown: "combatDrugs",
  skill: "medical",
  price: 0,
  consumes: combatDrugs,
  damage: {
    [DamageType.organic]: {
      multiplier: 0,
      flat: 1,
    },
  },
  conditions: {
    [Condition.focused]: {
      duration: 30,
      flat: 3,
      multiplier: 1,
    },
    [Condition.confused]: {
      duration: 60,
      flat: 3,
    },
  },
  logFormat: {
    base: "apply Combat Drugs (Focused) to ",
    success: "{targetDisplayName} with {rollResult} successes",
    failure: "{targetDisplayName} but failed",
  },
};
const combatDrugsFocusedAttention: ActionDetails = {
  ...combatDrugsFocused,
  id: "73607c23-37ee-4048-9402-c250f3bcc1a3",
  utilizes: {
    attention: true,
  },
  requirements: [combatDrugs],
};
combatDrugs.actions!.push(combatDrugsFocused);
combatDrugs.actions!.push(combatDrugsFocusedAttention);

const combatDrugsStimulated: ActionDetails = {
  id: "7b727ed2-bbae-4207-a4ac-61f1803a4c0c",
  displayName: "Combat Drugs (Stimulated)",
  description: `Gives a willing target X points of Stimulated for 30 seconds, followed by 3 points of Clumsy for 30 seconds.`,
  targetType: TargetType.character,
  minTargets: 1,
  maxTargets: 1,
  melee: true,
  lineOfSight: true,
  utilizes: {
    interaction: true,
  },
  duration: 1,
  cooldown: 10,
  sharedCooldown: "combatDrugs",
  skill: "medical",
  price: 0,
  consumes: combatDrugs,
  damage: {
    [DamageType.organic]: {
      multiplier: 0,
      flat: 1,
    },
  },
  conditions: {
    [Condition.stimulated]: {
      duration: 30,
      flat: 3,
      multiplier: 1,
    },
    [Condition.clumsy]: {
      duration: 60,
      flat: 3,
    },
  },
  logFormat: {
    base: "apply Combat Drugs (Stimulated) to ",
    success: "{targetDisplayName} with {rollResult} successes",
    failure: "{targetDisplayName} but failed",
  },
};
const combatDrugsStimulatedAttention: ActionDetails = {
  ...combatDrugsStimulated,
  id: "67133133-490c-4da0-9b20-033a0e628c9f",
  utilizes: {
    attention: true,
  },
  requirements: [surgicalTooling1],
};
combatDrugs.actions!.push(combatDrugsStimulated);
combatDrugs.actions!.push(combatDrugsStimulatedAttention);

const combatDrugsAmped: ActionDetails = {
  id: "ab2167ed-4cc2-4211-95d0-69b5dc6119da",
  displayName: "Combat Drugs (Amped)",
  description: `Gives a willing target X points of Amped for 30 seconds, followed by 3 points of Crippled for 30 seconds.`,
  targetType: TargetType.character,
  minTargets: 1,
  maxTargets: 1,
  melee: true,
  lineOfSight: true,
  utilizes: {
    interaction: true,
  },
  duration: 1,
  cooldown: 10,
  sharedCooldown: "combatDrugs",
  skill: "medical",
  price: 0,
  consumes: combatDrugs,
  damage: {
    [DamageType.organic]: {
      multiplier: 0,
      flat: 1,
    },
  },
  conditions: {
    [Condition.amped]: {
      duration: 30,
      multiplier: 1,
      flat: 3,
    },
    [Condition.crippled]: {
      duration: 60,
      flat: 3,
    },
  },
  logFormat: {
    base: "apply Combat Drugs (Amped) to ",
    success: "{targetDisplayName} with {rollResult} successes",
    failure: "{targetDisplayName} but failed",
  },
};
const combatDrugsAmpedAttention: ActionDetails = {
  ...combatDrugsAmped,
  id: "26646a0e-a494-44e6-8b52-da9d9df5ddeb",
  utilizes: {
    attention: true,
  },
  requirements: [surgicalTooling1],
};
combatDrugs.actions!.push(combatDrugsAmped);
combatDrugs.actions!.push(combatDrugsAmpedAttention);

const emergencyAmphetamines: ActionDetails = {
  id: "4a93d44d-9f8c-4ff6-860b-e3cd8fa30280",
  skill: "medical",
  displayName: "Emergency Adrenaline",
  description: `Gives a critically injured person an extra strength stimulant that forces them to be conscious and active for X minutes.
Can't be used again on the same person for 24 hours.
This action can be performed on yourself even while unconscious.`,
  targetType: TargetType.character,
  melee: true,
  maxTargets: 1,
  utilizes: {
    interaction: true,
  },
  duration: 5,
  cooldown: 30,
  price: 2000,
  lineOfSight: true,
  conditions: {
    [Condition.invigorated]: {
      flat: 1,
      durationMultiplier: 60,
    },
  },
  logFormat: {
    base: "inject Emergency Adrenaline for ",
    success: "{targetDisplayName} for a total of {rollResult} successes",
    failure: "{targetDisplayName} but failed",
  },
};
const emergencyAmphetaminesAttention: ActionDetails = {
  ...emergencyAmphetamines,
  id: "c0501a58-f064-41a4-bd8a-0c18c8276a2d",
  utilizes: {
    attention: true,
  },
  requirements: [surgicalTooling1],
  price: 0,
};

const jackedArms1: TechDetails = {
  id: "fc4c66ac-78c4-4ad3-aec3-c319e405e270",
  tier: 1,
  roles: [Role.infiltrator, Role.bruiser],
  displayName: "Combat Muscle Nanoweave (Tier 1)",
  image: techImage("muscle-nanoweave"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "A nanofiber weave overlaying key combat muscles that provides greater control over movement and better reflexes.",
  statModifiers: [
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "currentLegsCapacity", modifier: 5 },
    { stat: "handToHand", modifier: 2 },
    { stat: "athletics", modifier: 1 },
  ],
  price: 0,
  weight: 5,
};

const improvedFirewall1: TechDetails = {
  id: "09c03221-45d2-4063-82fd-fbc36d02edc1",
  tier: 1,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Improved Firewall (Tier 1)",
  image: techImage("firewall"),
  maxEquippable: 1,
  isEquippable: true,
  description: "Improves your firewall defense.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "firewall", modifier: 2 },
  ],
  price: 0,
};

// const breachFirewallProgram: TechDetails = {
//   id: "2f1db1e1-d20e-4450-8309-e496cd76c66e",
//   tier: 1,
//   roles: [Role.hacker],
//   maxEquippable: 1,
//   displayName: "Breach Firewall Program",
//   isEquippable: true,
//   description: `This program grants you the ability to reduce a target's firewall. Outside of combat this program allows you to attempt to gain access to a network.`,
//   statModifiers: [{ stat: "currentHeadCapacity", modifier: 2 }],
//   actions: [breachFirewall],
//   price: 0,
// };

// const boostFirewallProgram: TechDetails = {
//   id: "04f6a930-fe26-4628-885d-eeeb6512c0a8",
//   tier: 1,
//   roles: [Role.hacker],
//   maxEquippable: 1,
//   displayName: "Boost Firewall Program",
//   isEquippable: true,
//   description: `Boosts target's firewall. Outside of combat this program allows you to attempt to defend networks from attack.`,
//   statModifiers: [{ stat: "currentHeadCapacity", modifier: 2 }],
//   actions: [boostFirewall],
//   price: 0,
// };

const scanProgram: TechDetails = {
  id: "286c3b69-97da-4987-8a1c-e8248a6cee6c",
  tier: 1,
  roles: [Role.hacker],
  maxEquippable: 1,
  displayName: "Scan Program",
  image: techImage("program"),
  isEquippable: true,
  description: `This program allows you to scan a target and reveal what tech they're using. Outside of combat this program allows you to attempt skill challenges that reveal the capabilities and active connections of a network, e.g. it might tell you how many people are inside a building.`,
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 1 }],
  actions: [scan],
  price: 0,
};

const hackCommunicationsProgram: TechDetails = {
  id: "3b90f2df-14aa-41c5-86f0-026614e82a64",
  tier: 2,
  roles: [Role.hacker],
  maxEquippable: 1,
  displayName: "Hack Communications Program",
  image: techImage("program"),
  isEquippable: true,
  description: `Grants the ability to prevent target from being able to communicate via a network, or alternatively tap into their communications. Outside of combat this program gives you the ability to attempt to intercept, alter or prevent communications.`,
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 2 }],
  actions: [hackCommunications],
  price: 0,
};

const interferenceProgram: TechDetails = {
  id: "6d87ee42-01f3-40d2-ab5c-703a9502d74b",
  tier: 1,
  roles: [Role.hacker],
  maxEquippable: 1,
  displayName: "Interference Program",
  image: techImage("program"),
  isEquippable: true,
  description: `This program allows you to interfere with a target's ability to act, applying one of the following conditions - confused, dazed or paralyzed. Out of combat this program allows you to attempt to interfere with and slow down the operation of a network.`,
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 2 }],
  actions: [interferenceConfused, interferenceDazed, interferenceParalyzed],
  requirements: [processingCore1],
  price: 200,
};

const brassKnuckles1: TechDetails = {
  id: "d50c75b3-3964-43b8-9b60-764de43c31d7",
  tier: 1,
  roles: [Role.bruiser],
  displayName: "Built-in Brass Knuckles",
  image: techImage("brass-knuckles"),
  maxEquippable: 1,
  isEquippable: true,
  description: "Turns your fists into serious weapons.",
  statModifiers: [
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "unarmedDamage", modifier: 1 },
  ],
  price: 0,
  weight: 1,
};

const amphetamineInjector: TechDetails = {
  id: "6844c434-4628-4d61-aa80-1a2e18449e2c",
  tier: 2,
  roles: [Role.medic],
  displayName: "Emergency Adrenaline Injector",
  maxEquippable: 1,
  isEquippable: true,
  description: `The emergency stim injector can be used to return a critically injured person to consciousness.`,
  statModifiers: [{ stat: "currentTorsoCapacity", modifier: 5 }],
  price: 0,
  actions: [emergencyAmphetamines, emergencyAmphetaminesAttention],
  weight: 0.5,
};

const vitalsProtection1: TechDetails = {
  id: "3547f128-f5a1-43ac-9d3c-62cbc102fb35",
  tier: 1,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
    Role.hacker,
  ],
  displayName: "Vitals Protection (Tier 1)",
  image: techImage("vitals-protection"),
  isEquippable: true,
  maxEquippable: 1,
  description:
    "Adds shielding around your vital organs to protect you from damage, particularly effective against kinetic damage.",
  statModifiers: [
    { stat: "currentTorsoCapacity", modifier: 10 },
    { stat: "maxShield", modifier: 10 },
    { stat: DamageType.kinetic, modifier: 1 },
  ],
  price: 0,
  weight: 5,
};

const headProtection: TechDetails = {
  id: "bb049c32-5f45-412f-a7fe-9b1cca96dd78",
  tier: 1,
  roles: [Role.bruiser, Role.gunner, Role.infiltrator, Role.medic],
  displayName: "Skull Shielding (Tier 1)",
  image: techImage("skull-plating"),
  isEquippable: true,
  maxEquippable: 1,
  description:
    "Adds plating to your head to protect your brain and delicate sensory organs.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 10 },
    { stat: "maxShield", modifier: 10 },
    { stat: DamageType.electrical, modifier: 1 },
  ],
  price: 0,
  weight: 5,
};

const shellProtection: TechDetails = {
  id: "46aba295-2583-4347-99a7-be39f02149c3",
  tier: 1,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Nanofiber Skin Weave (Tier 1)",
  image: techImage("shell-protection"),
  isEquippable: true,
  maxEquippable: 1,
  description:
    "A layer of carbon nanofiber weave beneath that skin that provides shielding from all kinds of damage and insulation against thermal damage in particular.",
  statModifiers: [
    { stat: "currentShellCapacity", modifier: 10 },
    { stat: "maxShield", modifier: 10 },
    { stat: DamageType.thermal, modifier: 1 },
  ],
  price: 0,
  weight: 5,
};

const meleeCombatTracker1: TechDetails = {
  id: "293bf76c-d8b8-4b37-89bf-8c6d579a365f",
  tier: 1,
  roles: [Role.bruiser],
  displayName: "Melee Combat Tracker",
  image: techImage("targeting-reticle"),
  isEquippable: true,
  maxEquippable: 1,
  description:
    "Allows you to closely monitor engaged melee foes and take advantage of openings they offer. Gives you to the ability to do more damage to moving enemies with melee weapons.",
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 5 }],
  actions: [engage],
  price: 0,
  weight: 0.5,
};

const targetAcquisitionAssistant: TechDetails = {
  id: "346bb35d-a41a-4515-88c8-ebbf541ec414",
  tier: 1,
  roles: [Role.gunner],
  displayName: "Ranged Combat Tracker",
  image: techImage("targeting-reticle"),
  isEquippable: true,
  maxEquippable: 1,
  description:
    "Provides real-time target information and tracking assistance to help you compensate for your target's movement.",
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 5 }],
  actions: [markTarget],
  price: 0,
  weight: 0.5,
};

const emergencyLifeSupport: TechDetails = {
  id: "de4a2165-a6af-4519-8c57-f7a8766f09bd",
  tier: 1,
  image: techImage("life-support"),
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Emergency Life Support (Tier 1)",
  isEquippable: true,
  maxEquippable: 1,
  description:
    "Adds in case of emergency life support systems. When you take damage that would reduce you to below one health from greater than one health, you are instead reduced to one health point. This only works if your body is still intact.",
  statModifiers: [{ stat: "currentTorsoCapacity", modifier: 5 }],
  price: 1000,
  weight: 1,
};

// const pheromone1: TechDetails = {
//   id: "39a855cb-471c-41d0-aa38-2de43ab49d36",
//   tier: 1,
//   roles: [Role.socialite],
//   displayName: "Pheromone Hacking",
//   maxEquippable: 1,
//   isEquippable: true,
//   description:
//     "Adds the ability to secrete pheromones from your skin that subtly change other people's feelings towards you.",
//   statModifiers: [
//     { stat: "currentShellCapacity", modifier: 10 },
//     { stat: "persuasion", modifier: 1 },
//     { stat: "deception", modifier: 1 },
//     { stat: "intimidation", modifier: 1 },
//   ],
//   price: 0,
//   weight: 0.5,
// };

// const shellPhysicalArmour1: TechDetails = {
//   id: "3509b1d5-0c21-40fb-986c-bd84f8628cb1",
//   tier: 1,
//   roles: [Role.bruiser, Role.gunner],
//   displayName: "Hardened Shell",
//   maxEquippable: 1,
//   isEquippable: true,
//   description:
//     "Flexible carbon fiber plating underneath the skin that reduces physical damage.",
//   statModifiers: [
//     { stat: "currentShellCapacity", modifier: 10 },
//     { stat: "physicalArmour", modifier: 1 },
//   ],
//   price: 0,
// };

const car1: TechDetails = {
  id: "d1b89076-9cfd-4e06-b630-53d658be7e95",
  tier: 1,
  roles: [],
  displayName: "Cheap Vehicle",
  image: techImage("car"),
  isEquippable: false,
  isEquipment: true,
  description:
    "A secondhand commuting vehicle. It's not flash, but it lets you drive from point to point rather than needing to rely on public transport. Useful in a chase.",
  price: 2000,
};

const grenadeLauncher: TechDetails = {
  id: "5682d1a7-d665-440e-a621-fcdb4caf921f",
  tier: 1,
  roles: [Role.engineer],
  displayName: "Grenade Launcher",
  image: techImage("grenade-launcher"),
  isEquippable: true,
  isEquipment: true,
  description:
    "Grenade launchers can be used to fire a range of grenade supply types. Explosives cover a wide area and can't be dodged or defended against, but are slower to fire than other weapons.",
  price: 1000,
  weight: 7,
  actions: [],
  damage: {
    [DamageType.kinetic]: {
      multiplier: 0,
    },
  },
  melee: true,
  ammo: 0,
};

const readyGrenadeLauncher: ActionDetails = {
  id: "c197bf2f-7405-4789-8997-fdeb73f7c884",
  readyWeapon: grenadeLauncher,
  displayName: "Ready Grenade Launcher",
  description: `Readies your equipped grenade launcher.`,
  targetType: TargetType.self,
  utilizes: {
    interaction: true,
  },
  duration: 4,
  cooldown: 0,
  maxHelpers: 0,
};
grenadeLauncher.actions?.push(readyGrenadeLauncher);

const launchGrenade: ActionDetails = {
  id: "aa85d045-a1c2-4bcf-832c-b1335a2c1007",
  displayName: "Launch Grenade",
  description:
    "Launch a grenade that explodes and deals 3 kinetic damage and 2 thermal damage per success to everyone within a 5 metre radius.",
  targetType: TargetType.point,
  radius: 6,
  range: 30,
  targetDrone: 1,
  // requiredWeapon: grenadeLauncher,
  lineOfSight: true,
  utilizes: {
    interaction: true,
  },
  sharedCooldown: "grenade",
  duration: 3,
  cooldown: 2,
  poolModifier: {
    multiplier: 1,
    flat: 1,
  },
  maxTargets: 1,
  skill: "demolition",
  damage: {
    [DamageType.kinetic]: {
      multiplier: 3,
    },
    [DamageType.thermal]: {
      multiplier: 2,
    },
  },
  logFormat: {
    base: "Launch Grenade",
    success: " hitting {targetDisplayName} for {damage} damage",
    failure: " but failed",
  },
};

const launchFlashbang: ActionDetails = {
  id: "ca1bff59-c7cc-4232-ae41-61db20735035",
  displayName: "Launch Flashbang",
  description:
    "Launch a stun grenade that explodes and deals 3 kinetic damage and 0.33 stun points per success to everyone within a 5 metre radius.",
  targetType: TargetType.point,
  radius: 5,
  range: 30,
  targetDrone: 1,
  // requiredWeapon: grenadeLauncher,
  lineOfSight: true,
  utilizes: {
    interaction: true,
  },
  sharedCooldown: "grenade",
  duration: 3,
  cooldown: 2,
  poolModifier: {
    multiplier: 1,
    flat: 1,
  },
  maxTargets: 1,
  skill: "demolition",
  conditions: {
    [Condition.stunned]: {
      multiplier: 0.33,
      duration: 5,
    },
  },
  damage: {
    [DamageType.kinetic]: {
      multiplier: 3,
    },
  },
  logFormat: {
    base: "Launch Flashbang",
    success: " hitting {targetDisplayName} for {damage} damage",
    failure: " but failed",
  },
};

const grenadeSupply: TechDetails = {
  id: "f27e8c57-c3f0-471d-a63a-0d6731d103f3",
  tier: 2,
  image: techImage("grenade"),
  roles: [Role.engineer],
  requirements: [explosivesTargeting1],
  displayName: "Grenade Round",
  isEquippable: true,
  isEquipment: true,
  description: "A grenade round for an explosives launcher system.",
  price: 10,
  actions: [launchGrenade],
  weight: 0.5,
};
launchGrenade.consumes = grenadeSupply;

const flashbangSupply: TechDetails = {
  id: "72eeb998-2eba-4075-a1c2-8473a5b14a0e",
  tier: 1,
  image: techImage("grenade"),
  roles: [Role.engineer],
  requirements: [explosivesTargeting1],
  displayName: "Flashbang Round",
  isEquippable: true,
  isEquipment: true,
  description: "A flashbang round for an explosives launcher system.",
  price: 10,
  actions: [launchFlashbang],
  weight: 0.5,
};
launchFlashbang.consumes = flashbangSupply;

const firstAidKit: TechDetails = {
  id: "a1e1ffd6-c1b7-42e5-a8ed-b4a2845c493b",
  tier: 1,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
  ],
  displayName: "First Aid Kit",
  image: techImage("first-aid"),
  isEquippable: true,
  isEquipment: true,
  maxEquippable: 1,
  description:
    "A first aid kit that can be used to stabilize critically injured people.",
  price: 100,
  actions: [firstAid],
  weight: 1,
};

const douseFire: ActionDetails = {
  id: "2e0d345d-9150-4d8d-8c01-76b739d14974",
  displayName: "Douse Burning",
  description: `Covers the target in a fire suppressant foam that will suppress the burning condition for a short time.`,
  targetType: TargetType.character,
  minTargets: 1,
  maxTargets: 1,
  melee: true,
  lineOfSight: true,
  utilizes: {
    interaction: true,
  },
  duration: 1,
  cooldown: 30,
  skill: "medical",
  price: 0,
  conditions: {
    [Condition.doused]: {
      duration: 30,
      multiplier: 1,
    },
  },
  logFormat: {
    base: "Douse Burning for ",
    success: "{targetDisplayName} for {rollResult} successes",
    failure: "{targetDisplayName} but failed",
  },
};
const douseFireAttention: ActionDetails = {
  ...douseFire,
  id: "8be8203e-dc90-42f1-b3b6-eab877b0081b",
  utilizes: {
    attention: true,
  },
  requirements: [surgicalTooling1],
};
const fireSuppressor: TechDetails = {
  id: "1a1eb2f5-753c-419c-8364-6ffbb3b9afa1",
  tier: 1,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
  ],
  image: techImage("fire-suppressor"),
  displayName: "Fire Suppressor",
  isEquippable: true,
  isEquipment: true,
  maxEquippable: 1,
  description:
    "A fire extinguishing device that can be used to suppress the burning condition.",
  price: 200,
  actions: [douseFire, douseFireAttention],
  weight: 1,
};

const protectiveClothing: TechDetails = {
  id: "1cff0b5c-de66-4c8d-b987-3a3789423fa8",
  tier: 1,
  roles: [Role.bruiser, Role.gunner, Role.infiltrator, Role.engineer],
  isEquipment: true,
  displayName: "Protective Clothing",
  image: techImage("clothing"),
  isEquippable: true,
  description: "Basic protective clothing.",
  slots: ["clothing"],
  statModifiers: [
    { stat: DamageType.kinetic, modifier: 1 },
    { stat: DamageType.thermal, modifier: 1 },
    { stat: DamageType.electrical, modifier: 1 },
  ],
  price: 0,
  weight: 5,
};

const bodyArmour: TechDetails = {
  id: "6d21eb1c-5dec-4363-a52e-fbc0ce79a8bf",
  tier: 1,
  roles: [Role.bruiser, Role.gunner],
  isEquipment: true,
  displayName: "Body Armour",
  image: techImage("body-armor"),
  isEquippable: true,
  slots: ["clothing"],
  description:
    "Combat body armour made of a nanoweave designed to redistribute kinetic force safely.",
  statModifiers: [
    { stat: DamageType.kinetic, modifier: 3 },
    { stat: DamageType.thermal, modifier: 1 },
    { stat: DamageType.electrical, modifier: 1 },
  ],
  price: 0,
  weight: 10,
};

const droneFrame: TechDetails = {
  id: "c209209d-c7b0-48af-a649-9c52a36b2180",
  tier: 1,
  roles: [Role.engineer],
  isEquipment: true,
  isEquippable: true,
  displayName: "Drone Frame",
  image: techImage("drone-frame"),
  description:
    "Drone frame with basic sensory equipment for other modules to be plugged into. Can be controlled up to 300 metres away.",
  statModifiers: [{ stat: "maxDroneShield", modifier: 20 }],
  requirements: [droneLink1],
  actions: [deployDrone, undeployDrone],
  price: -500,
  weight: 1,
};

const droneSelfDestructModule: TechDetails = {
  id: "1a8805f6-13a9-4c06-a6be-ead6dab77bc8",
  tier: 1,
  roles: [Role.engineer],
  isEquipment: true,
  isEquippable: true,
  displayName: "Drone Self-Destruct Module",
  description: "",
  statModifiers: [],
  slots: ["smallDroneSlot"],
  price: 0,
  weight: 1,
};

const droneFlyingModule: TechDetails = {
  id: "d0c57401-204f-4d9c-aff0-34698a5ef913",
  tier: 1,
  roles: [Role.engineer],
  isEquipment: true,
  isEquippable: true,
  displayName: "Drone Flying Module",
  image: techImage("flying-drone"),
  description:
    "Equipment that gives a drone the ability to fly. A flying drone can't equip heavier modules.",
  statModifiers: [{ stat: "droneMovementSpeed", modifier: 10 }],
  slots: ["movementDroneSlot", "mediumDroneSlot", "largeDroneSlot"],
  requirements: [droneFrame],
  actions: [droneMove],
  price: -1000,
  weight: 1,
};

const droneLocomotionModule: TechDetails = {
  id: "597732f4-b346-412c-8284-2ad3e2c1ee4e",
  tier: 1,
  roles: [Role.engineer],
  isEquipment: true,
  isEquippable: true,
  displayName: "Drone Locomotion Module",
  image: techImage("drone-legs"),
  description:
    "Equipment that gives a drone the ability to move over the ground. A ground-based drone can equip other heavier equipment.",
  slots: ["movementDroneSlot"],
  requirements: [droneFrame],
  statModifiers: [{ stat: "droneMovementSpeed", modifier: 2 }],
  actions: [droneMove],
  price: 600,
  weight: 5,
};

const droneSpeedModule: TechDetails = {
  id: "87dbecfe-ace8-45f0-8496-359d6515e5bc",
  tier: 1,
  roles: [Role.engineer],
  isEquipment: true,
  isEquippable: true,
  displayName: "Drone Speed Module",
  image: techImage("drone-speed"),
  description: "A module that increases the speed of a drone.",
  slots: ["mediumDroneSlot"],
  requirements: [droneFrame],
  statModifiers: [{ stat: "droneMovementSpeed", modifier: 3 }],
  price: 0,
  weight: 1,
};

const droneStealthModule: TechDetails = {
  id: "f477a64f-cffc-4eed-b77d-377951757a33",
  tier: 2,
  roles: [Role.engineer],
  isEquipment: true,
  isEquippable: true,
  displayName: "Drone Stealth Module",
  description:
    "Allows you to make stealth roles for your drone using your engineering skill against other people's perception skill to not be detected.",
  slots: ["smallDroneSlot"],
  requirements: [droneFrame],
  statModifiers: [],
  price: 1500,
  weight: 1,
};

const droneRangeExtenderModule: TechDetails = {
  id: "e8cb0712-1902-4e97-90f2-7fe8875474b3",
  tier: 1,
  roles: [Role.engineer],
  isEquipment: true,
  isEquippable: true,
  displayName: "Drone Range Extender Module",
  image: techImage("drone-range"),
  description:
    "Increases the range at which you can control your drone up to 10 km.",
  slots: ["smallDroneSlot"],
  requirements: [droneFrame],
  statModifiers: [],
  price: 1000,
  weight: 1,
};

const droneHologramModule: TechDetails = {
  id: "e399f370-0a91-4145-908e-b7965047847b",
  tier: 2,
  roles: [Role.engineer],
  isEquipment: true,
  isEquippable: true,
  displayName: "Drone Hologram Module",
  description:
    "Allows your drone to project a hologram around itself no larger than two metres in size. The hologram can be controlled by the drone's pilot. This can be used, for example, to disguise the drone as a person. This hologram can only fool people from a distance, close examination will easily reveal its true nature.",
  slots: ["smallDroneSlot"],
  requirements: [droneFrame],
  statModifiers: [],
  price: 5000,
  weight: 1,
};

const droneShieldingModule: TechDetails = {
  id: "16a38a80-6858-49ee-9789-0adbf8bd3e18",
  tier: 1,
  roles: [Role.engineer],
  isEquipment: true,
  isEquippable: true,
  displayName: "Drone Shielding Module",
  image: techImage("drone-shield"),
  description:
    "Thick plating that protects your drone's critical components from damage.",
  slots: ["mediumDroneSlot"],
  requirements: [droneFrame],
  statModifiers: [{ stat: "maxDroneShield", modifier: 20 }],
  price: 0,
  weight: 3,
};

const droneStorageModule: TechDetails = {
  id: "e866d0dd-e586-464e-9a6f-6f4542803846",
  tier: 1,
  roles: [Role.engineer],
  isEquipment: true,
  isEquippable: true,
  displayName: "Drone Storage Module",
  image: techImage("drone-frame"),
  description: "Allows your drone to supplement your carrying capacity.",
  slots: ["mediumDroneSlot"],
  requirements: [droneFrame],
  statModifiers: [{ stat: "maxCarryingCapacity", modifier: 20 }],
  price: 0,
  weight: 3,
};

const droneSecurityBypassModule: TechDetails = {
  id: "ffc936f8-1d31-4e47-b767-3e0bdbbf9d1f",
  tier: 2,
  roles: [Role.engineer],
  isEquipment: true,
  isEquippable: true,
  displayName: "Drone Security Bypass Module",
  image: techImage("security-tools"),
  description:
    "Adds tools to your drone that allow you to make security checks to bypass physical security with your drone.",
  slots: ["mediumDroneSlot"],
  requirements: [droneFrame],
  statModifiers: [],
  price: 1000,
  weight: 3,
};

const droneGunModule: TechDetails = {
  id: "617e9a93-fc5e-4dc7-b54f-5fda9003d5bf",
  tier: 1,
  roles: [Role.engineer],
  isEquipment: true,
  isEquippable: true,
  displayName: "Drone Gun Module",
  image: techImage("drone-gun"),
  description: "A mounted gun module that can add some firepower to a drone.",
  slots: ["largeDroneSlot"],
  requirements: [droneFrame],
  statModifiers: [{ stat: "droneKineticDamage", modifier: 2 }],
  actions: [droneAttack],
  price: 0,
  weight: 3,
};

const dronesRepairKit: TechDetails = {
  id: "89f5ace9-b6be-4da1-9b78-2eaa87fec6ad",
  tier: 1,
  roles: [Role.engineer],
  displayName: "Drone Repair Kit",
  image: techImage("drone-repair-kit"),
  isEquippable: true,
  isEquipment: true,
  maxEquippable: 4,
  description:
    "A kit of repair supplies that allows engineers to perform field repairs on drones. Consumed on use.",
  price: 100,
  actions: [repairDrone],
  weight: 1,
};
repairDrone.consumes = dronesRepairKit;

// Tier 2

function upgradeTechToTier(tech: TechDetails, tier: number): TechDetails {
  return {
    ...tech,
    tier,
    requirements: [tech],
    displayName: `${tech.displayName
      .split("(")[0]
      .slice(0, -1)} (Tier ${tier})`,
  };
}

const headTier2: TechDetails = {
  ...upgradeTechToTier(headTier1, 2),
  id: "f80d3787-4b4a-4943-9481-5639dbbf9348",
  description: `Upgrades your head cybernetics platform to use miniaturized components that allow for more cybernetics to be installed at once.`,
};

const armsTier2: TechDetails = {
  ...upgradeTechToTier(armsTier1, 2),
  id: "474135a7-aba7-49c9-a534-fb3f85351c34",
  description: `Upgrades your arms cybernetics platform to use miniaturized components that allow for more cybernetics to be installed at once.`,
};

const legsTier2: TechDetails = {
  ...upgradeTechToTier(legsTier1, 2),
  id: "9603a982-508f-4ed1-ad57-d495b05bcef0",
  description: `Upgrades your legs cybernetics platform to use miniaturized components that allow for more cybernetics to be installed at once.`,
};

const torsoTier2: TechDetails = {
  ...upgradeTechToTier(torsoTier1, 2),
  id: "8c3c25d3-f0ab-4e49-aa7e-fd2184498e90",
  description: `Upgrades your torso cybernetics platform to use miniaturized components that allow for more cybernetics to be installed at once.`,
};

const shellTier2: TechDetails = {
  ...upgradeTechToTier(shellTier1, 2),
  id: "1e72d5de-2ddd-46d6-ba1e-f7a3e47cbb02",
  description: `Upgrades your shell cybernetics platform to use miniaturized components that allow for more cybernetics to be installed at once.`,
};

const cardiovascularBoost2: TechDetails = {
  ...upgradeTechToTier(cardiovascularBoost, 2),
  id: "6807b6b0-2717-4e88-8616-f33a16a84e19",
};

const hulkingForm2: TechDetails = {
  ...upgradeTechToTier(reinforcedMuscles, 2),
  id: "67efbfd4-3c22-4259-89d5-dc7499ffcf86",
};

const ferocity2: TechDetails = {
  ...upgradeTechToTier(threatSubroutines, 2),
  id: "f647f46f-eeda-4251-ab28-a5823ab52d07",
};

const targetingSystem2: TechDetails = {
  ...upgradeTechToTier(heavyRecoilSuppression1, 2),
  id: "79b8831f-abf2-418b-a3f5-2d63d66d6b89",
};

const precisionMovementServos2: TechDetails = {
  ...upgradeTechToTier(lightAimAssist1, 2),
  id: "e3014c5a-9a48-450f-8d73-0073f76ac1ed",
};

const activeCamouflage2: TechDetails = {
  ...upgradeTechToTier(shockAbsorbers1, 2),
  id: "99753a57-083d-4e8f-b950-6a5e0a32850a",
  statModifiers: [
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "currentLegsCapacity", modifier: 5 },
    { stat: "stealth", modifier: 2 },
    { stat: "deception", modifier: 1 },
  ],
  actions: [],
};

const emotionalFeedbackLoop2: TechDetails = {
  ...upgradeTechToTier(sinceritySubroutines, 2),
  id: "c0ebad7c-5ce8-491f-a516-6390cc1aab5f",
};

const reflexAcceleration2: TechDetails = {
  ...upgradeTechToTier(striking1, 2),
  id: "f4c8c119-ac71-4c5a-858a-ea4498d6c6dc",
};

const autoLink2: TechDetails = {
  ...upgradeTechToTier(autoLink1, 2),
  id: "c12e7981-eac3-4a4c-9865-4e0195e46179",
};

const droneLink2: TechDetails = {
  ...upgradeTechToTier(droneLink1, 2),
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: "engineering", modifier: 2 },
    { stat: "demolition", modifier: 1 },
  ],
  id: "2ef12e81-ef73-4a58-83d8-016155ca8e46",
};

const explosivesTargeting2: TechDetails = {
  ...upgradeTechToTier(explosivesTargeting1, 2),
  statModifiers: [
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: "demolition", modifier: 2 },
    { stat: "security", modifier: 1 },
  ],
  price: 0,
  id: "f8615055-090f-4309-bc42-0bb16956c718",
};

const securityTooling2: TechDetails = {
  ...upgradeTechToTier(securityTooling1, 2),
  id: "747fa1c5-7fe7-46ad-9344-5854f4b2e36d",
};

const processingCore2: TechDetails = {
  ...upgradeTechToTier(processingCore1, 2),
  id: "032c4642-7a39-4c2d-bbdf-8b87296d9bb5",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "hacking", modifier: 2 },
    { stat: "research", modifier: 1 },
  ],
  actions: [],
};

const dataLink2: TechDetails = {
  ...upgradeTechToTier(dataLink1, 2),
  id: "65b50e1a-446a-4ebd-9c10-45980b7c88ab",
};

const logicModeling2: TechDetails = {
  ...upgradeTechToTier(dialecticSubroutines, 2),
  id: "6cc3983a-5579-4e41-b160-a7ad8d75c7a3",
};

const forensicAnalyser2: TechDetails = {
  ...upgradeTechToTier(forensicAnalyser1, 2),
  id: "5e91bb4a-32ba-4822-b679-10ea9847b55d",
};

const extrasensoryArray2: TechDetails = {
  ...upgradeTechToTier(cyberEyes1, 2),
  id: "09559007-eff8-4d73-a3c9-3289b204593e",
};

const biologicalScanner2: TechDetails = {
  ...upgradeTechToTier(theoryOfMind1, 2),
  id: "7947d56f-157b-4745-8efe-95c3bf9a0921",
};

const surgicalTooling2: TechDetails = {
  ...upgradeTechToTier(surgicalTooling1, 2),
  id: "9614d49b-df4a-4c96-9546-ef38a7801d24",
  actions: [],
};

const muscleNanoweave2: TechDetails = {
  ...upgradeTechToTier(jackedArms1, 2),
  id: "89cdcfc4-4765-4d53-8175-bfd43c655074",
};

// ========================================

const athleticsSurgery: TechDetails = {
  id: "c948bb07-8aca-4485-833e-0423b90a4a31",
  tier: 2,
  roles: [Role.bruiser, Role.gunner, Role.infiltrator],
  displayName: "Synthetic Heart (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Permanently replaces your heart with a synthetic replacement that has higher performance.",
  statModifiers: [
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: "athletics", modifier: 1 },
  ],
  price: 0,
};

const physicalPowerSurgery: TechDetails = {
  id: "27b53096-9f7c-4882-a023-7f95ac269e59",
  tier: 2,
  roles: [Role.bruiser, Role.gunner],
  displayName: "Weighted Skeleton Replacement (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Replaces the skeletal structure of your lower body with a heavier metal alloy and adds extra power to make up for it. The extra weight and strength increases your physical power.",
  statModifiers: [
    { stat: "currentLegsCapacity", modifier: 5 },
    { stat: "physicalPower", modifier: 1 },
  ],
  price: 0,
};

const intimidationSurgery: TechDetails = {
  id: "cc7abaab-557d-4f14-b4c8-9f64a60b0fa1",
  tier: 2,
  roles: [Role.socialite],
  displayName: "Aggression Genetic Editing (Tier 2)",
  image: techImage("genetic"),
  maxEquippable: 1,
  isEquippable: false,
  description:
    "This surgery modifies your gene expression to permanently increase your aggression.",
  statModifiers: [
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: "intimidation", modifier: 1 },
  ],
  price: 0,
};

const heavyGunsSurgery: TechDetails = {
  id: "1ab53f6e-df90-4d03-87c7-7cc7d07a6223",
  tier: 2,
  roles: [Role.gunner],
  displayName: "Carbon-fiber Arm Ligature (Tier 2)",
  image: techImage("tendons"),
  maxEquippable: 1,
  isEquippable: false,
  description:
    "This surgery replaces the tendons and ligaments of your wrists, elbows and shoulders with carbon-fiber based alternatives specifically designed to absorb the shock of heavy gunfire.",
  statModifiers: [
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "heavyGuns", modifier: 1 },
  ],
  price: 0,
};

const lightGunsSurgery: TechDetails = {
  id: "be5486a2-d596-4851-a4ca-5248dc4f58aa",
  tier: 2,
  roles: [
    Role.gunner,
    Role.infiltrator,
    Role.socialite,
    Role.hacker,
    Role.medic,
  ],
  displayName: "Peripheral Nervous Signal Cycler (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "This surgery changes the nervous system of your arms and hands to effortlessly repeat actions without conscious intervention for a faster firing rate.",
  statModifiers: [
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "lightGuns", modifier: 1 },
  ],
  price: 0,
};

const stealthSurgery: TechDetails = {
  id: "b14c8dfb-e87c-494c-888a-e977ee39b149",
  tier: 2,
  roles: [Role.infiltrator],
  displayName: "Sound Absorbing Substructure (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Replace the muscles and tendons of your legs with a material designed to absorb the maximum amount of sound.",
  statModifiers: [
    { stat: "currentLegsCapacity", modifier: 5 },
    { stat: "stealth", modifier: 1 },
  ],
  price: 0,
};

const deceptionSurgery: TechDetails = {
  id: "8cb1457d-2709-4420-aeed-e941ca73ea2d",
  tier: 2,
  roles: [Role.socialite],
  displayName: "Pheromone Genetic Editing (Tier 2)",
  image: techImage("genetic"),
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Permanently alters your gene expression to release designer pheromones that lull other people into trusting you.",
  statModifiers: [
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "deception", modifier: 1 },
  ],
  price: 0,
};

const performanceSurgery: TechDetails = {
  id: "7f69933c-2b8a-4a97-a96c-5d4ac823d843",
  tier: 2,
  roles: [Role.socialite],
  displayName: "Enhanced Flexible Joints (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Replaces all the joints of your legs with artificial joints that provide a much broader range of controlled movement.",
  statModifiers: [
    { stat: "currentLegsCapacity", modifier: 5 },
    { stat: "performance", modifier: 1 },
  ],
  price: 0,
};

const drivingSurgery: TechDetails = {
  id: "3573906c-cb56-4682-968c-4b884eb2b24c",
  tier: 2,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Lower Nervous System Relay (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "A signal relay grafted into your lower spine and connected to your legs, speeding up your reaction time when driving.",
  statModifiers: [
    { stat: "currentLegsCapacity", modifier: 5 },
    { stat: "driving", modifier: 1 },
  ],
  price: 0,
};

const engineeringSurgery: TechDetails = {
  id: "ab09dd55-0891-44e2-8c9f-8f3464dc5686",
  tier: 2,
  roles: [Role.engineer],
  displayName: "Machine Language Processor (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Migrates some of your brain processing power to a module designed to facilitate communication and comprehension of machines.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "engineering", modifier: 1 },
  ],
  price: 0,
};

const demolitionSurgery: TechDetails = {
  id: "e2dd3335-81d1-45dc-a3ae-7dedb88c54ac",
  tier: 2,
  roles: [Role.engineer],
  displayName: "Demolition Power Source (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Weaves a bioelectrical power source into your torso that can provide extra power to demolition equipment.",
  statModifiers: [
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: "demolition", modifier: 1 },
  ],
  price: 0,
};

const securitySurgery: TechDetails = {
  id: "1c90b771-919a-4cec-9dc1-a36edbb87565",
  tier: 2,
  roles: [Role.infiltrator],
  displayName: "Piezoelectric sensors (Tier 2)",
  isEquippable: false,
  maxEquippable: 1,
  description:
    "This surgery installs a network of piezoelectric sensors in your shell that are capable of detecting tiny vibrations, enabling you to better sense and overcome physical security.",
  statModifiers: [
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "security", modifier: 1 },
  ],
  price: 0,
};

const hackingSurgery: TechDetails = {
  id: "7c4bb9ea-0204-44df-86ce-94b88e7b2bc8",
  tier: 2,
  roles: [Role.hacker],
  displayName: "Accelerated Wiring (Tier 2)",
  isEquippable: false,
  maxEquippable: 1,
  description:
    "Implants high-speed wiring into your brain that closely binds your wetware to your processing capacity, increasing your ability to direct programs.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "hacking", modifier: 1 },
  ],
  price: 0,
};

const researchSurgery: TechDetails = {
  id: "dbad0f51-3115-4946-9137-cc5e6bb03b7c",
  tier: 2,
  roles: [Role.hacker, Role.socialite],
  displayName: "Broad Subject Database (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Migrates some of your brain's memory storage to an artificial database, expanding your ability to research and remember information on a wide range of subjects.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "research", modifier: 1 },
  ],
  price: 0,
};

const environmentalAnalysisSurgery: TechDetails = {
  id: "9d079b88-8332-4189-8f84-524457ac8720",
  tier: 2,
  roles: [Role.infiltrator, Role.socialite],
  displayName: "Augmented Reality Interface (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Surgically implants a module into your brain that allows you to visually overlay your environment analysis modelling on your environment.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "environmentalAnalysis", modifier: 1 },
  ],
  price: 0,
};

const perceptionSurgery: TechDetails = {
  id: "7be97de0-d392-45df-9a26-866b65b993a7",
  tier: 2,
  roles: [Role.infiltrator, Role.socialite],
  displayName: "Cybernetic Eyes (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Replaces your eyes with a pair of cybernetic eyes that improve your ability to perceive your environment.",
  statModifiers: [
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "perception", modifier: 1 },
  ],
  price: 0,
};

const persuasionSurgery: TechDetails = {
  id: "f74d59ea-ae99-49b1-acfa-570f04f29e9a",
  tier: 2,
  roles: [Role.socialite],
  displayName: "Commanding Presence (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Replaces a portion of your shell with a system that automatically adjusts your expressions and posture to convey a sense of authority, improving your ability to persuade others.",
  statModifiers: [
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: "persuasion", modifier: 1 },
  ],
  price: 0,
};

const empathySurgery: TechDetails = {
  id: "9f02ccf7-27d0-4a87-b3a3-825793205624",
  tier: 2,
  roles: [Role.medic, Role.socialite],
  displayName: "Enhanced Empathy Genetic Modification (Tier 2)",
  image: techImage("genetic"),
  maxEquippable: 1,
  isEquippable: false,
  description:
    "This surgery edits your gene expression to heighten your sensitivity to other people thoughts and feelings.",
  statModifiers: [
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: "empathy", modifier: 1 },
  ],
  price: 0,
};

const medicalSurgery: TechDetails = {
  id: "c7bb6fd1-3417-4b6c-8d81-bf843e176bba",
  tier: 2,
  roles: [Role.medic, Role.bruiser],
  displayName: "Medicinal Pheromones (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Adds the capability to your body to produce pheromones with medicinal qualities, like a mild analgesic.",
  statModifiers: [
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "medical", modifier: 1 },
  ],
  price: 0,
};

const handToHandSurgery: TechDetails = {
  id: "cb0cb4ac-11a0-4a68-9370-9fbd18c269d6",
  tier: 2,
  roles: [Role.infiltrator, Role.bruiser],
  displayName: "Hand Reinforcement Surgery (Tier 2)",
  maxEquippable: 1,
  isEquippable: false,
  description:
    "Replaces the small, delicate bones in your hands with a replacement structure that can handle more force.",
  statModifiers: [
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "handToHand", modifier: 1 },
  ],
  price: 0,
};

// ==========================================

const shellProtection2: TechDetails = {
  ...upgradeTechToTier(shellProtection, 2),
  id: "abed572e-44f7-452a-aaa9-de6a35000de1",
};

const vitalsProtection2: TechDetails = {
  ...upgradeTechToTier(vitalsProtection1, 2),
  id: "06214570-a87c-49fd-aa46-cfaac75a3106",
};

const headProtection2: TechDetails = {
  ...upgradeTechToTier(headProtection, 2),
  id: "0f365d89-4e03-4370-ac14-0ed6a96051ee",
};

// const thermalInsulation: TechDetails = {
//   id: "d29c2b63-b20a-48c3-93a0-d3189a49b552",
//   tier: 2,
//   roles: [Role.bruiser, Role.gunner],
//   displayName: "Thermal Insulation",
//   maxEquippable: 1,
//   isEquippable: true,
//   description:
//     "Increases your shell's thermal resistance, allowing it to withstand higher temperatures without damage.",
//   statModifiers: [
//     { stat: "currentShellCapacity", modifier: 5 },
//     { stat: DamageType.thermal, modifier: 1 },
//   ],
//   price: 0,
//   weight: 5,
// };

// const conductiveInsulation: TechDetails = {
//   id: "0f8cd505-ad81-4a07-bf92-a79690b87797",
//   tier: 2,
//   roles: [Role.bruiser, Role.gunner],
//   displayName: "Conductive Insulation",
//   maxEquippable: 1,
//   isEquippable: true,
//   description:
//     "Increases your shell's electrical resistance, preventing some electrical attacks from penetrating your vulnerable internal systems.",
//   statModifiers: [
//     { stat: "currentShellCapacity", modifier: 5 },
//     { stat: DamageType.electrical, modifier: 1 },
//   ],
//   price: 0,
//   weight: 5,
// };

const processorShielding: TechDetails = {
  id: "d95f7055-37f5-43fb-9d4f-5b467f3ec419",
  tier: 2,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.engineer,
    Role.infiltrator,
  ],
  displayName: "Processor Shielding (Tier 2)",
  maxEquippable: 1,
  isEquippable: true,
  description: "Shields your processing systems from status effects.",
  statModifiers: [
    { stat: "currentShellCapacity", modifier: 5 },
    { stat: Condition.confused, modifier: 1 },
  ],
  price: 0,
  weight: 5,
};

const nervousSystemShielding: TechDetails = {
  id: "3a4dcd5f-e555-499c-8d00-28a44bff822d",
  tier: 2,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.engineer,
    Role.medic,
    Role.infiltrator,
  ],
  displayName: "Nervous System Shielding (Tier 2)",
  maxEquippable: 1,
  isEquippable: true,
  description: "Shields the nervous system from status effects.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: Condition.clumsy, modifier: 1 },
  ],
  price: 0,
  weight: 5,
};

const muscularAmplifiers: TechDetails = {
  id: "e64fa8eb-5e57-4645-a715-a79297993958",
  tier: 2,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.engineer,
    Role.medic,
    Role.infiltrator,
  ],
  displayName: "Muscular Amplifiers",
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Amplifying muscular compensators that can overcome status effects that prevent body movement.",
  statModifiers: [
    { stat: "currentTorsoCapacity", modifier: 5 },
    { stat: Condition.crippled, modifier: 1 },
  ],
  price: 0,
  weight: 5,
};

const improvedFirewall2: TechDetails = {
  id: "4495baa2-190e-4cfe-94f9-839ff6783da7",
  tier: 2,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Improved Firewall (Tier 2)",
  image: techImage("firewall"),
  maxEquippable: 1,
  isEquippable: true,
  description: "Improves your firewall defense.",
  statModifiers: [
    { stat: "currentHeadCapacity", modifier: 5 },
    { stat: "firewall", modifier: 1 },
    { stat: "firewallRegen", modifier: 1 },
  ],
  price: 0,
  weight: 0.5,
};

const disableSensesProgram: TechDetails = {
  id: "e43b95f3-2028-4999-aa43-8bbcc8b9d66c",
  tier: 2,
  roles: [Role.hacker],
  maxEquippable: 1,
  displayName: "Disable Senses Program",
  image: techImage("program"),
  isEquippable: true,
  description: `Interfere with target's senses.
    Outside of combat this program allows you to temporarily disable cameras and other sensors connected to a network you have access to.`,
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 2 }],
  actions: [breachFirewall],
  price: 0,
};

// const retractableBlades: TechDetails = {
//   id: "db5f6c3a-2d2f-4e21-9255-795daba351c1",
//   tier: 2,
//   roles: [Role.bruiser],
//   displayName: "Retractable Blades",
//   maxEquippable: 1,
//   isEquippable: true,
//   description:
//     "Adds retractable blades that can be used in hand to hand combat.",
//   statModifiers: [
//     { stat: "currentArmsCapacity", modifier: 8 },
//     { stat: "unarmedDamage", modifier: 1 },
//     { stat: "handToHand", modifier: 1 },
//   ],
//   price: 0,
//   weight: 1,
// };

const electrifiedFists: TechDetails = {
  id: "f8045b11-c63a-4231-96e5-e25ce5a069f2",
  tier: 2,
  roles: [Role.bruiser],
  displayName: "Electrified Fists",
  maxEquippable: 1,
  isEquippable: true,
  description: "Adds electrical damage to your unarmed strikes.",
  statModifiers: [
    { stat: "currentArmsCapacity", modifier: 5 },
    { stat: "unarmedElectricalDamage", modifier: 1 },
  ],
  price: 0,
  weight: 1,
};

const idScrambler: TechDetails = {
  id: "3e6bb25e-e903-4f76-8e35-05f1d0520e2d",
  tier: 2,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "ID Scrambler",
  maxEquippable: 1,
  isEquippable: true,
  description:
    "This implant scrambles the identification codes of your cybernetic transponders and disrupts facial recognition, thwarting the automated surveillance systems that typically track the locations of all citizens. Any attempt to track you through these systems suffers a penalty of 3, and if you're actively trying to remain hidden you have the opportunity to contest with stealth.",
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 5 }],
  price: 5000,
  weight: 0.5,
};

const overchargedLocomotion: TechDetails = {
  id: "075569b5-28d7-43e9-841f-53e1dbed8288",
  tier: 1,
  roles: [Role.bruiser],
  displayName: "Supercharged Dash Module",
  image: techImage("dashing"),
  maxEquippable: 1,
  isEquippable: true,
  description:
    "Grants the ability for a sudden burst of extra speed on a cooldown.",
  statModifiers: [{ stat: "currentLegsCapacity", modifier: 5 }],
  actions: [charge],
  price: 0,
  weight: 5,
};

const shutdownProgram: TechDetails = {
  id: "2e9f2479-3982-4235-9bfb-587bdfd08898",
  tier: 2,
  roles: [Role.hacker],
  maxEquippable: 1,
  displayName: "Shutdown Program",
  image: techImage("program"),
  isEquippable: true,
  description: `This program allows you to force a partial or complete shutdown, applying the stunned condition.`,
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 2 }],
  actions: [shutdown],
  requirements: [processingCore1],
  price: 0,
};

const overheatProgram: TechDetails = {
  id: "2f21637e-750c-40cb-bbbc-e78b3fc87de9",
  tier: 1,
  roles: [Role.hacker],
  maxEquippable: 1,
  displayName: "Overheat Program",
  image: techImage("program"),
  isEquippable: true,
  description: `This program allows you to cause the target's cyberware to overheat, applying the burning condition.`,
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 2 }],
  actions: [overheat],
  requirements: [processingCore1],
  price: 0,
};

const trackingProgram: TechDetails = {
  id: "7d33f25b-1fbd-434b-82e9-246ad5ce9268",
  tier: 2,
  roles: [Role.hacker],
  maxEquippable: 1,
  displayName: "Tracking Program",
  image: techImage("program"),
  isEquippable: true,
  description: `Grants the ability to perform a hacking skill challenge to hack into an area's network of cameras and tracking systems to locate the general location of a target, e.g. the most recent building or street they were detected on. The difficulty for this challenge starts at 1 and increases by 1 for each use of this program in a single day, resetting the next day. On a failure the program can't be used until the next day.`,
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 2 }],
  actions: [],
  requirements: [processingCore1],
  price: 1000,
};

const emergencyLifeSupport2: TechDetails = {
  id: "9dec88e7-bd7f-433c-bf1b-f72fcac579d8",
  tier: 2,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Emergency Life Support (Tier 2)",
  image: techImage("life-support"),
  isEquippable: true,
  maxEquippable: 1,
  description:
    "Adds improved in case of emergency life support systems. When you take damage that would reduce you to below ten health from greater than ten health, you are instead reduced to ten health points. This only works if your body is still intact.",
  statModifiers: [{ stat: "currentTorsoCapacity", modifier: 5 }],
  price: 5000,
  weight: 1,
};

// Tier 3

const headTier3: TechDetails = {
  ...upgradeTechToTier(headTier2, 3),
  id: "7282a933-c4d6-468b-ba5a-9455217fda24",
};

const armsTier3: TechDetails = {
  ...upgradeTechToTier(armsTier2, 3),
  id: "b3674732-ad5d-4f3e-9562-983c09ef4266",
};

const legsTier3: TechDetails = {
  ...upgradeTechToTier(legsTier2, 3),
  id: "5a52ca2f-1a6f-4d5d-8d61-43d9539e1ec6",
};

const torsoTier3: TechDetails = {
  ...upgradeTechToTier(torsoTier2, 3),
  id: "af2c81c6-cadb-4272-b2c1-a3765216a548",
};

const shellTier3: TechDetails = {
  ...upgradeTechToTier(shellTier2, 3),
  id: "f7a32b46-f791-486f-86b5-4b13211cfa74",
};

const processorShielding3: TechDetails = {
  ...upgradeTechToTier(processorShielding, 3),
  id: "305005fe-3bd8-462d-9d80-8398b03ed9a2",
};

const nervousSystemShielding3: TechDetails = {
  ...upgradeTechToTier(nervousSystemShielding, 3),
  id: "8a59e9e5-8baa-429b-9ded-fafee8355559",
};

const muscularAmplifiers3: TechDetails = {
  ...upgradeTechToTier(muscularAmplifiers, 3),
  id: "9332fa67-d8ef-4130-8fa0-e6276951a17b",
};

const cardiovascularBoost3: TechDetails = {
  ...upgradeTechToTier(cardiovascularBoost2, 3),
  id: "3a4b2bb8-7cd0-412f-a3b5-35ea92b9840f",
};

const hulkingForm3: TechDetails = {
  ...upgradeTechToTier(hulkingForm2, 3),
  id: "2120ca57-403a-4f7e-97b3-ce9a283ce08a",
};

const ferocity3: TechDetails = {
  ...upgradeTechToTier(ferocity2, 3),
  id: "296c2dca-ccdf-4fc2-9982-a04fbc222636",
};

const advancedTargeting3: TechDetails = {
  ...upgradeTechToTier(targetingSystem2, 3),
  id: "9fcae97b-0007-4e57-9f77-d28c884ff7b4",
};

const precisionMovementServos3: TechDetails = {
  ...upgradeTechToTier(precisionMovementServos2, 3),
  id: "6e45c246-e9d5-4506-966c-35acc3bd726f",
};

const activeCamouflage3: TechDetails = {
  ...upgradeTechToTier(activeCamouflage2, 3),
  id: "8a358da4-20dd-4c84-8b83-37de6d88eb05",
};

const emotionalFeedbackLoop3: TechDetails = {
  ...upgradeTechToTier(emotionalFeedbackLoop2, 3),
  id: "5bf718a0-128b-43db-b44a-6b02abd8201e",
};

const reflexAcceleration3: TechDetails = {
  ...upgradeTechToTier(reflexAcceleration2, 3),
  id: "01c4b31d-4e5c-49a1-8e2a-491a0975dc10",
};

const autoLink3: TechDetails = {
  ...upgradeTechToTier(autoLink2, 3),
  id: "e5b5917c-194b-4c2c-a8e3-507fade3a2a2",
};

const droneLink3: TechDetails = {
  ...upgradeTechToTier(droneLink2, 3),
  id: "d7ccb52b-ad13-4f21-bd68-95088bc5c6ce",
};

const explosivesTargeting3: TechDetails = {
  ...upgradeTechToTier(explosivesTargeting2, 3),
  id: "272f1d7e-a045-4ee4-acc4-de8715eec205",
};

const securityTooling3: TechDetails = {
  ...upgradeTechToTier(securityTooling2, 3),
  id: "ba3508a1-6419-47e3-b6de-1357267ed978",
};

const processingCore3: TechDetails = {
  ...upgradeTechToTier(processingCore2, 3),
  id: "b6958a18-1d69-4ac8-8aa9-591f0b5b0afc",
};

const dataLink3: TechDetails = {
  ...upgradeTechToTier(dataLink2, 3),
  id: "faef2f90-1531-4db5-9b54-856b30d16415",
};

const logicModeling3: TechDetails = {
  ...upgradeTechToTier(logicModeling2, 3),
  id: "eb20d14a-d61e-433e-8cae-bb9a6948d9e2",
};

const forensicAnalyser3: TechDetails = {
  ...upgradeTechToTier(forensicAnalyser2, 3),
  id: "3e62eb20-e244-4bc1-a421-d11895e9cac2",
};

const extrasensoryArray3: TechDetails = {
  ...upgradeTechToTier(extrasensoryArray2, 3),
  id: "dc114af4-be18-4649-9df4-be9c4d6d0461",
};

const biologicalScanner3: TechDetails = {
  ...upgradeTechToTier(biologicalScanner2, 3),
  id: "54362930-64e3-4a31-a45e-ebb7924a7f36",
};

const surgicalTooling3: TechDetails = {
  ...upgradeTechToTier(surgicalTooling2, 3),
  id: "c9a6d510-9203-48bc-80b9-cebb487a2422",
};

const muscleNanoweave3: TechDetails = {
  ...upgradeTechToTier(muscleNanoweave2, 3),
  id: "036fb390-4b4c-47d4-bf25-d756651e598f",
};

const advancedShielding3: TechDetails = {
  ...upgradeTechToTier(shellProtection2, 3),
  id: "c560fed2-39db-4b80-ba14-ce7ef834657d",
};

const vitalsProtection3: TechDetails = {
  ...upgradeTechToTier(vitalsProtection2, 3),
  id: "ce94ab37-178b-4d4f-aa70-0902368fb0bf",
};

const headProtection3: TechDetails = {
  ...upgradeTechToTier(headProtection2, 3),
  id: "b322e1c6-ebf3-4712-a8b5-dfbde41f92e4",
};

const athleticsSurgery3: TechDetails = {
  ...upgradeTechToTier(athleticsSurgery, 3),
  id: "3e1058ec-d5f3-4285-8ad1-ad3c7d6993e9",
};

const physicalPowerSurgery3: TechDetails = {
  ...upgradeTechToTier(physicalPowerSurgery, 3),
  id: "115a1d5b-99c0-4cb9-b1e9-5e7daa622e80",
};

const intimidationSurgery3: TechDetails = {
  ...upgradeTechToTier(intimidationSurgery, 3),
  id: "f231eca3-4f26-4d0b-8815-81292be7c2b5",
};

const heavyGunsSurgery3: TechDetails = {
  ...upgradeTechToTier(heavyGunsSurgery, 3),
  id: "e1c56edb-8eff-4aff-b1ed-30d30303f10c",
};

const lightGunsSurgery3: TechDetails = {
  ...upgradeTechToTier(lightGunsSurgery, 3),
  id: "5843ca9f-8f48-4b29-8acb-d8cb7349eac6",
};

const stealthSurgery3: TechDetails = {
  ...upgradeTechToTier(stealthSurgery, 3),
  id: "0c51fe61-4754-4cfd-b17f-fcea4e81c36d",
};

const deceptionSurgery3: TechDetails = {
  ...upgradeTechToTier(deceptionSurgery, 3),
  id: "c8177d69-9f89-473a-9877-969caa7c6326",
};

const performanceSurgery3: TechDetails = {
  ...upgradeTechToTier(performanceSurgery, 3),
  id: "066dd6c2-3f89-471c-9931-f0e102525f54",
};

const drivingSurgery3: TechDetails = {
  ...upgradeTechToTier(drivingSurgery, 3),
  id: "8e5a5a5e-7aeb-459e-a6dd-310506806f36",
};

const engineeringSurgery3: TechDetails = {
  ...upgradeTechToTier(engineeringSurgery, 3),
  id: "b5ec7844-2749-4ffd-bb70-373d140f8f5b",
};

const demolitionSurgery3: TechDetails = {
  ...upgradeTechToTier(demolitionSurgery, 3),
  id: "fe7d6558-9ff8-43f0-8083-4869d50e1545",
};

const securitySurgery3: TechDetails = {
  ...upgradeTechToTier(securitySurgery, 3),
  id: "4101aa07-2270-4c56-b1d4-609b93981505",
};

const hackingSurgery3: TechDetails = {
  ...upgradeTechToTier(hackingSurgery, 3),
  id: "84e549c6-030c-49cd-873a-da788ef7578e",
};

const researchSurgery3: TechDetails = {
  ...upgradeTechToTier(researchSurgery, 3),
  id: "107cbf07-a9c0-4ae3-88a8-affe67d9fbb5",
};

const environmentalAnalysisSurgery3: TechDetails = {
  ...upgradeTechToTier(environmentalAnalysisSurgery, 3),
  id: "ef376830-ba81-406c-b164-ae2a1c662252",
};

const perceptionSurgery3: TechDetails = {
  ...upgradeTechToTier(perceptionSurgery, 3),
  id: "f6fc51c7-1018-49b2-bd1c-3f1bde30188c",
};

const persuasionSurgery3: TechDetails = {
  ...upgradeTechToTier(persuasionSurgery, 3),
  id: "c8c30142-cb7a-4111-b191-1150cc90c88a",
};

const empathySurgery3: TechDetails = {
  ...upgradeTechToTier(empathySurgery, 3),
  id: "552d4203-7e40-4d6a-9aad-59aab9627f0e",
};

const medicalSurgery3: TechDetails = {
  ...upgradeTechToTier(medicalSurgery, 3),
  id: "1c26be7e-04d8-424c-a680-e440ff5df333",
};

const handToHandSurgery3: TechDetails = {
  ...upgradeTechToTier(handToHandSurgery, 3),
  id: "51fe6896-6972-4949-a9c9-5ad97f31f508",
};

// =============================================================================================

const timeDilation: ActionDetails = {
  id: "93aada6f-975b-4ddf-b9d6-e38e0b33b141",
  displayName: "Dilate Time",
  description: `Simulate the minds of up to 10 willing targets for X minutes within a single second. During this simulated time the targets can converse and examine their environment, but they can't physically move or interact with the world.`,
  targetType: TargetType.character,
  targetDrone: 1,
  minTargets: 1,
  maxTargets: 10,
  utilizes: {
    attention: true,
  },
  duration: 1,
  cooldown: 60,
  skill: "hacking",
  isFirewallable: false,
  price: 5000,
  logFormat: {
    base: "run the Time Dilation program for {targetDisplayNames}, simulating {rollResult} minutes",
  },
};

const timeDilationProgram: TechDetails = {
  id: "3afd7686-73cf-4181-b8ad-5dce33d5a8dd",
  tier: 3,
  roles: [Role.hacker],
  maxEquippable: 1,
  displayName: "Time Dilation Program",
  image: techImage("program"),
  isEquippable: true,
  description: `This program allows a hacker to simulate the minds of up to 10 willing targets for X minutes within a single second. During this simulated time the targets can converse and examine their environment, but they can't physically move or interact with the world.`,
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 2 }],
  actions: [timeDilation],
  price: 0,
};

const foresight: ActionDetails = {
  id: "cfeb2b10-686f-468e-b59f-93614543c7c9",
  displayName: "Foresight",
  description: ``,
  targetType: TargetType.self,
  targetDrone: 1,
  minTargets: 1,
  maxTargets: 1,
  utilizes: {
    attention: true,
  },
  duration: 10,
  cooldown: 600,
  skill: "hacking",
  isFirewallable: false,
  price: 5000,
  logFormat: {
    base: "run the Foresight program, providing insight into the next {rollResult} days",
  },
};

const foresightProgram: TechDetails = {
  id: "2be7af36-ca35-455b-b27d-f0d9865d0c60",
  tier: 3,
  roles: [Role.hacker],
  maxEquippable: 1,
  displayName: "Foresight Program",
  image: techImage("program"),
  isEquippable: true,
  description: `This program allows you to scan a target and reveal what tech they're using. Outside of combat this program allows you to attempt skill challenges that reveal the capabilities and active connections of a network, e.g. it might tell you how many people are inside a building.`,
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 2 }],
  actions: [foresight],
  price: 0,
};

const defrag: ActionDetails = {
  id: "c7987b14-69d6-4f2a-a5a7-49cb703a93a3",
  displayName: "Defrag",
  description: `Defrag your hacking systems to reduce the cooldowns of all Tier 1 programs by X seconds. This puts a lot of strain on your system, dealing one organic damage.`,
  targetType: TargetType.self,
  targetDrone: 1,
  minTargets: 1,
  maxTargets: 10,
  utilizes: {
    attention: true,
  },
  duration: 1,
  cooldown: 60,
  skill: "hacking",
  isFirewallable: false,
  damage: {
    [DamageType.organic]: {
      multiplier: 0,
      flat: 1,
    },
  },
  cooldownReduction: {
    [overheat.id]: {
      multiplier: 1,
    },
    [interferenceConfused.id]: {
      multiplier: 1,
    },
    [interferenceDazed.id]: {
      multiplier: 1,
    },
    [interferenceParalyzed.id]: {
      multiplier: 1,
    },
    [scan.id]: {
      multiplier: 1,
    },
  },
  price: 5000,
  logFormat: {
    base: "run the Defrag program ",
    success:
      "reducing the cooldowns of Tier 1 programs by {rollResult} seconds",
    failure: "but failed",
  },
};

const defragProgram: TechDetails = {
  id: "a28abd6d-45de-4644-a150-33207f97f41f",
  tier: 3,
  roles: [Role.hacker],
  maxEquippable: 1,
  displayName: "Defrag Program",
  image: techImage("program"),
  isEquippable: true,
  description: `This program allows you to defrag your hacking systems to reduce the cooldowns of all Tier 1 programs by X seconds. This puts a lot of strain on your system.`,
  statModifiers: [{ stat: "currentHeadCapacity", modifier: 2 }],
  actions: [defrag],
  price: 0,
};

const lazarus: TechDetails = {
  id: "968d533e-9c50-4e5e-9b4f-3f27fcfdc61e",
  tier: 3,
  roles: [
    Role.bruiser,
    Role.gunner,
    Role.hacker,
    Role.socialite,
    Role.infiltrator,
    Role.engineer,
    Role.medic,
  ],
  displayName: "Lazarus Procedure",
  description: `This procedure restores a recently deceased person to life. It requires that the body is mostly intact and died in the last 24 hours. You can only perform this procedure a handful of times before there isn't enough original material left to stitch back together.`,
  maxEquippable: 4,
  price: 50000,
};

// const reinforcedSkeleton2: TechDetails = {
//   id: "31fd7f99-081c-483e-88be-20cf4ca89b51",
//   tier: 3,
//   roles: [Role.bruiser],
//   displayName: "Improved Reinforced Skeleton",
//   maxEquippable: 1,
//   isEquippable: true,
//   description: "Improved Reinforced Skeleton",
//   statModifiers: [
//     { stat: "currentTorsoCapacity", modifier: 6 },
//     { stat: "currentArmsCapacity", modifier: 6 },
//     { stat: "currentLegsCapacity", modifier: 6 },
//     { stat: "physicalPower", modifier: 3 },
//     { stat: "handToHand", modifier: 1 },
//     { stat: "maxShield", modifier: 20 },
//   ],
//   price: 0,
// };

export const actionsList = {
  attack,
  sneakAttack,
  markTarget,
  droneAttack,
  deployDrone,
  undeployDrone,
  suppressRecoil,
  grapple,
  move,
  dash,
  chase,
  skillChallenge,
  dodge,
  defend,
  unreadyWeapon,
  readyPistol1,
  readyRifle1,
  readyKnife1,
  readyStunBaton,
  firstAid,
  firstAidAttention,
  emergencyAmphetamines,
  emergencyAmphetaminesAttention,
  launchGrenade,
  launchFlashbang,
  readyGrenadeLauncher,
  breachFirewall,
  boostFirewall,
  hackCommunications,
  interferenceConfused,
  interferenceDazed,
  interferenceParalyzed,
  scan,
  engage,
  repairCybernetics,
  repairCyberneticsAttention,
  selfDestruct,
  taze,
  droneMove,
  repairDrone,
  charge,
  readySword,
  readyFlameSword,
  readyPdw,
  readyMachineGun,
  shutdown,
  overheat,
  combatDrugsAmped,
  combatDrugsFocused,
  combatDrugsStimulated,
  combatDrugsAmpedAttention,
  combatDrugsFocusedAttention,
  combatDrugsStimulatedAttention,
  camouflage,
  hide,
  search,
  douseFire,
  douseFireAttention,
  defrag,
  foresight,
  timeDilation,
};

export function actionById(id: string) {
  return Object.values(actionsList).filter((a) => a.id === id)[0];
}

export function actionsBySharedCooldown(cooldown: string) {
  return Object.values(actionsList).filter(
    (a) => a.sharedCooldown === cooldown
  );
}

export const techList = {
  headProtection,
  reinforcedMuscles,
  shellProtection,
  combatDrugs,
  droneAmmoRound,
  lightAmmoClip,
  heavyAmmoClip,
  processorShielding,
  headTier1,
  armsTier1,
  legsTier1,
  torsoTier1,
  shellTier1,
  processingCore1,
  improvedFirewall1,
  // breachFirewallProgram,
  // boostFirewallProgram,
  hackCommunicationsProgram,
  cyberEyes1,
  dataLink1,
  droneLink1,
  explosivesTargeting1,
  forensicAnalyser1,
  theoryOfMind1,
  dialecticSubroutines,
  sinceritySubroutines,
  threatSubroutines,
  cardiovascularBoost2,
  shockAbsorbers1,
  jackedArms1,
  brassKnuckles1,
  surgicalTooling1,
  amphetamineInjector,
  securityTooling1,
  heavyRecoilSuppression1,
  lightAimAssist1,
  vitalsProtection1,
  meleeCombatTracker1,
  emergencyLifeSupport,
  // pheromone1,
  striking1,
  autoLink1,
  car1,
  knife1,
  pistol1,
  rifle1,
  protectiveClothing,
  stunBaton,
  // grenadeLauncher,
  grenadeSupply,
  flashbangSupply,
  firstAidKit,
  headTier2,
  armsTier2,
  legsTier2,
  torsoTier2,
  shellTier2,
  activeCamouflage2,
  vitalsProtection2,
  surgicalTooling2,
  processingCore2,
  ferocity2,
  cyberneticsRepairKit,
  improvedFirewall2,
  autoLink2,
  droneLink2,
  interferenceProgram,
  scanProgram,
  cardiovascularBoost,
  biologicalScanner2,
  targetingSystem2,
  reflexAcceleration2,
  muscleNanoweave2,
  idScrambler,
  shellProtection2,
  extrasensoryArray2,
  forensicAnalyser2,
  explosivesTargeting2,
  emotionalFeedbackLoop2,
  precisionMovementServos2,
  dataLink2,
  hulkingForm2,
  logicModeling2,
  overchargedLocomotion,
  electrifiedFists,
  bodyArmour,
  shutdownProgram,
  overheatProgram,
  droneFrame,
  droneFlyingModule,
  droneGunModule,
  droneShieldingModule,
  droneLocomotionModule,
  dronesRepairKit,
  droneSpeedModule,
  droneStealthModule,
  droneRangeExtenderModule,
  droneSecurityBypassModule,
  droneHologramModule,
  droneStorageModule,
  pdw,
  machineGun,
  flameSword,
  sword,
  targetAcquisitionAssistant,
  trackingProgram,
  emergencyLifeSupport2,
  headProtection2,
  nervousSystemShielding,
  muscularAmplifiers,
  headTier3,
  armsTier3,
  legsTier3,
  shellTier3,
  torsoTier3,
  lazarus,
  securityTooling2,
  athleticsSurgery,
  physicalPowerSurgery,
  intimidationSurgery,
  heavyGunsSurgery,
  lightGunsSurgery,
  stealthSurgery,
  deceptionSurgery,
  performanceSurgery,
  drivingSurgery,
  engineeringSurgery,
  demolitionSurgery,
  securitySurgery,
  hackingSurgery,
  researchSurgery,
  environmentalAnalysisSurgery,
  perceptionSurgery,
  persuasionSurgery,
  empathySurgery,
  medicalSurgery,
  handToHandSurgery,
  fireSuppressor,

  // Tier 3
  advancedShielding3,
  vitalsProtection3,
  headProtection3,
  processorShielding3,
  nervousSystemShielding3,
  muscularAmplifiers3,
  activeCamouflage3,
  surgicalTooling3,
  securityTooling3,
  processingCore3,
  ferocity3,
  autoLink3,
  droneLink3,
  cardiovascularBoost3,
  biologicalScanner3,
  advancedTargeting3,
  reflexAcceleration3,
  muscleNanoweave3,
  extrasensoryArray3,
  forensicAnalyser3,
  explosivesTargeting3,
  emotionalFeedbackLoop3,
  precisionMovementServos3,
  dataLink3,
  hulkingForm3,
  logicModeling3,
  athleticsSurgery3,
  physicalPowerSurgery3,
  intimidationSurgery3,
  heavyGunsSurgery3,
  lightGunsSurgery3,
  stealthSurgery3,
  deceptionSurgery3,
  performanceSurgery3,
  drivingSurgery3,
  engineeringSurgery3,
  demolitionSurgery3,
  securitySurgery3,
  hackingSurgery3,
  researchSurgery3,
  environmentalAnalysisSurgery3,
  perceptionSurgery3,
  persuasionSurgery3,
  empathySurgery3,
  medicalSurgery3,
  handToHandSurgery3,
  timeDilationProgram,
  defragProgram,
  foresightProgram,
};

const modifierValues: Stats = {
  maxHeadCapacity: 50,
  currentHeadCapacity: 0,
  maxTorsoCapacity: 50,
  currentTorsoCapacity: 0,
  maxLegsCapacity: 50,
  currentLegsCapacity: 0,
  maxShellCapacity: 50,
  currentShellCapacity: 0,
  maxArmsCapacity: 50,
  currentArmsCapacity: 0,
  maxCarryingCapacity: 50,
  currentCarryingCapacity: 0,
  currentHealth: 0,
  maxHealth: 0,
  currentShield: 0,
  maxShield: 50,
  currentDroneShield: 0,
  maxDroneShield: 50,
  droneMovementSpeed: 200,
  droneKineticDamage: 300,
  combatMovementSpeed: 0,
  firewall: 400,
  firewallRegen: 400,
  unarmedDamage: 400,
  unarmedElectricalDamage: 500,
  size: 0,
  // resistances
  [DamageType.kinetic]: 400,
  [DamageType.thermal]: 400,
  [DamageType.electrical]: 400,
  [Condition.stunned]: 600,
  [Condition.confused]: 400,
  [Condition.crippled]: 400,
  [Condition.clumsy]: 400,
  [Condition.burning]: 600,
  // unused
  [DamageType.organic]: 0,
  [Condition.frozenFirewall]: 0,
  [Condition.jammed]: 0,
  [Condition.focused]: 0,
  [Condition.amped]: 0,
  [Condition.stimulated]: 0,
  [Condition.camouflaged]: 0,
  [Condition.unstable]: 0,
  [Condition.invigorated]: 0,
  [Condition.doused]: 0,
  // skills
  engineering: 400,
  hacking: 400,
  medical: 400,
  empathy: 400,
  performance: 400,
  persuasion: 400,
  deception: 400,
  intimidation: 400,
  perception: 400,
  environmentalAnalysis: 400,
  research: 400,
  stealth: 400,
  security: 400,
  athletics: 400,
  handToHand: 400,
  heavyGuns: 400,
  lightGuns: 400,
  physicalPower: 400,
  driving: 400,
  demolition: 400,
};
for (const tech of Object.values(techList)) {
  if (tech.statModifiers) {
    for (const modifier of tech.statModifiers) {
      tech.price +=
        modifier.modifier *
        modifierValues[modifier.stat] *
        Math.max(1, Math.pow(5, tech.tier - 1));
    }
  }
  if (tech.actions) {
    for (const action of tech.actions) {
      tech.price += action.price || 0;
    }
  }
}

export function techById(id: string) {
  return Object.values(techList).filter((a) => a.id === id)[0];
}
