import { Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Table, Text } from "@arwes/core";
import { EditableCampaign } from "./useCampaign";
import { ConfirmButton, LoadingBars, WideFrameLines } from "../components";
import { SituationLimit, useCreateSituation } from "../situations";
import { useHistory, useRouteMatch } from "react-router";
import {
  useCampaignSituationList,
  useDeleteSituation,
} from "../situations/useSituation";
import { Link } from "../navigation";
import { useAccount, useSubscription } from "../account";
import { useCreateMap } from "../map";
import { useDeleteMap } from "../map/useMap";
import { MapLimit } from "../map/MapLimit";
import { useFirebaseApp } from "reactfire";

interface CampaignSituationsProps {
  editMode: boolean;
  loading: boolean;
  campaign: EditableCampaign;
}

export const CampaignSituations: React.FC<CampaignSituationsProps> = (
  props
) => {
  const { editMode, loading, campaign } = props;

  const createSituation = useCreateSituation();
  const deleteSituation = useDeleteSituation();
  const [loadingSituations, setLoadingSituations] = useState(
    {} as Record<string, boolean>
  );

  const createMap = useCreateMap();
  const deleteMap = useDeleteMap();

  const history = useHistory();

  const subscription = useSubscription();
  const account = useAccount();
  const firebaseApp = useFirebaseApp();

  return (
    <>
      <Grid container>
        <Grid item container justify="space-between">
          <Grid item>
            <Text as="h4">Situations</Text>
          </Grid>
          <Grid item>
            <Button
              onClick={async () => {
                if (
                  (account.totalSituations || 0) >= 5 &&
                  !subscription.hasSubscription()
                ) {
                  history.push("/subscription");
                } else {
                  const situation = await createSituation({
                    campaignId: campaign.id,
                  });
                  history.push(`/situations/${situation.id}`);
                }
              }}
            >
              <Text>Create situation</Text>
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ paddingBottom: "16px" }}>
          <SituationLimit />
        </Grid>
        <Table
          headers={[
            { id: "name", data: "Name" },
            { id: "actions", data: "Actions" },
          ]}
          dataset={Object.keys(campaign.state.situations)
            .sort((a, b) => {
              if (
                campaign.state.situations[a].displayName <
                campaign.state.situations[b].displayName
              ) {
                return -1;
              }
              if (
                campaign.state.situations[a].displayName >
                campaign.state.situations[b].displayName
              ) {
                return 1;
              }
              return 0;
            })
            .map((situationId) => {
              return {
                id: situationId,
                columns: [
                  {
                    id: "name",
                    data: campaign.state.situations[situationId].displayName,
                  },
                  {
                    id: "actions",
                    data: (
                      <Grid container justify="center">
                        {loadingSituations[situationId] ? (
                          <LoadingBars />
                        ) : (
                          <>
                            <Link href={`/situations/${situationId}`}>
                              <Button>
                                <Text>View</Text>
                              </Button>
                            </Link>
                            <ConfirmButton
                              onClick={() => {
                                setLoadingSituations({
                                  ...loadingSituations,
                                  [situationId]: true,
                                });
                                deleteSituation(situationId);
                              }}
                            >
                              <Text>Delete</Text>
                            </ConfirmButton>
                          </>
                        )}
                      </Grid>
                    ),
                  },
                ],
              };
            })}
          columnWidths={["calc(100% - 11em)", "11em"]}
        />
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
        <Grid item container justify="space-between">
          <Grid item>
            <Text as="h4">Locations</Text>
          </Grid>
          <Grid item>
            <Button
              onClick={async () => {
                if (
                  (account.totalMaps || 0) >= 5 &&
                  !subscription.hasSubscription()
                ) {
                  history.push("/subscription");
                } else {
                  const map = await createMap({
                    campaignId: campaign.id,
                  });
                  history.push(`/maps/${map.id}`);
                }
              }}
            >
              <Text>Create map</Text>
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ paddingBottom: "16px" }}>
          <MapLimit />
        </Grid>
        <Table
          headers={[
            { id: "name", data: "Name" },
            { id: "actions", data: "Actions" },
          ]}
          dataset={Object.keys(campaign.state.maps)
            .sort((a, b) => {
              if (
                campaign.state.maps[a].displayName <
                campaign.state.maps[b].displayName
              ) {
                return -1;
              }
              if (
                campaign.state.maps[a].displayName >
                campaign.state.maps[b].displayName
              ) {
                return 1;
              }
              return 0;
            })
            .map((mapId) => {
              return {
                id: mapId,
                columns: [
                  {
                    id: "name",
                    data: campaign.state.maps[mapId].displayName,
                  },
                  {
                    id: "actions",
                    data: (
                      <Grid container justify="center">
                        {loadingSituations[mapId] ? (
                          <LoadingBars />
                        ) : (
                          <>
                            <Link href={`/maps/${mapId}`}>
                              <Button>
                                <Text>View</Text>
                              </Button>
                            </Link>
                            <Button
                              onClick={async () => {
                                if (
                                  (account.totalMaps || 0) >= 5 &&
                                  !subscription.hasSubscription()
                                ) {
                                  history.push("/subscription");
                                } else {
                                  setLoadingSituations({
                                    ...loadingSituations,
                                    [mapId]: true,
                                  });
                                  const mapData = (
                                    await firebaseApp
                                      .firestore()
                                      .collection("maps")
                                      .doc(mapId)
                                      .get()
                                  ).data();
                                  const newMap = await createMap({
                                    ...mapData,
                                    campaignId: campaign.id,
                                  });
                                  history.push(`/maps/${newMap.id}`);
                                }
                              }}
                            >
                              <Text>Duplicate</Text>
                            </Button>
                            <ConfirmButton
                              onClick={() => {
                                setLoadingSituations({
                                  ...loadingSituations,
                                  [mapId]: true,
                                });
                                deleteMap(mapId);
                              }}
                            >
                              <Text>Delete</Text>
                            </ConfirmButton>
                          </>
                        )}
                      </Grid>
                    ),
                  },
                ],
              };
            })}
          columnWidths={["max(50%, calc(100% - 18em))", "min(50%, 18em)"]}
        />
      </Grid>
    </>
  );
};
