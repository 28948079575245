import React, { useEffect, useState } from "react";
import { PageLayout } from "../layout";
import { Grid } from "@material-ui/core";
import { Button, Card, Text } from "@arwes/core";

import { FrameContent } from "../layout/FrameContent";
import { LoadingBars } from "../components";
import { AnimatedRouteChildrenProps } from "../navigation/AnimatedRoute";
import firebase, { User } from "firebase";
import { useFirestore, useFirestoreCollection, useUser } from "reactfire";
import { useSubscription } from "./useSubscription";
import styled from "styled-components";

const StyledCard = styled(Card)`
  img {
    image-rendering: pixelated;
  }
`;

export const SubscriptionPage: React.FC<AnimatedRouteChildrenProps> = (
  props
) => {
  const { activate } = props;

  const [loading, setLoading] = useState(false);

  const currentUser = useUser<User>();

  const subscription = useSubscription();

  const checkoutRef = useFirestore()
    .collection("users")
    .doc(currentUser.uid)
    .collection("checkout_sessions");

  useEffect(() => {
    document.title = `Subscription`;
  }, []);

  return (
    <PageLayout>
      <FrameContent
        animator={{ root: true, activate }}
        style={{ padding: "16px" }}
      >
        <Grid container direction="column">
          <Grid item>
            <Text as="h3">Subscription</Text>
          </Grid>
          <Grid item container spacing={2} justify="space-around">
            <Grid item xs={12} sm={6}>
              <StyledCard
                image={{
                  src: "/images/street-kid.png",
                }}
                title="Pawn"
                options={<Text>Free</Text>}
              >
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Text>
                      • Everything you need to play in Archive games or get
                      started running your own
                    </Text>
                  </Grid>
                  <Grid item>
                    <Text>• 10 characters</Text>
                  </Grid>
                  <Grid item>
                    <Text>• 1 campaign</Text>
                  </Grid>
                  <Grid item>
                    <Text>• 5 situations</Text>
                  </Grid>
                  <Grid item>
                    <Text>• 5 locations</Text>
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FrameContent
                palette={
                  subscription.hasSubscription() ? "secondary" : "primary"
                }
              >
                <StyledCard
                  image={{
                    src: "/images/cyborg-on-roof.png",
                  }}
                  title="Dragon"
                  options={
                    loading ? (
                      <LoadingBars />
                    ) : subscription.hasSubscription() ? (
                      <Button
                        onClick={async () => {
                          setLoading(true);
                          try {
                            const functionRef = firebase
                              .app()
                              .functions("us-central1")
                              .httpsCallable(
                                "ext-firestore-stripe-subscriptions-createPortalLink"
                              );
                            const { data } = await functionRef({
                              returnUrl: window.location.origin,
                            });
                            window.location.assign(data.url);
                          } catch (error) {
                            // do something with the error
                            setLoading(false);
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <>
                        <Text>$4.99 per month </Text>{" "}
                        <Button
                          palette="secondary"
                          onClick={async () => {
                            setLoading(true);
                            const docRef = await checkoutRef.add({
                              price: "price_1JKf4gEs5Si1Mj5GCf8rMjRw",
                              success_url: window.location.origin,
                              cancel_url: `${window.location.origin}/subscription`,
                            });
                            // Wait for the CheckoutSession to get attached by the extension
                            docRef.onSnapshot((snap) => {
                              const { error, url } = snap.data()!;
                              if (error) {
                                // Show an error to your customer and
                                // inspect your Cloud Function logs in the Firebase console.
                                alert(`An error occured: ${error.message}`);
                                setLoading(false);
                              }
                              if (url) {
                                // We have a Stripe Checkout URL, let's redirect.
                                window.location.assign(url);
                              }
                            });
                          }}
                        >
                          <Text>Subscribe</Text>
                        </Button>
                      </>
                    )
                  }
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Text>
                        • Everything you need to run full-fledged Archive games
                      </Text>
                    </Grid>
                    <Grid item>
                      <Text>• Unlimited characters</Text>
                    </Grid>
                    <Grid item>
                      <Text>• Unlimited campaigns</Text>
                    </Grid>
                    <Grid item>
                      <Text>• Unlimited situations</Text>
                    </Grid>
                    <Grid item>
                      <Text>• Unlimited locations</Text>
                    </Grid>
                    <Grid item>
                      <Text>• Dynamic map vision</Text>
                    </Grid>
                  </Grid>
                </StyledCard>
              </FrameContent>
            </Grid>
          </Grid>
        </Grid>
      </FrameContent>
    </PageLayout>
  );
};
