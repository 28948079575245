import { Container, Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StyledContainer = styled(Container)`
  margin-top: 16px;
`;

export const PageLayout: React.FC = (props) => (
  <StyledContainer>
    <Grid container justify="center" style={{ minHeight: "400px" }}>
      <Grid container item xs md={10} xl={12}>
        {props.children}
      </Grid>
    </Grid>
  </StyledContainer>
);
