import { Button, Table, Text } from "@arwes/core";
import { DialogActions, Grid, InputLabel, Switch } from "@material-ui/core";
import { camelCase } from "lodash";
import React, { useEffect, useState } from "react";
import { EditableCampaign } from "../campaign";
import {
  actionById,
  ActionDetails,
  actionsBySharedCooldown,
  actionsList,
  ActionUtilization,
  TargetType,
  techById,
  techList,
} from "../character/tech";
import { Avatar } from "../character/Avatar";
import {
  useCampaignPlayerList,
  useSituationNpcList,
} from "../character/useCharacter";
import { LoadingButton } from "../components";
import { FrameContent, Modal } from "../layout";
import {
  EditableSituation,
  useCharacterActionsInSituation,
  useEditableSituation,
  useSetCharacterActionsInSituation,
} from "../situations/useSituation";
import { ActionModal } from "../character/CharacterAbilities";
import { FullCharacter } from "../character/utils";
import styled from "styled-components";
import { TargetController, TerrainInterference } from "./useTargetController";

const TabButton = styled(Button)`
  width: 50%;
`;

function utilizationToString(utilization: ActionUtilization = {}) {
  return `[ ${utilization.attention ? "A " : ""}${
    utilization.interaction ? "I " : ""
  }${utilization.movement ? "M " : ""}]`;
}

export const ActionsControl: React.FC<{
  situation: EditableSituation;
  campaign: EditableCampaign;
  character: FullCharacter;
  targetController: TargetController;
  targets: string[];
  showHidden?: boolean;
}> = (props) => {
  const {
    situation,
    character,
    showHidden,
    targets,
    campaign,
    targetController,
  } = props;
  const currentActions = useCharacterActionsInSituation(
    character.id,
    situation.id
  );

  const setCharacterActionsInSituation = useSetCharacterActionsInSituation();
  const [initiatedAction, setInitiatedAction] = useState(null as null | any);

  const [selectedTargets, setSelectedTargets] = useState(
    {} as Record<string, boolean>
  );

  const [selectedDrones, setSelectedDrones] = useState(
    {} as Record<string, boolean>
  );

  // Action details state
  const [actionDetails, setActionDetails] = useState(
    undefined as ActionDetails | undefined
  );

  const [activeTab, setActiveTab] = useState("actions");

  const [activeAction, setActiveAction] = useState(null as null | string);

  useEffect(() => {
    if (targetController.targetType === null) {
      setActiveAction(null);
    }
  }, [targetController.targetType]);

  const targetKeys = targetController.targets.map(
    (target) => `${target.q}-${target.r}`
  );

  return (
    <Grid container item direction="column">
      <InitiateActionModal
        initiatedAction={initiatedAction}
        handleClose={() => setInitiatedAction(null)}
        character={character}
        campaign={campaign}
        situation={situation}
        selectedTargets={selectedTargets}
        setSelectedTargets={setSelectedTargets}
        selectedDrones={selectedDrones}
        setSelectedDrones={setSelectedDrones}
      />

      {/* <Grid item container xs={12} sm={4} alignContent="flex-start"> */}
      {/* <Grid item container xs={12}> */}
      <Grid item container direction="row">
        <Grid item xs>
          <Text as="h4">Actions</Text>
        </Grid>
        <Grid item direction="column">
          <Button
            key={`${currentActions.ready}`}
            palette={currentActions.ready ? "success" : "error"}
            onClick={() => {
              return setCharacterActionsInSituation(
                character.id,
                {
                  ...currentActions,
                  ready: !currentActions.ready,
                },
                situation.id
              );
            }}
          >
            <Text>{currentActions.ready ? "Ready" : "Ready"}</Text>
          </Button>
          {/* <InputLabel shrink>Ready</InputLabel> */}
          {/* <Switch
            checked={currentActions.ready}
            onChange={(event) => {
              return setCharacterActionsInSituation(
                character.id,
                {
                  ...currentActions,
                  ready: event.target.checked,
                },
                situation.id
              );
            }}
          /> */}
        </Grid>
      </Grid>
      <Grid item style={{ paddingBottom: "16px" }}>
        <TabButton
          palette={activeTab === "actions" ? "primary" : "tabs"}
          onClick={() => setActiveTab("actions")}
          active={true}
        >
          <Text>Actions</Text>
        </TabButton>
        <TabButton
          palette={activeTab === "current" ? "primary" : "tabs"}
          onClick={() => setActiveTab("current")}
          active={true}
        >
          <Text>
            In Progress (
            {currentActions.actions?.filter((action) =>
              action.actionId === actionsList.skillChallenge.id
                ? situation.state.skillChallenges[action.targets[0]]?.visible ||
                  showHidden
                : true
            ).length || 0}
            )
          </Text>
        </TabButton>
      </Grid>
      <Grid item xs>
        <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
          {activeTab === "actions" ? (
            <Table
              key="actions"
              headers={[
                { id: "name", data: "Name" },
                { id: "cooldown", data: "Cooldown" },
                { id: "actions", data: "Actions" },
              ]}
              dataset={[
                ...Object.keys(situation.state.skillChallenges)
                  .map((key) => ({
                    challengeId: key,
                    targetType: TargetType.skill as TargetType.skill,
                    cooldown: 0,
                    ...situation.state.skillChallenges[key]!,
                  }))
                  .filter(
                    (skillChallenge) => skillChallenge.visible || showHidden
                  )
                  .sort((a, b) => {
                    if (a.displayName < b.displayName) {
                      return -1;
                    }
                    if (a.displayName > b.displayName) {
                      return 1;
                    }
                    return 0;
                  }),
                ...character.actions.sort((a, b) => {
                  if (a.displayName < b.displayName) {
                    return -1;
                  }
                  if (a.displayName > b.displayName) {
                    return 1;
                  }
                  return 0;
                }),
              ].map((action, index) => {
                const isSkillChallenge = !("id" in action);
                const actionId =
                  "id" in action ? action.id : actionsList.skillChallenge.id;
                const actionDetails = actionById(actionId);
                const cooldownTimer = actionDetails.sharedCooldown
                  ? Math.max(
                      ...actionsBySharedCooldown(
                        actionDetails.sharedCooldown
                      ).map((action) =>
                        Math.max(
                          action.cooldown -
                            (situation.state.time -
                              (currentActions?.lastActivations?.[action.id] ||
                                -1000)),
                          0
                        )
                      )
                    )
                  : Math.max(
                      action.cooldown -
                        (situation.state.time -
                          (currentActions?.lastActivations?.[actionId] ||
                            -1000)),
                      0
                    );

                const activateAction = (targets: string[]) => {
                  const adjustedTargets =
                    action.targetType === TargetType.self
                      ? [character.id]
                      : action.targetType === TargetType.skill &&
                        !("id" in action)
                      ? [action.challengeId]
                      : targets;
                  setCharacterActionsInSituation(
                    character.id,
                    {
                      ...currentActions,
                      ready: false,
                      actions: [
                        ...(currentActions.actions || []),
                        {
                          id: actionId,
                          actionId: actionId,
                          targets: adjustedTargets,
                          timeStarted: situation.state.time,
                          ...("id" in action
                            ? {}
                            : {
                                skillChallengeId: action.challengeId,
                              }),
                        },
                      ],
                    },
                    situation.id
                  );
                };

                function done() {
                  setActiveAction(null);
                  activateAction(targetController.targets);
                  targetController.clear();
                }

                if (
                  activeAction === (action as any).id &&
                  (targetController.targets.length >=
                    targetController.maxTargets ||
                    (targetController.targetType === TargetType.path &&
                      new Set(targetKeys).size !== targetKeys.length))
                ) {
                  done();
                }

                return {
                  id: !isSkillChallenge ? action.id : action.challengeId,
                  columns: [
                    {
                      id: "name",
                      data:
                        "id" in action ? (
                          <div
                            onClick={() => {
                              setActionDetails(action);
                            }}
                          >
                            {action.displayName +
                              " " +
                              utilizationToString(action.utilizes) +
                              " 🔍"}
                          </div>
                        ) : (
                          action.displayName +
                          " " +
                          utilizationToString(action.utilizes)
                        ),
                    },
                    {
                      id: "cooldown",
                      data: cooldownTimer,
                    },
                    {
                      id: "actions",
                      data: (
                        <Grid container justify="center">
                          {activeAction === (action as any).id ? (
                            <>
                              <Button
                                palette="secondary"
                                onClick={done}
                                disabled={
                                  targetController.maxTargets > 0 &&
                                  targetController.targets.length === 0
                                }
                              >
                                <Text>{" ✔ "}</Text>
                              </Button>
                              <Button
                                palette="secondary"
                                onClick={() => {
                                  targetController.clear();
                                  setActiveAction(null);
                                }}
                              >
                                <Text>{" ✖ "}</Text>
                              </Button>
                            </>
                          ) : (
                            <Button
                              key="initiate"
                              disabled={
                                currentActions.actions
                                  ?.filter((action) =>
                                    action.actionId ===
                                    actionsList.skillChallenge.id
                                      ? situation.state.skillChallenges[
                                          action.targets[0]
                                        ]
                                      : true
                                  )
                                  .some((a) => {
                                    const aDetails =
                                      a.actionId ===
                                      actionsList.skillChallenge.id
                                        ? situation.state.skillChallenges[
                                            a.targets[0]
                                          ]!
                                        : actionById(a.actionId);
                                    const aUtilizationNumber = Object.values(
                                      aDetails.utilizes
                                    )
                                      .map((x) => (x ? 1 : 0) as number)
                                      .reduce((x, y) => x + y);
                                    const actionUtilizationNumber =
                                      Object.values(action.utilizes)
                                        .map((x) => (x ? 1 : 0) as number)
                                        .reduce((x, y) => x + y);

                                    return (
                                      (aDetails.utilizes.movement &&
                                        action.utilizes.movement) ||
                                      (aDetails.utilizes.interaction &&
                                        action.utilizes.interaction) ||
                                      (aDetails.utilizes.attention &&
                                        action.utilizes.attention)
                                      //     &&
                                      // (action.duration !== Infinity ||
                                      //   aDetails.duration !== Infinity ||
                                      //   aUtilizationNumber > 1 ||
                                      //   actionUtilizationNumber > 1)
                                    );
                                  }) ||
                                cooldownTimer > 0 ||
                                (!isSkillChallenge &&
                                  (action as any).requiredWeapon !==
                                    undefined &&
                                  situation.state.characters[character.id]
                                    ?.readyWeapon !==
                                    (action as any).requiredWeapon.id) ||
                                ((action as any).droneRequired &&
                                  (!situation.state.characters[character.id]
                                    ?.droneDeployed ||
                                    character.stats.currentDroneShield <= 0)) ||
                                ((action as any).consumes &&
                                  (!character.tech[
                                    (action as any).consumes.id
                                  ] ||
                                    character.tech[(action as any).consumes.id]
                                      .equippedCount === 0)) ||
                                ([
                                  actionsList.attack.id,
                                  actionsList.sneakAttack.id,
                                ].includes((action as any).id) &&
                                  situation.state.characters[character.id]
                                    ?.readyWeapon &&
                                  techById(
                                    situation.state.characters[character.id]!
                                      .readyWeapon!
                                  )?.ammo &&
                                  situation.state.characters[character.id]!
                                    .ammo <= 0) ||
                                activeAction !== null
                              }
                              onClick={() => {
                                if (
                                  !isSkillChallenge &&
                                  [
                                    TargetType.path,
                                    TargetType.character,
                                    TargetType.point,
                                  ].includes(action.targetType)
                                ) {
                                  targetController.setTargetType(
                                    action.targetType
                                  );
                                  if (action.maxTargets) {
                                    targetController.setMaxTargets(
                                      action.maxTargets
                                    );
                                  }

                                  if (action.radius) {
                                    targetController.setRadius(action.radius);
                                  }

                                  if (action.range) {
                                    targetController.setRange(action.range);
                                  }

                                  targetController.setTerrainInterference(
                                    action.isAttack
                                      ? TerrainInterference.canHitExposed
                                      : action.lineOfSight ||
                                        action.movementMultiplier ||
                                        action.droneMovementMultiplier
                                      ? TerrainInterference.blocked
                                      : TerrainInterference.ignored
                                  );

                                  if (action.droneRequired) {
                                    targetController.setSource(
                                      situation.state.characters[
                                        `drone-${character.id}`
                                      ]?.coordinates || null
                                    );
                                  } else {
                                    targetController.setSource(
                                      situation.state.characters[character.id]
                                        ?.coordinates || null
                                    );
                                  }

                                  setActiveAction(action.id);
                                } else {
                                  if (
                                    action.targetType === TargetType.self ||
                                    action.targetType === TargetType.skill
                                  ) {
                                    activateAction(targets);
                                  } else {
                                    setInitiatedAction({
                                      action,
                                      activateAction,
                                    });
                                  }
                                }
                              }}
                            >
                              <Text>{" > "}</Text>
                            </Button>
                          )}
                        </Grid>
                      ),
                    },
                  ],
                };
              })}
              columnWidths={["calc(57% - 2em)", "calc(43% - 3em)", "5em"]}
            />
          ) : (
            <Table
              key="in-progress"
              headers={[
                { id: "name", data: "Name" },
                { id: "time", data: "Time Left" },
                { id: "actions", data: "Actions" },
              ]}
              dataset={
                currentActions.actions
                  ?.filter((action) =>
                    action.actionId === actionsList.skillChallenge.id
                      ? situation.state.skillChallenges[action.targets[0]]
                          ?.visible || showHidden
                      : true
                  )
                  .map((action, index) => {
                    const id =
                      action.actionId === actionsList.skillChallenge.id
                        ? action.id
                        : action.actionId;
                    const actionDetails =
                      action.actionId === actionsList.skillChallenge.id
                        ? situation.state.skillChallenges[action.targets[0]]!
                        : actionById(action.actionId);
                    return {
                      id: `${id}-${index}`,
                      columns: [
                        {
                          id: "name",
                          data: (
                            <div onClick={() => {}}>
                              {actionDetails.displayName +
                                " " +
                                utilizationToString(actionDetails.utilizes)}
                            </div>
                          ),
                        },
                        {
                          id: "time",
                          data:
                            action.timeStarted +
                            actionDetails.duration -
                            situation.state.time,
                        },
                        {
                          id: "actions",
                          data: (
                            <Grid container justify="center">
                              {/* {"id" in actionDetails &&
                                actionDetails.targetType !==
                                  TargetType.self && (
                                  <Button
                                    onClick={() => {
                                      const activateAction = (
                                        targets: string[]
                                      ) => {
                                        const newActions = [
                                          ...currentActions.actions!,
                                        ];
                                        newActions[index].targets = targets;
                                        setCharacterActionsInSituation(
                                          character.id,
                                          {
                                            ...currentActions,
                                            ready: false,
                                            actions: newActions,
                                          },
                                          situation.id
                                        );
                                      };
                                      setSelectedTargets(
                                        currentActions
                                          .actions![index].targets.map(
                                            (target) => ({
                                              [target]: true,
                                            })
                                          )
                                          .reduce(
                                            (a, b) => ({ ...a, ...b }),
                                            {}
                                          )
                                      );
                                      setInitiatedAction({
                                        action: actionDetails,
                                        activateAction,
                                      });
                                    }}
                                  >
                                    <Text>Edit</Text>
                                  </Button>
                                )} */}
                              <LoadingButton
                                onClick={() =>
                                  setCharacterActionsInSituation(
                                    character.id,
                                    {
                                      ...currentActions,
                                      ...(situation.state.time !==
                                      action.timeStarted
                                        ? {
                                            lastActivations: {
                                              ...currentActions.lastActivations,
                                              [id]: situation.state.time,
                                            },
                                          }
                                        : {}),
                                      ready: false,
                                      actions: (
                                        currentActions.actions || []
                                      ).filter((_, i) => i !== index),
                                    },
                                    situation.id
                                  )
                                }
                              >
                                <Text>{" ✖ "}</Text>
                              </LoadingButton>
                            </Grid>
                          ),
                        },
                      ],
                    };
                  }) || []
              }
              columnWidths={["calc(58% - 2em)", "calc(42% - 3em)", "5em"]}
            />
          )}
        </div>
      </Grid>
      {/* </Grid> */}

      {/* <Grid item container xs={12} sm={4} alignContent="flex-start">
          <FrameContent>
            <Grid item xs={12}>
              <Text as="h4">In Progress</Text>
            </Grid>
            <Grid item xs>
              <Table
                headers={[
                  { id: "name", data: "Name" },
                  { id: "time", data: "Time Left" },
                  { id: "actions", data: "Actions" },
                ]}
                dataset={
                  currentActions.actions
                    ?.filter((action) =>
                      action.actionId === actionsList.skillChallenge.id
                        ? situation.state.skillChallenges[action.targets[0]]
                        : true
                    )
                    .map((action, index) => {
                      const id =
                        action.actionId === actionsList.skillChallenge.id
                          ? action.id
                          : action.actionId;
                      const actionDetails =
                        action.actionId === actionsList.skillChallenge.id
                          ? situation.state.skillChallenges[action.targets[0]]!
                          : actionById(action.actionId);
                      return {
                        id: `${id}-${index}`,
                        columns: [
                          {
                            id: "name",
                            data: (
                              <div onClick={() => {}}>
                                {actionDetails.displayName}
                              </div>
                            ),
                          },
                          {
                            id: "time",
                            data:
                              action.timeStarted +
                              actionDetails.duration -
                              situation.state.time,
                          },
                          {
                            id: "actions",
                            data: (
                              <Grid container justify="center">
                                {"id" in actionDetails &&
                                  actionDetails.targetType !==
                                    TargetType.self && (
                                    <Button
                                      onClick={() => {
                                        const activateAction = (
                                          targets: string[],
                                          drones: string[]
                                        ) => {
                                          const newActions = [
                                            ...currentActions.actions!,
                                          ];
                                          newActions[index].targets = targets;
                                          newActions[index].drones = drones;
                                          setCharacterActionsInSituation(
                                            character.id,
                                            {
                                              ...currentActions,
                                              ready: false,
                                              actions: newActions,
                                            },
                                            situation.id
                                          );
                                        };
                                        setSelectedTargets(
                                          currentActions
                                            .actions![index].targets.map(
                                              (target) => ({
                                                [target]: true,
                                              })
                                            )
                                            .reduce(
                                              (a, b) => ({ ...a, ...b }),
                                              {}
                                            )
                                        );
                                        setSelectedDrones(
                                          currentActions
                                            .actions![index].drones.map(
                                              (target) => ({
                                                [target]: true,
                                              })
                                            )
                                            .reduce(
                                              (a, b) => ({ ...a, ...b }),
                                              {}
                                            )
                                        );
                                        setInitiatedAction({
                                          action: actionDetails,
                                          activateAction,
                                        });
                                      }}
                                    >
                                      <Text>Edit</Text>
                                    </Button>
                                  )}
                                <LoadingButton
                                  onClick={() =>
                                    setCharacterActionsInSituation(
                                      character.id,
                                      {
                                        ...currentActions,
                                        ready: false,
                                        actions: (
                                          currentActions.actions || []
                                        ).filter((_, i) => i !== index),
                                      },
                                      situation.id
                                    )
                                  }
                                >
                                  <Text>Cancel</Text>
                                </LoadingButton>
                              </Grid>
                            ),
                          },
                        ],
                      };
                    }) || []
                }
                columnWidths={["calc(58% - 5em)", "calc(42% - 3em)", "8em"]}
              />
            </Grid>
          </FrameContent>
        </Grid> */}
      <ActionModal
        action={actionDetails}
        onClose={() => {
          setActionDetails(undefined);
        }}
      />
    </Grid>
  );
};

const InitiateActionModal: React.FC<{
  initiatedAction: any | null;
  handleClose(): any;
  situation: EditableSituation;
  campaign: EditableCampaign;
  character: FullCharacter;
  selectedTargets: any;
  setSelectedTargets: any;
  selectedDrones: any;
  setSelectedDrones: any;
}> = (props) => {
  const {
    initiatedAction,
    handleClose,
    character,
    situation,
    campaign,
    selectedTargets,
    setSelectedTargets,
  } = props;

  const players = useCampaignPlayerList(campaign.id);
  const npcs = useSituationNpcList(campaign.id, situation.id);
  const allCharacters = [...players, ...npcs]
    .map((character) => ({
      [character.id]: character,
      ...(situation.state.characters[character.id]?.droneDeployed
        ? { [`drone-${character.id}`]: character }
        : {}),
    }))
    .reduce((a, b) => ({ ...a, ...b }), {});
  // const drones = [...players, ...npcs]
  //   .filter((c) => situation.state.characters[c.id]?.droneDeployed)
  //   .map((c) => ({
  //     id: c.id,
  //     displayName: `Drone (${c.displayName})`,
  //   }));

  // const targets = Object.keys(selectedTargets).filter(
  //   (key) => selectedTargets[key]
  // );

  return (
    <Modal isOpen={!!initiatedAction} onClose={handleClose}>
      <Grid container xs={12} direction="column">
        <Grid item>
          <Text as="h4">Select Target(s)</Text>
        </Grid>
        <Grid item container spacing={2} justify="center">
          {
            // ...[
            //   ...players.filter(
            //     (character) =>
            //       campaign.state.charactersCurrentSituation[character.id] ===
            //       situation.id
            //   ),
            //   ...npcs.filter(
            //     (character) => situation.state.characters[character.id]?.visible
            //   ),
            // ]
            Object.keys(allCharacters).map((characterId) => (
              <Grid item key={characterId}>
                <Button
                  palette={
                    selectedTargets[characterId] ? "secondary" : "primary"
                  }
                  onClick={() => {
                    setSelectedTargets({
                      ...selectedTargets,
                      [characterId]: !selectedTargets[characterId],
                    });
                  }}
                >
                  <Avatar id={allCharacters[characterId].avatar} />
                  <Text style={{ marginTop: "8px" }}>
                    {allCharacters[characterId].displayName}
                    {characterId.startsWith("drone-") ? "'s Drone" : ""}
                  </Text>
                </Button>
              </Grid>
            ))
          }
        </Grid>
        <Grid>
          <DialogActions>
            <Button
              disabled={
                initiatedAction &&
                initiatedAction.action.targetType === TargetType.character &&
                (Object.keys(selectedTargets).filter(
                  (key) => selectedTargets[key]
                ).length < (initiatedAction.action.minTargets || 0) ||
                  Object.keys(selectedTargets).filter(
                    (key) => selectedTargets[key]
                  ).length > (initiatedAction.action.maxTargets || Infinity))
              }
              onClick={async () => {
                await initiatedAction.activateAction(
                  Object.keys(selectedTargets).filter(
                    (key) => selectedTargets[key]
                  )
                );
                setSelectedTargets({});
                handleClose();
              }}
            >
              <Text>Go</Text>
            </Button>
            <Button
              onClick={() => {
                handleClose();
                setSelectedTargets({});
              }}
            >
              <Text>Cancel</Text>
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Modal>
  );
};
