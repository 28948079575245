import React from "react";
import { Card, Table, Text } from "@arwes/core";
import {
  ActionDetails,
  actionsList,
  Condition,
  conditionNames,
  damageNames,
  DamageType,
  TargetType,
} from "./tech";
import { Grid } from "@material-ui/core";
import { statToDisplayName } from "./Tech";
import { upperFirst } from "lodash";

export interface ActionProps {
  action: ActionDetails;
  activate?: boolean;
  actions?: JSX.Element;
}

export const Action: React.FC<ActionProps> = (props) => {
  const { action, actions, activate } = props;

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Text as="h3">{action.displayName}</Text>
      </Grid>
      <Grid item>
        <Text style={{ whiteSpace: "pre-wrap" }}>{action.description}</Text>
      </Grid>
      <Table
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "16px",
        }}
        headers={[
          { id: "stat", data: "Stat" },
          { id: "value", data: "Value" },
        ]}
        dataset={[
          {
            id: "range",
            columns: [
              {
                id: "stat",
                data: "Range",
              },
              {
                id: "value",
                data:
                  action.targetType === TargetType.self
                    ? "Self"
                    : action.melee
                    ? "Melee"
                    : "Ranged",
              },
            ],
          },
          {
            id: "type",
            columns: [
              {
                id: "stat",
                data: "Type",
              },
              {
                id: "value",
                data: Object.keys(action.utilizes || {})
                  .filter((u) => (action.utilizes as any)[u])
                  .map(upperFirst)
                  .join(", "),
              },
            ],
          },
          ...(action.duration !== Infinity
            ? [
                {
                  id: "duration",
                  columns: [
                    {
                      id: "stat",
                      data: "Duration",
                    },
                    {
                      id: "value",
                      data: `${action.duration} seconds`,
                    },
                  ],
                },
              ]
            : []),
          ...(action.cooldown > 0
            ? [
                {
                  id: "cooldown",
                  columns: [
                    {
                      id: "stat",
                      data: "Cooldown",
                    },
                    {
                      id: "value",
                      data: `${action.cooldown} seconds`,
                    },
                  ],
                },
              ]
            : []),
          ...(action.sharedCooldown
            ? [
                {
                  id: "sharedCooldown",
                  columns: [
                    {
                      id: "stat",
                      data: "Shared Cooldown",
                    },
                    {
                      id: "value",
                      data: Object.values(actionsList)
                        .filter(
                          (a) =>
                            a.sharedCooldown === action.sharedCooldown &&
                            a.id !== action.id
                        )
                        .map((a) => a.displayName)
                        .join(", "),
                    },
                  ],
                },
              ]
            : []),

          ...(action.requiredWeapon
            ? [
                {
                  id: "requiredWeapon",
                  columns: [
                    {
                      id: "stat",
                      data: "Required Ready Weapon",
                    },
                    {
                      id: "value",
                      data: action.requiredWeapon.displayName,
                    },
                  ],
                },
              ]
            : []),
          // ...(tech.requirements
          //   ? [
          //       {
          //         id: "requirements",
          //         columns: [
          //           {
          //             id: "stat",
          //             data: "Requirements",
          //           },
          //           {
          //             id: "value",
          //             data: tech.requirements.map((tech) => tech.displayName),
          //           },
          //         ],
          //       },
          //     ]
          //   : []),

          ...(action.skill
            ? [
                {
                  id: "skill",
                  columns: [
                    {
                      id: "stat",
                      data: "Skill",
                    },
                    {
                      id: "value",
                      data: statToDisplayName(action.skill),
                    },
                  ],
                },
              ]
            : []),

          ...(action.shieldHealing
            ? [
                {
                  id: "shieldHealing",
                  columns: [
                    {
                      id: "stat",
                      data: "Shield Repair Multiplier",
                    },
                    {
                      id: "value",
                      data: action.shieldHealing,
                    },
                  ],
                },
              ]
            : []),

          ...(action.consumes
            ? [
                {
                  id: "consumes",
                  columns: [
                    {
                      id: "stat",
                      data: "Consumes",
                    },
                    {
                      id: "value",
                      data: action.consumes.displayName,
                    },
                  ],
                },
              ]
            : []),

          ...(action.poolModifier
            ? [
                {
                  id: "poolModifier",
                  columns: [
                    {
                      id: "stat",
                      data: "Pool Modifier",
                    },
                    {
                      id: "value",
                      data:
                        (action.poolModifier.multiplier
                          ? `${action.poolModifier.multiplier}X` +
                            (action.poolModifier.flat ? ` + ` : "")
                          : "") +
                        (action.poolModifier.flat
                          ? `${action.poolModifier.flat}`
                          : ""),
                    },
                  ],
                },
              ]
            : []),

          ...(action.damage
            ? [
                {
                  id: "damage",
                  columns: [
                    {
                      id: "stat",
                      data: "Damage",
                    },
                    {
                      id: "value",
                      data: (
                        Object.keys(action.damage) as unknown as DamageType[]
                      )
                        .map(
                          (damageType) =>
                            `${damageNames[damageType]} ` +
                            (action.damage![damageType]?.multiplier
                              ? `${action.damage![damageType]?.multiplier}X` +
                                (action.damage![damageType]?.flat ? ` + ` : "")
                              : "") +
                            (action.damage![damageType]?.flat
                              ? `${action.damage![damageType]?.flat}`
                              : "")
                        )
                        .filter((s) => s !== undefined)
                        .join(", "),
                    },
                  ],
                },
              ]
            : []),

          ...(action.conditions
            ? [
                {
                  id: "conditions",
                  columns: [
                    {
                      id: "stat",
                      data: "Conditions",
                    },
                    {
                      id: "value",
                      data: (
                        Object.keys(action.conditions) as unknown as Condition[]
                      )
                        .map(
                          (conditionType) =>
                            `${conditionNames[conditionType]} ` +
                            (action.conditions![conditionType]?.multiplier
                              ? `${
                                  action.conditions![conditionType]?.multiplier
                                }X` +
                                (action.conditions![conditionType]?.flat
                                  ? ` + `
                                  : "")
                              : "") +
                            (action.conditions![conditionType]?.flat
                              ? `${action.conditions![conditionType]?.flat}`
                              : "") +
                            ` for ${
                              action.conditions![conditionType]?.duration ??
                              `${
                                action.conditions![conditionType]
                                  ?.durationMultiplier
                              }X`
                            } seconds`
                        )
                        .filter((s) => s !== undefined)
                        .join(", "),
                    },
                  ],
                },
              ]
            : []),

          ...(action.requirements
            ? [
                {
                  id: "requirements",
                  columns: [
                    {
                      id: "stat",
                      data: "Requirements",
                    },
                    {
                      id: "value",
                      data: action.requirements
                        .map((tech) => tech.displayName)
                        .join(", "),
                    },
                  ],
                },
              ]
            : []),
        ]}
      />
    </Grid>
  );
};
