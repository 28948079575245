/** @jsx jsx */
import {
  ReactElement,
  MutableRefObject,
  CSSProperties,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { cx } from "@emotion/css";
import { jsx, useTheme } from "@emotion/react";
import { AnimatorRef, useAnimator } from "@arwes/animation";
import { useBleeps } from "@arwes/sounds";
import {
  TableRowPropsColumn,
  TableRowPropsColumnWidth,
  TableRow,
} from "@arwes/core/lib/Table/TableRow";
import { generateStyles } from "./Table.styles";
import { FixedSizeList as List } from "react-window";
import React from "react";

interface TableRowPropsDataRow {
  id: string | number;
  columns: TableRowPropsColumn[];
}

export interface TableProps {
  headers: TableRowPropsDataRow["columns"];
  dataset: TableRowPropsDataRow[];
  innerHeight: number;
  itemSize: number;
  columnWidths?: TableRowPropsColumnWidth[];
  condensed?: boolean;
  className?: string;
  style?: CSSProperties;
  rootRef?:
    | MutableRefObject<HTMLDivElement | null>
    | ((node: HTMLDivElement) => void);
}

const Table = (props: TableProps): ReactElement => {
  const {
    headers,
    dataset,
    columnWidths,
    condensed,
    style,
    className,
    rootRef,
    innerHeight,
    itemSize,
  } = props;

  const animator = useAnimator() as AnimatorRef;
  const theme = useTheme() as any;
  const bleeps = useBleeps();
  const styles = useMemo(() => generateStyles(theme), [theme]);

  animator.setupAnimateRefs(bleeps);

  // const Row = useCallback(
  //   ({ index, style }: any) => (
  //     <div style={style}>
  //       <TableRow
  //         theme={theme}
  //         key={dataset[index].id}
  //         columns={dataset[index].columns}
  //         columnWidths={columnWidths}
  //         condensed={condensed}
  //       />
  //     </div>
  //   ),
  //   [
  //     JSON.stringify(
  //       dataset.map((row) =>
  //         row.columns.filter((col) => typeof col.data !== "object")
  //       )
  //     ),
  //   ]
  // );

  const Row = ({ index, style }: any) => (
    <div style={style}>
      <TableRow
        animator={{ animate: false }}
        theme={theme}
        key={dataset[index].id}
        columns={dataset[index].columns}
        columnWidths={columnWidths}
        condensed={condensed}
      />
    </div>
  );

  return (
    <div
      className={cx("arwes-table", className)}
      css={[styles.root, !animator.flow.entered && styles.rootIsTransitioning]}
      style={style}
      ref={rootRef}
    >
      <div className="arwes-table__container" css={styles.container}>
        <TableRow
          theme={theme}
          isHeader
          columns={headers}
          columnWidths={columnWidths}
          condensed={condensed}
        />
        <Inner
          innerHeight={innerHeight}
          itemCount={dataset.length}
          itemSize={itemSize}
        >
          {Row}
        </Inner>
      </div>
    </div>
  );
};

const Inner = React.memo((props: any) => (
  <List
    height={props.innerHeight}
    itemCount={props.itemCount}
    itemSize={props.itemSize}
    width="100%"
  >
    {props.children}
  </List>
));

const propTypeColumns = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    data: PropTypes.node.isRequired,
  }).isRequired
).isRequired;

Table.propTypes = {
  headers: propTypeColumns,
  dataset: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      columns: propTypeColumns,
    }).isRequired
  ).isRequired,
  columnWidths: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  ),
  condensed: PropTypes.bool,
  rootRef: PropTypes.any,
  className: PropTypes.string,
};

export { Table };
