import raw from "raw.macro";

export type Contents = Chapter[];
export interface Chapter {
  title: string;
  path: string;
  content?: string;
  sections: Section[];
}
export interface Section {
  title: string;
  path: string;
  content: string;
}

const contents: Contents = [
  {
    title: "Introduction",
    path: "introduction",
    content: raw("./source/introduction.md"),
    sections: [
      {
        title: "Preface",
        path: "preface",
        content: raw("./source/preface.md"),
      },
      {
        title: "Getting Started",
        path: "getting-started",
        content: raw("./source/getting-started.md"),
      },
    ],
  },
  // {
  //   title: "The World",
  //   path: "world",
  //   sections: [
  //     {
  //       title: "Timeline",
  //       path: "timeline",
  //       content: raw("./source/timeline.md"),
  //     },
  //     {
  //       title: "Megacorps",
  //       path: "megacorps",
  //       content: raw("./source/megacorps.md"),
  //     },
  //     {
  //       title: "Hephaestus Industries",
  //       path: "hephaestus",
  //       content: raw("./source/hephaestus.md"),
  //     },
  //     {
  //       title: "Meridian",
  //       path: "meridian",
  //       content: raw("./source/meridian.md"),
  //     },
  //     {
  //       title: "Eden",
  //       path: "eden",
  //       content: raw("./source/eden.md"),
  //     },
  //     {
  //       title: "Vanguard",
  //       path: "vanguard",
  //       content: raw("./source/vanguard.md"),
  //     },
  //   ],
  // },

  {
    title: "Characters",
    path: "characters",
    content: raw("./source/characters.md"),
    sections: [
      // {
      //   title: "Freelancers",
      //   path: "freelancers",
      //   content: raw("./source/freelancers.md"),
      // },
      {
        title: "Roles",
        path: "roles",
        content: raw("./source/roles.md"),
      },
      {
        title: "Attributes",
        path: "attributes",
        content: raw("./source/attributes.md"),
      },
      {
        title: "Tech",
        path: "tech",
        content: raw("./source/tech.md"),
      },
      {
        title: "Tier",
        path: "tier",
        content: raw("./source/tier.md"),
      },
      {
        title: "Stats and Skills",
        path: "skills",
        content: raw("./source/skills.md"),
      },
    ],
  },
  {
    title: "Locations",
    path: "locations",
    content: raw("./source/locations.md"),
    sections: [
      {
        title: "Terrain",
        path: "terrain",
        content: raw("./source/terrain.md"),
      },
      {
        title: "Dynamic Vision",
        path: "dynamic",
        content: raw("./source/dynamic-vision.md"),
      },
    ],
  },
  {
    title: "Situations",
    path: "situations",
    content: raw("./source/situations.md"),
    sections: [
      // {
      //   title: "Introduction to Situations",
      //   path: "intro-situations",
      //   content: raw("./source/introduction-to-situations.md"),
      // },
      {
        title: "Skill Challenges",
        path: "skill-challenges",
        content: raw("./source/skill-challenges.md"),
      },
      {
        title: "Time",
        path: "time",
        content: raw("./source/time.md"),
      },
      {
        title: "Social Conflicts",
        path: "social-conflicts",
        content: raw("./source/social-conflicts.md"),
      },
      {
        title: "Combat",
        path: "combat",
        content: raw("./source/combat.md"),
      },
      {
        title: "Conditions",
        path: "conditions",
        content: raw("./source/conditions.md"),
      },
    ],
  },
  // {
  //   title: "Gigs",
  //   path: "gigs",
  //   sections: [],
  // },
  {
    title: "Downtime",
    path: "downtime",
    content: raw("./source/downtime.md"),
    sections: [
      {
        title: "Upgrades",
        path: "upgrades",
        content: raw("./source/upgrades.md"),
      },
      {
        title: "Healing",
        path: "healing",
        content: raw("./source/healing.md"),
      },
      // Travel
    ],
  },
];

export default contents;
