import React, { useEffect, useState } from "react";
import { PageLayout } from "../layout";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { useEditableCharacter } from "./useCharacter";
import { Avatar } from "./Avatar";
import { AvatarPicker } from "./AvatarPicker";
import styled from "styled-components";
import {
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { CharacterOverview } from "./CharacterOverview";
import { CharacterLoadout } from "./CharacterLoadout";
import { Animator } from "@arwes/animation";
import { Button, Text } from "@arwes/core";

import { FrameContent } from "../layout/FrameContent";
import { upperFirst } from "lodash";
import { CapacityBar, LoadingBars } from "../components";
import { AnimatedRoute, Link } from "../navigation";
import { AnimatedRouteChildrenProps } from "../navigation/AnimatedRoute";
import { CharacterAbilities } from "./CharacterAbilities";
import { HealthBar } from "./HealthBar";
import { techList } from "./tech";
import { useEditableCampaign } from "../campaign";
import { useUser } from "reactfire";
import { User } from "firebase";
import { GmCheck } from "./GmCheck";

const TabButton = styled(Button)`
  width: 33%;
`;

function CharacterPageButtons(props: any) {
  const { character, setEditMode } = props;
  return (
    <Animator key="view">
      {character.state.campaignId &&
        (!character.state.npc ? (
          <Link href={`/players/${character.id}`}>
            <Button>
              <Text>Play</Text>
            </Button>
          </Link>
        ) : (
          <Link href={`/campaigns/${character.state.campaignId}/characters`}>
            <Button>
              <Text>Campaign</Text>
            </Button>
          </Link>
        ))}
      <Button onClick={() => setEditMode(true)}>
        <Text>Edit</Text>
      </Button>
    </Animator>
  );
}

export const CharacterPage = (
  props: AnimatedRouteChildrenProps<{ id: string }>
) => {
  const { activate } = props;
  const currentUser = useUser<User>();
  const id = props.match.params.id;
  const character = useEditableCharacter(id);

  const [avatarPickerOpen, setAvatarPickerOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [loading, setLoading] = useState(false);

  const tabs = ["overview", "loadout", "abilities"];

  const location = useLocation();
  const history = useHistory();

  const [health, setHealth] = useState(
    `${character.state.stats.currentHealth}`
  );
  useEffect(() => {
    setHealth(`${character.state.stats.currentHealth}`);
  }, [character.state.stats.currentHealth]);
  const [shield, setShield] = useState(
    `${character.state.stats.currentShield}`
  );
  useEffect(() => {
    setShield(`${character.state.stats.currentShield}`);
  }, [character.state.stats.currentShield]);
  const [droneShield, setDroneShield] = useState(
    `${character.state.stats.currentDroneShield}`
  );
  useEffect(() => {
    setDroneShield(`${character.state.stats.currentDroneShield}`);
  }, [character.state.stats.currentDroneShield]);

  useEffect(() => {
    document.title = `${character.state.displayName} - Character`;
  }, [character.state.displayName]);

  const buttons = (
    <Grid item>
      {editMode ? (
        <Animator key="edit">
          <Button
            disabled={loading}
            onClick={() => {
              character.reset();
              setTimeout(() => setEditMode(false), 50);
            }}
          >
            <Text>Cancel</Text>
          </Button>
          <Button
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              character.setCurrentDroneShield(parseInt(droneShield));
              character.setCurrentHealth(parseInt(health));
              character.setCurrentShield(parseInt(shield));
              try {
                await character.commit();
              } catch (e) {
                console.log(e);
              }

              setEditMode(false);
              setLoading(false);
            }}
          >
            <Text>Save</Text>
          </Button>
        </Animator>
      ) : character.state.owner === currentUser.uid ? (
        <CharacterPageButtons character={character} setEditMode={setEditMode} />
      ) : character.state.campaignId ? (
        <GmCheck campaignId={character.state.campaignId}>
          <CharacterPageButtons
            character={character}
            setEditMode={setEditMode}
          />
        </GmCheck>
      ) : null}
    </Grid>
  );

  return (
    <PageLayout>
      <FrameContent
        animator={{ root: true, activate }}
        style={{ padding: "16px" }}
        // lineWidth={2}
        // squareSize={35}
        // hideShapes
      >
        <Grid container spacing={2} direction="column" justify="space-between">
          <Grid container spacing={2} item justify="space-between">
            <Grid item xs={5} sm={4} md={3}>
              <Avatar
                id={character.state.avatar}
                onClick={
                  editMode && !loading
                    ? () => setAvatarPickerOpen(true)
                    : undefined
                }
              />
            </Grid>
            <Hidden smUp>{buttons}</Hidden>
            <Grid item xs={12} sm container direction="column" spacing={1}>
              <Grid item>
                {editMode ? (
                  <TextField
                    style={{ maxWidth: "400px" }}
                    label="Character Name"
                    variant="filled"
                    value={character.state.displayName}
                    fullWidth
                    disabled={loading}
                    onChange={(e) => {
                      character.setDisplayName(e.target.value);
                    }}
                  />
                ) : (
                  <Text as="h3">{character.state.displayName}</Text>
                )}
              </Grid>
              <Grid item>
                <InputLabel shrink>Tier</InputLabel>
                {editMode ? (
                  <Select
                    id="tier-select"
                    fullWidth
                    style={{ maxWidth: "400px" }}
                    disabled={!editMode}
                    value={character.state.tier}
                    onChange={(event) => {
                      character.setTier(event.target.value as number);
                    }}
                  >
                    {new Array(5).fill(0).map((_, index) => {
                      const tier = index + 1;
                      return (
                        <MenuItem key={tier} value={tier}>
                          {tier}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <Text>{character.state.tier}</Text>
                )}
              </Grid>

              <Grid item>
                <Grid
                  style={{ marginBottom: "10px", alignContent: "flex-end" }}
                  item
                >
                  {editMode ? (
                    <>
                      <Text key="edit">$</Text>
                      <TextField
                        inputProps={{
                          type: "number",
                          min: "0",
                        }}
                        value={character.state.credits}
                        disabled={loading}
                        onChange={(e) => {
                          character.setCredits(parseInt(e.target.value));
                        }}
                      />
                    </>
                  ) : (
                    <Text animator={{ root: true }}>
                      ${character.state.credits}
                    </Text>
                  )}
                </Grid>

                <Grid item container>
                  {editMode ? (
                    <>
                      <Grid item xs={12} md>
                        <Text>Health:</Text>
                        <TextField
                          style={{ maxWidth: "50px" }}
                          inputProps={{
                            type: "number",
                            min: "0",
                            max: `${character.state.stats.maxHealth}`,
                            style: {
                              textAlign: "center",
                            },
                          }}
                          // label="Current Health"
                          // variant="filled"
                          value={health}
                          // fullWidth
                          disabled={loading}
                          onChange={(e) => {
                            setHealth(e.target.value);
                          }}
                        />
                        <Text>/ {character.state.stats.maxHealth}</Text>
                      </Grid>
                      <Grid item xs={12} md>
                        <Text>Shield:</Text>
                        <TextField
                          style={{ maxWidth: "50px" }}
                          inputProps={{
                            type: "number",
                            min: "0",
                            max: `${character.state.stats.maxShield}`,
                            style: { textAlign: "center" },
                          }}
                          // label="Current Health"
                          // variant="filled"
                          value={shield}
                          disabled={loading}
                          onChange={(e) => {
                            setShield(e.target.value);
                          }}
                        />
                        <Text>/ {character.state.stats.maxShield}</Text>
                      </Grid>
                      {character.state.tech[techList.droneFrame.id]
                        ?.equippedCount > 0 && (
                        <Grid item xs={12} md>
                          <Text>Drone Shield:</Text>
                          <TextField
                            style={{ maxWidth: "50px" }}
                            inputProps={{
                              type: "number",
                              min: "0",
                              max: `${character.state.stats.maxDroneShield}`,
                              style: { textAlign: "center" },
                            }}
                            // label="Current Health"
                            // variant="filled"
                            value={droneShield}
                            disabled={loading}
                            onChange={(e) => {
                              setDroneShield(e.target.value);
                            }}
                          />
                          <Text>/ {character.state.stats.maxDroneShield}</Text>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid container spacing={4}>
                      <Grid item>
                        <InputLabel shrink>Health</InputLabel>
                        <Grid>
                          <HealthBar character={character.state} />
                        </Grid>
                      </Grid>
                      {character.state.tech[techList.droneFrame.id]
                        ?.equippedCount > 0 && (
                        <Grid item>
                          <InputLabel shrink>Drone Shield</InputLabel>
                          <Grid>
                            <CapacityBar
                              length={10}
                              current={character.state.stats.currentDroneShield}
                              maximum={character.state.stats.maxDroneShield}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Hidden xsDown>{buttons}</Hidden>
          </Grid>
          <div>
            {tabs.map((tab) => {
              return (
                <TabButton
                  palette={
                    location.pathname.split("/").pop() === tab
                      ? "primary"
                      : "tabs"
                  }
                  key={tab}
                  onClick={() => history.push(`/characters/${id}/${tab}`)}
                  active={true}
                >
                  <Text>{upperFirst(tab)}</Text>
                </TabButton>
              );
            })}
          </div>

          <Grid item>
            <AnimatedRoute
              exact
              path={props.match.path}
              render={() => <Redirect to={`/characters/${id}/overview`} />}
            />
            <AnimatedRoute
              path={`${props.match.path}/overview`}
              exact
              render={({ activate }) => (
                <Animator
                  animator={{
                    root: true,
                    activate,
                  }}
                >
                  <CharacterOverview
                    activate={activate}
                    editMode={editMode}
                    loading={loading}
                    character={character}
                  />
                </Animator>
              )}
            />
            <AnimatedRoute
              path={`${props.match.path}/loadout`}
              exact
              render={({ activate }) => (
                <CharacterLoadout
                  activate={activate}
                  editMode={editMode}
                  loading={loading}
                  character={character}
                />
              )}
            />
            <AnimatedRoute
              path={`${props.match.path}/abilities`}
              exact
              render={({ activate }) => (
                <CharacterAbilities
                  activate={activate}
                  editMode={editMode}
                  loading={loading}
                  character={character}
                />
              )}
            />
          </Grid>
        </Grid>
      </FrameContent>
      <AvatarPicker
        open={avatarPickerOpen}
        selectedValue={character.state.avatar}
        onClose={(avatar) => {
          character.setAvatar(avatar);
          setAvatarPickerOpen(false);
        }}
      />
    </PageLayout>
  );
};
