import { LoadingBars } from "@arwes/core";
import { Grid } from "@material-ui/core";
import React from "react";
import { SuspenseWithPerf } from "reactfire";

export const SuspenseWithLoadingBars: React.FC<{
  traceId: string;
  size?: number;
  length?: number;
}> = (props) => {
  const { size, length } = props;
  return (
    <SuspenseWithPerf
      traceId={props.traceId}
      fallback={
        <Grid container style={{ marginTop: "64px" }} justify="center">
          <Grid item>
            <LoadingBars size={size} length={length} />
          </Grid>
        </Grid>
      }
    >
      {props.children}
    </SuspenseWithPerf>
  );
};
