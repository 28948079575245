import styled from "styled-components";

const { FrameLines, FrameCorners } = require("@arwes/core");

export { LoadingBars } from "./LoadingBars";

export const WideFrameLines = styled(FrameLines)`
  width: 100%;
`;

export { SuspenseWithLoadingBars } from "./SuspenseWithLoadingBars";

export { Clock } from "./Clock";
export { LoadingButton } from "./LoadingButton";

export { CapacityBar } from "./CapacityBar";
export { ConfirmButton } from "./ConfirmButton";
