import { Button, ButtonProps, Text } from "@arwes/core";
import { DialogActions, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { Modal } from "../layout/Modal";

export const ConfirmButton: React.FC<ButtonProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Button {...props} onClick={() => setIsOpen(true)} />
      <Modal maxWidth={600} isOpen={isOpen} onClose={handleClose}>
        <Grid container xs={12} direction="column" spacing={2}>
          <Grid item>
            <Text as="h4">Are you sure?</Text>
          </Grid>
          <Grid item xs={12}>
            <Text>This action cannot be undone.</Text>
          </Grid>
          <Grid>
            <DialogActions>
              <Button onClick={props.onClick}>
                <Text>Okay</Text>
              </Button>
              <Button onClick={handleClose}>
                <Text>Close</Text>
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
