import styled from "styled-components";

export { PageLayout } from "./PageLayout";

export { FrameContent } from "./FrameContent";

export { Modal } from "./Modal";

export { CardText } from "./CardText";

export const RightCol = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CenterCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export { Sidebar } from "./Sidebar";
