import { Button, Text } from "@arwes/core";
import {
  DialogActions,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import { LoadingButton } from "../components";
import { Modal } from "../layout";
import { Table } from "../layout/table";
import { StyledFigure } from "./Tech";
import { hasSufficientFreeCapacity, Role, techList } from "./tech";
import { EditableCharacter } from "./useCharacter";

const { FrameCorners } = require("@arwes/core");

interface ShopModalProps {
  isOpen: boolean;
  character: EditableCharacter;
  handleClose: () => void;
  setTechDetails: any;
}

const ModalContainer = styled.div`
  margin-left: 1%;
  margin-right: 1%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 16px;
`;

const ModalInner = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

const ModalFrameCorners = styled(FrameCorners)`
  width: 100%;
`;

const techSearchTerms = Object.values(techList)
  .map((tech) => ({
    [tech.id]: JSON.stringify(
      Object.values({
        ...tech,
        actions: undefined,
        requirements: undefined,
      })
    ).toLowerCase(),
  }))
  .reduce((acc, cur) => ({ ...acc, ...cur }), {});

export const ShopModal: React.FC<ShopModalProps> = (props) => {
  const { isOpen, handleClose, character, setTechDetails } = props;
  const [role, setRole] = useState("All" as Role | "All");
  const [tier, setTier] = useState("All" as number | "All");
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const matches = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      actions={
        <Grid>
          <DialogActions>
            <Button onClick={handleClose}>
              <Text>Close</Text>
            </Button>
          </DialogActions>
        </Grid>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Text as="h4">Purchase Tech</Text>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Search"
              fullWidth
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel shrink>Role</InputLabel>
            <Select
              id="role-select"
              fullWidth
              value={role}
              onChange={(event) => {
                setRole(event.target.value as Role);
              }}
            >
              <MenuItem key="All" value={"All"}>
                All
              </MenuItem>
              {Object.keys(Role)
                .filter((key) => !isNaN(Number(key)))
                .map((tag) => {
                  return (
                    <MenuItem key={tag} value={Number(tag)}>
                      {Role[Number(tag)]}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <InputLabel shrink>Tier</InputLabel>
            <Select
              id="tier-select"
              fullWidth
              value={tier}
              onChange={(event) => {
                setTier(event.target.value as number);
              }}
            >
              <MenuItem key="All" value={"All"}>
                All
              </MenuItem>
              {new Array(5).fill(0).map((_, index) => {
                const tier = index + 1;
                return (
                  <MenuItem key={tier} value={tier}>
                    {tier}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <ModalInner>
          <Table
            headers={[
              { id: "name", data: "Name" },
              { id: "owned", data: "Owned" },
              ...(matches ? [{ id: "actions", data: "Actions" }] : []),
            ]}
            innerHeight={350}
            itemSize={60}
            dataset={Object.values(techList)
              .filter(
                (tech) =>
                  tech.isEquippable ||
                  !character.state.tech[tech.id] ||
                  character.state.tech[tech.id]?.personalCount <
                    (tech.maxEquippable || 1) ||
                  tech.isEquipment
              )
              .filter((tech) => role === "All" || tech.roles.includes(role))
              .filter((tech) => tier === "All" || tech.tier === tier)
              .filter((tech) =>
                searchQuery
                  .toLowerCase()
                  .split(" ")
                  .every((term) => techSearchTerms[tech.id].includes(term))
              )
              .map((tech) => {
                return {
                  id: `${tech.displayName}-${tech.id}-${tier}-${role}`,
                  columns: [
                    {
                      id: "name",
                      data: (
                        <Grid
                          container
                          onClick={() => {
                            setTechDetails(tech);
                          }}
                          alignItems={matches ? "center" : undefined}
                          style={{
                            height: matches ? "50px" : "50px",
                            overflow: "hidden",
                          }}
                        >
                          {tech.image && matches && (
                            <Grid item>
                              <img
                                src={tech.image}
                                alt={tech.displayName}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  marginRight: "8px",
                                }}
                              />
                            </Grid>
                          )}
                          <Grid item>{tech.displayName + " 🔍"}</Grid>
                        </Grid>
                      ),
                    },
                    {
                      id: "owned",
                      data:
                        character.state.tech[tech.id]?.personalCount +
                          character.state.tech[tech.id]?.equippedCount +
                          character.state.tech[tech.id]?.storageCount || 0,
                    },
                    ...(matches
                      ? [
                          {
                            id: "actions",
                            data: (
                              <>
                                <Grid container justify="center">
                                  <Button
                                    disabled={
                                      character.state.credits <
                                        (tech.price || 0) ||
                                      character.state.tier < tech.tier ||
                                      (!tech.isEquippable &&
                                        (!hasSufficientFreeCapacity(
                                          character.state,
                                          tech
                                        ) ||
                                          (tech.requirements &&
                                            tech.requirements.some(
                                              (requirement) =>
                                                requirement.isEquippable
                                                  ? !character.state.tech[
                                                      requirement.id
                                                    ]?.equippedCount
                                                  : !character.state.tech[
                                                      requirement.id
                                                    ]?.personalCount
                                            )) ||
                                          (tech.slots &&
                                            tech.slots.some((slot) =>
                                              Object.values(
                                                character.state.tech
                                              ).some(
                                                (tech) =>
                                                  tech.equippedCount > 0 &&
                                                  tech.slots?.includes(slot)
                                              )
                                            ))))
                                    }
                                    onClick={async () => {
                                      character.removeCredits(tech.price || 0);
                                      character.addTech(tech.id);
                                    }}
                                  >
                                    <Text>Buy ${tech.price || 0}</Text>
                                  </Button>
                                  {tech.isEquippable && (
                                    <Button
                                      disabled={
                                        character.state.credits <
                                          (tech.price || 0) ||
                                        character.state.tier < tech.tier ||
                                        !hasSufficientFreeCapacity(
                                          character.state,
                                          tech
                                        ) ||
                                        (tech.requirements &&
                                          tech.requirements.some(
                                            (requirement) =>
                                              !character.state.tech[
                                                requirement.id
                                              ]?.equippedCount
                                          )) ||
                                        (tech.slots &&
                                          tech.slots.some((slot) =>
                                            Object.values(
                                              character.state.tech
                                            ).some(
                                              (tech) =>
                                                tech.equippedCount > 0 &&
                                                tech.slots?.includes(slot)
                                            )
                                          ))
                                      }
                                      onClick={() => {
                                        character.removeCredits(
                                          tech.price || 0
                                        );
                                        character.addTech(tech.id);
                                        character.equipTech(tech.id);
                                      }}
                                    >
                                      <Text>
                                        Buy and{" "}
                                        {tech.isEquipment ? "Equip" : "Install"}
                                      </Text>
                                    </Button>
                                  )}
                                  <Button
                                    disabled={
                                      !character.state.tech[tech.id] ||
                                      character.state.tech[tech.id]
                                        ?.personalCount === 0
                                    }
                                    onClick={async () => {
                                      character.addCredits(
                                        (tech.price || 0) / 2
                                      );
                                      character.removeTech(tech.id);
                                    }}
                                  >
                                    <Text>Sell ${tech.price / 2 || 0}</Text>
                                  </Button>
                                </Grid>
                              </>
                            ),
                          },
                        ]
                      : []),
                  ],
                };
              })
              .sort((a, b) => {
                if (a.id < b.id) {
                  return -1;
                }
                if (a.id > b.id) {
                  return 1;
                }
                return 0;
              })}
            columnWidths={[
              matches ? "calc(50% - 2.5em)" : "calc(100% - 5em)",
              "5em",
              "calc(50% - 2.5em)",
            ]}
          />
        </ModalInner>
      </Grid>
    </Modal>
  );
};
