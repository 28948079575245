import { AxialCoordinates } from "honeycomb-grid";
import { useState } from "react";
import { TargetType } from "../character/tech";
import { grid } from "../hexgrid";

export type Target = any; //string | { x: number; y: number };

export enum TerrainInterference {
  canHitExposed,
  blocked,
  ignored,
}

export interface TargetController {
  targets: Target[];
  maxTargets: number;
  radius: number;
  range: number;
  setRange: React.Dispatch<React.SetStateAction<number>>;
  addTarget: (target: Target) => void;
  removeTarget: (target: Target) => void;
  setTargets: React.Dispatch<React.SetStateAction<Target[]>>;
  setMaxTargets: React.Dispatch<React.SetStateAction<number>>;
  setRadius: React.Dispatch<React.SetStateAction<number>>;
  targetType: TargetType | null;
  setTargetType: React.Dispatch<React.SetStateAction<TargetType | null>>;
  clear: () => void;
  source: AxialCoordinates | null;
  setSource: React.Dispatch<React.SetStateAction<AxialCoordinates | null>>;
  terrainInterference: TerrainInterference;
  setTerrainInterference: React.Dispatch<
    React.SetStateAction<TerrainInterference>
  >;
}

export function useTargetController(): TargetController {
  const [targets, setTargets] = useState<Target[]>([]);

  const [maxTargets, setMaxTargets] = useState<number>(100);
  const [radius, setRadius] = useState<number>(0);
  const [range, setRange] = useState<number>(Infinity);

  const removeTarget = (target: Target) => {
    setTargets(targets.filter((t) => t !== target));
  };

  const [targetType, setTargetType] = useState<TargetType | null>(null);

  const [source, setSource] = useState<AxialCoordinates | null>(null);

  const [terrainInterference, setTerrainInterference] =
    useState<TerrainInterference>(TerrainInterference.ignored);

  const addTarget = (target: Target) => {
    if (
      !source ||
      typeof target !== "object" ||
      grid.distance(source, target) / 2 <= range
    ) {
      setTargets([...targets, target]);
    }
  };

  const clear = () => {
    setTargets([]);
    setTargetType(null);
    setSource(null);
    setMaxTargets(100);
    setRadius(0);
    setRange(Infinity);
    setTerrainInterference(TerrainInterference.ignored);
  };

  return {
    targets,
    maxTargets,
    radius,
    setRadius,
    range,
    setRange,
    addTarget,
    setMaxTargets,
    removeTarget,
    setTargets,
    targetType,
    setTargetType,
    clear,
    source,
    setSource,
    terrainInterference,
    setTerrainInterference,
  };
}
