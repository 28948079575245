import { Grid } from "@material-ui/core";
import React from "react";
import { useAccount, useSubscription } from "../account";

export const CampaignLimit: React.FC = () => {
  const subscription = useSubscription();
  const account = useAccount();
  return (
    <>
      {!subscription.hasSubscription() &&
        `${account.totalCampaigns || 0}/1 free campaign slots used`}
    </>
  );
};
