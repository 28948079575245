import { Button, Text } from "@arwes/core";
import {
  DialogActions,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import { LoadingButton } from "../components";
import { Modal } from "../layout";
import { Table } from "../layout/table";
import { StyledFigure } from "./Tech";
import { hasSufficientFreeCapacity, Role, techList } from "./tech";
import {
  EditableCharacter,
  useCreateCharacter,
  usePublishedCharacterList,
} from "./useCharacter";
import { Avatar } from "./Avatar";
import { useAccount, useSubscription } from "../account";
import { useHistory } from "react-router-dom";

interface CharacterImportModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const ModalInner = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

const techSearchTerms = Object.values(techList)
  .map((tech) => ({
    [tech.id]: JSON.stringify(
      Object.values({
        ...tech,
        actions: undefined,
        requirements: undefined,
      })
    ).toLowerCase(),
  }))
  .reduce((acc, cur) => ({ ...acc, ...cur }), {});

export const CharacterImportModal: React.FC<CharacterImportModalProps> = (
  props
) => {
  const { isOpen, handleClose } = props;
  const subscription = useSubscription();
  const account = useAccount();
  const history = useHistory();
  const createCharacter = useCreateCharacter();

  const characters = usePublishedCharacterList();

  const matches = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      actions={
        <Grid>
          <DialogActions>
            <Button onClick={handleClose}>
              <Text>Close</Text>
            </Button>
          </DialogActions>
        </Grid>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Text as="h4">Import Character</Text>
        </Grid>
        <ModalInner>
          <Table
            headers={[
              { id: "name", data: "Name" },
              { id: "description", data: "description" },
              { id: "actions", data: "Actions" },
            ]}
            innerHeight={350}
            itemSize={145}
            dataset={characters
              .map((characterDoc) => {
                const character = characterDoc.data();
                return {
                  id: characterDoc.id,
                  columns: [
                    {
                      id: "name",
                      data: (
                        <Grid
                          container
                          style={{
                            height: "150px",
                            overflow: "hidden",
                          }}
                          spacing={2}
                        >
                          {character.avatar && matches && (
                            <Grid item>
                              <Avatar id={character.avatar} />
                            </Grid>
                          )}
                          <Grid item>{character.displayName}</Grid>
                        </Grid>
                      ),
                    },
                    {
                      id: "description",
                      data: character.description,
                    },
                    {
                      id: "actions",
                      data: (
                        <>
                          <Grid container justify="center">
                            <LoadingButton
                              onClick={async () => {
                                if (
                                  (account.totalCharacters || 0) >= 10 &&
                                  !subscription.hasSubscription()
                                ) {
                                  history.push("/subscription");
                                } else {
                                  const newCharacterData = {
                                    ...character,
                                    situations: {},
                                    npc: false,
                                    published: undefined,
                                    campaignId: undefined,
                                    owner: undefined,
                                  };
                                  delete newCharacterData.owner;
                                  const newCharacter = await createCharacter(
                                    newCharacterData
                                  );
                                  history.push(
                                    `/characters/${newCharacter.id}`
                                  );
                                }
                              }}
                            >
                              <Text>Import</Text>
                            </LoadingButton>
                          </Grid>
                        </>
                      ),
                    },
                  ],
                };
              })
              .sort((a, b) => {
                if (a.id < b.id) {
                  return -1;
                }
                if (a.id > b.id) {
                  return 1;
                }
                return 0;
              })}
            columnWidths={["calc(40% - 3em)", "calc(60% - 4em)", "7em"]}
          />
        </ModalInner>
      </Grid>
    </Modal>
  );
};
