import React from "react";
import { Text } from "@arwes/core";
import { truncate } from "lodash";

export const CardText: React.FC<{ children: string; long?: boolean }> = (
  props
) => (
  <Text style={{ height: "3em" }}>
    {truncate(props.children, { length: props.long ? 120 : 70 })}
  </Text>
);
