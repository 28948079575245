import { FC } from "react";
import { WithAnimatorOutputProps, withAnimator } from "@arwes/animation";

import {
  LoadingBarsProps as Props,
  LoadingBars as Component,
} from "./LoadingBars.component";

import { animator } from "./LoadingBars.animator";

export const LoadingBars: FC<
  LoadingBarsProps & WithAnimatorOutputProps
> = withAnimator(animator)(Component);

export type LoadingBarsProps = Props;
