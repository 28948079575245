import React, { useEffect, useState } from "react";
import { Button, Card, Text } from "@arwes/core";
import { CardText, PageLayout } from "../layout";
import { useUser } from "reactfire";
import { useHistory } from "react-router-dom";
import { User } from "firebase";
import { Grid } from "@material-ui/core";
import { FrameContent } from "../layout";
import { truncate } from "lodash";
import { Link } from "../navigation";
import {
  useCampaignList,
  useCreateCampaign,
  useDeleteCampaign,
} from "./useCampaign";
import { headers } from "./headers";
import { CampaignLimit } from "./CampaignLimit";
import { useSubscription, useAccount } from "../account";
import { ConfirmButton } from "../components";

export const CampaignsPage = (props: { activate: boolean }) => {
  const [firstRenderTime] = useState(() => Date.now());
  const { activate } = props;
  const history = useHistory();
  const subscription = useSubscription();
  const campaigns = useCampaignList();
  const account = useAccount();

  const createCampaign = useCreateCampaign();
  const deleteCampaign = useDeleteCampaign();

  useEffect(() => {
    document.title = `Campaigns`;
  }, []);

  return (
    <PageLayout>
      <FrameContent style={{ padding: "16px" }} animator={{ activate }}>
        <Grid container>
          <Grid item container direction="row">
            <Grid item xs>
              <Text as="h3">Campaigns</Text>
            </Grid>
            <Grid item>
              <Button
                onClick={async () => {
                  if (
                    (account.totalCampaigns || 0) >= 1 &&
                    !subscription.hasSubscription()
                  ) {
                    history.push("/subscription");
                  } else {
                    const newCampaign = await createCampaign();
                    history.push(`/campaigns/${newCampaign.id}`);
                  }
                }}
              >
                <Text>Create Campaign</Text>
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <CampaignLimit />
          </Grid>
          {campaigns.docs.length === 0 && (
            <Grid item container justify="center" style={{ padding: "16px" }}>
              <Grid item xs md={6}>
                <Card
                  title="Nothing to see yet!"
                  image={{
                    src: "/images/people-in-white-futuristic.jpg",
                  }}
                >
                  <Text>Get started by creating a campaign above</Text>
                </Card>
              </Grid>
            </Grid>
          )}
          <Grid item container direction="row" spacing={2}>
            {campaigns.docs.map((campaignRef, index) => {
              const campaign = campaignRef.data();
              return (
                <Grid key={campaignRef.id} item xs={12} md={6}>
                  <Card
                    animator={{
                      root: true,
                      activate,
                      duration: {
                        delay: Math.max(
                          100 * index - (Date.now() - firstRenderTime),
                          0
                        ),
                      },
                    }}
                    title={campaign.displayName}
                    options={
                      <>
                        <Link href={`/campaigns/${campaignRef.id}/overview`}>
                          <Button>
                            <Text>View</Text>
                          </Button>
                        </Link>
                        <ConfirmButton
                          key={campaignRef.id}
                          onClick={() => deleteCampaign(campaignRef.id)}
                        >
                          <Text>Delete</Text>
                        </ConfirmButton>
                      </>
                    }
                  >
                    <CardText long>{campaign.description}</CardText>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </FrameContent>
    </PageLayout>
  );
};
