import { Text } from "@arwes/core";
import { useTheme } from "@emotion/react";
import { Grid } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { WideFrameLines } from "../components";
import { EditableSituation } from "./useSituation";
import { SituationLog, SituationLogType } from "./utils";

const FadeInDiv = styled.div`
  animation: fadeIn linear 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const logColors: Record<SituationLogType, string> = {
  [SituationLogType.attack]: "red",
  [SituationLogType.defense]: "blue",
  [SituationLogType.movement]: "green",
};

export const SituationLogView: React.FC<{
  situation: EditableSituation;
  character?: string;
  showHidden?: boolean;
}> = React.memo(
  (props) => {
    const { situation, showHidden, character } = props;
    const theme: any = useTheme();

    const endRef = useRef(null as null | HTMLDivElement);

    useEffect(() => {
      if (endRef.current) {
        endRef.current.scrollTo({ behavior: "smooth", top: 0 });
      }
    }, [endRef.current, situation.state.logs]);

    function getLogColor(character: string, log: SituationLog) {
      if (log.actors.includes(character)) {
        return theme.palette.success.main;
      } else if (log.targets.includes(character)) {
        return theme.palette.secondary.main;
        // if (log.type === SituationLogType.attack) {
        //   return theme.palette.error.main;
        // } else {
        //   return theme.palette.secondary.main;
        // }
      }

      return undefined;
    }

    const visibleLogs = situation.state.logs.filter(
      (log) => log.visible || showHidden
    );
    return (
      <Grid
        ref={(el) => (endRef.current = el)}
        item
        container
        direction="column-reverse"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          height: "30vh",
          minHeight: "200px",
        }}
      >
        <div>
          <Grid key="beginning" item>
            <Text>00:00 -- Beginning of Situation</Text>
          </Grid>
          {visibleLogs.map((log, index) => (
            <Grid item key={index}>
              {index > 0 &&
              visibleLogs[index - 1].timestamp !== log.timestamp ? (
                <FadeInDiv>-------------------------</FadeInDiv>
              ) : null}
              <FadeInDiv
                style={
                  character ? { color: getLogColor(character, log) } : undefined
                }
              >
                {`${Math.floor(log.timestamp / 60)}`.padStart(2, "0")}:
                {`${log.timestamp % 60}`.padStart(2, "0")} -- {log.message}
              </FadeInDiv>
            </Grid>
          ))}
        </div>
      </Grid>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.situation.state.logs.length ===
        nextProps.situation.state.logs.length &&
      prevProps.showHidden === nextProps.showHidden &&
      prevProps.character === nextProps.character
    );
  }
);
