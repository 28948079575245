import React, { useEffect, useState } from "react";
import { Button, Card, Text } from "@arwes/core";
import { CardText, Modal, PageLayout } from "../layout";
import { useFirestore, useUser, useFirestoreCollection } from "reactfire";
import { useHistory } from "react-router-dom";
import { User, firestore } from "firebase";
import {
  useCreateCharacter,
  useCurrentUserCharacterList,
} from "./useCharacter";
import { DialogActions, Grid } from "@material-ui/core";
import { FrameContent } from "../layout/FrameContent";
import { avatars } from "./avatars";
import { truncate } from "lodash";
import { Link } from "../navigation";
import { useAccount, useSubscription } from "../account";
import { CharacterLimit } from "./CharacterLimit";
import { ConfirmButton } from "../components";
import { CharacterImportModal } from "./CharacterImportModal";

export const CharactersPage = (props: { activate: boolean }) => {
  const [firstRenderTime] = useState(() => Date.now());
  const { activate } = props;
  const history = useHistory();
  const charactersRef = useFirestore().collection("characters");
  const characters = useCurrentUserCharacterList();
  const createCharacter = useCreateCharacter();
  const subscription = useSubscription();
  const account = useAccount();

  const [characterModalOpen, setCharacterModalOpen] = useState(false);

  useEffect(() => {
    document.title = `Characters`;
  }, []);

  return (
    <PageLayout>
      <FrameContent style={{ padding: "16px" }} animator={{ activate }}>
        <Grid container direction="column">
          <Grid item container direction="row">
            <Grid item xs>
              <Text as="h3">Characters</Text>
            </Grid>
            <Grid item>
              <Button
                onClick={async () => {
                  if (
                    (account.totalCharacters || 0) >= 10 &&
                    !subscription.hasSubscription()
                  ) {
                    history.push("/subscription");
                  } else {
                    const newCharacter = await createCharacter();
                    history.push(`/characters/${newCharacter.id}`);
                  }
                }}
              >
                <Text>Create</Text>
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={async () => {
                  setCharacterModalOpen(true);
                }}
              >
                <Text>Import</Text>
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <CharacterLimit />
          </Grid>
          {characters.docs.length === 0 && (
            <Grid item container justify="center" style={{ padding: "16px" }}>
              <Grid item xs md={6}>
                <Card
                  title="Nothing to see yet!"
                  image={{
                    src: "/images/people-in-white-futuristic.jpg",
                  }}
                >
                  <Text>Get started by creating a character above</Text>
                </Card>
              </Grid>
            </Grid>
          )}
          <Grid item container direction="row" spacing={2}>
            {characters.docs.map((characterRef, index) => {
              const character = characterRef.data();
              return (
                <Grid key={characterRef.id} item xs={12} md={6}>
                  <Card
                    animator={{
                      root: true,
                      activate,
                      duration: {
                        delay: Math.max(
                          100 * index - (Date.now() - firstRenderTime),
                          0
                        ),
                      },
                    }}
                    title={character.displayName}
                    image={{ src: avatars[character.avatar] }}
                    landscape
                    options={
                      <Grid container justify="center">
                        {character.campaignId && (
                          <Link href={`/players/${characterRef.id}`}>
                            <Button>
                              <Text>Play</Text>
                            </Button>
                          </Link>
                        )}
                        <Link href={`/characters/${characterRef.id}/overview`}>
                          <Button>
                            <Text>View</Text>
                          </Button>
                        </Link>
                        <ConfirmButton
                          key={characterRef.id}
                          onClick={() =>
                            charactersRef.doc(characterRef.id).delete()
                          }
                        >
                          <Text>Delete</Text>
                        </ConfirmButton>
                      </Grid>
                    }
                  >
                    <CardText>{character.description}</CardText>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </FrameContent>
      <CharacterImportModal
        isOpen={characterModalOpen}
        handleClose={() => setCharacterModalOpen(false)}
      />
    </PageLayout>
  );
};
