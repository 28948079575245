import { Animator } from "@arwes/animation";
import { Button, Text } from "@arwes/core";
import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import { Hex } from "honeycomb-grid";
import { capitalize } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSubscription } from "../account";
import { useEditableCampaign } from "../campaign";
import { TargetType } from "../character/tech";
import { WideFrameLines } from "../components";
import { pathCoordinatesToHexes } from "../hexgrid";
import { Sidebar } from "../layout";
import { AnimatedRouteChildrenProps } from "../navigation/AnimatedRoute";
import { useTargetController } from "../player/useTargetController";
import { SituationMap } from "../situations/SituationMap";
import { useEditableMap } from "./useMap";
import { FogOfWarStatus } from "./utils";

export const MapPage: React.FC<AnimatedRouteChildrenProps<{ id: string }>> = (
  props
) => {
  const { activate } = props;
  const { id } = props.match.params;
  const targetController = useTargetController();

  const map = useEditableMap(id)!;
  const campaign = useEditableCampaign(map.state.campaignId);

  const subscription = useSubscription();

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [terrainType, setTerrainType] = useState(0);

  useEffect(() => {
    const targetKeys = targetController.targets.map(
      (target) => `${target.q}-${target.r}`
    );
    if (
      targetController.targetType === TargetType.path &&
      new Set(targetKeys).size !== targetKeys.length
    ) {
      const hexPath: Hex[] = pathCoordinatesToHexes(targetController.targets);
      map.setTerrain(hexPath, terrainType);
      targetController.setTargets([]);
    }
  }, [targetController.targetType, targetController.targets]);

  useEffect(() => {
    document.title = `${map.state.displayName} - Map`;
  }, [map.state.displayName]);

  return (
    <Animator
      animator={{
        root: true,
        activate,
      }}
    >
      <SituationMap
        map={map}
        campaign={campaign}
        targetController={targetController}
      />
      <Sidebar>
        <Grid container spacing={2} direction="column">
          <Grid container spacing={2} item>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item>
                {editMode ? (
                  <TextField
                    style={{ maxWidth: "400px" }}
                    label="Situation Name"
                    variant="filled"
                    value={map.state.displayName}
                    fullWidth
                    disabled={loading}
                    onChange={(e) => {
                      map.setDisplayName(e.target.value);
                    }}
                  />
                ) : (
                  <Text as="h3">{map.state.displayName}</Text>
                )}
              </Grid>
            </Grid>
            <Grid item>
              {editMode ? (
                <div>
                  <Button
                    disabled={loading}
                    onClick={async () => {
                      map.reset();
                      setEditMode(false);
                      targetController.clear();
                    }}
                  >
                    <Text>Cancel</Text>
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={async () => {
                      setLoading(true);
                      await map.commit();
                      setEditMode(false);
                      setLoading(false);
                      targetController.clear();
                    }}
                  >
                    <Text>Save</Text>
                  </Button>
                </div>
              ) : (
                <Grid item container>
                  <Button onClick={() => setEditMode(true)}>
                    <Text>Edit</Text>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          {editMode ? (
            <Grid item container spacing={2}>
              <InputLabel shrink>Terrain Type</InputLabel>
              <Grid item container>
                <Button
                  palette={terrainType === 0 ? "secondary" : "primary"}
                  active
                  onClick={() => setTerrainType(0)}
                >
                  <Text>Clear</Text>
                </Button>
                <Button
                  palette={terrainType === 10 ? "secondary" : "primary"}
                  active
                  onClick={() => setTerrainType(10)}
                >
                  <Text>Pit</Text>
                </Button>
                <Button
                  palette={terrainType === 15 ? "secondary" : "primary"}
                  active
                  onClick={() => setTerrainType(15)}
                >
                  <Text>Low Wall</Text>
                </Button>
                <Button
                  palette={terrainType === 20 ? "secondary" : "primary"}
                  active
                  onClick={() => setTerrainType(20)}
                >
                  <Text>High Wall</Text>
                </Button>
                <Button
                  palette={terrainType === 25 ? "secondary" : "primary"}
                  active
                  onClick={() => setTerrainType(25)}
                >
                  <Text>Door</Text>
                </Button>
              </Grid>
              <InputLabel shrink>Shape</InputLabel>
              <Grid item container>
                <Button
                  active
                  palette={
                    targetController.targetType === null
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() => targetController.setTargetType(null)}
                >
                  <Text>None</Text>
                </Button>
                <Button
                  active
                  palette={
                    targetController.targetType === TargetType.path
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() =>
                    targetController.setTargetType(TargetType.path)
                  }
                >
                  <Text>Path</Text>
                </Button>
              </Grid>
            </Grid>
          ) : null}
          <WideFrameLines
            hideTopLines
            hideShapes
            style={{ marginBottom: "16px" }}
          />
          <Grid container item direction="column">
            <Grid item>
              <Text as="h4">Background</Text>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction="column">
            <Grid item container direction="column">
              {editMode ? (
                <TextField
                  label="Image URL"
                  variant="filled"
                  fullWidth
                  value={map.state.backgroundImage?.src}
                  disabled={loading}
                  onChange={(e) => {
                    map.setBackgroundSrc(e.target.value);
                  }}
                />
              ) : (
                <>
                  <InputLabel shrink>Image URL</InputLabel>
                  <Grid item>
                    <Text>{map.state.backgroundImage?.src}</Text>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item container direction="column">
              {editMode ? (
                <TextField
                  label="Scale"
                  variant="filled"
                  type="number"
                  fullWidth
                  value={map.state.backgroundImage?.scale || 1}
                  disabled={loading}
                  onChange={(e) => {
                    map.setBackgroundScale(parseFloat(e.target.value));
                  }}
                />
              ) : (
                <>
                  <InputLabel shrink>Scale</InputLabel>
                  <Grid item>
                    <Text>{map.state.backgroundImage?.scale}</Text>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item>
              <InputLabel shrink>Fog of war</InputLabel>
              {editMode ? (
                <Select
                  id="fow-select"
                  fullWidth
                  value={map.state.fogOfWar || FogOfWarStatus.disabled}
                  disabled={!subscription.hasSubscription()}
                  onChange={(event) => {
                    map.setFogOfWar(event.target.value as FogOfWarStatus);
                  }}
                >
                  <MenuItem key="disabled" value={"disabled"}>
                    Disabled
                  </MenuItem>
                  <MenuItem key="dynamic" value={"dynamic"}>
                    Dynamic
                  </MenuItem>
                  <MenuItem key="enabled" value={"enabled"}>
                    Enabled
                  </MenuItem>
                </Select>
              ) : (
                <Text>
                  {capitalize(map.state.fogOfWar || FogOfWarStatus.disabled)}
                </Text>
              )}
            </Grid>
          </Grid>
          <WideFrameLines
            hideTopLines
            hideShapes
            style={{ marginBottom: "16px" }}
          />
          <Grid container item direction="column">
            <Grid item>
              <Text as="h4">Notes</Text>
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              maxHeight: "35vh",
              // marginLeft: "16px",
              // marginRight: "16px",
              overflowY: "auto",
            }}
          >
            {editMode ? (
              <TextField
                label="Map Notes"
                variant="filled"
                multiline
                rows={10}
                fullWidth
                value={map.state.notes}
                disabled={loading}
                onChange={(e) => {
                  map.setNotes(e.target.value);
                }}
              />
            ) : (
              <Text style={{ whiteSpace: "pre-wrap" }}>{map.state.notes}</Text>
            )}
          </Grid>
        </Grid>
      </Sidebar>
    </Animator>
  );
};
