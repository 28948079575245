import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

export const Link = withRouter((props: RouteComponentProps & any) => (
  <a
    {...props}
    onClick={(e: any) => {
      if (!props.href.includes("#")) {
        e.preventDefault();
      }

      props.history.push(props.href);
    }}
  ></a>
));
