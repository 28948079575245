import React, { useCallback, useEffect, useRef, useState } from "react";
import firebase from "firebase";
import { Modal, PageLayout, Sidebar } from "../layout";
import { EditableCampaign, useEditableCampaign } from "../campaign";
import {
  DialogActions,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import { Button, Table, Text } from "@arwes/core";

import { FrameContent } from "../layout/FrameContent";
import {
  Clock,
  LoadingBars,
  LoadingButton,
  SuspenseWithLoadingBars,
  WideFrameLines,
} from "../components";
import { Link } from "../navigation";
import { AnimatedRouteChildrenProps } from "../navigation/AnimatedRoute";
import {
  EditableSituation,
  useAddCharacterToSituation,
  useRemoveCharacterFromSituation,
  useEditableSituation,
  useSituationActions,
  useSetCharacterActionsInSituation,
} from "./useSituation";
import { useCampaignCharacterList, useSituationNpcList } from "../character";
import { skillList } from "../character/useCharacter";
import { startCase } from "lodash";
import { useFirestore } from "reactfire";
import { SituationLogView } from "./SituationLog";
import { SituationPlayerView } from "../player/SituationPlayerView";
import {
  LabelData,
  SkillChallengeObjectiveType,
  SkillChallengeTemplate,
} from "./utils";
import { AttitudeType, FullCharacter, Stat } from "../character/utils";
import { actionById } from "../character/tech";
import { SituationMap } from "./SituationMap";
import { useTargetController } from "../player/useTargetController";
import { useEditableMap } from "../map/useMap";
import { useSubscription } from "../account";
import { AxialCoordinates } from "honeycomb-grid";
import { Animator } from "@arwes/animation";

// function useTimer() {
//   const [timer, setTimer] = useState(0);
//   useEffect(() => {
//     setTimeout(() => setTimer(timer + 1), 1000);
//   }, [timer]);
//   return timer;
// }

// const TimeController: React.FC<{
//   situation: EditableSituation;
//   pause: boolean;
// }> = ({ situation, pause }) => {
//   const timer = useTimer();
//   const currentTime = useRef(timer);
//   const [lastStepTime, setLastStepTime] = useState(timer);
//   const players = useCampaignCharacterList(situation.state.campaignId);
//   const npcs = useSituationNpcList(situation.state.campaignId, situation.id);
//   const campaign = useEditableCampaign(situation.state.campaignId);
//   const actions = useSituationActions(situation.id);

//   useEffect(() => {
//     currentTime.current = timer;
//   }, [timer]);

//   const allCharacters = [...players, ...npcs]
//     .map((character) => ({
//       [character.id]: character,
//     }))
//     .reduce((a, b) => ({ ...a, ...b }), {});

//   const processSituationStep = firebase
//     .functions()
//     .httpsCallable("processSituationStep");

//   const processNextStep = async (skipToTime?: number) => {
//     setLastStepTime(Infinity);
//     await processSituationStep({
//       situationId: situation.id,
//       mapId: situation.state.mapId,
//       campaignId: campaign.id,
//       skipToTime,
//     });
//     setLastStepTime(currentTime.current);
//   };

//   useEffect(() => {
//     if (
//       !pause &&
//       currentTime.current > lastStepTime + 5 &&
//       actions.docs.some((actionDoc) => {
//         return (
//           (allCharacters[actionDoc.id] !== undefined &&
//             allCharacters[actionDoc.id].npc === true) ||
//           campaign.state.charactersCurrentSituation[actionDoc.id] ===
//             situation.id
//         );
//       }) &&
//       actions.docs.every((actionDoc) => {
//         return (
//           actionDoc.data().ready ||
//           allCharacters[actionDoc.id] === undefined ||
//           (campaign.state.charactersCurrentSituation[actionDoc.id] !==
//             situation.id &&
//             allCharacters[actionDoc.id].npc !== true)
//         );
//       })
//     ) {
//       processNextStep();
//     }
//   }, [currentTime.current, pause, lastStepTime]);

//   return null;
// };

export const SituationPage: React.FC<
  AnimatedRouteChildrenProps<{ id: string }>
> = (props) => {
  const { activate } = props;
  const id = props.match.params.id;

  // const firestore = useFirestore();
  const situation = useEditableSituation(id);

  // const players = useCampaignCharacterList(situation.state.campaignId);
  // const npcs = useSituationNpcList(situation.state.campaignId, id);
  const campaign = useEditableCampaign(situation.state.campaignId);

  const map = useEditableMap(situation.state.mapId);
  // const actions = useSituationActions(situation.id);

  // const allCharacters = [...players, ...npcs]
  //   .map((character) => ({
  //     [character.id]: character,
  //   }))
  //   .reduce((a, b) => ({ ...a, ...b }), {});

  // const [pause, setPause] = useState(false);

  const [modalCharacter, setModalCharacter] = useState(
    undefined as FullCharacter | undefined
  );

  const [labelId, setLabelId] = useState(undefined as string | undefined);
  const [labelCoordinates, setLabelCoordinates] = useState(
    undefined as AxialCoordinates | undefined
  );

  const [isAddNpcModalOpen, setIsAddNpcModalOpen] = useState(false);
  const [npcCoordinates, setNpcCoordinates] = useState(
    undefined as AxialCoordinates | undefined
  );

  const targetController = useTargetController();

  useEffect(() => {
    document.title = `${situation.state.displayName} - Situation`;
  }, [situation.state.displayName]);

  return (
    <Animator
      animator={{
        root: true,
        activate,
      }}
    >
      {/* <TimeController situation={situation} pause={pause} /> */}
      <SituationMap
        targetController={targetController}
        character={modalCharacter}
        situation={situation}
        map={map}
        campaign={campaign}
        onClickCharacter={(character) => setModalCharacter(character)}
        onClickLabel={(labelId) => setLabelId(labelId)}
        onCreateLabel={(coordinates) => {
          setModalCharacter(undefined);
          setLabelCoordinates(coordinates);
          setLabelId("new");
        }}
        onAddNpc={(coordinates) => {
          setModalCharacter(undefined);
          setNpcCoordinates(coordinates);
          setIsAddNpcModalOpen(true);
        }}
        admin
      />
      <Grid container direction="row">
        {modalCharacter ? (
          <SuspenseWithLoadingBars traceId="situation-character-view">
            <SituationPlayerView
              campaign={campaign}
              character={modalCharacter}
              situation={situation}
              targetController={targetController}
              admin
              key={modalCharacter.id}
            />
          </SuspenseWithLoadingBars>
        ) : (
          <SituationSidebar
            situationId={situation.id}
            labelId={labelId}
            setLabelId={setLabelId}
            labelCoordinates={labelCoordinates}
            npcCoordinates={npcCoordinates}
            isAddNpcModalOpen={isAddNpcModalOpen}
            setIsAddNpcModalOpen={setIsAddNpcModalOpen}
            modalCharacter={modalCharacter}
            setModalCharacter={setModalCharacter}
            // pause={pause}
            // setPause={setPause}
            //processNextStep={processNextStep}
          />
        )}
        {modalCharacter && (
          <Grid item style={{ paddingTop: "8px" }} key={modalCharacter.id}>
            <Button
              active
              onClick={() => {
                targetController.clear();
                setModalCharacter(undefined);
              }}
            >
              <Text>{`Deselect ${modalCharacter.displayName}`}</Text>
            </Button>
          </Grid>
        )}
      </Grid>
    </Animator>
  );
};

const SituationSidebar: React.FC<{
  situationId: string;
  labelId: any;
  setLabelId: any;
  labelCoordinates?: AxialCoordinates;
  npcCoordinates?: AxialCoordinates;
  isAddNpcModalOpen: boolean;
  setIsAddNpcModalOpen: any;
  modalCharacter?: FullCharacter;
  setModalCharacter: (character: FullCharacter | undefined) => void;
  // pause: boolean;
  // setPause: (pause: boolean) => void;
  // processNextStep: (skipToTime?: number) => void;
}> = ({
  situationId,
  modalCharacter,
  setModalCharacter,
  labelId,
  setLabelId,
  labelCoordinates,
  npcCoordinates,
  isAddNpcModalOpen,
  setIsAddNpcModalOpen,
}) => {
  const situation = useEditableSituation(situationId);

  const processSituationStep = firebase
    .functions()
    .httpsCallable("processSituationStep");

  const processNextStep = async (skipToTime?: number) => {
    await processSituationStep({
      situationId: situation.id,
      mapId: situation.state.mapId,
      campaignId: campaign.id,
      skipToTime,
    });
  };
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modalSkillChallengeId, setModalSkillChallengeId] = useState(
    undefined as string | undefined
  );

  const removeCharacterFromSituation = useRemoveCharacterFromSituation();

  const [processing, setProcessing] = useState(false);

  const players = useCampaignCharacterList(situation.state.campaignId);
  const npcs = useSituationNpcList(situation.state.campaignId, situation.id);
  const campaign = useEditableCampaign(situation.state.campaignId);
  const actions = useSituationActions(situation.id);

  const subscription = useSubscription();

  const npcsAndDrones = npcs
    .map((character) => ({
      [character.id]: character,
      ...(situation.state.characters[character.id]?.droneDeployed
        ? { [`drone-${character.id}`]: character }
        : {}),
    }))
    .reduce((a, b) => ({ ...a, ...b }), {});

  const allCharacters = {
    ...players
      .map((character) => ({
        [character.id]: character,
      }))
      .reduce((a, b) => ({ ...a, ...b }), {}),
    ...npcsAndDrones,
  };

  const allReady = actions.docs.every((actionDoc) => {
    return (
      actionDoc.data().ready ||
      allCharacters[actionDoc.id] === undefined ||
      (!allCharacters[actionDoc.id].npc &&
        campaign.state.charactersCurrentSituation[actionDoc.id] !==
          situation.id)
    );
  });

  return (
    <Sidebar>
      <Grid container spacing={2} direction="column">
        <Grid container spacing={2} item>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item>
              {editMode ? (
                <TextField
                  style={{ maxWidth: "400px" }}
                  label="Situation Name"
                  variant="filled"
                  value={situation.state.displayName}
                  fullWidth
                  disabled={loading}
                  onChange={(e) => {
                    situation.setDisplayName(e.target.value);
                  }}
                />
              ) : (
                <Text as="h3">{situation.state.displayName}</Text>
              )}
            </Grid>
          </Grid>
          <Grid item>
            {editMode ? (
              <div>
                <Button
                  disabled={loading}
                  onClick={async () => {
                    situation.reset();
                    setEditMode(false);
                  }}
                >
                  <Text>Cancel</Text>
                </Button>
                <Button
                  disabled={loading}
                  onClick={async () => {
                    setLoading(true);
                    await situation.commit();
                    setEditMode(false);
                    setLoading(false);
                  }}
                >
                  <Text>Save</Text>
                </Button>
              </div>
            ) : (
              <Grid item container>
                <Button onClick={() => setEditMode(true)}>
                  <Text>Edit</Text>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
        <Grid container item direction="column">
          <Grid item>
            <Text as="h4">Notes</Text>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            maxHeight: "35vh",
            // marginLeft: "16px",
            // marginRight: "16px",
            overflowY: "auto",
          }}
        >
          {editMode ? (
            <TextField
              label="Situation Notes"
              variant="filled"
              multiline
              rows={10}
              fullWidth
              value={situation.state.notes}
              disabled={loading}
              onChange={(e) => {
                situation.setNotes(e.target.value);
              }}
            />
          ) : (
            <Text style={{ whiteSpace: "pre-wrap" }}>
              {situation.state.notes}
            </Text>
          )}
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
        <Grid item>
          <Text as="h4">Map</Text>
        </Grid>
        <Grid item>
          <InputLabel shrink>Location</InputLabel>
          {editMode ? (
            <Select
              id="map-select"
              value={situation.state.mapId}
              fullWidth
              onChange={(event) => situation.setMap(event.target.value as any)}
            >
              {Object.keys(campaign.state.maps).map((mapId) => {
                return (
                  <MenuItem key={mapId} value={mapId}>
                    {campaign.state.maps[mapId].displayName}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <Text style={{ whiteSpace: "pre-wrap" }}>
              {(situation.state.mapId &&
                campaign.state.maps[situation.state.mapId].displayName) ||
                "None"}
            </Text>
          )}
        </Grid>
        <Grid item>
          <InputLabel shrink>Dynamic vision</InputLabel>
          {editMode ? (
            <Switch
              disabled={!subscription.hasSubscription()}
              checked={situation.state.dynamicVision}
              onChange={(event) => {
                situation.setDynamicVision(event.target.checked);
              }}
            />
          ) : (
            <Text>{situation.state.dynamicVision ? "On" : "Off"}</Text>
          )}
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
        <Grid container item justify="space-between">
          <Grid item>
            <Text as="h3">Situation Log</Text>
          </Grid>
          <Grid item>
            <Clock time={situation.state.time} />
            <span style={{ paddingLeft: "16px" }}>
              {/* <Button
                palette={pause ? "error" : "primary"}
                onClick={async () => {
                  setPause(!pause);
                }}
              >
                <Text>{"| |"}</Text>
              </Button> */}

              {processing ? (
                <LoadingBars />
              ) : (
                <>
                  <Button
                    disabled={processing}
                    palette={allReady ? "success" : "primary"}
                    onClick={async () => {
                      setProcessing(true);
                      await processNextStep();
                      setProcessing(false);
                    }}
                  >
                    <Text>{">|"}</Text>
                  </Button>
                  <Button
                    disabled={processing}
                    onClick={async () => {
                      setProcessing(true);
                      const skipToTime = actions.docs
                        .filter(
                          (actionDoc) =>
                            allCharacters[actionDoc.id] !== undefined
                        )
                        .map((actionDoc) => actionDoc.data().actions)
                        .filter((a) => a !== undefined)
                        .reduce((a, b) => a?.concat(b!), [])
                        ?.map(
                          (actions) =>
                            actions.timeStarted +
                            actionById(actions.actionId).duration
                        )
                        .reduce((a, b) => Math.min(a, b), Infinity);
                      await processNextStep(
                        skipToTime !== Infinity ? skipToTime : undefined
                      );
                      setProcessing(false);
                    }}
                  >
                    <Text>{">>"}</Text>
                  </Button>
                </>
              )}
            </span>
          </Grid>
        </Grid>
        <SituationLogView situation={situation} showHidden />
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
        <Grid container>
          <Grid item container justify="space-between">
            <Grid item>
              <Text as="h4">Players</Text>
            </Grid>
          </Grid>
          <Grid item xs>
            <Table
              headers={[
                { id: "name", data: "Name" },
                { id: "active", data: "Active" },
                { id: "actions", data: "Actions" },
              ]}
              dataset={players.map((player) => {
                const playerActions = actions.docs
                  .find((a) => a.id === player.id)
                  ?.data();
                return {
                  id: player.id,
                  columns: [
                    {
                      id: "name",
                      data: (
                        <Text>
                          {player.displayName}{" "}
                          {playerActions?.ready ? "✅" : "⚠"}
                        </Text>
                      ),
                    },
                    {
                      id: "active",
                      data: (
                        <Grid container justify="center">
                          <Switch
                            checked={
                              campaign.state.charactersCurrentSituation[
                                player.id
                              ] === situation.id
                            }
                            onChange={(event) => {
                              if (event.target.checked) {
                                campaign.setCurrentSituationForCharacter(
                                  player.id,
                                  situation.id
                                );
                              } else {
                                campaign.setCurrentSituationForCharacter(
                                  player.id,
                                  null
                                );
                              }
                              campaign.commit();
                            }}
                          />{" "}
                        </Grid>
                      ),
                    },
                    {
                      id: "actions",
                      data: (
                        <Grid container justify="center">
                          <Button onClick={() => setModalCharacter(player)}>
                            <Text>Details</Text>
                          </Button>
                        </Grid>
                      ),
                    },
                  ],
                };
              })}
              columnWidths={["calc(65% - 5em)", "5em", "35%"]}
            />
          </Grid>
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
        <Grid container>
          <Grid item container justify="space-between">
            <Grid item>
              <Text as="h4">Skill Challenges</Text>
            </Grid>
            <Grid item>
              <Button
                onClick={async () => {
                  setModalSkillChallengeId("new");
                }}
              >
                <Text>Create</Text>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs>
            <Table
              headers={[
                { id: "name", data: "Name" },
                { id: "visible", data: "Visible" },
                { id: "actions", data: "Actions" },
              ]}
              dataset={Object.keys(situation.state.skillChallenges)
                .sort((a, b) => {
                  const sa = situation.state.skillChallenges[a]!;
                  const sb = situation.state.skillChallenges[b]!;
                  if (sa.displayName < sb.displayName) {
                    return -1;
                  }
                  if (sa.displayName > sb.displayName) {
                    return 1;
                  }
                  return 0;
                })
                .map((id) => {
                  const skillChallenge = situation.state.skillChallenges[id]!;
                  return {
                    id,
                    columns: [
                      {
                        id: "name",
                        data: skillChallenge.displayName,
                      },
                      {
                        id: "visible",
                        data: (
                          <Grid container justify="center">
                            <Switch
                              checked={skillChallenge.visible}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  situation.commitUpdateSkillChallenge(id, {
                                    ...skillChallenge,
                                    visible: true,
                                  });
                                } else {
                                  situation.commitUpdateSkillChallenge(id, {
                                    ...skillChallenge,
                                    visible: false,
                                  });
                                }
                              }}
                            />{" "}
                          </Grid>
                        ),
                      },
                      {
                        id: "actions",
                        data: !id.startsWith("contest-") && (
                          <Grid container justify="center">
                            <Button
                              onClick={() => {
                                setModalSkillChallengeId(id);
                              }}
                            >
                              <Text>Edit</Text>
                            </Button>
                            <Button
                              onClick={async () => {
                                await situation.commitDeleteSkillChallenge(id);
                                await situation.commitDeleteSkillChallenge(
                                  `contest-${id}`
                                );
                              }}
                            >
                              Delete
                            </Button>
                          </Grid>
                        ),
                      },
                    ],
                  };
                })}
              columnWidths={["calc(65% - 5em)", "5em", "35%"]}
            />
          </Grid>
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
        <Grid container>
          <Grid item container justify="space-between">
            <Grid item>
              <Text as="h4">Labels</Text>
            </Grid>
            <Grid item>
              <Button
                onClick={async () => {
                  setLabelId("new");
                }}
              >
                <Text>Create</Text>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs>
            <Table
              headers={[
                { id: "name", data: "Name" },
                { id: "visible", data: "Visible" },
                { id: "actions", data: "Actions" },
              ]}
              dataset={
                situation.state.labels
                  ? Object.keys(situation.state.labels)
                      .sort((a, b) => {
                        const sa = situation.state.labels![a]!;
                        const sb = situation.state.labels![b]!;
                        if (sa.displayName < sb.displayName) {
                          return -1;
                        }
                        if (sa.displayName > sb.displayName) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((id) => {
                        const label = situation.state.labels![id]!;
                        return {
                          id,
                          columns: [
                            {
                              id: "name",
                              data: label.displayName,
                            },
                            {
                              id: "visible",
                              data: (
                                <Grid container justify="center">
                                  <Switch
                                    checked={label.visible}
                                    onChange={(event) => {
                                      if (event.target.checked) {
                                        situation.commitUpdateLabel(id, {
                                          ...label,
                                          visible: true,
                                        });
                                      } else {
                                        situation.commitUpdateLabel(id, {
                                          ...label,
                                          visible: false,
                                        });
                                      }
                                    }}
                                  />{" "}
                                </Grid>
                              ),
                            },
                            {
                              id: "actions",
                              data: !id.startsWith("contest-") && (
                                <Grid container justify="center">
                                  <Button
                                    onClick={() => {
                                      setLabelId(id);
                                    }}
                                  >
                                    <Text>Edit</Text>
                                  </Button>
                                  <Button
                                    onClick={async () => {
                                      await situation.commitDeleteLabel(id);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </Grid>
                              ),
                            },
                          ],
                        };
                      })
                  : []
              }
              columnWidths={["calc(65% - 5em)", "5em", "35%"]}
            />
          </Grid>
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
        <Grid container>
          <Grid item container justify="space-between">
            <Grid item>
              <Text as="h4">NPCs</Text>
            </Grid>
            <Grid item>
              <Button
                onClick={async () => {
                  setIsAddNpcModalOpen(true);
                }}
              >
                <Text>Add NPC</Text>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table
              headers={[
                { id: "name", data: "Name" },
                { id: "visible", data: "Visible" },
                { id: "actions", data: "Actions" },
              ]}
              dataset={Object.entries(npcsAndDrones).map(([npcId, npc]) => {
                const npcActions = actions.docs
                  .find((a) => a.id === npc.id)
                  ?.data();
                return {
                  id: npcId,
                  columns: [
                    {
                      id: "name",
                      data: `${
                        npc.displayName +
                        (npcId.startsWith("drone-") ? "'s Drone" : "")
                      } ${npcActions?.ready ? "✅" : "⚠"}`,
                    },
                    {
                      id: "visible",
                      data: (
                        <Grid container justify="center">
                          <Switch
                            checked={situation.state.characters[npcId]?.visible}
                            onChange={(event) => {
                              situation.commitUpdateCharacterVisibility(
                                npcId,
                                event.target.checked
                              );
                            }}
                          />{" "}
                        </Grid>
                      ),
                    },
                    {
                      id: "actions",
                      data: npcId.startsWith("drone-") ? null : (
                        <Grid container justify="center">
                          <Button onClick={() => setModalCharacter(npc)}>
                            <Text>Details</Text>
                          </Button>
                          <Link href={`/characters/${npc.id}`}>
                            <Button>
                              <Text>View</Text>
                            </Button>
                          </Link>
                          <Button
                            onClick={() =>
                              removeCharacterFromSituation(npc.id, situation.id)
                            }
                          >
                            <Text>Remove</Text>
                          </Button>
                        </Grid>
                      ),
                    },
                  ],
                };
              })}
              condensed
              columnWidths={["calc(65% - 5em)", "5em", "35%"]}
            />
          </Grid>
        </Grid>
      </Grid>
      <AddNpcModal
        isOpen={isAddNpcModalOpen}
        npcCoordinates={npcCoordinates}
        handleClose={() => setIsAddNpcModalOpen(false)}
        campaign={campaign}
        situation={situation}
      />
      <SkillChallengeModal
        key={modalSkillChallengeId || "new"}
        skillChallengeId={modalSkillChallengeId}
        handleClose={() => setModalSkillChallengeId(undefined)}
        situation={situation}
      />
      <LabelModal
        labelId={labelId}
        coordinates={labelCoordinates}
        handleClose={() => setLabelId(undefined)}
        situation={situation}
      />
      {/* <CharacterDetailsModal
        character={modalCharacter}
        handleClose={() => setModalCharacter(undefined)}
        situation={situation}
        campaign={campaign}
      /> */}
    </Sidebar>
  );
};

const LabelModal: React.FC<{
  handleClose: any;
  situation: EditableSituation;
  labelId?: string;
  coordinates?: AxialCoordinates;
}> = (props) => {
  const { handleClose, situation, labelId, coordinates } = props;
  const defaultState: LabelData = {
    displayName: "",
    size: "medium",
    color: "blue",
    visible: true,
    coordinates: coordinates || {
      q: 0,
      r: 0,
    },
  };
  const [label, setLabel] = useState(defaultState);

  useEffect(() => {
    if (labelId && labelId !== "new") {
      setLabel(situation.state.labels![labelId]!);
    } else {
      setLabel(defaultState);
    }
  }, [labelId]);

  return (
    <Modal isOpen={!!labelId} onClose={handleClose} maxWidth={600}>
      <Grid container xs={12} direction="column">
        <Grid item>
          <Text as="h4">Label</Text>
        </Grid>
        <Grid container item xs={12} spacing={2} justify="center">
          <Grid item xs={10}>
            <TextField
              label="Text"
              value={label.displayName}
              fullWidth
              onChange={(e) => {
                setLabel({
                  ...label,
                  displayName: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <InputLabel shrink>Color</InputLabel>
            <Select
              id="color-select"
              value={label.color}
              fullWidth
              onChange={(event) =>
                setLabel({
                  ...label,
                  color: event.target.value as any,
                })
              }
            >
              <MenuItem key={"blue"} value={"blue"}>
                Blue
              </MenuItem>
              <MenuItem key={"green"} value={"green"}>
                Green
              </MenuItem>
              <MenuItem key={"orange"} value={"orange"}>
                Orange
              </MenuItem>
              <MenuItem key={"pink"} value={"pink"}>
                Pink
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={10}>
            <InputLabel shrink>Size</InputLabel>
            <Select
              id="size-select"
              value={label.size}
              fullWidth
              onChange={(event) =>
                setLabel({
                  ...label,
                  size: event.target.value as any,
                })
              }
            >
              <MenuItem key={"small"} value={"small"}>
                Small
              </MenuItem>
              <MenuItem key={"medium"} value={"medium"}>
                Medium
              </MenuItem>
              <MenuItem key={"large"} value={"large"}>
                Large
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={10}>
            <InputLabel shrink>Visible</InputLabel>
            <Switch
              checked={label.visible}
              onChange={(event) => {
                setLabel({
                  ...label,
                  visible: event.target.checked,
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid>
          <DialogActions>
            <LoadingButton
              disabled={label.displayName === ""}
              onClick={async () => {
                if (labelId && labelId !== "new") {
                  await situation.commitUpdateLabel(labelId, label);
                } else {
                  await situation.commitCreateLabel(label);
                }
                setLabel(defaultState);
                handleClose();
              }}
            >
              <Text>Save</Text>
            </LoadingButton>
            <Button
              onClick={() => {
                setLabel(defaultState);
                handleClose();
              }}
            >
              <Text>Cancel</Text>
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Modal>
  );
};

const AddNpcModal: React.FC<{
  isOpen: boolean;
  npcCoordinates?: AxialCoordinates;
  handleClose: any;
  situation: EditableSituation;
  campaign: EditableCampaign;
}> = (props) => {
  const { isOpen, handleClose, campaign, situation, npcCoordinates } = props;
  const npcs = useSituationNpcList(situation.state.campaignId, situation.id);
  const setCharacterActionsInSituation = useSetCharacterActionsInSituation();
  const addCharacterToSituation = useAddCharacterToSituation();
  const [selectedCharacter, setSelectedCharacter] = useState(
    undefined as string | undefined
  );
  return (
    <Modal isOpen={isOpen} onClose={handleClose} maxWidth={600}>
      <Grid container xs={12} direction="column">
        <Grid item>
          <Text as="h4">Add NPC</Text>
        </Grid>
        <Grid item style={{ marginBottom: "8px" }}>
          <Text>Select a character to add to the situation:</Text>
        </Grid>
        <Grid container item xs={12} justify="center">
          <Grid item xs={6}>
            <Select
              id="character-select"
              value={selectedCharacter}
              fullWidth
              onChange={(event) =>
                setSelectedCharacter(event.target.value as any)
              }
            >
              {Object.keys(campaign.state.npcs)
                .filter(
                  (npcId) => !npcs.some((otherNpc) => otherNpc.id === npcId)
                )
                .sort((a, b) => {
                  if (
                    campaign.state.npcs[a].displayName ===
                    campaign.state.npcs[b].displayName
                  ) {
                    return 0;
                  } else if (
                    campaign.state.npcs[a].displayName <
                    campaign.state.npcs[b].displayName
                  ) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((npcId) => {
                  return (
                    <MenuItem key={npcId} value={npcId}>
                      {campaign.state.npcs[npcId].displayName}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
        </Grid>
        <Grid>
          <DialogActions>
            <Button
              disabled={!selectedCharacter}
              onClick={async () => {
                await Promise.all([
                  addCharacterToSituation(selectedCharacter!, situation.id),
                  setCharacterActionsInSituation(
                    selectedCharacter!,
                    { ready: false },
                    situation.id
                  ),
                  situation.updateCharacterPosition(
                    selectedCharacter!,
                    npcCoordinates || { q: 0, r: 0 }
                  ),
                ]);
                handleClose();
                setSelectedCharacter(undefined);
              }}
            >
              <Text>Add</Text>
            </Button>
            <Button onClick={handleClose}>
              <Text>Cancel</Text>
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Modal>
  );
};

// // Shows SituationPlayerView with the given character and situation
// const CharacterDetailsModal: React.FC<{
//   handleClose: any;
//   character?: FullCharacter;
//   situation: EditableSituation;
//   campaign: EditableCampaign;
// }> = (props) => {
//   const { handleClose, character, situation, campaign } = props;
//   return (
//     <Modal isOpen={!!character} onClose={handleClose}>
//       <Text as="h3">{character?.displayName}</Text>
//       <SuspenseWithLoadingBars traceId="situation-actions">
//         <SituationPlayerView
//           campaign={campaign}
//           character={character!}
//           situation={situation}
//           admin
//         />
//       </SuspenseWithLoadingBars>
//       <DialogActions>
//         <Button onClick={handleClose}>
//           <Text>Close</Text>
//         </Button>
//       </DialogActions>
//     </Modal>
//   );
// };

const SkillChallengeModal: React.FC<{
  handleClose: any;
  situation: EditableSituation;
  skillChallengeId?: string;
}> = (props) => {
  const { handleClose, situation, skillChallengeId } = props;
  const defaultState: SkillChallengeTemplate = {
    displayName: "",
    skill: "athletics",
    targetAttitude: null,
    idealAttitude: null,
    utilizes: {
      attention: true,
      movement: true,
      interaction: true,
    },
    contested: false,
    objectiveType: SkillChallengeObjectiveType.singular,
    threshold: 0,
    duration: 1,
    maxHelpers: 1000,
    helpPercentage: 50,
    visible: false,
  };
  const defaultContestState: Partial<SkillChallengeTemplate> = {
    skill: "athletics",
    objectiveType: SkillChallengeObjectiveType.singular,
    utilizes: {
      attention: true,
      movement: true,
      interaction: true,
    },
    threshold: 0,
    maxHelpers: 1000,
    helpPercentage: 50,
  };
  const [skillChallenge, setSkillChallenge] = useState(
    skillChallengeId
      ? situation.state.skillChallenges[skillChallengeId]!
      : defaultState
  );

  const [contestSkillChallenge, setContestSkillChallenge] = useState(
    skillChallengeId && skillChallenge.contested
      ? situation.state.skillChallenges[`contest-${skillChallengeId}`]!
      : defaultContestState
  );

  const [challengeType, setChallengeType] = useState(
    (skillChallenge.idealAttitude !== null
      ? "convince"
      : skillChallenge.targetAttitude !== null
      ? "manipulate"
      : skillChallenge.duration === Infinity
      ? "continuous"
      : "one-off") as "one-off" | "continuous" | "manipulate" | "convince"
  );

  useEffect(() => {
    if (skillChallengeId && skillChallengeId !== "new") {
      setSkillChallenge(situation.state.skillChallenges[skillChallengeId]!);
      if (situation.state.skillChallenges[skillChallengeId]!.contested) {
        setContestSkillChallenge(
          situation.state.skillChallenges[`contest-${skillChallengeId}`]!
        );
      }
      setChallengeType(
        situation.state.skillChallenges[skillChallengeId]!.idealAttitude !==
          null
          ? "convince"
          : situation.state.skillChallenges[skillChallengeId]!
              .targetAttitude !== null
          ? "manipulate"
          : situation.state.skillChallenges[skillChallengeId]!.duration ===
            Infinity
          ? "continuous"
          : "one-off"
      );
    } else {
      setSkillChallenge(defaultState);
      setContestSkillChallenge(defaultContestState);
    }
  }, [skillChallengeId]);

  return (
    <Modal
      isOpen={!!skillChallengeId}
      onClose={handleClose}
      maxWidth={600}
      actions={
        <DialogActions>
          <LoadingButton
            disabled={skillChallenge.displayName === ""}
            onClick={async () => {
              if (skillChallengeId && skillChallengeId !== "new") {
                await situation.commitUpdateSkillChallenge(
                  skillChallengeId,
                  skillChallenge
                );
                if (skillChallenge.contested) {
                  await situation.commitUpdateSkillChallenge(
                    `contest-${skillChallengeId}`,
                    {
                      ...skillChallenge,
                      ...contestSkillChallenge,
                      displayName: `Contest ${skillChallenge.displayName}`,
                    }
                  );
                } else {
                  await situation.commitDeleteSkillChallenge(
                    `contest-${skillChallengeId}`
                  );
                }
              } else {
                const id = await situation.commitCreateSkillChallenge(
                  skillChallenge
                );
                if (skillChallenge.contested) {
                  await situation.commitCreateSkillChallengeWithId(
                    `contest-${id}`,
                    {
                      ...skillChallenge,
                      ...contestSkillChallenge,
                      displayName: `Contest ${skillChallenge.displayName}`,
                    }
                  );
                }
              }
              setSkillChallenge(defaultState);
              handleClose();
            }}
          >
            <Text>Save</Text>
          </LoadingButton>
          <Button
            onClick={() => {
              setSkillChallenge(defaultState);
              handleClose();
            }}
          >
            <Text>Cancel</Text>
          </Button>
        </DialogActions>
      }
    >
      <Grid container xs={12} direction="column">
        <Grid item>
          <Text as="h4">Skill Challenge</Text>
        </Grid>
        <Grid container item xs={12} spacing={2} justify="center">
          <Grid item xs={10}>
            <TextField
              label="Name"
              value={skillChallenge.displayName}
              fullWidth
              onChange={(e) => {
                setSkillChallenge({
                  ...skillChallenge,
                  displayName: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <InputLabel shrink>Type</InputLabel>
            <Select
              label="Type"
              fullWidth
              value={challengeType}
              onChange={(e) => {
                setChallengeType(e.target.value as any);
                if (e.target.value === "continuous") {
                  setSkillChallenge({
                    ...skillChallenge,
                    duration: Infinity,
                    targetAttitude: null,
                    idealAttitude: null,
                  });
                  if (skillChallenge.contested) {
                    setContestSkillChallenge({
                      ...contestSkillChallenge,
                      duration: Infinity,
                      targetAttitude: null,
                      idealAttitude: null,
                    });
                  }
                } else if (e.target.value === "manipulate") {
                  setSkillChallenge({
                    ...skillChallenge,
                    targetAttitude: AttitudeType.friendly,
                    idealAttitude: null,
                    duration: 1,
                    skill: "persuasion",
                    contested: true,
                  });
                  if (skillChallenge.contested) {
                    setContestSkillChallenge({
                      ...contestSkillChallenge,
                      duration: 1,
                      skill: "persuasion",
                      contested: true,
                    });
                  }
                } else if (e.target.value === "convince") {
                  setSkillChallenge({
                    ...skillChallenge,
                    idealAttitude: AttitudeType.friendly,
                    targetAttitude: null,
                    duration: 1,
                    skill: "persuasion",
                    contested: true,
                  });
                  if (skillChallenge.contested) {
                    setContestSkillChallenge({
                      ...contestSkillChallenge,
                      duration: 1,
                      skill: "persuasion",
                      contested: true,
                    });
                  }
                } else {
                  setSkillChallenge({
                    ...skillChallenge,
                    duration: 1,
                    targetAttitude: null,
                    idealAttitude: null,
                  });
                  if (skillChallenge.contested) {
                    setContestSkillChallenge({
                      ...contestSkillChallenge,
                      duration: 1,
                      targetAttitude: null,
                      idealAttitude: null,
                    });
                  }
                }
              }}
            >
              <MenuItem value="continuous">Continuous</MenuItem>
              <MenuItem value="one-off">One-off</MenuItem>
              <MenuItem value="manipulate">Manipulate</MenuItem>
              <MenuItem value="convince">Convince</MenuItem>
            </Select>
          </Grid>
          {/* Select field for skill challenge attitude*/}
          {["manipulate", "convince"].includes(challengeType) && (
            <Grid item xs={10}>
              <InputLabel shrink>Attitude</InputLabel>
              <Select
                label="Attitude"
                fullWidth
                value={
                  skillChallenge.targetAttitude ?? skillChallenge.idealAttitude
                }
                onChange={(e) => {
                  setSkillChallenge({
                    ...skillChallenge,
                    [skillChallenge.targetAttitude !== null
                      ? "targetAttitude"
                      : "idealAttitude"]: e.target.value as AttitudeType,
                    [skillChallenge.targetAttitude !== null
                      ? "idealAttitude"
                      : "targetAttitude"]: null,
                  });
                }}
              >
                <MenuItem value={AttitudeType.friendly}>Friendly</MenuItem>
                <MenuItem value={AttitudeType.hostile}>Hostile</MenuItem>
                <MenuItem value={AttitudeType.awed}>Awed</MenuItem>
                <MenuItem value={AttitudeType.dismissive}>Dismissive</MenuItem>
                <MenuItem value={AttitudeType.intimidated}>
                  Intimidated
                </MenuItem>
                <MenuItem value={AttitudeType.protective}>Protective</MenuItem>
              </Select>
            </Grid>
          )}
          <Grid item xs={10}>
            <InputLabel shrink>Skill</InputLabel>
            <Select
              label="Skill"
              fullWidth
              value={skillChallenge.skill}
              onChange={(e) => {
                setSkillChallenge({
                  ...skillChallenge,
                  skill: e.target.value as Stat,
                });
              }}
            >
              {(skillChallenge.targetAttitude !== null ||
              skillChallenge.idealAttitude !== null
                ? ["persuasion", "intimidation", "deception", "performance"]
                : skillList
              ).map((skill) => {
                return (
                  <MenuItem key={skill} value={skill}>
                    {startCase(skill)}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          {!["manipulate", "convince"].includes(challengeType) && (
            <>
              <Grid item xs={10}>
                <InputLabel shrink>Objective Type</InputLabel>
                <Select
                  label="Objective Type"
                  fullWidth
                  value={skillChallenge.objectiveType}
                  onChange={(e) => {
                    setSkillChallenge({
                      ...skillChallenge,
                      objectiveType: e.target
                        .value as SkillChallengeObjectiveType,
                      helpPercentage:
                        e.target.value === SkillChallengeObjectiveType.singular
                          ? 50
                          : 100,
                    });
                  }}
                >
                  <MenuItem value={SkillChallengeObjectiveType.singular}>
                    Singular
                  </MenuItem>
                  <MenuItem value={SkillChallengeObjectiveType.group}>
                    Group
                  </MenuItem>
                  <MenuItem value={SkillChallengeObjectiveType.individual}>
                    Individual
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={10}>
                <InputLabel shrink>Contested</InputLabel>
                <Switch
                  checked={skillChallenge.contested}
                  onChange={(event) => {
                    setSkillChallenge({
                      ...skillChallenge,
                      contested: event.target.checked,
                    });
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={10}>
            <TextField
              label="Difficulty"
              fullWidth
              inputProps={{
                type: "number",
                min: "0",
              }}
              value={skillChallenge.threshold}
              onChange={(e) => {
                setSkillChallenge({
                  ...skillChallenge,
                  threshold: parseInt(e.target.value),
                });
              }}
            />
          </Grid>
          {challengeType !== "continuous" && (
            <Grid item xs={10}>
              <TextField
                label="Duration"
                fullWidth
                inputProps={{
                  type: "number",
                  min: "1",
                }}
                disabled={skillChallenge.duration === Infinity}
                value={skillChallenge.duration}
                onChange={(e) => {
                  setSkillChallenge({
                    ...skillChallenge,
                    duration: parseInt(e.target.value),
                  });
                }}
              />
            </Grid>
          )}
          {/* {skillChallenge.objectiveType ===
            SkillChallengeObjectiveType.singular && (
            <>
              <Grid item xs={10}>
                <TextField
                  label="Number of helpers"
                  fullWidth
                  inputProps={{
                    type: "number",
                    min: "0",
                  }}
                  value={skillChallenge.maxHelpers}
                  onChange={(e) => {
                    setSkillChallenge({
                      ...skillChallenge,
                      maxHelpers: parseInt(e.target.value),
                    });
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label="Helper percentage"
                  fullWidth
                  inputProps={{
                    type: "number",
                    min: "0",
                  }}
                  value={skillChallenge.helpPercentage}
                  onChange={(e) => {
                    setSkillChallenge({
                      ...skillChallenge,
                      helpPercentage: parseInt(e.target.value),
                    });
                  }}
                />
              </Grid>
            </>
          )} */}
          <Grid item xs={10}>
            <InputLabel shrink>Utilizes</InputLabel>
            <Grid item>
              <Text>Interaction</Text>
              <Switch
                checked={skillChallenge.utilizes.interaction}
                onChange={(event) => {
                  setSkillChallenge({
                    ...skillChallenge,
                    utilizes: {
                      ...skillChallenge.utilizes,
                      interaction: event.target.checked,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item>
              <Text>Movement</Text>
              <Switch
                checked={skillChallenge.utilizes.movement}
                onChange={(event) => {
                  setSkillChallenge({
                    ...skillChallenge,
                    utilizes: {
                      ...skillChallenge.utilizes,
                      movement: event.target.checked,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item>
              <Text>Attention</Text>
              <Switch
                checked={skillChallenge.utilizes.attention}
                onChange={(event) => {
                  setSkillChallenge({
                    ...skillChallenge,
                    utilizes: {
                      ...skillChallenge.utilizes,
                      attention: event.target.checked,
                    },
                  });
                }}
              />
            </Grid>
          </Grid>
          {skillChallenge.contested === true && (
            <>
              <Grid item xs={12}>
                <Text as="h5">Contest Details</Text>
              </Grid>
              <Grid item xs={10}>
                <InputLabel shrink>Contested Skill</InputLabel>
                <Select
                  label="Contest Skill"
                  fullWidth
                  value={contestSkillChallenge.skill}
                  onChange={(e) => {
                    setContestSkillChallenge({
                      ...contestSkillChallenge,
                      skill: e.target.value as Stat,
                    });
                  }}
                >
                  {(skillChallenge.targetAttitude !== null ||
                  skillChallenge.idealAttitude !== null
                    ? ["persuasion", "intimidation", "deception", "performance"]
                    : skillList
                  ).map((skill) => {
                    return (
                      <MenuItem key={skill} value={skill}>
                        {startCase(skill)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label="Contest Difficulty"
                  fullWidth
                  inputProps={{
                    type: "number",
                    min: "0",
                  }}
                  value={contestSkillChallenge.threshold}
                  onChange={(e) => {
                    setContestSkillChallenge({
                      ...contestSkillChallenge,
                      threshold: parseInt(e.target.value),
                    });
                  }}
                />
              </Grid>
              {!["manipulate", "convince"].includes(challengeType) && (
                <Grid item xs={10}>
                  <InputLabel shrink>Objective Type</InputLabel>
                  <Select
                    label="Objective Type"
                    fullWidth
                    value={contestSkillChallenge.objectiveType}
                    onChange={(e) => {
                      setContestSkillChallenge({
                        ...contestSkillChallenge,
                        objectiveType: e.target
                          .value as SkillChallengeObjectiveType,
                      });
                    }}
                  >
                    <MenuItem value={SkillChallengeObjectiveType.singular}>
                      Singular
                    </MenuItem>
                    <MenuItem value={SkillChallengeObjectiveType.group}>
                      Group
                    </MenuItem>
                    <MenuItem value={SkillChallengeObjectiveType.individual}>
                      Individual
                    </MenuItem>
                  </Select>
                </Grid>
              )}
              {/* {contestSkillChallenge.objectiveType ===
                SkillChallengeObjectiveType.singular && (
                <>
                  <Grid item xs={10}>
                    <TextField
                      label="Number of contest helpers"
                      fullWidth
                      inputProps={{
                        type: "number",
                        min: "0",
                      }}
                      value={contestSkillChallenge.maxHelpers}
                      onChange={(e) => {
                        setContestSkillChallenge({
                          ...contestSkillChallenge,
                          maxHelpers: parseInt(e.target.value),
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      label="Contest helper percentage"
                      fullWidth
                      inputProps={{
                        type: "number",
                        min: "0",
                      }}
                      value={contestSkillChallenge.helpPercentage}
                      onChange={(e) => {
                        setContestSkillChallenge({
                          ...contestSkillChallenge,
                          helpPercentage: parseInt(e.target.value),
                        });
                      }}
                    />
                  </Grid>
                </>
              )} */}
              <Grid item xs={10}>
                <InputLabel shrink>Utilizes</InputLabel>
                <Grid item>
                  <Text>Interaction</Text>
                  <Switch
                    checked={contestSkillChallenge.utilizes!.interaction}
                    onChange={(event) => {
                      setContestSkillChallenge({
                        ...contestSkillChallenge,
                        utilizes: {
                          ...contestSkillChallenge.utilizes,
                          interaction: event.target.checked,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item>
                  <Text>Movement</Text>
                  <Switch
                    checked={contestSkillChallenge.utilizes!.movement}
                    onChange={(event) => {
                      setContestSkillChallenge({
                        ...contestSkillChallenge,
                        utilizes: {
                          ...contestSkillChallenge.utilizes,
                          movement: event.target.checked,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item>
                  <Text>Attention</Text>
                  <Switch
                    checked={contestSkillChallenge.utilizes!.attention}
                    onChange={(event) => {
                      setContestSkillChallenge({
                        ...contestSkillChallenge,
                        utilizes: {
                          ...contestSkillChallenge.utilizes,
                          attention: event.target.checked,
                        },
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};
