import { Animator } from "@arwes/animation";
import { Button, Table, Text } from "@arwes/core";
import { DialogActions, Grid, Slider } from "@material-ui/core";
import { upperFirst } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import { CapacityBar, LoadingBars, WideFrameLines } from "../components";
import { Modal } from "../layout";
import { GmCheck } from "./GmCheck";
import { ShopModal } from "./ShopModal";
import { hasSufficientFreeCapacity, TechDetails } from "./tech";
import { Tech } from "./Tech";
import { EditableCharacter } from "./useCharacter";
import { Stat } from "./utils";

const TabButton = styled(Button)`
  width: 33%;
`;

interface CharacterLoadoutProps {
  editMode: boolean;
  loading: boolean;
  activate: boolean;
  character: EditableCharacter;
}

export const CharacterLoadout: React.FC<CharacterLoadoutProps> = (props) => {
  const { editMode, loading, character, activate } = props;

  const maxAttributes = 10;
  const attributesTotal = Object.values(character.state.attributes).reduce(
    (a, b) => a + b,
    0
  );

  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);

  const [techDetails, setTechDetails] = useState(
    undefined as TechDetails | undefined
  );

  // Tab state
  const [activeTab, setActiveTab] = useState("inventory");

  return (
    <Animator
      animator={{
        root: true,
        activate,
      }}
    >
      <Grid container>
        <Grid container item spacing={2}>
          <Grid item>
            <Text as="h4">Organic Attributes</Text>
          </Grid>
          <Grid item>
            <Text as="h4">
              {attributesTotal < maxAttributes
                ? ` (${maxAttributes - attributesTotal} points left to spend)`
                : ""}
            </Text>
          </Grid>
        </Grid>
        <Grid container item justify="space-evenly">
          {[
            [{ name: "strength" }, { name: "coordination" }],
            [{ name: "intelligence" }, { name: "sensitivity" }],
          ].map((column, index) => (
            <Grid item key={index} xs={12} sm={5} md={4}>
              {column.map(({ name }) => (
                <div key={name}>
                  <Text>{upperFirst(name)}</Text>
                  <Slider
                    color={editMode ? "secondary" : "primary"}
                    disabled={loading}
                    valueLabelDisplay="auto"
                    step={null}
                    marks={[
                      { value: 1 },
                      { value: 2 },
                      { value: 3 },
                      { value: 4 },
                      { value: 5 },
                    ]}
                    min={0}
                    max={5}
                    value={(character.state.attributes as any)[name]}
                    onChange={(_, value: any) => {
                      if (!editMode) {
                        return;
                      }
                      const previousValue = (character.state.attributes as any)[
                        name
                      ];
                      if (
                        attributesTotal +
                          (value - (character.state.attributes as any)[name]) <=
                          maxAttributes ||
                        value < previousValue
                      ) {
                        if (name === "strength") {
                          character.setStrength(value);
                        }
                        if (name === "intelligence") {
                          character.setIntelligence(value);
                        }
                        if (name === "coordination") {
                          character.setCoordination(value);
                        }
                        if (name === "sensitivity") {
                          character.setSensitivity(value);
                        }
                      }
                    }}
                  />
                </div>
              ))}
            </Grid>
          ))}
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{
            marginBottom: "16px",
          }}
        />
      </Grid>

      <Grid container>
        <Grid item>
          <Text as="h4">Capacity</Text>
        </Grid>

        <Grid container item justify="space-evenly">
          {[
            { name: "shell" },
            { name: "head" },
            { name: "torso" },
            { name: "arms" },
            { name: "legs" },
            { name: "carrying" },
          ].map(({ name }, index) => {
            return (
              <Grid
                key={index}
                item
                xs={6}
                md={4}
                container
                direction="column"
                alignItems="center"
              >
                <Grid item>
                  <Text>{upperFirst(name)}</Text>
                </Grid>
                <Grid item>
                  <CapacityBar
                    palette={
                      character.state.stats[
                        `max${upperFirst(name)}Capacity` as Stat
                      ] > 0 &&
                      character.state.stats[
                        `current${upperFirst(name)}Capacity` as Stat
                      ] <=
                        character.state.stats[
                          `max${upperFirst(name)}Capacity` as Stat
                        ]
                        ? "primary"
                        : "error"
                    }
                    length={10}
                    current={
                      character.state.stats[
                        `current${upperFirst(name)}Capacity` as Stat
                      ]
                    }
                    maximum={
                      character.state.stats[
                        `max${upperFirst(name)}Capacity` as Stat
                      ]
                    }
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
      </Grid>

      <Grid container>
        <Grid item container justify="space-between">
          <Grid item>
            <Text as="h4">Tech</Text>
          </Grid>
          <Grid item>
            {editMode ? (
              <Button
                disabled={loading}
                onClick={() => {
                  setIsPurchaseModalOpen(true);
                }}
              >
                <Text>Open Shop</Text>
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Grid container item style={{ paddingBottom: "16px" }} direction="row">
          <TabButton
            palette={activeTab === "inventory" ? "primary" : "tabs"}
            onClick={() => setActiveTab("inventory")}
            active={true}
          >
            <Text>Inventory</Text>
          </TabButton>
          <TabButton
            palette={activeTab === "storage" ? "primary" : "tabs"}
            onClick={() => setActiveTab("storage")}
            active={true}
          >
            <Text>Storage</Text>
          </TabButton>
        </Grid>
        {activeTab === "inventory" ? (
          <Table
            headers={[
              { id: "name", data: "Name" },
              { id: "count", data: "Active" },
              { id: "actions", data: "Actions" },
            ]}
            dataset={Object.values(character.state.tech)
              .sort((a, b) => {
                if (a.displayName < b.displayName) {
                  return -1;
                }
                if (a.displayName > b.displayName) {
                  return 1;
                }
                return 0;
              })
              .filter((tech) => tech.personalCount + tech.equippedCount > 0)
              .map((tech) => {
                const isEquipped = tech.equippedCount > 0;
                return {
                  id: tech.id,
                  columns: [
                    {
                      id: "name",
                      data: (
                        <Grid
                          container
                          onClick={() => {
                            setTechDetails(tech);
                          }}
                          alignItems="center"
                        >
                          {tech.image && (
                            <Grid item>
                              <img
                                src={tech.image}
                                alt={tech.displayName}
                                style={{
                                  maxWidth: "50px",
                                  marginRight: "8px",
                                  imageRendering: "pixelated",
                                }}
                              />
                            </Grid>
                          )}
                          <Grid item>{tech.displayName + " 🔍"}</Grid>
                        </Grid>
                      ),
                    },
                    {
                      id: "count",
                      data: tech.isEquippable
                        ? `${tech.equippedCount}/${
                            tech.personalCount + tech.equippedCount
                          }`
                        : tech.personalCount,
                    },
                    {
                      id: "actions",
                      data: (
                        <>
                          {editMode ? (
                            <Grid container justify="center">
                              {tech.isEquippable && (
                                <>
                                  <Button
                                    disabled={
                                      tech.personalCount === 0 ||
                                      loading ||
                                      !hasSufficientFreeCapacity(
                                        character.state,
                                        tech
                                      ) ||
                                      (tech.requirements &&
                                        tech.requirements.some(
                                          (requirement) =>
                                            !character.state.tech[
                                              requirement.id
                                            ]?.equippedCount
                                        )) ||
                                      (tech.slots &&
                                        tech.slots.some((slot) =>
                                          Object.values(
                                            character.state.tech
                                          ).some(
                                            (tech) =>
                                              tech.equippedCount > 0 &&
                                              tech.slots?.includes(slot)
                                          )
                                        ))
                                    }
                                    onClick={() => {
                                      character.equipTech(tech.id);
                                    }}
                                  >
                                    <Text>
                                      {tech.isEquipment ? "Equip" : "Install"}
                                    </Text>
                                  </Button>
                                  <Button
                                    disabled={
                                      tech.equippedCount === 0 ||
                                      loading ||
                                      Object.values(character.state.tech).some(
                                        (otherTech) =>
                                          otherTech.equippedCount > 0 &&
                                          otherTech.requirements &&
                                          otherTech.requirements.some(
                                            (requirement) =>
                                              requirement.id === tech.id
                                          )
                                      )
                                    }
                                    onClick={() => {
                                      character.unequipTech(tech.id);
                                    }}
                                  >
                                    <Text>
                                      {tech.isEquipment
                                        ? "Unequip"
                                        : "Uninstall"}
                                    </Text>
                                  </Button>
                                  <Button
                                    disabled={
                                      tech.personalCount === 0 || loading
                                    }
                                    onClick={() => {
                                      character.storeTech(tech.id);
                                    }}
                                  >
                                    <Text>Store</Text>
                                  </Button>
                                </>
                              )}
                              {character.state.campaignId && (
                                <GmCheck
                                  campaignId={character.state.campaignId}
                                >
                                  <Button
                                    disabled={
                                      !character.state.tech[tech.id] ||
                                      character.state.tech[tech.id]
                                        ?.personalCount === 0
                                    }
                                    onClick={async () => {
                                      character.addCredits(tech.price || 0);
                                      character.removeTech(tech.id);
                                    }}
                                  >
                                    <Text>Refund</Text>
                                  </Button>
                                </GmCheck>
                              )}
                            </Grid>
                          ) : null}{" "}
                        </>
                      ),
                    },
                  ],
                };
              })}
            columnWidths={["calc(55% - 4em)", "5em", "calc(45% - 1em)"]}
          />
        ) : (
          <Table
            key="storage"
            headers={[
              { id: "name", data: "Name" },
              { id: "stored", data: "Stored" },
              { id: "actions", data: "Actions" },
            ]}
            dataset={Object.values(character.state.tech)
              .sort((a, b) => {
                if (a.displayName < b.displayName) {
                  return -1;
                }
                if (a.displayName > b.displayName) {
                  return 1;
                }
                return 0;
              })
              .filter((tech) => tech.storageCount > 0)
              .map((tech) => {
                return {
                  id: tech.id,
                  columns: [
                    {
                      id: "name",
                      data: (
                        <div
                          onClick={() => {
                            setTechDetails(tech);
                          }}
                        >
                          {tech.displayName + " 🔍"}
                        </div>
                      ),
                    },
                    {
                      id: "stored",
                      data: tech.storageCount,
                    },
                    {
                      id: "actions",
                      data: (
                        <>
                          {editMode ? (
                            <Grid container justify="center">
                              <Button
                                disabled={tech.storageCount === 0 || loading}
                                onClick={() => {
                                  character.unstoreTech(tech.id);
                                }}
                              >
                                <Text>Unstore</Text>
                              </Button>
                            </Grid>
                          ) : null}{" "}
                        </>
                      ),
                    },
                  ],
                };
              })}
            columnWidths={["calc(55% - 4em)", "5em", "calc(45% - 1em)"]}
          />
        )}
      </Grid>
      <TechModal
        tech={techDetails}
        character={editMode ? character : undefined}
        handleClose={() => {
          setTechDetails(undefined);
        }}
      />
      <ShopModal
        isOpen={isPurchaseModalOpen}
        character={character}
        handleClose={() => {
          setIsPurchaseModalOpen(false);
        }}
        setTechDetails={setTechDetails}
      />
    </Animator>
  );
};

const TechModal: React.FC<{
  tech?: TechDetails;
  handleClose: () => void;
  character?: EditableCharacter;
}> = (props) => {
  const { tech, handleClose, character } = props;
  return (
    <Modal
      isOpen={!!tech}
      onClose={handleClose}
      maxWidth={800}
      actions={
        <Grid container alignItems="flex-end" wrap="nowrap" justify="flex-end">
          {tech && character && (
            <>
              <Grid item>
                <Button
                  disabled={
                    character.state.credits < (tech.price || 0) ||
                    character.state.tier < tech.tier ||
                    (!tech.isEquippable &&
                      (!hasSufficientFreeCapacity(character.state, tech) ||
                        (tech.requirements &&
                          tech.requirements.some((requirement) =>
                            requirement.isEquippable
                              ? !character.state.tech[requirement.id]
                                  ?.equippedCount
                              : !character.state.tech[requirement.id]
                                  ?.personalCount
                          )) ||
                        (tech.slots &&
                          tech.slots.some((slot) =>
                            Object.values(character.state.tech).some(
                              (tech) =>
                                tech.equippedCount > 0 &&
                                tech.slots?.includes(slot)
                            )
                          ))))
                  }
                  onClick={async () => {
                    character.removeCredits(tech.price || 0);
                    character.addTech(tech.id);
                  }}
                >
                  <Text>Buy ${tech.price || 0}</Text>
                </Button>
              </Grid>
              {tech.isEquippable && (
                <>
                  <Grid item>
                    <Button
                      disabled={
                        character.state.credits < (tech.price || 0) ||
                        character.state.tier < tech.tier ||
                        !hasSufficientFreeCapacity(character.state, tech) ||
                        (tech.requirements &&
                          tech.requirements.some(
                            (requirement) =>
                              !character.state.tech[requirement.id]
                                ?.equippedCount
                          )) ||
                        (tech.slots &&
                          tech.slots.some((slot) =>
                            Object.values(character.state.tech).some(
                              (tech) =>
                                tech.equippedCount > 0 &&
                                tech.slots?.includes(slot)
                            )
                          ))
                      }
                      onClick={() => {
                        character.removeCredits(tech.price || 0);
                        character.addTech(tech.id);
                        character.equipTech(tech.id);
                      }}
                    >
                      <Text>
                        Buy and {tech.isEquipment ? "Equip" : "Install"}
                      </Text>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={
                        !character.state.tech[tech.id] ||
                        character.state.tech[tech.id]?.personalCount === 0
                      }
                      onClick={async () => {
                        character.addCredits((tech.price || 0) / 2);
                        character.removeTech(tech.id);
                      }}
                    >
                      <Text>Sell ${tech.price / 2 || 0}</Text>
                    </Button>
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid item>
            <Button onClick={handleClose}>
              <Text>Close</Text>
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Tech
        tech={tech!}
        actions={
          <Button onClick={handleClose}>
            <Text>Close</Text>
          </Button>
        }
      />
    </Modal>
  );
};
