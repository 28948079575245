import React, { Suspense } from "react";
import { User } from "firebase";
import { useUser } from "reactfire";
import { useEditableCampaign } from "../campaign";
import { SuspenseWithLoadingBars } from "../components";

export interface GmCheck {
  campaignId: string;
}

export const GmCheckInner: React.FC<GmCheck> = (props) => {
  const { campaignId } = props;
  const currentUser = useUser<User>();
  const campaign = useEditableCampaign(campaignId);

  if (currentUser && currentUser.uid === campaign.state.owner) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export const GmCheck: React.FC<GmCheck> = (props) => {
  return (
    <Suspense fallback={null}>
      <GmCheckInner {...props} />
    </Suspense>
  );
};
