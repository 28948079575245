import React, { useState } from "react";
import { avatars } from "./avatars";
import {
  GridList,
  Dialog,
  DialogTitle,
  GridListTile,
  makeStyles,
  Theme,
  createStyles,
  useMediaQuery,
  TextField,
  Grid,
} from "@material-ui/core";
import { Button, Text } from "@arwes/core";

interface AvatarPickerProps {
  open: boolean;
  selectedValue: number | string;
  onClose: (value: number | string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: 550,
      height: 500,
    },
  })
);

export function AvatarPicker(props: AvatarPickerProps) {
  const classes = useStyles();
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  const { onClose, selectedValue, open } = props;
  const [url, setUrl] = useState(
    typeof selectedValue === "string" ? selectedValue : ""
  );

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: number) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="avatar-picker" open={open}>
      <DialogTitle>Select an avatar</DialogTitle>
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: "16px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <Grid xs={8} item>
          <TextField
            style={{ maxWidth: "400px" }}
            label="Custom Avatar URL"
            variant="filled"
            value={url}
            fullWidth
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              onClose(url);
            }}
          >
            <Text>Save</Text>
          </Button>
        </Grid>
      </Grid>
      <div className={classes.root}>
        <GridList
          className={classes.gridList}
          cellHeight={matches ? 128 : 100}
          cols={matches ? 4 : 3}
        >
          {avatars.map((avatar, index) => (
            <GridListTile key={index} cols={1}>
              <img
                src={avatar}
                alt={`Avatar ${index}`}
                onClick={() => handleListItemClick(index)}
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    </Dialog>
  );
}
