import React, { useState } from "react";
import {
  EditableCharacter,
  HealthBar,
  useCampaignCharacterList,
  useSituationNpcList,
} from "../character";
import {
  DialogActions,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import { Button, FrameBox, Table, Text } from "@arwes/core";

import { CapacityBar, Clock, LoadingBars, WideFrameLines } from "../components";
import { EditableCampaign } from "../campaign";
import { useEditableSituation, SituationLog } from "../situations";
import { EditableSituation } from "../situations/useSituation";
import { ActionsControl } from "./ActionsControl";
import {
  Condition,
  conditionNames,
  TargetType,
  techList,
} from "../character/tech";
import { Modal } from "../layout/Modal";
import { FullCharacter } from "../character/utils";
import { SituationCharacter } from "../situations/utils";
import { SituationMap } from "../situations/SituationMap";
import { FrameContent, Sidebar } from "../layout";
import { TargetController, useTargetController } from "./useTargetController";
import { useEditableMap } from "../map/useMap";

export const SituationPlayerWrapper: React.FC<{
  campaign: EditableCampaign;
  situationId: string;
  character: FullCharacter;
  admin?: boolean;
}> = (props) => {
  const { situationId } = props;
  const situation = useEditableSituation(situationId);
  const map = useEditableMap(situation.state.mapId);

  const targetController = useTargetController();

  return (
    <>
      <SituationMap
        situation={situation}
        map={map}
        {...props}
        targetController={targetController}
      />
      <SituationPlayerView
        situation={situation}
        {...props}
        targetController={targetController}
      />
    </>
  );
};

export const SituationPlayerView: React.FC<{
  campaign: EditableCampaign;
  situation: EditableSituation;
  character: FullCharacter;
  targetController: TargetController;
  admin?: boolean;
}> = (props) => {
  const { campaign, character, admin, situation, targetController } = props;

  const players = useCampaignCharacterList(campaign.id);
  const npcs = useSituationNpcList(campaign.id, situation.id);
  const allCharacters = [
    ...players.filter(
      (char) =>
        char.id !== character.id &&
        campaign.state.charactersCurrentSituation[char.id] === situation.id
    ),
    ...npcs.filter(
      (char) =>
        char.id !== character.id &&
        (situation.state.characters[char.id]?.visible || admin)
    ),
  ]
    .map((character) => ({
      [character.id]: character,
      ...(situation.state.characters[character.id]?.droneDeployed
        ? { [`drone-${character.id}`]: character }
        : {}),
    }))
    .reduce((a, b) => ({ ...a, ...b }), {});

  const [selectedTargets, setSelectedTargets] = useState(
    {} as Record<string, boolean>
  );

  const [isConditionModalOpen, setIsConditionModalOpen] = useState(
    false as boolean
  );

  const characterSituationDetails: SituationCharacter = {
    readyWeapon: null,
    ammo: 0,
    visible: true,
    droneDeployed: false,
    firewall: character.stats.firewall,
    cover: {},
    conditions: [],
    ...situation.state.characters[character.id],
  };
  const readyWeapon = Object.values(techList).filter(
    (tech) => tech.id === characterSituationDetails.readyWeapon
  )[0];

  return (
    <Sidebar
      hide={targetController.targetType !== null}
      onOpen={() => targetController.clear()}
    >
      <Grid container spacing={2} direction="column" justify="space-between">
        <Grid
          item
          container
          direction="row"
          justify="center"
          spacing={2}
          alignItems="center"
        >
          <Grid item direction="column">
            <Text as="h5">Health</Text>
            <HealthBar character={character} />
          </Grid>
          {characterSituationDetails.droneDeployed && (
            <Grid item direction="column">
              <Text as="h5">Drone</Text>
              <Grid>
                <CapacityBar
                  length={10}
                  current={character.stats.currentDroneShield}
                  maximum={character.stats.maxDroneShield}
                />
              </Grid>
            </Grid>
          )}
          <Grid item direction="column">
            <Text as="h5">Weapon</Text>
            <Grid item>
              <Text style={{ height: "30px" }}>
                {readyWeapon ? readyWeapon.displayName : "Fists"}
              </Text>
            </Grid>
          </Grid>
          <Grid item direction="column">
            <Text as="h5">Ammo</Text>
            <Grid item>
              <CapacityBar
                length={5}
                palette="secondary"
                current={
                  readyWeapon?.ammo
                    ? characterSituationDetails.ammo *
                      (readyWeapon.roundsPerSecond || 1)
                    : 1
                }
                maximum={
                  readyWeapon?.ammo
                    ? readyWeapon.ammo * (readyWeapon.roundsPerSecond || 1)
                    : 1
                }
              />
            </Grid>
          </Grid>
          <Grid item direction="column">
            <Text as="h5">Firewall</Text>
            <Grid item>
              <CapacityBar
                length={5}
                palette="secondary"
                current={
                  situation.state.characters[character.id]?.firewall !==
                  undefined
                    ? situation.state.characters[character.id]?.firewall!
                    : character.stats.firewall
                }
                maximum={character.stats.firewall}
              />
            </Grid>
          </Grid>
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
        <Grid item container justify="space-between">
          <Grid item>
            <Text as="h3">Conditions</Text>
          </Grid>
          {admin && (
            <Grid item>
              <Button onClick={() => setIsConditionModalOpen(true)}>
                <Text>Add</Text>
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item>
          {characterSituationDetails.conditions.map((condition) => (
            <>
              <Text style={{ paddingRight: "8px" }}>
                {conditionNames[condition.condition]} (strength:{" "}
                {condition.strength}, Time left:{" "}
                {condition.expires - situation.state.time})
              </Text>
              {admin && (
                <Button
                  style={{ fontSize: "8px", height: "24px" }}
                  FrameComponent={FrameBox}
                  onClick={() =>
                    situation.commitRemoveCondition(character.id, condition)
                  }
                >
                  <Text>x</Text>
                </Button>
              )}
            </>
          ))}
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
        {!admin && (
          <>
            <Grid container item justify="space-between">
              <Grid item>
                <Text as="h3">Situation Log</Text>
              </Grid>
              <Grid item>
                <Clock time={situation.state.time} />
              </Grid>
            </Grid>
            <SituationLog situation={situation} character={character.id} />
            <WideFrameLines
              hideTopLines
              hideShapes
              style={{ marginBottom: "16px" }}
            />
          </>
        )}
        <ActionsControl
          character={character}
          situation={situation}
          campaign={campaign}
          targetController={targetController}
          targets={Object.keys(selectedTargets).filter(
            (key) => selectedTargets[key]
          )}
          showHidden={admin}
        />
        {/* <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
        <Grid item>
          <Text as="h3">Other Characters</Text>
        </Grid>
        <Grid item container spacing={2}>
          <Table
            headers={[
              { id: "name", data: "Name" },
              { id: "cover", data: "Cover" },
            ]}
            dataset={Object.keys(allCharacters).map((characterId) => {
              return {
                id: characterId,
                columns: [
                  {
                    id: "name",
                    data:
                      allCharacters[characterId].displayName +
                      (characterId.startsWith("drone-") ? "'s Drone" : ""),
                  },
                  {
                    id: "cover",
                    data: (
                      <Grid container justify="center">
                        {admin ? (
                          <>
                            <Switch
                              checked={
                                !!situation.state.characters[character.id]
                                  ?.cover?.[characterId]
                              }
                              onChange={(event) => {
                                situation.commitUpdateCover(
                                  character.id,
                                  characterId,
                                  event.target.checked
                                );
                              }}
                            />{" "}
                          </>
                        ) : (
                          <Text>
                            {JSON.stringify(
                              situation.state.characters[character.id]?.cover?.[
                                characterId
                              ] || false
                            )}
                          </Text>
                        )}
                      </Grid>
                    ),
                  },
                ],
              };
            })}
            columnWidths={["70%", "30%"]}
          />
        </Grid> */}
      </Grid>
      <AddConditionModal
        isOpen={isConditionModalOpen}
        handleClose={() => setIsConditionModalOpen(false)}
        situation={situation}
        characterId={character.id}
      />
    </Sidebar>
  );
};

const AddConditionModal: React.FC<{
  isOpen: boolean;
  handleClose: any;
  situation: EditableSituation;
  characterId: string;
}> = (props) => {
  const { isOpen, handleClose, situation, characterId } = props;
  const [selectedCondition, setSelectedCondition] = useState(
    Condition.stunned as Condition
  );
  const [strength, setStrength] = useState(1);
  const [duration, setDuration] = useState(1);
  return (
    <Modal isOpen={isOpen} onClose={handleClose} maxWidth={600}>
      <Grid container xs={12} direction="column">
        <Grid item>
          <Text as="h4">Add Condition</Text>
        </Grid>
        <Grid container item xs={12} spacing={2} direction="column">
          <Grid item>
            <InputLabel shrink>Condition</InputLabel>
            <Select
              id="condition-select"
              value={selectedCondition}
              fullWidth
              onChange={(event) =>
                setSelectedCondition(event.target.value as any)
              }
            >
              {Object.keys(conditionNames).map((condition) => {
                return (
                  <MenuItem key={condition} value={condition}>
                    {conditionNames[condition as unknown as Condition]}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item>
            <TextField
              label="Strength"
              fullWidth
              inputProps={{
                type: "number",
                min: "1",
              }}
              value={strength}
              onChange={(e) => {
                setStrength(parseInt(e.target.value));
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Duration (s)"
              fullWidth
              inputProps={{
                type: "number",
                min: "1",
              }}
              value={duration}
              onChange={(e) => {
                setDuration(parseInt(e.target.value));
              }}
            />
          </Grid>
        </Grid>
        <Grid>
          <DialogActions>
            <Button
              onClick={async () => {
                await situation.commitAddCondition(characterId, {
                  condition: selectedCondition,
                  strength,
                  expires: situation.state.time + duration,
                });
                handleClose();
              }}
            >
              <Text>Add</Text>
            </Button>
            <Button onClick={handleClose}>
              <Text>Cancel</Text>
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Modal>
  );
};
