import React, { useEffect, useState } from "react";
import {
  RouteComponentProps,
  RouteProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

export type AnimatedRouteChildrenProps<T = any> = RouteComponentProps<T> & {
  activate: boolean;
};

export const AnimatedRoute: React.FC<
  Omit<RouteProps, "component" | "render"> & {
    component?: React.ComponentType<AnimatedRouteChildrenProps>;
    render?: (props: AnimatedRouteChildrenProps) => React.ReactElement | null;
  }
> = (props) => {
  const [activate, setActivate] = useState(false);
  const [mount, setMount] = useState(false);
  const [matchState, setMatchState] = useState(null as any);

  const match = useRouteMatch(props.path!);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (match && (match?.isExact || !props.exact)) {
      setMatchState(JSON.stringify(match));
      setTimeout(() => setMount(true), 201);
      setActivate(true);
    } else {
      setActivate(false);
      setTimeout(() => setMount(false), 200);
    }
  }, [match, props.exact]);

  const childProps = {
    activate,
    match: JSON.parse(matchState),
    history,
    location,
  };
  const Component = props.component;
  if (mount) {
    return Component ? (
      <Component {...childProps} />
    ) : (
      props.render!(childProps)
    );
  } else {
    return null;
  }
};
