import { firestore, User } from "firebase";
import { useEffect, useState } from "react";
import {
  useFirestoreDocData,
  useFirestore,
  useUser,
  useFirestoreCollection,
} from "reactfire";
import { CampaignData } from "./utils";

export class EditableCampaign {
  public id: string;
  public state: CampaignData;

  private campaignRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  private campaignData: CampaignData;
  private setCampaignUpdates: React.Dispatch<
    React.SetStateAction<CampaignData>
  >;

  constructor(id: string) {
    this.id = id;
    this.campaignRef = useFirestore().collection("campaigns").doc(id);
    const campaignData = useFirestoreDocData<CampaignData>(this.campaignRef);

    const [campaignUpdates, setCampaignUpdates] = useState(campaignData);
    useEffect(() => setCampaignUpdates(campaignData), [campaignData]);

    this.setCampaignUpdates = setCampaignUpdates;
    this.campaignData = campaignData;

    this.state = campaignUpdates;
  }

  setOwner(owner: string) {
    return this.setCampaignUpdates((prevState) => ({
      ...prevState,
      owner,
    }));
  }
  setDisplayName(displayName: string) {
    return this.setCampaignUpdates((prevState) => ({
      ...prevState,
      displayName,
    }));
  }
  setDescription(description: string) {
    return this.setCampaignUpdates((prevState) => ({
      ...prevState,
      description,
    }));
  }
  setNotes(notes: string) {
    return this.setCampaignUpdates((prevState) => ({
      ...prevState,
      notes,
    }));
  }
  setCurrentSituationForCharacter(
    characterId: string,
    situationId: string | null
  ) {
    return this.setCampaignUpdates((prevState) => ({
      ...prevState,
      charactersCurrentSituation: {
        ...prevState.charactersCurrentSituation,
        [characterId]: situationId,
      },
    }));
  }
  commit() {
    return new Promise((resolve) => {
      this.setCampaignUpdates((prevState) => {
        resolve(this.campaignRef.update(prevState));
        return prevState;
      });
    });
  }
  reset() {
    return this.setCampaignUpdates(this.campaignData);
  }
}

export function useEditableCampaign(id: string): EditableCampaign {
  return new EditableCampaign(id);
}

export function useCreateCampaign() {
  const campaignsRef = useFirestore().collection("campaigns");
  const currentUser = useUser<User>();

  return (campaign?: Partial<CampaignData>) => {
    const newCampaign: CampaignData = {
      owner: currentUser.uid,
      createdAt: firestore.FieldValue.serverTimestamp() as any,
      displayName: "[New Campaign]",
      description: "A new campaign.",
      notes: "",
      maps: {},
      npcs: {},
      situations: {},
      charactersCurrentSituation: {},
      ...campaign,
    };
    return campaignsRef.add(newCampaign);
  };
}

export function useCampaignList() {
  const campaignsRef = useFirestore().collection("campaigns");
  const currentUser = useUser<User>();

  const campaigns = useFirestoreCollection<void>(
    campaignsRef.where("owner", "==", currentUser.uid).orderBy("createdAt")
  ) as firestore.QuerySnapshot<CampaignData>;

  return campaigns;
}

export function useModuleList() {
  const campaignsRef = useFirestore().collection("campaigns");

  const campaigns = useFirestoreCollection<void>(
    campaignsRef.where("published", "==", true).orderBy("createdAt")
  ) as firestore.QuerySnapshot<CampaignData>;

  return campaigns;
}

export function useDeleteCampaign() {
  const campaignsRef = useFirestore().collection("campaigns");

  return (id: string) => {
    return campaignsRef.doc(id).delete();
  };
}
