import { useAnalytics } from "reactfire";
import { useAccount } from "./useAccount";

export function useConfiguredAnalytics() {
  const account = useAccount();

  if (!account.analyticsId) {
    const waitForId = new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
    throw waitForId;
  }

  const analytics = useAnalytics();
  if (process.env.NODE_ENV !== "production") {
    analytics.setAnalyticsCollectionEnabled(false);
  }

  analytics.setUserId(account.analyticsId);

  return analytics;
}
