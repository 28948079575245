import { Grid, Hidden, Switch, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Card, Table, Text } from "@arwes/core";
import { EditableCampaign } from "./useCampaign";
import { ConfirmButton, LoadingBars, WideFrameLines } from "../components";
import {
  useCampaignInvite,
  useCreateCampaignInvite,
  useDeleteInvite,
} from "../invite/useInvite";
import {
  useFirebaseApp,
  useFirestore,
  useFirestoreCollection,
} from "reactfire";
import { firestore } from "firebase";
import { avatars } from "../character/avatars";
import {
  useCampaignCharacterList,
  useCampaignNpcList,
  useCreateCharacter,
  useDeleteCharacter,
} from "../character";
import { Link } from "../navigation";
import { padEnd, truncate } from "lodash";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { CharacterLimit } from "../character/CharacterLimit";
import { useAccount, useSubscription } from "../account";
import { CardText } from "../layout";

interface CampaignOverviewProps {
  editMode: boolean;
  loading: boolean;
  campaign: EditableCampaign;
}

export const CampaignCharacters: React.FC<CampaignOverviewProps> = (props) => {
  const { editMode, loading, campaign } = props;
  const history = useHistory();
  const invite = useCampaignInvite(campaign.id);
  const createCampaignInvite = useCreateCampaignInvite();
  const deleteInvite = useDeleteInvite();

  const [firstRenderTime] = useState(() => Date.now());
  const characters = useCampaignCharacterList(campaign.id);

  const createCharacter = useCreateCharacter();
  const deleteCharacter = useDeleteCharacter();
  const [loadingCharacters, setLoadingCharacters] = useState(
    {} as Record<string, boolean>
  );

  const [leavingCampaign, setLeavingCampaign] = useState(
    {} as Record<string, boolean>
  );
  const leaveCampaign = firebase.functions().httpsCallable("leaveCampaign");

  const subscription = useSubscription();
  const account = useAccount();
  const firebaseApp = useFirebaseApp();

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item container>
          <Grid item container justify="space-between">
            <Grid item>
              <Text as="h4">Players</Text>
            </Grid>
            <Grid item>
              <Text>Enable joining</Text>
              <Switch
                checked={!!invite}
                onChange={(event) => {
                  if (event.target.checked) {
                    createCampaignInvite(campaign.id);
                  } else {
                    invite && deleteInvite(invite?.inviteCode);
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid item style={{ marginLeft: "16px", marginRight: "16px" }}>
            {invite ? (
              <>
                <Text>Invite Link:</Text>{" "}
                <Hidden xsDown>
                  <a href={`https://archiverpg.com/join/${invite.inviteCode}`}>
                    <Text>https://archiverpg.com/join/{invite.inviteCode}</Text>
                  </a>
                </Hidden>
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://archiverpg.com/join/${invite.inviteCode}`
                    );
                  }}
                >
                  <Text>Copy</Text>
                </Button>
              </>
            ) : (
              <>
                <Text>Enable joining to get an invite link.</Text>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={2}>
          {characters.map((character, index) => {
            return (
              <Grid key={character.id} item xs={12} md={6}>
                <Card
                  animator={{
                    duration: {
                      delay: Math.max(
                        100 * index - (Date.now() - firstRenderTime),
                        0
                      ),
                    },
                  }}
                  title={character.displayName}
                  image={{ src: avatars[character.avatar] }}
                  landscape
                  options={
                    leavingCampaign[character.id] ? (
                      <LoadingBars />
                    ) : (
                      <>
                        <Link href={`/characters/${character.id}/overview`}>
                          <Button>
                            <Text>View</Text>
                          </Button>
                        </Link>
                        <Button
                          disabled={loading}
                          onClick={async () => {
                            setLeavingCampaign({
                              ...leavingCampaign,
                              [character.id]: true,
                            });
                            try {
                              await leaveCampaign({
                                characterId: character.id,
                              });
                            } catch (error) {
                              console.log(error);
                            } finally {
                              setLeavingCampaign({
                                ...leavingCampaign,
                                [character.id]: false,
                              });
                            }
                          }}
                        >
                          <Text>Remove</Text>
                        </Button>
                      </>
                    )
                  }
                >
                  <CardText>{character.description}</CardText>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
      </Grid>

      <Grid container direction="row">
        <Grid item container justify="space-between">
          <Grid item>
            <Text as="h4">Non-player Characters</Text>
          </Grid>
          <Grid item>
            <Button
              onClick={async () => {
                if (
                  (account.totalCharacters || 0) >= 10 &&
                  !subscription.hasSubscription()
                ) {
                  history.push("/subscription");
                } else {
                  const newCharacter = await createCharacter({
                    npc: true,
                    campaignId: campaign.id,
                  });
                  history.push(`/characters/${newCharacter.id}`);
                }
              }}
            >
              <Text>Create NPC</Text>
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ paddingBottom: "16px" }}>
          <CharacterLimit />
        </Grid>
        <Table
          headers={[
            { id: "name", data: "Name" },
            { id: "actions", data: "Actions" },
          ]}
          dataset={Object.keys(campaign.state.npcs)
            .sort((a, b) => {
              if (
                campaign.state.npcs[a].displayName <
                campaign.state.npcs[b].displayName
              ) {
                return -1;
              }
              if (
                campaign.state.npcs[a].displayName >
                campaign.state.npcs[b].displayName
              ) {
                return 1;
              }
              return 0;
            })
            .map((npcId) => {
              return {
                id: npcId,
                columns: [
                  {
                    id: "name",
                    data: campaign.state.npcs[npcId].displayName,
                  },
                  {
                    id: "actions",
                    data: (
                      <Grid container justify="center">
                        {loadingCharacters[npcId] ? (
                          <LoadingBars />
                        ) : (
                          <>
                            <Link href={`/characters/${npcId}`}>
                              <Button>
                                <Text>View</Text>
                              </Button>
                            </Link>
                            <Button
                              onClick={async () => {
                                if (
                                  (account.totalCharacters || 0) >= 10 &&
                                  !subscription.hasSubscription()
                                ) {
                                  history.push("/subscription");
                                } else {
                                  const characterData = (
                                    await firebaseApp
                                      .firestore()
                                      .collection("characters")
                                      .doc(npcId)
                                      .get()
                                  ).data();
                                  const newCharacter = await createCharacter({
                                    ...characterData,
                                    situations: {},
                                    npc: true,
                                    campaignId: campaign.id,
                                  });
                                  history.push(
                                    `/characters/${newCharacter.id}`
                                  );
                                }
                              }}
                            >
                              <Text>Duplicate</Text>
                            </Button>
                            <ConfirmButton
                              onClick={() => {
                                setLoadingCharacters({
                                  ...loadingCharacters,
                                  [npcId]: true,
                                });
                                deleteCharacter(npcId);
                              }}
                            >
                              <Text>Delete</Text>
                            </ConfirmButton>
                          </>
                        )}
                      </Grid>
                    ),
                  },
                ],
              };
            })}
          columnWidths={["max(50%, calc(100% - 18em))", "min(50%, 18em)"]}
        />
      </Grid>
    </>
  );
};
