import React, { useEffect } from "react";
import { PageLayout } from "../layout";
import { Grid } from "@material-ui/core";

import { FrameContent } from "../layout/FrameContent";
import { AnimatedRouteChildrenProps } from "../navigation/AnimatedRoute";
import { Button, Card, Text, Figure, FrameHexagon } from "@arwes/core";
import { Link } from "../navigation/Link";
import { StyledFigure } from "../rules/RulesPage";

export const HomePage: React.FC<AnimatedRouteChildrenProps> = (props) => {
  const { activate } = props;

  useEffect(() => {
    document.title = `Archive`;
  }, []);

  return (
    <PageLayout>
      <FrameContent
        animator={{ root: true, activate }}
        style={{ padding: "16px" }}
      >
        <Grid container spacing={2} direction="column" justify="space-between">
          <Grid item container direction="row" spacing={2}>
            <Grid item md={4}>
              <Card
                image={{
                  src: "/images/street-kid.png",
                }}
                landscape
                options={
                  <Link href={`/rules/introduction`}>
                    <Button>
                      <Text>Go</Text>
                    </Button>
                  </Link>
                }
              >
                <Text>Getting started</Text>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card
                image={{
                  src: "/images/create-character.png",
                }}
                landscape
                options={
                  <Link href={`/characters`}>
                    <Button>
                      <Text>Create</Text>
                    </Button>
                  </Link>
                }
              >
                <Text>Create a character</Text>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card
                image={{
                  src: "/images/cyborg-on-roof.png",
                }}
                landscape
                options={
                  <Link href={`/subscription`}>
                    <Button>
                      <Text>Benefits</Text>
                    </Button>
                  </Link>
                }
              >
                <Text>Subscription benefits</Text>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs>
            <Text as="h2">News</Text>
          </Grid>
          <Grid item xs>
            <FrameContent>
              <Grid container item direction="column">
                <StyledFigure src="/images/bus-stop.png"></StyledFigure>
                <Text as="h3">Welcome to the alpha of Archive</Text>
                <Text>
                  Archive is a digital tabletop roleplaying game set in the near
                  future of Earth. Corporate greed and political mismanagement
                  have created a deeply unequal society where the most powerful
                  people have accumulated enough wealth and technology to be
                  effectively immortal while the vast majority struggle just to
                  survive. Humanity's technological progress has turned inward,
                  creating cybernetic augmentation that blurs the line between
                  man and machine. People with ambitions to build a better life
                  or make a difference work as freelancers, taking on dangerous
                  contracts on behalf of powerful entities.
                </Text>
                <br />
                <Text>
                  Archive is a work in progress. Only the early game is fully
                  fleshed out, and you're likely to encounter bugs. But
                  development is ongoing, the experience is complete enough to
                  be fun, and if you'd like to help us out we would love to hear
                  feedback from you.
                </Text>
              </Grid>
            </FrameContent>
          </Grid>
        </Grid>
      </FrameContent>
    </PageLayout>
  );
};
