import { FC, memo } from "react";
import { withAnimator, WithAnimatorOutputProps } from "@arwes/animation";

import { Table as Component, TableProps } from "./Table.component";
import { animator } from "@arwes/core/lib/Table/Table.animator";

const Table: FC<TableProps & WithAnimatorOutputProps> = withAnimator(animator)(
  memo(Component as any)
);

export * from "./Table.component";
export { Table };
