import { AxialCoordinates } from "honeycomb-grid";
import { Condition } from "../character/tech";
import { AttitudeType, Stat } from "../character/utils";
import { Terrain } from "../map/utils";

export enum SkillChallengeObjectiveType {
  singular,
  group,
  individual,
}

export interface SkillChallengeTemplate {
  displayName: string;
  skill: Stat;
  utilizes: {
    movement?: boolean;
    interaction?: boolean;
    attention?: boolean;
  };
  targetAttitude: AttitudeType | null;
  idealAttitude: AttitudeType | null;
  contested: boolean;
  objectiveType: SkillChallengeObjectiveType;
  threshold?: number;
  duration: number;
  maxHelpers?: number;
  helpPercentage?: number;
  visible: boolean;
}

export enum SituationLogType {
  attack,
  defense,
  movement,
}

export interface SituationLog {
  message: string;
  timestamp: number;
  visible: boolean;
  actors: string[];
  targets: string[];
  type?: SituationLogType;
}

export interface ConditionInfo {
  condition: Condition;
  expires: number;
  strength: number;
}

export interface SituationCharacter {
  visible: boolean;
  droneDeployed: boolean;
  readyWeapon: string | null;
  firewall: number;
  ammo: number;
  cover: Record<string, boolean>;
  conditions: ConditionInfo[];
  search?: number;
  stealth?: number;
  lastSightCoordinates?: {
    q: number;
    r: number;
  };
  coordinates?: {
    q: number;
    r: number;
  };
}

export interface SituationDrone {
  visible: boolean;
  melee: Record<string, boolean>;
  cover: Record<string, boolean>;
}

export interface SituationData {
  owner: string;
  campaignId: string;
  time: number;
  createdAt: string;
  displayName: string;
  description: string;
  notes: string;
  skillChallenges: Record<string, SkillChallengeTemplate | undefined>;
  logs: SituationLog[];
  characters: Record<string, SituationCharacter | undefined>;
  visibleArea: Terrain;
  dynamicVision: boolean;
  mapId?: string;
  ping?: Ping;
  shouldUpdateDynamicVision: boolean;
  labels?: Record<string, LabelData>;
}

export interface LabelData {
  displayName: string;
  size: string;
  color: string;
  visible: boolean;
  coordinates: {
    q: number;
    r: number;
  };
}

export interface ActionData {
  id: string;
  actionId: string;
  targets: string[];
  // drones: string[];
  timeStarted: number;
}

export interface ActionsData {
  lastActivations?: Record<string, number>;
  ready?: boolean;
  actions?: ActionData[];
  ping?: Ping;
}

export interface Ping {
  coordinates: {
    q: number;
    r: number;
  };
  createdAt: string;
}
