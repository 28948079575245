import { Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Text } from "@arwes/core";
import { EditableCampaign } from "./useCampaign";
import { WideFrameLines } from "../components";

interface CampaignOverviewProps {
  editMode: boolean;
  loading: boolean;
  campaign: EditableCampaign;
}

export const CampaignOverview: React.FC<CampaignOverviewProps> = (props) => {
  const { editMode, loading, campaign } = props;
  return (
    <>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Text as="h4">Description</Text>
        </Grid>
        <Grid item style={{ marginLeft: "16px", marginRight: "16px" }}>
          {editMode ? (
            <TextField
              label="Campaign Description"
              variant="filled"
              multiline
              rows={5}
              fullWidth
              value={campaign.state.description}
              disabled={loading}
              onChange={(e) => {
                campaign.setDescription(e.target.value);
              }}
            />
          ) : (
            <Text style={{ whiteSpace: "pre-wrap" }}>
              {campaign.state.description}
            </Text>
          )}
        </Grid>
      </Grid>
      <WideFrameLines
        hideTopLines
        hideShapes
        style={{ marginBottom: "16px" }}
      />
      <Grid container direction="column">
        <Grid item>
          <Text as="h4">Notes</Text>
        </Grid>
        <Grid item style={{ marginLeft: "16px", marginRight: "16px" }}>
          {editMode ? (
            <TextField
              label="Campaign Notes"
              variant="filled"
              multiline
              rows={20}
              fullWidth
              value={campaign.state.notes}
              disabled={loading}
              onChange={(e) => {
                campaign.setNotes(e.target.value);
              }}
            />
          ) : (
            <Text style={{ whiteSpace: "pre-wrap" }}>
              {campaign.state.notes}
            </Text>
          )}
        </Grid>
      </Grid>
    </>
  );
};
