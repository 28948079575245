import { DialogActions, Grid, TextField } from "@material-ui/core";
import { Button, Table, Text } from "@arwes/core";
import React, { useState } from "react";
import { EditableCharacter, skillList } from "./useCharacter";
import { WideFrameLines } from "../components";
import { Animator } from "@arwes/animation";
import { startCase } from "lodash";
import { Modal } from "../layout";
import { Action } from "./Action";
import { ActionDetails, Condition, DamageType } from "./tech";
import { Stat } from "./utils";
import { GmCheck } from "./GmCheck";

interface CharacterAbilitiesProps {
  activate: boolean;
  editMode: boolean;
  loading: boolean;
  character: EditableCharacter;
}

export const statDisplayNames: any = {
  maxHealth: "Max Health",
  maxShield: "Max Shield",
  firewall: "Firewall",
  firewallRegen: "Firewall Regen",
  combatMovementSpeed: "Combat Movement Speed (m/s)",
  unarmedDamage: "Unarmed Damage",
  unarmedElectricalDamage: "Unarmed Electrical Damage",
  droneKineticDamage: "Drone Kinetic Damage",
  droneMovementSpeed: "Drone Movement Speed",
  [DamageType.kinetic]: "Kinetic Armour",
  [DamageType.thermal]: "Thermal Armour",
  [DamageType.electrical]: "Electrical Armour",
  [Condition.stunned]: "Stunned Resistance",
  [Condition.confused]: "Confused Resistance",
  [Condition.crippled]: "Crippled Resistance",
  [Condition.clumsy]: "Clumsy Resistance",
  [Condition.burning]: "Burn Resistance",
  size: "Size",
};

export const CharacterAbilities: React.FC<CharacterAbilitiesProps> = (
  props
) => {
  const { character, activate, editMode, loading } = props;

  const [actionDetails, setActionDetails] = useState(
    undefined as ActionDetails | undefined
  );

  const [isStatModifiersModalOpen, setIsStatModifiersModalOpen] =
    useState(false);

  return (
    <Animator
      animator={{
        root: true,
        activate,
      }}
    >
      <Grid container>
        <Grid item>
          <Text as="h4">Stats</Text>
        </Grid>

        <Grid container item justify="space-evenly">
          {Object.keys(statDisplayNames).map((stat) => {
            return (
              <Grid
                container
                key={stat}
                item
                xs={6}
                sm={4}
                md={3}
                alignItems="center"
                justify="center"
              >
                <Grid item container direction="column" alignItems="center">
                  <Grid item>
                    <strong>
                      <Text
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {statDisplayNames[stat]}
                      </Text>
                    </strong>
                  </Grid>

                  <Grid item>
                    <Text>{character.state.stats[stat as Stat]}</Text>
                  </Grid>
                </Grid>
                {editMode && character.state.campaignId && (
                  <GmCheck campaignId={character.state.campaignId}>
                    <Grid item>
                      <TextField
                        style={{ maxWidth: "3em" }}
                        inputProps={{
                          // type: "number",
                          style: { textAlign: "center" },
                        }}
                        label="Modifier"
                        defaultValue={
                          character.state.statModifiers?.[stat as Stat] || 0
                        }
                        disabled={loading}
                        onChange={(e) => {
                          const value = [
                            "combatMovementSpeed",
                            "size",
                          ].includes(stat)
                            ? parseFloat(e.target.value)
                            : parseInt(e.target.value);
                          if (!isNaN(value)) {
                            character.setStatModifiers({
                              ...character.state.statModifiers,
                              [stat as Stat]: value,
                            });
                          }
                        }}
                      />
                    </Grid>
                  </GmCheck>
                )}
              </Grid>
            );
          })}
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Text as="h4">Skills</Text>
        </Grid>

        <Grid container item justify="space-evenly">
          {skillList.map((skill) => {
            return (
              <Grid
                container
                key={skill}
                item
                xs={6}
                sm={4}
                md={3}
                alignItems="center"
                justify="center"
              >
                <Grid item container direction="column" alignItems="center">
                  <Grid item>
                    <strong>
                      <Text
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {startCase(skill)}
                      </Text>
                    </strong>
                  </Grid>
                  <Grid item>
                    <Text>{character.state.stats[skill as Stat]}</Text>
                  </Grid>
                </Grid>
                {editMode && character.state.campaignId && (
                  <GmCheck campaignId={character.state.campaignId}>
                    <Grid item>
                      <TextField
                        style={{ maxWidth: "3em" }}
                        inputProps={{
                          // type: "number",
                          style: { textAlign: "center" },
                        }}
                        label="Modifier"
                        defaultValue={
                          character.state.statModifiers?.[skill as Stat] || 0
                        }
                        disabled={loading}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          if (!isNaN(value)) {
                            character.setStatModifiers({
                              ...character.state.statModifiers,
                              [skill as Stat]: value,
                            });
                          }
                        }}
                      />
                    </Grid>
                  </GmCheck>
                )}
              </Grid>
            );
          })}
        </Grid>
        <WideFrameLines
          hideTopLines
          hideShapes
          style={{ marginBottom: "16px" }}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Text as="h4">Actions</Text>
        </Grid>
        <Table
          headers={[
            { id: "name", data: "Name" },
            { id: "source", data: "Source Tech" },
          ]}
          dataset={character.state.actions
            .sort((a, b) => {
              if (a.displayName < b.displayName) {
                return -1;
              }
              if (a.displayName > b.displayName) {
                return 1;
              }
              return 0;
            })
            .map((action) => {
              return {
                id: action.id,
                columns: [
                  {
                    id: "name",
                    data: (
                      <div
                        onClick={() => {
                          setActionDetails(action);
                        }}
                      >
                        {action.displayName + " 🔍"}
                      </div>
                    ),
                  },
                  {
                    id: "source",
                    data:
                      Object.values(character.state.tech)
                        .filter((tech) => tech.actions?.includes(action))
                        .map((tech) => tech.displayName)
                        .join(", ") || "Default",
                  },
                ],
              };
            })}
          columnWidths={["50%", "50%"]}
        />
      </Grid>
      <ActionModal
        action={actionDetails}
        onClose={() => {
          setActionDetails(undefined);
        }}
      />
      <StatModifiersModal
        character={character}
        isOpen={isStatModifiersModalOpen}
        onClose={() => {
          setIsStatModifiersModalOpen(false);
        }}
      />
    </Animator>
  );
};

export const ActionModal: React.FC<{
  action?: ActionDetails;
  onClose: () => void;
}> = (props) => {
  const { action, onClose } = props;
  return (
    <Modal isOpen={!!action} onClose={onClose} maxWidth={800}>
      <Action
        action={action!}
        actions={
          <Button onClick={onClose}>
            <Text>Close</Text>
          </Button>
        }
      />
      <DialogActions>
        <Button onClick={onClose}>
          <Text>Close</Text>
        </Button>
      </DialogActions>
    </Modal>
  );
};

export const StatModifiersModal: React.FC<{
  character: EditableCharacter;
  isOpen: boolean;
  onClose: () => void;
}> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose} maxWidth={800}>
      <DialogActions>
        <Button onClick={onClose}>
          <Text>Close</Text>
        </Button>
      </DialogActions>
    </Modal>
  );
};
