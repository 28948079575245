import { Card, Text } from "@arwes/core";
import { Grid } from "@material-ui/core";
import { analytics } from "firebase";
import React from "react";
import { FrameContent, PageLayout } from "./layout";

export class ErrorBoundaryPage extends React.Component<
  { errorType: string },
  {
    hasError: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    analytics().logEvent("error", { type: this.props.errorType });
  }

  render() {
    if (this.state.hasError) {
      return (
        <PageLayout>
          <FrameContent>
            <Grid
              item
              container
              spacing={3}
              direction="column"
              alignItems="center"
            >
              <Grid style={{ width: "100%" }} item>
                <Card
                  style={{ width: "100%" }}
                  title="Something went wrong"
                  image={{
                    src: "/images/explosion-flare.jpg",
                  }}
                >
                  Wait a minute and try refreshing the page.
                </Card>
              </Grid>
            </Grid>
          </FrameContent>
        </PageLayout>
      );
    }

    return this.props.children;
  }
}
