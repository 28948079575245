import React, { useEffect, useState } from "react";
import { PageLayout } from "../layout";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { useEditableCampaign } from "./useCampaign";
import styled from "styled-components";
import { Grid, Hidden, TextField } from "@material-ui/core";
import { Animator } from "@arwes/animation";
import { Button, Text } from "@arwes/core";

import { FrameContent } from "../layout/FrameContent";
import { upperFirst } from "lodash";
import { SuspenseWithLoadingBars } from "../components";
import { AnimatedRoute } from "../navigation";
import { AnimatedRouteChildrenProps } from "../navigation/AnimatedRoute";
import { CampaignOverview } from "./CampaignOverview";
import { CampaignSituations } from "./CampaignSituations";
import { CampaignCharacters } from "./CampaignCharacters";

const TabButton = styled(Button)`
  width: 33%;
`;

export const CampaignPage: React.FC<
  AnimatedRouteChildrenProps<{ id: string }>
> = (props) => {
  const { activate } = props;
  const id = props.match.params.id;
  const campaign = useEditableCampaign(id);

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const tabs = ["overview", "characters", "situations"];

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    document.title = `${campaign.state.displayName} - Campaign`;
  }, [campaign.state.displayName]);

  return (
    <PageLayout>
      <FrameContent
        animator={{ root: true, activate }}
        style={{ padding: "16px" }}
      >
        <Grid container spacing={2} direction="column" justify="space-between">
          <Grid container spacing={2} item>
            <Hidden xsDown>
              <Grid item sm={2} md={2}></Grid>
            </Hidden>

            <Grid item xs container direction="column" spacing={1}>
              <Grid item>
                {editMode ? (
                  <TextField
                    style={{ maxWidth: "400px" }}
                    label="Campaign Name"
                    variant="filled"
                    value={campaign.state.displayName}
                    fullWidth
                    disabled={loading}
                    onChange={(e) => {
                      campaign.setDisplayName(e.target.value);
                    }}
                  />
                ) : (
                  <Text as="h3">{campaign.state.displayName}</Text>
                )}
              </Grid>
            </Grid>
            <Grid item>
              {location.pathname.split("/").pop() === "overview" &&
                (editMode ? (
                  <div key="edit">
                    <Button
                      disabled={loading}
                      onClick={async () => {
                        campaign.reset();
                        setEditMode(false);
                      }}
                    >
                      <Text>Cancel</Text>
                    </Button>
                    <Button
                      disabled={loading}
                      onClick={async () => {
                        setLoading(true);
                        await campaign.commit();
                        setEditMode(false);
                        setLoading(false);
                      }}
                    >
                      <Text>Save</Text>
                    </Button>
                  </div>
                ) : (
                  <div key="non-edit">
                    <Button onClick={() => setEditMode(true)}>
                      <Text>Edit</Text>
                    </Button>
                  </div>
                ))}
            </Grid>
          </Grid>
          <Grid>
            {tabs.map((tab) => {
              return (
                <TabButton
                  palette={
                    location.pathname.split("/").pop() === tab
                      ? "primary"
                      : "tabs"
                  }
                  key={tab}
                  onClick={() => history.push(`/campaigns/${id}/${tab}`)}
                  active={true}
                >
                  <Text>{upperFirst(tab)}</Text>
                </TabButton>
              );
            })}
          </Grid>

          <Grid item>
            <AnimatedRoute
              exact
              path={`/campaigns/${id}`}
              render={() => <Redirect to={`/campaigns/${id}/overview`} />}
            />
            <AnimatedRoute
              path={`/campaigns/${id}/overview`}
              exact
              render={({ activate }) => (
                <Animator
                  animator={{
                    root: true,
                    activate,
                  }}
                >
                  <CampaignOverview
                    editMode={editMode}
                    loading={loading}
                    campaign={campaign}
                  />
                </Animator>
              )}
            />
            <AnimatedRoute
              path={`/campaigns/${id}/characters`}
              exact
              render={({ activate }) => (
                <SuspenseWithLoadingBars traceId="campaign-characters-load">
                  <Animator
                    animator={{
                      root: true,
                      activate,
                    }}
                  >
                    <CampaignCharacters
                      editMode={editMode}
                      loading={loading}
                      campaign={campaign}
                    />
                  </Animator>
                </SuspenseWithLoadingBars>
              )}
            />
            <AnimatedRoute
              path={`/campaigns/${id}/situations`}
              exact
              render={({ activate }) => (
                <SuspenseWithLoadingBars traceId="campaign-situations-load">
                  <Animator
                    animator={{
                      root: true,
                      activate,
                    }}
                  >
                    <CampaignSituations
                      editMode={editMode}
                      loading={loading}
                      campaign={campaign}
                    />
                  </Animator>
                </SuspenseWithLoadingBars>
              )}
            />
          </Grid>
        </Grid>
      </FrameContent>
    </PageLayout>
  );
};
