import { Button, Text } from "@arwes/core";
import { SwipeableDrawer, Grid, Hidden } from "@material-ui/core";
import React, { useState } from "react";
import { FrameContent } from ".";

export const Sidebar: React.FC<{ hide?: boolean; onOpen?: any }> = ({
  children,
  hide,
  onOpen,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      <Hidden smUp>
        <SwipeableDrawer
          anchor="left"
          open={isOpen && !hide}
          onClose={() => setIsOpen(false)}
          onOpen={() => {
            setIsOpen(true);
            onOpen && onOpen();
          }}
          swipeAreaWidth={25}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Button
            style={{ maxWidth: "99%", height: "5vh" }}
            onClick={() => setIsOpen(false)}
          >
            {"<< Close Controls"}
          </Button>
          <Grid
            container
            xs={12}
            sm={5}
            md={4}
            xl={3}
            style={{
              minWidth: "350px",
              // maxWidth: "97%",
              padding: "8px",
            }}
            direction="row"
            alignContent="center"
          >
            <FrameContent style={{ padding: "0px", maxHeight: "87vh" }}>
              <div
                style={{
                  height: "85vh",
                  overflowY: "auto",
                  padding: "16px",
                }}
              >
                {children}
              </div>
            </FrameContent>
          </Grid>
        </SwipeableDrawer>
        <Button
          style={{ width: "100px" }}
          onClick={() => {
            setIsOpen(true);
            onOpen && onOpen();
          }}
        >
          {">>"}
        </Button>
      </Hidden>
      <Hidden xsDown>
        <Grid
          container
          // item
          xs={12}
          sm={5}
          md={4}
          xl={3}
          style={{
            minWidth: "350px",
            padding: "8px",
          }}
          direction="row"
        >
          <FrameContent style={{ padding: "0px" }}>
            <div
              style={{
                height: "calc(97vh - 9em)",
                overflowY: "auto",
                padding: "16px",
              }}
            >
              {children}
            </div>
          </FrameContent>
        </Grid>
      </Hidden>
    </>
  );
};
