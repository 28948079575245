import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import {
  PixiComponent,
  useApp,
  Stage,
  Sprite,
  Graphics,
  Text as PixiText,
  Container,
  useTick,
} from "@inlet/react-pixi";
import { Viewport as PixiViewport } from "pixi-viewport";

import * as PIXI from "pixi.js";

import {
  createHexPrototype,
  Grid,
  rectangle,
  hexToPoint,
  pointToCube,
  line,
  HexCoordinates,
  Hex,
  AxialCoordinates,
  spiral,
  createHex,
} from "honeycomb-grid";
import { Graphics as PixiGraphics, State, TextStyle } from "pixi.js";
import { EditableCampaign } from "../campaign";
import {
  EditableSituation,
  useSetCharacterActionsInSituation,
  useSituationActions,
  useUpdateCharacterActionsInSituation,
  useUpdateSituation,
} from "./useSituation";
import { FullCharacter } from "../character/utils";
import { useCampaignCharacterList, useSituationNpcList } from "../character";
import { avatars } from "../character/avatars";
import { number } from "prop-types";
import { useTheme } from "@emotion/react";
import { ArwesTheme, LoadingBars } from "@arwes/core";
import {
  actionById,
  actionsList,
  TargetType,
  techById,
} from "../character/tech";
import {
  TargetController,
  TerrainInterference,
} from "../player/useTargetController";
import { position } from "polished";
import { ActionsData } from "./utils";
import {
  addHex,
  centerHex,
  centerPoint,
  CoverLevel,
  getArea,
  getCoverLevel,
  getExplosion,
  getLineOfSight,
  grid,
  gridRectangle,
  hexPrototype,
  hexToAxial,
  pathCoordinatesToHexes,
  subtractHex,
} from "../hexgrid";
import { firestore } from "firebase";
import { EditableMap } from "../map";
import { FirebaseAppProvider } from "reactfire/firebaseApp";
import { firebaseConfig } from "../App";
import { SuspenseWithLoadingBars } from "../components";
import { SuspenseWithPerf } from "reactfire/performance";
import { useEditableMap, useMap } from "../map/useMap";
import { FogOfWarStatus, MapData, Terrain } from "../map/utils";
import { Menu, MenuItem } from "@material-ui/core";

// const HexFactory = extendHex({ size: 20 });
// const HexGrid = defineGrid(HexFactory);

export interface ViewportProps {
  children?: React.ReactNode;
  onMove: (position: { x: number; y: number }) => void;
  onScale: (scale: number) => void;
  onClick: (e: any) => void;
}

const ViewportContext = React.createContext({
  scale: 1,
  setDraggable: (enabled: boolean) => {},
  toWorld: (x: number, y: number) => ({ x, y }),
  viewport: undefined as PixiViewport | undefined,
});

const Viewport = (props: ViewportProps) => {
  const app = useApp();
  const [draggable, setDraggable] = useState(true);
  const [scale, setScale] = useState(1);
  const [toWorld, setToWorld] = useState<PixiViewport["toWorld"]>(() => ({
    x: 0,
    y: 0,
  }));
  const [viewport, setViewport] = useState<PixiViewport>();
  return (
    <ViewportContext.Provider
      value={{
        setDraggable: (enabled: boolean) => setDraggable(enabled),
        scale,
        toWorld,
        viewport,
      }}
    >
      <PixiComponentViewport
        app={app}
        width={app.screen.width}
        height={app.screen.height}
        draggable={draggable}
        setScaleContext={setScale}
        setToWorldContext={setToWorld}
        setViewportContext={setViewport}
        {...props}
      />
    </ViewportContext.Provider>
  );
};

export interface PixiComponentViewportProps extends ViewportProps {
  draggable: boolean;
  setScaleContext: (scale: number) => void;
  setToWorldContext: (toWorld: PixiViewport["toWorld"]) => void;
  setViewportContext: (viewport: PixiViewport) => void;
  onMove: (position: { x: number; y: number }) => void;
  onScale: (scale: number) => void;
  onClick: (e: any) => void;
  width: number;
  height: number;
  app: any;
}

const PixiComponentViewport = PixiComponent("Viewport", {
  create: (props: PixiComponentViewportProps) => {
    const viewport = new PixiViewport({
      screenWidth: props.width,
      screenHeight: props.width,
      // worldWidth: 500 * 50,
      // worldHeight: 500 * 50,
      ticker: props.app.ticker,
      interaction: props.app.renderer.plugins.interaction,
      passiveWheel: false,
    });

    viewport
      .drag()
      .decelerate({ friction: 0.95, bounce: 0.8, minSpeed: 0.2 })
      .clamp({
        left: -6000,
        top: -4000,
        right: 5000,
        bottom: 4000,
      })
      .pinch()
      .wheel()
      .clampZoom({
        minScale: 0.4,
        maxScale: 2,
      });

    // Trigger resize when app resizes
    props.app.renderer.on("resize", () => {
      viewport.resize(props.app.screen.width, props.app.screen.height);
    });

    const { x, y } = viewport.position;
    props.onScale(viewport.scale.x);
    props.onMove({ x, y });
    props.setScaleContext(viewport.scale.x);

    // On scale change update context
    viewport.on("zoomed", () => {
      props.setScaleContext(viewport.scale.x);
      props.onScale(viewport.scale.x);
    });

    viewport.on("moved", () => {
      const { x, y } = viewport.position;
      props.onMove({ x, y });
    });

    viewport.on("clicked", props.onClick);

    props.setToWorldContext(((x: number, y: number) =>
      viewport.toWorld(x, y)) as any);
    props.setViewportContext(viewport);

    return viewport;
  },
  applyProps: (
    instance: PixiViewport,
    prevProps: PixiComponentViewportProps,
    props: PixiComponentViewportProps
  ) => {
    instance.off("clicked", prevProps.onClick);
    instance.on("clicked", props.onClick);
    if (prevProps.draggable !== props.draggable) {
      instance.drag({
        pressDrag: props.draggable,
      });
    }
  },
});

export const SituationMap: React.FC<{
  campaign: EditableCampaign;
  situation?: EditableSituation;
  map?: EditableMap;
  character?: FullCharacter;
  targetMode?: TargetType;
  targetController?: TargetController;
  admin?: boolean;
  onClickCharacter?: (character: FullCharacter) => void;
  onClickLabel?: (label: string) => void;
  onCreateLabel?: (coordinates: AxialCoordinates) => void;
  onAddNpc?: (coordinates: AxialCoordinates) => void;
}> = (props) => {
  const {
    campaign,
    character,
    admin,
    situation,
    targetController,
    map,
    onCreateLabel,
    onAddNpc,
  } = props;
  const theme = useTheme() as ArwesTheme;

  const updateCharacterActionsInSituation =
    useUpdateCharacterActionsInSituation();
  const updateSituation = useUpdateSituation();

  const [backgroundPosition, setBackgroundPosition] = useState({ x: 0, y: 0 });
  const [backgroundScale, setBackgroundScale] = useState(1);

  const [timeLastClick, setTimeLastClick] = useState(0);
  const [lastClickPosition, setLastClickPosition] = useState({ q: 0, r: 0 });
  const [timeLastPing, setTimeLastPing] = useState(0);

  const viewportClick = (e: any) => {
    const mouseHex = grid.pointToHex({
      x: e.world.x,
      y: e.world.y,
    });

    const now = Date.now();
    if (
      now - timeLastClick < 400 &&
      now - timeLastPing > 2000 &&
      lastClickPosition.q === mouseHex.q &&
      lastClickPosition.r === mouseHex.r
    ) {
      setTimeLastPing(now);
      if (character && situation) {
        updateCharacterActionsInSituation(
          character.id,
          {
            ping: {
              coordinates: {
                ...hexToAxial(mouseHex),
              },
              createdAt: firestore.FieldValue.serverTimestamp() as any,
            },
          },
          situation.id
        );
        setTimeout(
          () =>
            updateCharacterActionsInSituation(
              character.id,
              {
                ping: firestore.FieldValue.delete() as any,
              },
              situation.id
            ),
          2000
        );
      } else if (situation) {
        updateSituation(situation.id, {
          ping: {
            coordinates: {
              ...hexToAxial(mouseHex),
            },
            createdAt: firestore.FieldValue.serverTimestamp() as any,
          },
        });
        setTimeout(
          () =>
            updateSituation(situation.id, {
              ping: firestore.FieldValue.delete() as any,
            }),
          2000
        );
      }
    }
    setTimeLastClick(now);
    setLastClickPosition({ q: mouseHex.q, r: mouseHex.r });
    if (
      targetController?.targetType &&
      [TargetType.path, TargetType.point].includes(targetController?.targetType)
    ) {
      targetController?.addTarget(hexToAxial(mouseHex));
    } else if (admin) {
      map?.toggleDoor(mouseHex);
      situation?.commitShouldUpdateDynamicVision(true);
    }
  };

  const [menuAnchorPoint, setMenuAnchorPoint] = useState(
    undefined as { x: number; y: number } | undefined
  );

  return (
    <div
      style={{
        userSelect: "none",
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        if (admin) {
          setMenuAnchorPoint({ x: e.pageX, y: e.pageY });
        }
      }}
    >
      {menuAnchorPoint && (
        <>
          <Menu
            id="context-menu"
            open={Boolean(menuAnchorPoint)}
            anchorReference="anchorPosition"
            anchorPosition={{ top: menuAnchorPoint.y, left: menuAnchorPoint.x }}
            onClose={() => setMenuAnchorPoint(undefined)}
          >
            <MenuItem
              onClick={() => {
                setMenuAnchorPoint(undefined);
                onAddNpc?.(hexToAxial(lastClickPosition));
              }}
            >
              Add NPC
            </MenuItem>
            <MenuItem
              onClick={() => {
                setMenuAnchorPoint(undefined);
                onCreateLabel?.(hexToAxial(lastClickPosition));
              }}
            >
              Create Label
            </MenuItem>
          </Menu>
        </>
      )}
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          background: "black",
        }}
      />
      {map ? (
        <MapBackgroundLayer
          map={map}
          backgroundPosition={backgroundPosition}
          backgroundScale={backgroundScale}
        />
      ) : null}
      <Stage
        style={{
          display: "block",
          position: "fixed",
          top: 0,
          left: 0,
          //zIndex: -1,
        }}
        width={window.innerWidth}
        height={window.innerHeight}
        options={{
          backgroundColor: 0x000000,
          resizeTo: window,
          backgroundAlpha: 0,
        }}
      >
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
          <Viewport
            onMove={setBackgroundPosition}
            onScale={setBackgroundScale}
            onClick={viewportClick}
          >
            {situation && situation.state.mapId ? (
              <SuspenseWithPerf traceId="map-layer" fallback={<></>}>
                <MapLayerLoader
                  id={situation.state.mapId}
                  theme={theme}
                  targetController={props.targetController}
                />
              </SuspenseWithPerf>
            ) : (
              <MapLayer
                map={map?.state}
                theme={theme}
                targetController={props.targetController}
              />
            )}
            {situation && (
              <SuspenseWithPerf traceId="map-character-layer" fallback={<></>}>
                <CharacterLayer
                  campaign={campaign}
                  situation={situation}
                  character={character}
                  targetMode={props.targetMode}
                  admin={admin}
                  theme={theme}
                  onMove={setBackgroundPosition}
                  viewportOffset={backgroundPosition}
                  onScale={setBackgroundScale}
                  onClickCharacter={props.onClickCharacter}
                  onClickLabel={props.onClickLabel}
                  targetController={props.targetController}
                />
              </SuspenseWithPerf>
            )}
            {situation && situation.state.dynamicVision && (
              <FogOfWarLayer
                visibleArea={situation.state.visibleArea}
                seenArea={map?.state.seenArea}
                admin={!!admin}
                revealed={
                  !map?.state.fogOfWar ||
                  map?.state.fogOfWar === FogOfWarStatus.disabled
                }
              />
            )}
          </Viewport>
        </FirebaseAppProvider>
      </Stage>
    </div>
  );
};

// const FogOfWarLayerWithMap: React.FC<{
//   visibleArea: Terrain;
//   admin: boolean;
//   mapId: string;
// }> = (props) => {
//   const map = useEditableMap(props.mapId);
//   return (
//     <FogOfWarLayer
//       visibleArea={props.visibleArea}
//       seenArea={map.state.seenArea}
//       admin={props.admin}
//       revealed={map.state.revealed}
//     />
//   );
// };

const FogOfWarLayer: React.FC<{
  visibleArea: Terrain;
  admin: boolean;
  seenArea?: Terrain;
  revealed?: boolean;
}> = ({ visibleArea, seenArea, admin, revealed }) => {
  const drawGrid = useCallback(
    (graphics: PixiGraphics) => {
      // graphics.lineStyle(1.5, 0x999999, 0.15);
      graphics.clear();

      gridRectangle
        .each((hex) => {
          //const point = hex.toPoint();
          // add the hex's position to each of its corner points
          if (visibleArea[hex.q]?.[hex.r] !== 1) {
            const corners = hex.corners; //.map((corner) => corner.add(point));
            if (admin || revealed || seenArea?.[hex.q]?.[hex.r] === 1) {
              graphics.beginFill(0x000000, 0.4);
            } else {
              graphics.beginFill(0x000000, 1.0);
            }
            graphics.drawPolygon(
              ...corners.map((corner) => new PIXI.Point(corner.x, corner.y))
            );
          }
        })
        .run();
    },
    [JSON.stringify(visibleArea), JSON.stringify(seenArea), revealed]
  );

  return <Graphics draw={drawGrid} />;
};

interface MapBackgroundLayerProps {
  map: EditableMap;
  backgroundPosition: { x: number; y: number };
  backgroundScale: number;
}

// const MapBackgroundLayerLoader: React.FC<
//   Omit<MapBackgroundLayerProps, "map"> & { id: string }
// > = (props) => {
//   const map = useEditableMap(props.id);
//   return <MapBackgroundLayer {...props} map={map} />;
// };

const MapBackgroundLayer: React.FC<MapBackgroundLayerProps> = ({
  map,
  backgroundPosition,
  backgroundScale,
}) => {
  const backgroundStyle = {
    position: "fixed" as "fixed",
    top: backgroundPosition.y + centerPoint.y * backgroundScale,
    left: backgroundPosition.x + centerPoint.x * backgroundScale,
    maxWidth: "10000%",
    transformOrigin: "center",
    transform: `translate(-50%, -50%) scale(${
      (map.state.backgroundImage?.scale || 1) * backgroundScale
    }, ${(map.state.backgroundImage?.scale || 1) * backgroundScale})`,
  };

  const backgroundImage = map.state.backgroundImage;

  return (
    <>
      {backgroundImage &&
        (backgroundImage.isVideo ? (
          <video
            preload="auto"
            loop
            autoPlay
            muted
            // On pause start playing the video again
            // onEnded={(event) => {
            //   (event.target as any).play();
            //   console.log("video looped");
            // }}
            src={backgroundImage.src}
            style={backgroundStyle}
          />
        ) : (
          <img src={backgroundImage.src} style={backgroundStyle} />
        ))}
    </>
  );
};

interface MapLayerProps {
  map?: MapData;
  theme: ArwesTheme;
  targetController?: TargetController;
}

const MapLayerLoader: React.FC<Omit<MapLayerProps, "map"> & { id: string }> = (
  props
) => {
  const map = useMap(props.id);
  return <MapLayer {...props} map={map} />;
};

const MapLayer: React.FC<MapLayerProps> = (props) => {
  const { map, theme, targetController } = props;

  const drawGrid = useCallback((graphics: PixiGraphics) => {
    graphics.lineStyle(1.5, 0x999999, 0.15);
    gridRectangle
      .each((hex) => {
        //const point = hex.toPoint();
        // add the hex's position to each of its corner points
        const corners = hex.corners; //.map((corner) => corner.add(point));

        graphics.drawPolygon(
          ...corners.map((corner) => new PIXI.Point(corner.x, corner.y))
        );
      })
      .run();
  }, []);

  const drawTerrain = useCallback(
    (graphics: PixiGraphics) => {
      const terrainColours: Record<number, number> = {
        // Open Door
        5: PIXI.utils.string2hex(theme.palette.success.main),
        // Pit
        10: PIXI.utils.string2hex(theme.palette.neutral.main),
        // Low wall
        15: PIXI.utils.string2hex(theme.palette.secondary.light1),
        // High wall
        20: PIXI.utils.string2hex(theme.palette.primary.main),
        // Closed Door
        25: PIXI.utils.string2hex(theme.palette.error.main),
      };
      graphics.clear();
      gridRectangle
        .each((hex) => {
          const terrainType = map?.terrain[hex.q]?.[hex.r];

          if (terrainType) {
            graphics.lineStyle(3, terrainColours[terrainType], 0.8);

            //const point = hex.toPoint();
            // add the hex's position to each of its corner points
            const corners = hex.corners; //.map((corner) => corner.add(point));

            graphics.drawPolygon(
              ...corners.map((corner) => new PIXI.Point(corner.x, corner.y))
            );
          }
        })
        .run();
    },
    [JSON.stringify(map?.terrain)]
  );

  const viewportContext = useContext(ViewportContext);

  const [mouseHex, setMouseHex] = useState({ q: 0, r: 0 });

  // const viewportClick = (e: any) => {
  //   if (targetController?.targetType === TargetType.path) {
  //     const mouseHex = HexGrid.pointToHex({
  //       x: e.world.x,
  //       y: e.world.y,
  //     }).subtract(centerHex);
  //     targetController?.addTarget(mouseHex.coordinates());
  //   }
  // };

  return (
    <Container
      interactive
      pointermove={(e) => {
        const worldPoint = viewportContext.viewport?.toWorld({
          x: e.data.global.x,
          y: e.data.global.y,
        });
        if (worldPoint) {
          const hex = grid.pointToHex(worldPoint);
          setMouseHex(hex);
        }
      }}
    >
      {targetController?.targetType &&
      targetController.maxTargets > targetController.targets.length ? (
        <>
          <HexagonArea
            color={theme.palette.success.main}
            target={mouseHex}
            radius={0}
            alpha={0.5}
          />
          {targetController?.targetType === TargetType.path ? (
            <HexagonPath
              color={theme.palette.success.main}
              terrain={
                targetController.terrainInterference !==
                TerrainInterference.ignored
                  ? map?.terrain
                  : undefined
              }
              blockingTerrain={10}
              path={[
                ...(targetController?.source
                  ? [grid.getHex(targetController?.source)]
                  : []),
                ...targetController.targets.map((target) => target as any),
                mouseHex,
              ]}
            />
          ) : null}
          {targetController?.targetType === TargetType.point
            ? [...targetController.targets, mouseHex]
                .filter(
                  (targetPoint) =>
                    grid.distance(targetController.source!, targetPoint) / 2 <=
                    targetController.range
                )
                .map((targetPoint) => {
                  return (
                    <>
                      <HexagonLineOfSight
                        color={theme.palette.error.main}
                        animate
                        start={targetController.source!}
                        end={targetPoint}
                        terrain={map?.terrain}
                        pathBlocking={
                          targetController.terrainInterference ===
                          TerrainInterference.ignored
                            ? undefined
                            : 20
                        }
                        canHitExposed={
                          targetController.terrainInterference ===
                          TerrainInterference.canHitExposed
                        }
                      />
                      {targetController.radius > 0 ? (
                        <HexagonArea
                          color={theme.palette.error.main}
                          radius={targetController.radius}
                          source={targetController.source || undefined}
                          target={targetPoint}
                          alpha={0.5}
                          terrain={map?.terrain}
                          pathBlocking={20}
                          areaBlocking={15}
                        />
                      ) : null}
                    </>
                  );
                })
            : null}
          {targetController?.targetType === TargetType.character &&
          grid.distance(targetController.source!, mouseHex) / 2 <=
            targetController.range ? (
            <>
              <HexagonLineOfSight
                color={theme.palette.error.main}
                animate
                start={targetController.source!}
                end={mouseHex}
                terrain={map?.terrain}
                pathBlocking={
                  targetController.terrainInterference ===
                  TerrainInterference.ignored
                    ? undefined
                    : 20
                }
                canHitExposed={
                  targetController.terrainInterference ===
                  TerrainInterference.canHitExposed
                }
              />
              {targetController.radius > 0 ? (
                <HexagonArea
                  color={theme.palette.error.main}
                  radius={targetController.radius}
                  source={targetController.source || undefined}
                  target={mouseHex}
                  alpha={0.5}
                  terrain={map?.terrain}
                  pathBlocking={20}
                  areaBlocking={15}
                />
              ) : null}
            </>
          ) : null}
        </>
      ) : null}

      <Graphics draw={drawGrid} />
      <Graphics draw={drawTerrain} />
    </Container>
  );
};

const CharacterLayer: React.FC<{
  campaign: EditableCampaign;
  situation: EditableSituation;
  character?: FullCharacter;
  targetMode?: TargetType;
  theme: ArwesTheme;
  onMove: (position: { x: number; y: number }) => void;
  viewportOffset: { x: number; y: number };
  onScale: (scale: number) => void;
  admin?: boolean;
  onClickCharacter?: (character: FullCharacter) => void;
  onClickLabel?: (label: string) => void;
  targetController?: TargetController;
}> = (props) => {
  const {
    campaign,
    character,
    admin,
    situation,
    theme,
    targetMode,
    onMove,
    viewportOffset,
    onScale,
    targetController,
    onClickLabel,
  } = props;

  const players = useCampaignCharacterList(campaign.id);
  const npcs = useSituationNpcList(campaign.id, situation.id);

  const allCharacters = [...players, ...npcs]
    .map((character) => ({
      [character.id]: character,
      ...(situation.state.characters[character.id]?.droneDeployed
        ? { [`drone-${character.id}`]: character }
        : {}),
    }))
    .reduce((a, b) => ({ ...a, ...b }), {});

  function characterIdToDisplayName(characterId: string) {
    return characterId.startsWith("drone-")
      ? `${allCharacters[characterId]?.displayName}'s Drone`
      : allCharacters[characterId]?.displayName;
  }

  const clickCharacter = (char: string) => {
    if (targetController?.targetType === TargetType.character) {
      if (targetController.targets.includes(char)) {
        targetController.removeTarget(char);
      } else {
        targetController.addTarget(char);
      }
    } else if (
      targetController?.targetType === null &&
      props.onClickCharacter
    ) {
      props.onClickCharacter(allCharacters[char]);
    }
  };

  const updateCharacterPosition = (
    characterId: string,
    position: AxialCoordinates
  ) => {
    situation?.updateCharacterPosition(
      characterId,
      subtractHex(position, centerHex)
    );
  };

  const updateLabelPosition = (labelId: string, position: AxialCoordinates) => {
    situation?.commitUpdateLabel(labelId, {
      ...situation.state.labels![labelId],
      coordinates: hexToAxial(position),
    });
  };

  const actions = useSituationActions(situation.id);
  const charactersActions = actions.docs
    .map((a) => ({ [a.id]: a.data() }))
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

  const viewportContext = useContext(ViewportContext);

  const map = useMap(situation.state.mapId || "1");

  useEffect(() => {
    // snap viewport to character
    if (situation && character) {
      const characterPoint = hexToPoint(
        grid.getHex(
          situation.state.characters[character.id]?.coordinates || {
            q: 0,
            r: 0,
          }
        )
      );
      viewportContext.viewport?.snap(characterPoint.x, characterPoint.y, {
        removeOnComplete: true,
      });
    }
  }, [viewportContext.viewport]);

  return (
    <>
      {Object.entries(charactersActions).map(([id, actions]) =>
        actions.ping ? (
          <Ping
            {...actions.ping.coordinates}
            color={theme.palette.primary.main}
          />
        ) : null
      )}
      {situation?.state.ping ? (
        <Ping
          {...situation.state.ping.coordinates}
          color={theme.palette.primary.main}
        />
      ) : null}
      <Container sortableChildren={true}>
        {Object.entries(allCharacters)
          .filter(([characterId, char]) => {
            return (
              !character ||
              (!character.npc
                ? allCharacters[characterId].npc
                  ? situation.state.characters[characterId]?.visible === true &&
                    (!situation.state.dynamicVision ||
                      (situation.state.characters[characterId]?.coordinates &&
                        getArea(
                          situation.state.characters[characterId]?.coordinates!,
                          0.5
                        ).some(
                          (hex) => situation.state.visibleArea?.[hex.q]?.[hex.r]
                        ))) &&
                    (situation.state.characters[character.id]?.search || 0) >=
                      (situation.state.characters[characterId]?.stealth || 0)
                  : // Object.entries(allCharacters).some(
                    //   ([viewerId, viewer]) =>
                    //     !viewer.npc &&
                    //     getCoverLevel(
                    //       situation.state.characters[viewerId]?.coordinates!,
                    //       situation.state.characters[characterId]?.coordinates!,
                    //       map?.terrain
                    //     ) !== CoverLevel.full
                    // )
                    // situation.state.visibleArea?.[
                    //   situation.state.characters[characterId]!.coordinates!.q
                    // ]?.[situation.state.characters[characterId]!.coordinates!.r]
                    campaign.state.charactersCurrentSituation[char.id] ===
                    situation.id
                : character.id === characterId ||
                  (situation.state.characters[character.id]?.search || 0) >=
                    (situation.state.characters[characterId]?.stealth || 0))
            );
          })
          .map(([characterId, char]) => {
            const { q, r } = addHex(
              centerHex,
              createHex(
                hexPrototype,
                situation.state.characters[characterId]?.coordinates || {
                  q: 0,
                  r: 0,
                }
              )
            );

            const isDrone = characterId !== char.id;

            const movementPath =
              (character?.id === char.id || admin || !char.npc) &&
              charactersActions[char.id]?.actions
                ?.filter(
                  (action) =>
                    (actionById(action.actionId)?.movementMultiplier &&
                      !isDrone) ||
                    (actionById(action.actionId)?.droneMovementMultiplier &&
                      isDrone)
                )
                .map((action) => {
                  const actionDetails = actionById(action.actionId);
                  return action.targets.map((target) => {
                    return actionDetails.targetType === TargetType.path
                      ? addHex(centerHex, target as any)
                      : addHex(
                          centerHex,
                          situation.state.characters[target]?.coordinates!
                        );
                  });
                })[0];
            return (
              <>
                {movementPath && (
                  <HexagonPath
                    color={theme.palette.success.main}
                    animate
                    terrain={map?.terrain}
                    blockingTerrain={10}
                    path={[
                      addHex(
                        centerHex,
                        grid.getHex(
                          situation.state.characters[characterId]!.coordinates
                        )
                      ),
                      ...movementPath,
                    ]}
                  />
                )}
                {
                  charactersActions[char.id]?.actions
                    ?.filter((action) => {
                      const actionDetails = actionById(action.actionId);
                      return (
                        (character?.id === char.id ||
                          admin ||
                          !char.npc ||
                          (action.timeStarted < situation.state.time &&
                            actionDetails.duration !== Infinity)) &&
                        [TargetType.character, TargetType.point].includes(
                          actionById(action.actionId)?.targetType
                        )
                      );
                    })
                    .map((action) => {
                      const actionDetails = actionById(action.actionId);
                      return action.targets.map((target) => {
                        const targetPoint =
                          actionDetails.targetType === TargetType.character
                            ? situation.state.characters[target]?.coordinates
                            : (target as unknown as AxialCoordinates);

                        if (!targetPoint) {
                          return null;
                        }

                        const readyWeapon =
                          situation.state.characters[characterId]!
                            .readyWeapon &&
                          techById(
                            situation.state.characters[characterId]!
                              .readyWeapon!
                          );
                        const minRange = readyWeapon
                          ? readyWeapon.minEffectiveRange || 0
                          : 0;
                        const maxRange = readyWeapon
                          ? readyWeapon.maxEffectiveRange || Infinity
                          : 0.5;

                        const distance =
                          grid.distance(
                            situation.state.characters[characterId]!
                              .coordinates!,
                            targetPoint
                          ) /
                            2 -
                          allCharacters[characterId].stats.size / 2 -
                          (actionDetails.targetType === TargetType.character
                            ? allCharacters[target].stats.size
                            : 0) /
                            2;

                        return !!actionDetails.droneRequired === isDrone ? (
                          <>
                            <HexagonLineOfSight
                              color={
                                !actionDetails.isAttack ||
                                (distance >= minRange && distance <= maxRange)
                                  ? theme.palette.error.main
                                  : "#808080"
                              }
                              animate
                              start={
                                situation.state.characters[characterId]!
                                  .coordinates!
                              }
                              end={targetPoint}
                              terrain={
                                actionDetails.lineOfSight
                                  ? map?.terrain
                                  : undefined
                              }
                              pathBlocking={
                                actionDetails.lineOfSight ? 20 : undefined
                              }
                              canHitExposed={actionDetails.isAttack}
                            />
                            {actionDetails.radius ? (
                              <HexagonArea
                                color={theme.palette.error.main}
                                target={targetPoint}
                                source={
                                  situation.state.characters[characterId]!
                                    .coordinates!
                                }
                                radius={
                                  character?.id === char.id ||
                                  admin ||
                                  !char.npc
                                    ? actionDetails.radius
                                    : 1
                                }
                                alpha={0.2}
                                terrain={map?.terrain}
                                pathBlocking={20}
                                areaBlocking={15}
                              />
                            ) : null}
                          </>
                        ) : null;
                      });
                    })
                    .reduce((a, b) => [...a, ...b], [])
                  // targetPoints?.map((targetPoint, index) =>
                  //       targetPoint.drone === isDrone ? (
                  //         <>
                  //           <HexagonLineOfSight
                  //             color={theme.palette.error.main}
                  //             animate
                  //             start={
                  //               situation.state.characters[characterId]!.coordinates!
                  //             }
                  //             end={targetPoint.point}
                  //             terrain={
                  //               targetPoint.lineOfSight ? map?.terrain : undefined
                  //             }
                  //             pathBlocking={targetPoint.lineOfSight ? 20 : undefined}
                  //             canHitExposed={targetPoint.canHitExposed}
                  //           />
                  //           {targetPoint.radius ? (
                  //             <HexagonArea
                  //               color={theme.palette.error.main}
                  //               target={targetPoint.point}
                  //               source={
                  //                 situation.state.characters[characterId]!
                  //                   .coordinates!
                  //               }
                  //               radius={targetPoint.radius}
                  //               alpha={0.2}
                  //               terrain={map?.terrain}
                  //               pathBlocking={20}
                  //               areaBlocking={15}
                  //             />
                  //           ) : null}
                  //         </>
                  //       ) : null
                  //     )}
                }
                <CharacterToken
                  key={characterId}
                  highlight={
                    targetController?.targets.includes(characterId) ||
                    charactersActions[char.id]?.ready
                  }
                  highlightColor={
                    !targetController?.targets.includes(characterId) &&
                    charactersActions[char.id]?.ready
                      ? theme.palette.success.main
                      : undefined
                  }
                  characterId={characterId}
                  size={allCharacters[characterId].stats.size}
                  q={q}
                  r={r}
                  avatar={
                    characterId.startsWith("drone-") ? undefined : char.avatar
                  }
                  displayName={characterIdToDisplayName(characterId)}
                  onDragEnd={updateCharacterPosition}
                  textColor={theme.palette.text.root}
                  onClick={clickCharacter}
                  draggable={admin}
                  inactive={
                    allCharacters[characterId].npc
                      ? !situation.state.characters[characterId]?.visible
                      : campaign.state.charactersCurrentSituation[char.id] !==
                        situation.id
                  }
                />
              </>
            );
          })}
      </Container>
      <Container sortableChildren={true}>
        {situation.state.labels &&
          Object.entries(situation.state.labels)
            .filter(([labelId, label]) => {
              return (
                admin || situation.state.labels![labelId]?.visible === true
              );
            })
            .map(([labelId, label]) => {
              const { q, r } = situation.state.labels![labelId]!.coordinates;
              return (
                <Label
                  key={labelId}
                  labelId={labelId}
                  size={label.size as any}
                  q={q}
                  r={r}
                  displayName={label.displayName}
                  onDragEnd={updateLabelPosition}
                  onClick={onClickLabel}
                  textColor={
                    {
                      green: theme.palette.success.main,
                      orange: theme.palette.error.main,
                      pink: theme.palette.secondary.light1,
                      blue: theme.palette.primary.main,
                    }[label.color] || theme.palette.primary.main
                  }
                  draggable={admin}
                  inactive={!situation.state.labels![labelId]!.visible}
                />
              );
            })}
      </Container>
    </>
  );
};

// const BlinkingHexagonArea: React.FC<{
//   q: number;
//   r: number;
//   radius?: number;
//   alpha?: number;
//   color?: string;
// }> = React.memo((props) => {
//   const animation = useSpring({
//     from: {
//       alpha: (props.alpha || 1) * 0.5,
//     },
//     to: [
//       {
//         alpha: props.alpha || 1,
//       },
//       {
//         alpha: (props.alpha || 1) * 0.5,
//       },
//     ],
//     loop: true,
//   });

//   return <AnimatedHexagonArea {...props} alpha={animation.alpha} />;
// });

const HexagonArea: React.FC<{
  target: AxialCoordinates;
  source?: AxialCoordinates;
  radius?: number;
  alpha?: number;
  color?: string;
  terrain?: Terrain;
  pathBlocking?: number;
  areaBlocking?: number;
}> = (props) => {
  const {
    radius,
    target,
    source,
    alpha,
    color,
    terrain,
    pathBlocking,
    areaBlocking,
  } = props;

  const drawHighlight = useCallback(
    (graphics: PixiGraphics) => {
      // graphics.lineStyle(1.5, 0x999999, 0.5);
      graphics.clear();
      graphics.beginFill(color ? PIXI.utils.string2hex(color) : 0x009900);

      const hexes = radius
        ? getExplosion(
            target,
            radius,
            source,
            terrain,
            pathBlocking,
            areaBlocking
          )
        : [grid.getHex(target)];

      for (const hex of hexes) {
        const corners = hex.corners; //.map((corner) => corner.add(point));

        graphics.drawPolygon(
          ...corners.map((corner) => new PIXI.Point(corner.x, corner.y))
        );
      }

      // grid
      //   .traverse(spiral({ radius: (radius || 0) * 2, start: { q, r } }))
      //   .each((hex) => {
      //     //const point = hex.toPoint();
      //     // add the hex's position to each of its corner points
      //     const corners = hex.corners; //.map((corner) => corner.add(point));

      //     graphics.drawPolygon(
      //       ...corners.map((corner) => new PIXI.Point(corner.x, corner.y))
      //     );
      //   })
      //   .run();
    },
    [target.q, target.r, radius, source?.q, source?.r]
  );
  return <Graphics draw={drawHighlight} alpha={alpha} />;
};

const AnimatedHexagonArea = animated(HexagonArea);

const Circle: React.FC<{
  q: number;
  r: number;
  radius: number;
  alpha?: number;
  color?: string;
}> = (props) => {
  const { radius, q, r, alpha, color } = props;

  const drawCircle = useCallback(
    (graphics: PixiGraphics) => {
      // graphics.lineStyle(1.5, 0x999999, 0.5);
      graphics.clear();
      graphics.beginFill(color ? PIXI.utils.string2hex(color) : 0x1155ff);

      const center = hexToPoint(grid.getHex({ q, r }));
      graphics.drawCircle(center.x, center.y, radius);
    },
    [q, r, radius]
  );
  return <Graphics draw={drawCircle} alpha={alpha} />;
};

const AnimatedCircle = animated(Circle);

const Ping: React.FC<{
  q: number;
  r: number;
  color?: string;
}> = ({ q, r, color }) => {
  const n = useRef(0);
  const animation = useSpring({
    from: {
      alpha: 1,
      radius: 0,
    },
    to: {
      alpha: 0,
      radius: 300,
    },
    loop: () => 3 >= n.current++,
  });

  return (
    <Container>
      <AnimatedCircle
        q={q}
        r={r}
        radius={animation.radius}
        alpha={animation.alpha}
        color={color}
      />
    </Container>
  );
};

const HexagonLineOfSight: React.FC<{
  start: AxialCoordinates;
  end: AxialCoordinates;
  color?: string;
  animate?: boolean;
  terrain?: Terrain;
  pathBlocking?: number;
  canHitExposed?: boolean;
}> = (props) => {
  const { start, end, color, terrain, pathBlocking, animate, canHitExposed } =
    props;

  const hexPath = useMemo(() => {
    const hexPath = getLineOfSight(
      start,
      end,
      terrain,
      pathBlocking,
      canHitExposed
    );
    return hexPath;
  }, [JSON.stringify({ start, end })]);

  const [animation] = useSpring(() => ({
    loop: true,
    reset: true,
    to: [{ index: 500 }],
    from: { index: 0 },
    config: {
      duration: 500 * 30,
      round: 1,
    },
  }));

  return (
    <AnimatedHighlightedHexagonPath
      hexPath={hexPath}
      highlightedIndex={animate ? animation.index : -1}
      color={color}
    />
  );
};

interface PathProps {
  path: AxialCoordinates[];
  animate?: boolean;
  color?: string;
  terrain?: Terrain;
  blockingTerrain?: number;
}

const HexagonPath: React.FC<PathProps> = React.memo(
  ({ path, animate, color, terrain, blockingTerrain }) => {
    const hexPath = useMemo(() => {
      const hexPath: Hex[] = pathCoordinatesToHexes(
        path,
        terrain,
        blockingTerrain
      );
      return hexPath;
    }, [JSON.stringify(path)]);

    // const drawPath = useCallback(
    //   (graphics: PixiGraphics) => {
    //     // graphics.lineStyle(1.5, 0x999999, 0.5);
    //     graphics.clear();
    //     graphics.beginFill(0x009900, 0.5);

    //     hexPath
    //       .each((hex: any) => {
    //         const point = hex.toPoint();
    //         // add the hex's position to each of its corner points
    //         const corners = hex
    //           .corners()
    //           .map((corner: any) => corner.add(point));

    //         graphics.drawPolygon(
    //           ...corners.map(
    //             (corner: any) => new PIXI.Point(corner.x, corner.y)
    //           )
    //         );
    //       })
    //       .run();
    //   },
    //   [JSON.stringify(path)]
    // );

    const [animation] = useSpring(() => ({
      loop: true,
      reset: true,
      to: [{ index: 500 }],
      from: { index: 0 },
      config: {
        duration: 500 * 30,
        round: 1,
      },
    }));

    return (
      <AnimatedHighlightedHexagonPath
        hexPath={hexPath}
        highlightedIndex={animate ? animation.index : -1}
        color={color}
      />
    );
    // <>
    //   {/* <Graphics draw={drawPath} /> */}
    //   {hexPath.map((hex, index) => (
    //     <AnimatedHexagonArea radius={0} x={hex.x} y={hex.y} />
    //   ))}
    //   {/* <HexagonArea radius={0} x={hexPath[animation.index].}/> */}
    // </>
  },
  (prevProps, newProps) =>
    JSON.stringify(prevProps.path) === JSON.stringify(newProps.path)
);

const HighlightedHexagonPath: React.FC<{
  hexPath: Hex[];
  highlightedIndex: number;
  color?: string;
}> = ({ hexPath, highlightedIndex, color }) => {
  const drawPath = useCallback(
    (graphics: PixiGraphics) => {
      // graphics.lineStyle(1.5, 0x999999, 0.5);
      graphics.clear();

      hexPath.forEach((hex, index) => {
        graphics.beginFill(
          color ? PIXI.utils.string2hex(color) : 0x009900,
          Math.abs((highlightedIndex % (hexPath.length + 3)) - index) <= 1
            ? 0.6
            : 0.4
        );
        //const point = hex.toPoint();
        // add the hex's position to each of its corner points
        const corners = hex.corners;

        graphics.drawPolygon(
          ...corners.map((corner: any) => new PIXI.Point(corner.x, corner.y))
        );
      });
    },
    [hexPath, highlightedIndex]
  );

  return (
    <>
      <Graphics draw={drawPath} />
      {/* {hexPath.map((hex: any, index: any) => (
        <AnimatedHexagonArea
          key={`${hex.x}-${hex.y}`}
          radius={0}
          x={hex.x}
          y={hex.y}
          alpha={Math.abs(highlightedIndex - index) <= 1 ? 1 : 0.5}
        />
      ))} */}
    </>
  );
};

const AnimatedHighlightedHexagonPath = animated(HighlightedHexagonPath);

interface CharacterTokenProps {
  q: number;
  r: number;
  avatar?: number | string;
  characterId: string;
  displayName: string;
  textColor: string;
  size?: number;
  draggable?: boolean;
  onDragEnd?: (characterId: string, position: any) => void;
  onClick?: (characterId: string) => void;
  inactive?: boolean;
  highlight?: boolean;
  highlightColor?: string;
}

const AnimatedContainer = animated(Container);

const CharacterToken: React.FC<CharacterTokenProps> = React.memo((props) => {
  const {
    avatar,
    displayName,
    onDragEnd,
    textColor,
    onClick,
    q,
    r,
    characterId,
    draggable,
    inactive,
    highlight,
    highlightColor,
    size = 1,
  } = props;

  const position = { q, r };

  const characterCenter = useMemo(() => {
    const characterHex = grid.getHex(position) || grid.getHex([0, 0])!;
    return hexToPoint(characterHex);
  }, [q, r]);

  const viewportContext = useContext(ViewportContext);

  const isDragging = React.useRef(false);
  const isClicking = React.useRef(false);
  const offset = React.useRef({ x: 0, y: 0 });

  const [alpha, setAlpha] = React.useState(1);

  const [zIndex, setZIndex] = React.useState(0);

  const [currentPosition, setCurrentPosition] = React.useState(characterCenter);
  const [previousPosition, setPreviousPosition] =
    React.useState(currentPosition);

  const [animatedPosition, setAnimatedPosition] =
    React.useState(currentPosition);

  // useTick((delta) => {
  //   const diff = currentPosition.subtract(previousPosition);
  //   setAnimatedPosition(animatedPosition.add(diff.multiply(delta)));
  // });

  const animations = useSpring({
    alpha: alpha * (inactive ? 0.5 : 1),
    x: currentPosition.x,
    y: currentPosition.y,
  });

  useEffect(() => {
    setPreviousPosition(currentPosition);
    setCurrentPosition(characterCenter);
  }, [q, r]);

  function onStart(e: any) {
    if (draggable) {
      viewportContext.setDraggable(false);
      isDragging.current = true;
      // Set offset accounting for viewport scale
      offset.current = {
        x: e.data.global.x / viewportContext.scale - currentPosition.x,
        y: e.data.global.y / viewportContext.scale - currentPosition.y,
      };

      setAlpha(0.7);
      setZIndex(10);
    }

    isClicking.current = true;
  }

  function onEnd() {
    if (isDragging.current) {
      const newCharacterHex = grid.pointToHex(currentPosition);
      viewportContext.setDraggable(true);
      isDragging.current = false;
      setAlpha(1);
      setZIndex(0);
      if (newCharacterHex) {
        if (onDragEnd) {
          onDragEnd(characterId, newCharacterHex);
        }
      } else {
        setCurrentPosition(characterCenter);
      }
    }
  }

  function onTap() {
    if (isClicking.current && onClick) {
      onClick(characterId);
    }
  }

  function onMove(e: any) {
    if (isDragging.current) {
      const newPixelPosition = {
        x: e.data.global.x / viewportContext.scale - offset.current.x,
        y: e.data.global.y / viewportContext.scale - offset.current.y,
      };
      const newCharacterHex = grid.pointToHex(newPixelPosition);
      const newCharacterCenter = hexToPoint(newCharacterHex);
      if (
        isClicking.current &&
        (newCharacterHex.q !== position.q || newCharacterHex.r !== position.r)
      ) {
        isClicking.current = false;
      }
      setCurrentPosition(newCharacterCenter);
    }
  }

  const drawCharacter = useCallback(
    async (graphics: PixiGraphics) => {
      graphics.clear();
      if (highlight) {
        graphics.lineStyle(
          4,
          PIXI.utils.string2hex(highlightColor || textColor)
        );
      } else {
        graphics.lineStyle(2, 0x000000);
      }

      if (avatar !== undefined) {
        try {
          const texture = await PIXI.Texture.fromURL(avatars[avatar], {
            // width: 70 * size,
            // height: 70 * size,
          });

          graphics.beginTextureFill({
            texture,
            matrix: new PIXI.Matrix(
              (70 / texture.baseTexture.width) * size,
              0,
              0,
              (70 / texture.baseTexture.height) * size,
              -35 * size,
              -35 * size
            ),
          });
        } catch (e) {
          graphics.beginFill(0x337788);
        }
      } else {
        graphics.beginFill(0x337788);
      }
      graphics.drawCircle(0, 0, 35 * size);
      graphics.endFill();
    },
    [avatar, highlight, highlightColor, size]
  );

  return (
    <AnimatedContainer
      x={isDragging.current ? currentPosition.x : animations.x}
      y={isDragging.current ? currentPosition.y : animations.y}
      zIndex={zIndex}
      alpha={animations.alpha}
    >
      <Graphics
        draw={drawCharacter}
        interactive={true}
        pointerdown={onStart}
        pointerup={onEnd}
        pointertap={onTap}
        pointerupoutside={onEnd}
        pointermove={onMove}
      />
      <PixiText
        anchor={0.4}
        // x={characterCenter.x}
        y={35 * size + 5}
        text={displayName}
        style={
          new TextStyle({
            align: "center",
            // fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: 20,
            fontWeight: "400",
            fill: textColor,
            stroke: "black",
            strokeThickness: 5,
            letterSpacing: 3,
            // dropShadow: true,
            // dropShadowColor: "#ccced2",
            // dropShadowBlur: 4,
            // dropShadowAngle: Math.PI / 6,
            // dropShadowDistance: 6,
            // wordWrap: true,
            // wordWrapWidth: 440,
          })
        }
      />
    </AnimatedContainer>
  );
});

interface LabelProps {
  q: number;
  r: number;
  labelId: string;
  displayName: string;
  textColor: string;
  size?: "medium";
  draggable?: boolean;
  onDragEnd?: (characterId: string, position: any) => void;
  onClick?: (characterId: string) => void;
  inactive?: boolean;
}

const Label: React.FC<LabelProps> = React.memo((props) => {
  const {
    displayName,
    onDragEnd,
    textColor = "blue",
    onClick,
    q,
    r,
    labelId,
    draggable,
    inactive,
    size = "medium",
  } = props;

  const position = { q, r };

  const fontSize = {
    small: 20,
    medium: 40,
    large: 60,
  }[size];

  const characterCenter = useMemo(() => {
    const characterHex = grid.getHex(position) || grid.getHex([0, 0])!;
    return hexToPoint(characterHex);
  }, [q, r]);

  const viewportContext = useContext(ViewportContext);

  const isDragging = React.useRef(false);
  const isClicking = React.useRef(false);
  const offset = React.useRef({ x: 0, y: 0 });

  const [alpha, setAlpha] = React.useState(1);

  const [zIndex, setZIndex] = React.useState(0);

  const [currentPosition, setCurrentPosition] = React.useState(characterCenter);
  const [previousPosition, setPreviousPosition] =
    React.useState(currentPosition);

  // useTick((delta) => {
  //   const diff = currentPosition.subtract(previousPosition);
  //   setAnimatedPosition(animatedPosition.add(diff.multiply(delta)));
  // });

  const animations = useSpring({
    alpha: alpha * (inactive ? 0.5 : 1),
    x: currentPosition.x,
    y: currentPosition.y,
  });

  useEffect(() => {
    setPreviousPosition(currentPosition);
    setCurrentPosition(characterCenter);
  }, [q, r]);

  function onStart(e: any) {
    if (draggable) {
      viewportContext.setDraggable(false);
      isDragging.current = true;
      // Set offset accounting for viewport scale
      offset.current = {
        x: e.data.global.x / viewportContext.scale - currentPosition.x,
        y: e.data.global.y / viewportContext.scale - currentPosition.y,
      };

      setAlpha(0.7);
      setZIndex(10);
    }

    isClicking.current = true;
  }

  function onEnd() {
    if (isDragging.current) {
      const newCharacterHex = grid.pointToHex(currentPosition);
      viewportContext.setDraggable(true);
      isDragging.current = false;
      setZIndex(0);
      setAlpha(1);
      if (newCharacterHex) {
        if (onDragEnd) {
          onDragEnd(labelId, newCharacterHex);
        }
      } else {
        setCurrentPosition(characterCenter);
      }
    }
    if (isClicking.current && onClick) {
      onClick(labelId);
    }
  }

  function onMove(e: any) {
    if (isDragging.current) {
      const newPixelPosition = {
        x: e.data.global.x / viewportContext.scale - offset.current.x,
        y: e.data.global.y / viewportContext.scale - offset.current.y,
      };
      const newCharacterHex = grid.pointToHex(newPixelPosition);
      const newCharacterCenter = hexToPoint(newCharacterHex);
      setCurrentPosition(newCharacterCenter);
      if (
        isClicking.current &&
        (newCharacterHex.q !== position.q || newCharacterHex.r !== position.r)
      ) {
        isClicking.current = false;
      }
    }
  }

  return (
    <AnimatedContainer
      x={isDragging.current ? currentPosition.x : animations.x}
      y={isDragging.current ? currentPosition.y : animations.y}
      interactive={true}
      pointerdown={onStart}
      pointerup={onEnd}
      pointerupoutside={onEnd}
      pointermove={onMove}
      zIndex={zIndex}
      alpha={animations.alpha}
    >
      <PixiText
        anchor={[0.4, 0.5]}
        // x={characterCenter.x}
        // y={35 * size + 5}
        text={displayName}
        style={
          new TextStyle({
            //align: "center",
            // fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize,
            fontWeight: "400",
            fill: textColor,
            stroke: "black",
            strokeThickness: 5,
            letterSpacing: 3,
            // dropShadow: true,
            // dropShadowColor: "#ccced2",
            // dropShadowBlur: 4,
            // dropShadowAngle: Math.PI / 6,
            // dropShadowDistance: 6,
            // wordWrap: true,
            // wordWrapWidth: 440,
          })
        }
      />
    </AnimatedContainer>
  );
});
