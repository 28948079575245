import { Grid } from "@material-ui/core";
import React from "react";
import { CapacityBar, LoadingBars } from "../components";
import { FullCharacter } from "./utils";

export const HealthBar: React.FC<{ character: FullCharacter }> = (props) => {
  const { character } = props;
  return (
    <Grid item direction="row">
      <CapacityBar
        length={5}
        palette="error"
        current={Math.min(
          character.stats.currentHealth,
          character.stats.maxHealth / 2
        )}
        maximum={character.stats.maxHealth / 2}
      />
      <CapacityBar
        length={5}
        palette="success"
        current={Math.max(
          0,
          character.stats.currentHealth - character.stats.maxHealth / 2
        )}
        maximum={character.stats.maxHealth / 2}
      />
      {character.stats.maxShield > 0 && (
        <CapacityBar
          length={10}
          current={character.stats.currentShield}
          maximum={character.stats.maxShield}
        />
      )}
    </Grid>
  );
};
