import { firestore, User } from "firebase";
import { useEffect, useState } from "react";
import {
  useFirestoreDocData,
  useFirestore,
  useUser,
  useFirestoreCollection,
} from "reactfire";
import {
  FullCharacter,
  getFullCharacter,
  CharacterData,
  Attitude,
  Stats,
} from "./utils";

export const skillList = [
  "athletics",
  "deception",
  "demolition",
  "driving",
  "empathy",
  "engineering",
  "environmentalAnalysis",
  "hacking",
  "handToHand",
  "heavyGuns",
  "intimidation",
  "lightGuns",
  "medical",
  "perception",
  "performance",
  "persuasion",
  "physicalPower",
  "research",
  "security",
  "stealth",
];

export class EditableCharacter {
  public state: FullCharacter;
  public id: string;

  private db: firestore.Firestore;
  private characterRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  private characterData: CharacterData;
  private setCharacterUpdates: React.Dispatch<
    React.SetStateAction<CharacterData>
  >;

  constructor(id: string) {
    this.id = id;
    this.db = useFirestore();
    this.characterRef = this.db.collection("characters").doc(id);
    const characterData = useFirestoreDocData<CharacterData>(this.characterRef);
    const [characterUpdates, setCharacterUpdates] = useState(characterData);
    useEffect(() => setCharacterUpdates(characterData), [characterData]);

    this.setCharacterUpdates = setCharacterUpdates;
    this.characterData = characterData;

    this.state = getFullCharacter(id, characterUpdates);
  }

  setOwner(owner: string) {
    return this.setCharacterUpdates((prevState) => ({
      ...prevState,
      owner,
    }));
  }
  setTier(tier: number) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      tier,
    }));
  }
  setAvatar(avatar: number | string) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      avatar,
    }));
  }
  setCurrentHealth(health: number) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      healthDamage: this.state.stats.maxHealth - health,
    }));
  }
  setCurrentShield(shield: number) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      shieldDamage: this.state.stats.maxShield - shield,
    }));
  }
  setCurrentDroneShield(shield: number) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      droneDamage: this.state.stats.maxDroneShield - shield,
    }));
  }
  setCredits(credits: number) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      credits,
    }));
  }
  addCredits(credits: number) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      credits: (prevState.credits ?? 0) + credits,
    }));
  }
  removeCredits(credits: number) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      credits: (prevState.credits ?? 0) - credits,
    }));
  }
  setDisplayName(displayName: string) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      displayName,
    }));
  }
  setDescription(description: string) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      description,
    }));
  }
  setNotes(notes: string) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      notes,
    }));
  }
  setStrength(strength: number) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      attributes: {
        ...prevState.attributes,
        strength,
      },
    }));
  }
  setCoordination(coordination: number) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      attributes: {
        ...prevState.attributes,
        coordination,
      },
    }));
  }
  setAttitude(attitude: Attitude) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      attitude,
    }));
  }
  setIntelligence(intelligence: number) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      attributes: {
        ...prevState.attributes,
        intelligence,
      },
    }));
  }
  setSensitivity(sensitivity: number) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      attributes: {
        ...prevState.attributes,
        sensitivity,
      },
    }));
  }
  addTech(id: string) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      tech: {
        ...prevState.tech,
        [id]: {
          ...prevState.tech[id],
          personalCount: (prevState.tech[id]?.personalCount ?? 0) + 1,
          equippedCount: prevState.tech[id]?.equippedCount ?? 0,
          storageCount: prevState.tech[id]?.storageCount ?? 0,
        },
      },
    }));
  }
  removeTech(id: string) {
    this.setCharacterUpdates((prevState) => {
      const newPersonalCount = (prevState.tech[id]?.personalCount ?? 0) - 1;
      return {
        ...prevState,
        tech: {
          ...prevState.tech,
          [id]: {
            ...prevState.tech[id],
            personalCount: newPersonalCount,
          },
        },
      };
    });
  }
  equipTech(id: string) {
    this.setCharacterUpdates((prevState) => {
      if (prevState.tech[id]?.personalCount < 1) {
        return prevState;
      }
      return {
        ...prevState,
        tech: {
          ...prevState.tech,
          [id]: {
            ...prevState.tech[id],
            equippedCount: (prevState.tech[id]?.equippedCount ?? 0) + 1,
            personalCount: (prevState.tech[id]?.personalCount ?? 0) - 1,
          },
        },
      };
    });
  }
  unequipTech(id: string) {
    this.setCharacterUpdates((prevState) => {
      if (prevState.tech[id]?.equippedCount < 1) {
        return prevState;
      }
      return {
        ...prevState,
        tech: {
          ...prevState.tech,
          [id]: {
            ...prevState.tech[id],
            equippedCount: (prevState.tech[id]?.equippedCount ?? 0) - 1,
            personalCount: (prevState.tech[id]?.personalCount ?? 0) + 1,
          },
        },
      };
    });
  }
  storeTech(id: string) {
    this.setCharacterUpdates((prevState) => {
      if (prevState.tech[id]?.personalCount < 1) {
        return prevState;
      }
      return {
        ...prevState,
        tech: {
          ...prevState.tech,
          [id]: {
            ...prevState.tech[id],
            storageCount: (prevState.tech[id]?.storageCount ?? 0) + 1,
            personalCount: (prevState.tech[id]?.personalCount ?? 0) - 1,
          },
        },
      };
    });
  }
  unstoreTech(id: string) {
    this.setCharacterUpdates((prevState) => {
      if (prevState.tech[id]?.storageCount < 1) {
        return prevState;
      }
      return {
        ...prevState,
        tech: {
          ...prevState.tech,
          [id]: {
            ...prevState.tech[id],
            storageCount: (prevState.tech[id]?.storageCount ?? 0) - 1,
            personalCount: (prevState.tech[id]?.personalCount ?? 0) + 1,
          },
        },
      };
    });
  }
  setStatModifiers(statModifiers: Partial<Stats>) {
    this.setCharacterUpdates((prevState) => ({
      ...prevState,
      statModifiers,
    }));
  }

  reset() {
    this.setCharacterUpdates(this.characterData);
  }

  commit() {
    return new Promise((resolve) => {
      this.setCharacterUpdates((prevState) => {
        if (
          prevState.npc &&
          prevState.displayName != this.characterData.displayName
        ) {
          const campaignRef = this.db
            .collection("campaigns")
            .doc(prevState.campaignId);
          const batch = this.db.batch();
          batch.update(this.characterRef, prevState);
          batch.update(campaignRef, {
            [`npcs.${this.id}`]: {
              displayName: prevState.displayName,
            },
          });

          resolve(batch.commit());
        } else {
          resolve(this.characterRef.update(prevState));
        }

        return prevState;
      });
    });
  }
}

export function useEditableCharacter(id: string): EditableCharacter {
  return new EditableCharacter(id);
}

export function useCreateCharacter() {
  const currentUser = useUser<User>();
  const charactersRef = useFirestore().collection("characters");

  return (character?: Partial<CharacterData>) => {
    const newCharacter: CharacterData = {
      owner: currentUser.uid,
      npc: false,
      attitude: {
        x: 0,
        y: 0,
        z: 0,
      },
      displayName: "[New Character]",
      avatar: 0,
      description: "A new character.",
      notes: "",
      attributes: {
        strength: 1,
        coordination: 1,
        intelligence: 1,
        sensitivity: 1,
      },
      healthDamage: 0,
      shieldDamage: 0,
      droneDamage: 0,
      tech: {},
      credits: 6000,
      tier: 1,
      ...character,
      createdAt: firestore.FieldValue.serverTimestamp() as any,
    };
    return charactersRef.add(newCharacter);
  };
}

export function useDeleteCharacter() {
  const charactersRef = useFirestore().collection("characters");
  return (id: string) => {
    return charactersRef.doc(id).delete();
  };
}

export function useCurrentUserCharacterList() {
  const charactersRef = useFirestore().collection("characters");
  const currentUser = useUser<User>();

  const characters = useFirestoreCollection<void>(
    charactersRef
      .where("owner", "==", currentUser.uid)
      .where("npc", "==", false)
      .orderBy("createdAt", "desc")
  ) as firestore.QuerySnapshot<CharacterData>;

  return characters;
}

export function useCampaignPlayerList(campaignId: string) {
  const charactersRef = useFirestore().collection("characters");

  const characterDocs = useFirestoreCollection<void>(
    charactersRef
      .where("campaignId", "==", campaignId)
      .where("npc", "==", false)
      .orderBy("createdAt", "desc")
  ) as firestore.QuerySnapshot<CharacterData>;

  const characters = characterDocs.docs.map((characterDoc) =>
    getFullCharacter(characterDoc.id, characterDoc.data())
  );

  return characters;
}

export function useCampaignNpcList(campaignId: string) {
  const charactersRef = useFirestore().collection("characters");

  const characterDocs = useFirestoreCollection<void>(
    charactersRef
      .where("campaignId", "==", campaignId)
      .where("npc", "==", true)
      .orderBy("createdAt", "desc")
  ) as firestore.QuerySnapshot<CharacterData>;

  const characters = characterDocs.docs.map((characterDoc) =>
    getFullCharacter(characterDoc.id, characterDoc.data())
  );

  return characters;
}

export function useSituationNpcList(campaignId: string, situationId: string) {
  const charactersRef = useFirestore().collection("characters");

  const characterDocs = useFirestoreCollection<void>(
    charactersRef
      .where("campaignId", "==", campaignId)
      .where(`situations.${situationId}`, "==", true)
  ) as firestore.QuerySnapshot<CharacterData>;

  const characters = characterDocs.docs.map((characterDoc) =>
    getFullCharacter(characterDoc.id, characterDoc.data())
  );

  return characters;
}

export function usePublishedCharacterList() {
  const charactersRef = useFirestore().collection("characters");

  const characterDocs = useFirestoreCollection<void>(
    charactersRef.where("published", "==", true).orderBy("createdAt", "desc")
  ) as firestore.QuerySnapshot<CharacterData>;

  const characters = characterDocs.docs;

  return characters;
}
