import React, { Suspense, useEffect } from "react";
import { AnimatorGeneralProvider } from "@arwes/animation";
import { ArwesThemeProvider, StylesBaseline } from "@arwes/core";
import { Footer, Navigation } from "./navigation";
import { BrowserRouter, Route, useLocation } from "react-router-dom";
import { RulesPage } from "./rules";
// import background from "./background.png";
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import {
  AuthCheck,
  FirebaseAppProvider,
  preloadAnalytics,
  preloadAuth,
  preloadFirestore,
  preloadUser,
  useUser,
} from "reactfire";
import {
  SignInPage,
  LogoutPage,
  SubscriptionPage,
  SignInPrompt,
  useConfiguredAnalytics,
  useSubscription,
} from "./account";
import { CharactersPage } from "./character";
import { CharacterPage } from "./character";
import { AnimatedRoute } from "./navigation";

import "./App.css";
import { SuspenseWithLoadingBars } from "./components";
import { CampaignPage, CampaignsPage } from "./campaign";
import { SituationPage } from "./situations";
import { PlayerPage } from "./player";
import firebase, { User } from "firebase";
import { JoinCampaignPage } from "./invite/JoinCampaignPage";
import { HomePage } from "./home";
import { PrivacyPolicyPage } from "./terms/PrivacyPolicyPage";
import { TermsOfServicePage } from "./terms/TermsOfServicePage";
import { MapPage } from "./map";
import { ErrorBoundaryPage } from "./ErrorBoundaryPage";
import { ModulesPage } from "./module";

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: "#081927",
      paper: "#081927",
    },
    primary: {
      main: "#40DAE6",
    },
    error: {
      main: "#F74940",
    },
    secondary: {
      main: "#F74091",
    },
    info: {
      main: "#D019A8",
    },
  },
});

const ROOT_FONT_FAMILY = '"Titillium Web", sans-serif';
const generalAnimator = { duration: { enter: 200, exit: 200 } };

export const firebaseConfig = {
  apiKey: "AIzaSyD9SP4X7XxqPrFnlUfQD0uj-DvxpVS6Opo",
  authDomain: "archiverpg.com",
  databaseURL: "https://archive-1337.firebaseio.com",
  projectId: "archive-1337",
  storageBucket: "archive-1337.appspot.com",
  messagingSenderId: "658520759478",
  appId: "1:658520759478:web:fb05043c08ccf99436da17",
  measurementId: "G-H3VSZ2YTC8",
};

const PageViewLogger: React.FC = () => {
  const analytics = useConfiguredAnalytics();
  const location = useLocation();
  useEffect(() => {
    analytics.logEvent("page_view", {
      path_name: location.pathname,
    });
  }, [analytics, location.pathname]);

  return null;
};

const RefetchUserToken: React.FC = () => {
  const subscription = useSubscription();
  const currentUser = useUser<User | undefined>();

  useEffect(() => {
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
    if (currentUser) {
      currentUser.getIdToken(true);
    }
  }, [currentUser, subscription]);

  return null;
};

const PreloadHooks: React.FC = () => {
  preloadFirestore({
    setup(firestore) {
      firestore().settings({
        ignoreUndefinedProperties: true,
      });
    },
  });
  preloadAuth();
  preloadUser();

  return null;
};

// const ConfigureAnalytics: React.FC = () => {
//   const currentUser = useUser<User>();
//   const analytics = useAnalytics();
//   currentUser.getIdToken(true);
//   analytics.setUserId(currentUser.uid);

//   const location = useLocation();
//   useEffect(() => {
//     // analytics.logEvent("screen_view", {
//     //   app_name: "Archive",
//     //   screen_name: location.pathname,
//     // });
//     analytics.logEvent("page_view", {
//       path_name: location.pathname,
//     });
//   }, [analytics, location.pathname]);

//   return null;
// };

const App = () => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Suspense fallback={null}>
        <PreloadHooks />
      </Suspense>
      <Suspense fallback={null}>
        <AuthCheck fallback={null}>
          <RefetchUserToken />
        </AuthCheck>
      </Suspense>

      <MuiThemeProvider theme={darkTheme}>
        <ArwesThemeProvider
          themeSettings={{
            palette: {
              primary: {
                main: "#40DAE6",
              },
              error: {
                main: "#F74940",
              },
              secondary: {
                main: "#F74091",
              },
              success: {
                main: "#00FF00",
              },
              text: {
                root: "#40DAE6",
                headings: "#40DAE6",
                link: "#F74091",
                linkHover: "#ff7ab5",
              },
              tabs: {
                main: "#005B5C",
              },
            },
          }}
        >
          <StylesBaseline styles={{ body: { fontFamily: ROOT_FONT_FAMILY } }} />
          <AnimatorGeneralProvider animator={generalAnimator}>
            <div style={{ paddingBottom: "4em" }} />
            <BrowserRouter>
              <ErrorBoundaryPage errorType="unknown">
                <AnimatedRoute path="/rules" component={RulesPage} />
                <AnimatedRoute
                  exact
                  path="/characters"
                  render={(props) => (
                    <SuspenseWithLoadingBars traceId={"characters-page-load"}>
                      <SignInPrompt>
                        <CharactersPage {...props} />
                      </SignInPrompt>
                    </SuspenseWithLoadingBars>
                  )}
                />
                <AnimatedRoute
                  path="/characters/:id"
                  render={(props) => (
                    <SuspenseWithLoadingBars traceId={"character-page-load"}>
                      <SignInPrompt>
                        <CharacterPage {...props} />
                      </SignInPrompt>
                    </SuspenseWithLoadingBars>
                  )}
                />
                <AnimatedRoute
                  exact
                  path="/campaigns"
                  render={(props) => (
                    <SuspenseWithLoadingBars traceId={"campaigns-page-load"}>
                      <SignInPrompt>
                        <CampaignsPage {...props} />
                      </SignInPrompt>
                    </SuspenseWithLoadingBars>
                  )}
                />
                <AnimatedRoute
                  exact
                  path={["/campaigns/:id/:subpath", "/campaigns/:id"]}
                  render={(props) => (
                    <SuspenseWithLoadingBars traceId={"campaign-page-load"}>
                      <SignInPrompt>
                        <CampaignPage {...props} />
                      </SignInPrompt>
                    </SuspenseWithLoadingBars>
                  )}
                />
                <AnimatedRoute
                  path="/situations/:id"
                  render={(props) => (
                    <SuspenseWithLoadingBars traceId={"situation-page-load"}>
                      <SignInPrompt>
                        <SituationPage {...props} />
                      </SignInPrompt>
                    </SuspenseWithLoadingBars>
                  )}
                />
                <AnimatedRoute
                  path="/maps/:id"
                  render={(props) => (
                    <SuspenseWithLoadingBars traceId={"map-page-load"}>
                      <SignInPrompt>
                        <MapPage {...props} />
                      </SignInPrompt>
                    </SuspenseWithLoadingBars>
                  )}
                />
                <AnimatedRoute
                  path="/players/:id"
                  render={(props) => (
                    <SuspenseWithLoadingBars traceId={"player-page-load"}>
                      <SignInPrompt>
                        <PlayerPage {...props} />
                      </SignInPrompt>
                    </SuspenseWithLoadingBars>
                  )}
                />
                <AnimatedRoute
                  path="/signin"
                  render={() => (
                    <SuspenseWithLoadingBars traceId={"signin-page-load"}>
                      <SignInPage />
                    </SuspenseWithLoadingBars>
                  )}
                />
                <AnimatedRoute
                  path="/logout"
                  render={(props) => (
                    <SuspenseWithLoadingBars traceId={"logout-page-load"}>
                      <SignInPrompt>
                        <LogoutPage {...props} />
                      </SignInPrompt>
                    </SuspenseWithLoadingBars>
                  )}
                />
                <AnimatedRoute
                  path="/join/:inviteCode"
                  render={(props) => (
                    <SuspenseWithLoadingBars traceId={"join-page-load"}>
                      <SignInPrompt>
                        <JoinCampaignPage {...props} />
                      </SignInPrompt>
                    </SuspenseWithLoadingBars>
                  )}
                />
                <AnimatedRoute
                  path="/subscription"
                  render={(props) => (
                    <SuspenseWithLoadingBars traceId={"subscription-page-load"}>
                      <SignInPrompt>
                        <SubscriptionPage {...props} />
                      </SignInPrompt>
                    </SuspenseWithLoadingBars>
                  )}
                />
                <AnimatedRoute
                  path="/modules"
                  render={(props) => (
                    <SuspenseWithLoadingBars traceId={"modules-page-load"}>
                      <SignInPrompt>
                        <ModulesPage {...props} />
                      </SignInPrompt>
                    </SuspenseWithLoadingBars>
                  )}
                />
                <AnimatedRoute
                  exact
                  path="/"
                  render={(props) => <HomePage {...props} />}
                />
                <AnimatedRoute path="/privacy" component={PrivacyPolicyPage} />
                <AnimatedRoute path="/tos" component={TermsOfServicePage} />
                <Navigation />
                <Footer />

                <Suspense fallback={null}>
                  <AuthCheck fallback={null}>
                    <PageViewLogger />
                  </AuthCheck>
                </Suspense>
              </ErrorBoundaryPage>
            </BrowserRouter>
          </AnimatorGeneralProvider>
        </ArwesThemeProvider>
      </MuiThemeProvider>
    </FirebaseAppProvider>
  );
};

export default App;
