export interface MapData {
  owner: string;
  campaignId: string;
  createdAt: string;
  displayName: string;
  notes: string;
  backgroundImage?: {
    src: string;
    scale: number;
    isVideo: boolean;
  };
  terrain: Terrain;
  seenArea: Terrain;
  fogOfWar: FogOfWarStatus;
}

export enum FogOfWarStatus {
  enabled = "enabled",
  dynamic = "dynamic",
  disabled = "disabled",
}

/*
0 - Ground
5 - Open Door
10 - Pit
15 - Low Wall
20 - High Wall
25 - Closed Door
*/
export type Terrain = {
  [key: number]:
    | {
        [key: number]: number | undefined;
      }
    | undefined;
};
