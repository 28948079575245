import { Animator } from "@arwes/animation";
import { Button, Text } from "@arwes/core";
import { DialogActions, Modal as MaterialModal } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { FrameContent } from "../layout/FrameContent";

const { FrameCorners } = require("@arwes/core");

const ModalContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
  margin-top: 10vh;
`;

const FrameInner = styled.div`
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
  width: 100%;
  height: 100%;
`;

export const Modal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  maxWidth?: number;
  actions?: React.ReactNode;
}> = (props) => {
  const { isOpen, onClose, children, maxWidth, actions } = props;
  return (
    <MaterialModal open={isOpen} onClose={onClose}>
      <Animator animator={{ root: true }}>
        <ModalContainer style={{ maxWidth: maxWidth ?? "1000px" }}>
          <FrameContent style={{ padding: "0px" }}>
            <FrameInner>{children}</FrameInner>
            {actions && (
              <div
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingBottom: "16px",
                }}
              >
                {actions}
              </div>
            )}
          </FrameContent>
        </ModalContainer>
      </Animator>
    </MaterialModal>
  );
};
