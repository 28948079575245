import React, { useState } from "react";
import { Card, Figure, Table, Text } from "@arwes/core";
import { ActionDetails, damageNames, DamageType, TechDetails } from "./tech";
import { Grid } from "@material-ui/core";
import { startCase } from "lodash";
import { ActionModal, statDisplayNames } from "./CharacterAbilities";
import styled from "styled-components";

export interface TechProps {
  tech: TechDetails;
  activate?: boolean;
  actions?: JSX.Element;
}

export const StyledFigure = styled(Figure)`
  img {
    min-width: 0px;
    min-height: 0px;
    image-rendering: pixelated;
  }
`;

export function statToDisplayName(stat: string) {
  if (stat.endsWith("Capacity")) {
    stat = stat.replace("current", "used").replace("max", "available");
  } else if (statDisplayNames[stat] !== undefined) {
    return statDisplayNames[stat];
  }

  return startCase(stat);
}

export const Tech: React.FC<TechProps> = (props) => {
  const { tech, actions, activate } = props;

  const [actionDetails, setActionDetails] = useState(
    undefined as ActionDetails | undefined
  );

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Text as="h3">{tech.displayName}</Text>
      </Grid>
      <Grid item container direction="row" spacing={4}>
        {tech.image && (
          <Grid item sm={4}>
            <StyledFigure src={tech.image} alt={tech.displayName} />
          </Grid>
        )}
        <Grid item sm={8}>
          <Text style={{ whiteSpace: "pre-wrap" }}>{tech.description}</Text>
        </Grid>
      </Grid>
      <Table
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "16px",
        }}
        headers={[
          { id: "stat", data: "Stat" },
          { id: "value", data: "Value" },
        ]}
        dataset={[
          {
            id: "tier",
            columns: [
              {
                id: "stat",
                data: "Tier",
              },
              {
                id: "value",
                data: tech.tier,
              },
            ],
          },
          {
            id: "type",
            columns: [
              {
                id: "stat",
                data: "Type",
              },
              {
                id: "value",
                data: tech.isEquipment
                  ? "Equipment"
                  : tech.isEquippable
                  ? "Cyberware"
                  : "Permanent Surgery",
              },
            ],
          },
          ...(tech.requirements
            ? [
                {
                  id: "requirements",
                  columns: [
                    {
                      id: "stat",
                      data: "Requirements",
                    },
                    {
                      id: "value",
                      data: tech.requirements
                        .map((tech) => tech.displayName)
                        .join(", "),
                    },
                  ],
                },
              ]
            : []),

          ...(tech.slots
            ? [
                {
                  id: "slots",
                  columns: [
                    {
                      id: "stat",
                      data: "Used Slots",
                    },
                    {
                      id: "value",
                      data: tech.slots
                        .map((slot) => startCase(slot))
                        .join(", "),
                    },
                  ],
                },
              ]
            : []),

          ...(tech.weaponSkill
            ? [
                {
                  id: "weaponSkill",
                  columns: [
                    {
                      id: "stat",
                      data: "Weapon Skill",
                    },
                    {
                      id: "value",
                      data: statToDisplayName(tech.weaponSkill),
                    },
                  ],
                },
                {
                  id: "range",
                  columns: [
                    {
                      id: "stat",
                      data: "Range",
                    },
                    {
                      id: "value",
                      data: tech.melee
                        ? `${tech.range} metres`
                        : `${tech.minEffectiveRange} - ${tech.maxEffectiveRange} metres`,
                    },
                  ],
                },
              ]
            : []),

          ...(tech.poolModifier
            ? [
                {
                  id: "poolMultiplier",
                  columns: [
                    {
                      id: "stat",
                      data: "Pool Modifier",
                    },
                    {
                      id: "value",
                      data:
                        (tech.poolModifier.multiplier
                          ? `${tech.poolModifier.multiplier}X` +
                            (tech.poolModifier.flat ? ` + ` : "")
                          : "") +
                        (tech.poolModifier.flat
                          ? `${tech.poolModifier.flat}`
                          : ""),
                    },
                  ],
                },
              ]
            : []),

          ...(tech.damage
            ? [
                {
                  id: "damage",
                  columns: [
                    {
                      id: "stat",
                      data: "Damage Multiplier",
                    },
                    {
                      id: "value",
                      data: (
                        Object.keys(tech.damage) as unknown as DamageType[]
                      )
                        .map(
                          (damageType) =>
                            `${damageNames[damageType]} ${
                              tech.damage![damageType]?.multiplier
                            }`
                        )
                        .filter((s) => s !== undefined)
                        .join(", "),
                    },
                  ],
                },
              ]
            : []),

          ...(tech.ammo && tech.ammo !== Infinity
            ? [
                {
                  id: "ammo",
                  columns: [
                    {
                      id: "stat",
                      data: "Ammo Capacity",
                    },
                    {
                      id: "value",
                      data: tech.ammo * (tech.roundsPerSecond || 1),
                    },
                  ],
                },
              ]
            : []),

          ...(tech.roundsPerSecond
            ? [
                {
                  id: "roundsPerSecond",
                  columns: [
                    {
                      id: "stat",
                      data: "Rounds Per Second",
                    },
                    {
                      id: "value",
                      data: tech.roundsPerSecond,
                    },
                  ],
                },
              ]
            : []),

          ...(tech.recoil
            ? [
                {
                  id: "recoil",
                  columns: [
                    {
                      id: "stat",
                      data: "Recoil",
                    },
                    {
                      id: "value",
                      data: `${tech.recoil * 100}%`,
                    },
                  ],
                },
              ]
            : []),

          ...(tech.maxEquippable
            ? [
                {
                  id: "maxEquippable",
                  columns: [
                    {
                      id: "stat",
                      data: "Max Equippable",
                    },
                    {
                      id: "value",
                      data: tech.maxEquippable,
                    },
                  ],
                },
              ]
            : []),

          ...(tech.weight
            ? [
                {
                  id: "weight",
                  columns: [
                    {
                      id: "stat",
                      data: "Weight",
                    },
                    {
                      id: "value",
                      data: tech.weight + " kgs",
                    },
                  ],
                },
              ]
            : []),

          ...(tech.price
            ? [
                {
                  id: "price",
                  columns: [
                    {
                      id: "stat",
                      data: "Value",
                    },
                    {
                      id: "value",
                      data: "$ " + tech.price,
                    },
                  ],
                },
              ]
            : []),

          ...(tech.statModifiers
            ? tech.statModifiers.map((modifier) => ({
                id: modifier.stat,
                columns: [
                  {
                    id: "stat",
                    data: statToDisplayName(modifier.stat),
                  },
                  {
                    id: "value",
                    data: `${modifier.modifier > 0 ? "+" : ""}${
                      modifier.modifier
                    }`,
                  },
                ],
              }))
            : []),

          ...(tech.actions
            ? tech.actions.map((action) => ({
                id: action.id,
                columns: [
                  {
                    id: "stat",
                    data: "Action",
                  },
                  {
                    id: "value",
                    data: (
                      <div
                        onClick={() => {
                          setActionDetails(action);
                        }}
                      >
                        {action.displayName + " 🔍"}
                      </div>
                    ),
                  },
                ],
              }))
            : []),
        ]}
      />
      <ActionModal
        action={actionDetails}
        onClose={() => {
          setActionDetails(undefined);
        }}
      />
    </Grid>
  );
};
