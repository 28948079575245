import React, { Suspense } from "react";
import styled from "styled-components";
import { Link } from "./Link";
import { AuthCheck } from "reactfire";
import { Menu, MenuItem, Hidden, Container, Grid } from "@material-ui/core";
import { Button, LoadingBars, Text } from "@arwes/core";
import logo from "./logo.png";

const LinkContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

const StyledLink = styled(Link)`
  margin-left: 16px;
`;

export const Footer = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);

  return (
    <footer
      style={{
        position: "absolute",
        height: "64px",
        bottom: "0",
        paddingTop: "16px",
        width: "100%",
        paddingBottom: "16px",
        background: "#01101B",
        borderTop: "4px solid #40DAE6",
      }}
    >
      <Container>
        <Grid container direction="row" justify="center">
          <Grid container item md={10} xl={12}>
            <StyledLink href="/tos">
              <Text>Terms of Service</Text>
            </StyledLink>
            <StyledLink href="/privacy">
              <Text>Privacy Policy</Text>
            </StyledLink>
            <a
              style={{ marginLeft: "16px" }}
              href="mailto:admin@archiverpg.com"
            >
              <Text>Contact</Text>
            </a>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};
