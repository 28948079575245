import React, { Suspense } from "react";
import styled from "styled-components";
import { Link } from "./Link";
import { AuthCheck } from "reactfire";
import { Menu, MenuItem, Hidden, Container, Grid } from "@material-ui/core";
import { Button, LoadingBars, Text } from "@arwes/core";
import logo from "./logo.png";

const LinkContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

const StyledLink = styled(Link)`
  margin-left: 16px;
`;

export const Navigation = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        paddingTop: "16px",
        paddingBottom: "16px",
        background: "#01101B",
        borderBottom: "4px solid #40DAE6",
      }}
    >
      <Container>
        <Grid container direction="row" justify="center">
          <Grid container item md={10} xl={12} justify="space-between">
            <Grid item xs={6} sm={4} onClick={() => {}}>
              <Link href="/">
                <img
                  style={{
                    maxHeight: "2.5em",
                    position: "absolute",
                    top: "0.5em",
                  }}
                  src={logo}
                />
                <Text
                  as="h5"
                  style={{ marginLeft: "2.25em", marginBottom: "0" }}
                >
                  Archive
                </Text>{" "}
                <Text>ALPHA</Text>
              </Link>
            </Grid>

            <LinkContainer item xs={6} sm={8}>
              <Hidden xsDown>
                <StyledLink href="/rules/introduction">
                  <Text>Game Rules</Text>
                </StyledLink>
                <Suspense
                  fallback={
                    <LoadingBars style={{ marginLeft: "24px" }} size={0.8} />
                  }
                >
                  <AuthCheck
                    fallback={
                      <StyledLink href="/signin">
                        <Text>Sign In</Text>
                      </StyledLink>
                    }
                  >
                    <StyledLink href="/characters">
                      <Text>Characters</Text>
                    </StyledLink>
                    <StyledLink href="/campaigns">
                      <Text>Campaigns</Text>
                    </StyledLink>
                    <StyledLink href="/subscription">
                      <Text>Subscription</Text>
                    </StyledLink>
                    <StyledLink href="/logout">
                      <Text>Logout</Text>
                    </StyledLink>
                  </AuthCheck>
                </Suspense>
              </Hidden>
              <Hidden smUp>
                <Button
                  aria-controls="navigation-menu"
                  aria-haspopup="true"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  style={{ paddingBottom: "6px" }}
                >
                  <Text>Open Menu</Text>
                </Button>
                <Menu
                  id="simple-menu"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  keepMounted
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <Link href="/rules">Game Rules</Link>
                  </MenuItem>
                  <Suspense
                    fallback={
                      <LoadingBars style={{ marginLeft: "24px" }} size={0.8} />
                    }
                  >
                    <AuthCheck
                      fallback={
                        <MenuItem onClick={handleClose}>
                          <Link href="/signin">Sign In</Link>
                        </MenuItem>
                      }
                    >
                      <MenuItem onClick={handleClose}>
                        <Link href="/characters">Characters</Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Link href="/campaigns">Campaigns</Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Link href="/subscription">Subscription</Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Link href="/logout">Logout</Link>
                      </MenuItem>
                    </AuthCheck>
                  </Suspense>
                </Menu>
              </Hidden>
            </LinkContainer>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
