import { firestore, User } from "firebase";
import { AxialCoordinates, CompassDirection, neighborOf } from "honeycomb-grid";
import { useEffect, useState } from "react";
import {
  useFirestoreDocData,
  useFirestore,
  useUser,
  useFirestoreCollection,
} from "reactfire";
import { grid, hexToString } from "../hexgrid";
import { FogOfWarStatus, MapData, Terrain } from "./utils";

export class EditableMap {
  public id: string | undefined;
  public state: MapData;

  private db: firestore.Firestore;
  public mapRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  private mapData: MapData;
  private setMapUpdates: React.Dispatch<React.SetStateAction<MapData>>;

  constructor(id?: string) {
    this.id = id;
    this.db = useFirestore();
    this.mapRef = this.db.collection("maps").doc(id || "x");
    const mapData = useFirestoreDocData<MapData>(this.mapRef);

    const [mapUpdates, setMapUpdates] = useState(mapData);
    useEffect(() => setMapUpdates(mapData), [mapData]);

    this.setMapUpdates = setMapUpdates;
    this.mapData = mapData;

    this.state = mapUpdates;
  }
  setOwner(owner: string) {
    return this.setMapUpdates((prevState) => ({
      ...prevState,
      owner,
    }));
  }
  setDisplayName(displayName: string) {
    return this.setMapUpdates((prevState) => ({
      ...prevState,
      displayName,
    }));
  }
  setDescription(description: string) {
    return this.setMapUpdates((prevState) => ({
      ...prevState,
      description,
    }));
  }
  setNotes(notes: string) {
    return this.setMapUpdates((prevState) => ({
      ...prevState,
      notes,
    }));
  }
  setBackgroundSrc(src: string) {
    return this.setMapUpdates((prevState) => ({
      ...prevState,
      backgroundImage: {
        ...{ src: "", scale: 1, isVideo: false },
        ...prevState.backgroundImage,
        src,
      },
    }));
  }
  setBackgroundScale(scale: number) {
    return this.setMapUpdates((prevState) => ({
      ...prevState,
      backgroundImage: {
        ...{ src: "", scale: 1, isVideo: false },
        ...prevState.backgroundImage,
        scale,
      },
    }));
  }
  setFogOfWar(fogOfWar: FogOfWarStatus) {
    return this.setMapUpdates((prevState) => ({
      ...prevState,
      fogOfWar,
    }));
  }
  setTerrain(hexagons: AxialCoordinates[], terrain: number) {
    return this.setMapUpdates((prevState) => ({
      ...prevState,
      terrain: {
        ...prevState.terrain,
        ...hexagons.reduce((acc, hex) => {
          acc[hex.q] = {
            ...prevState.terrain[hex.q],
            ...acc[hex.q],
            [hex.r]: terrain,
          };
          return acc;
        }, {} as Terrain),
      },
    }));
  }
  toggleDoor(point: AxialCoordinates) {
    if (![5, 25].includes(this.state.terrain[point.q]?.[point.r]!)) {
      return;
    }
    const connectedHexes = { [hexToString(point)]: point };
    const toCheck = [point];
    while (toCheck.length > 0) {
      const current = toCheck.pop();
      const neighbors = [
        CompassDirection.NE,
        CompassDirection.E,
        CompassDirection.SE,
        CompassDirection.SW,
        CompassDirection.W,
        CompassDirection.NW,
      ].map((direction) => neighborOf(grid.getHex(current), direction));
      for (const neighbor of neighbors) {
        if (
          !connectedHexes[hexToString(neighbor)] &&
          [5, 25].includes(this.state.terrain[neighbor.q]?.[neighbor.r]!)
        ) {
          connectedHexes[hexToString(neighbor)] = neighbor;
          toCheck.push(neighbor);
        }
      }
    }
    return this.mapRef.update(
      Object.values(connectedHexes)
        .map((hex) => ({
          [`terrain.${hex.q}.${hex.r}`]:
            this.state.terrain[point.q]?.[point.r] === 5 ? 25 : 5,
        }))
        .reduce((acc, update) => ({ ...acc, ...update }), {})
    );
  }
  commit() {
    return new Promise((resolve) => {
      this.setMapUpdates((prevState) => {
        for (const q of Object.keys(prevState.terrain)) {
          for (const r of Object.keys(prevState.terrain[q as any]!)) {
            if (prevState.terrain[q as any]?.[r as any] === 0) {
              delete prevState.terrain[q as any]![r as any];
            }
          }
          if (Object.keys(prevState.terrain[q as any]!).length === 0) {
            delete prevState.terrain[q as any];
          }
        }
        if (prevState.fogOfWar !== FogOfWarStatus.dynamic) {
          prevState.seenArea = {};
        }
        if (prevState.displayName !== this.mapData.displayName) {
          const campaignRef = this.db
            .collection("campaigns")
            .doc(prevState.campaignId);

          const batch = this.db.batch();
          batch.update(this.mapRef, prevState);
          batch.update(campaignRef, {
            [`maps.${this.id}`]: {
              displayName: prevState.displayName,
            },
          });

          resolve(batch.commit());
        } else {
          resolve(this.mapRef.update(prevState));
        }

        return prevState;
      });
    });
  }
  reset() {
    return this.setMapUpdates(this.mapData);
  }
}

export function useEditableMap(id?: string): EditableMap | undefined {
  const map = new EditableMap(id);
  if (id) {
    return map;
  }
}

export function useMap(id: string) {
  const mapRef = useFirestore().collection("maps").doc(id);
  const mapData = useFirestoreDocData<MapData | undefined>(mapRef);
  return mapData;
}

export function useCreateMap() {
  const mapsRef = useFirestore().collection("maps");
  const currentUser = useUser<User>();

  return (
    map: Partial<Omit<MapData, "campaignId">> & {
      campaignId: string;
    }
  ) => {
    const newMap: MapData = {
      owner: currentUser.uid,
      createdAt: firestore.FieldValue.serverTimestamp() as any,
      displayName: "[New Map]",
      notes: "",
      terrain: {},
      seenArea: {},
      fogOfWar: FogOfWarStatus.disabled,
      ...map,
    };
    return mapsRef.add(newMap);
  };
}

export function useDeleteMap() {
  const mapRef = useFirestore().collection("maps");

  return (id: string) => {
    return mapRef.doc(id).delete();
  };
}
