import avatar0 from "./avatar0.jpeg";
import avatar1 from "./avatar1.jpeg";
import avatar2 from "./avatar2.jpeg";
import avatar3 from "./avatar3.jpeg";
import avatar4 from "./avatar4.jpeg";
import avatar5 from "./avatar5.jpeg";
import avatar6 from "./avatar6.jpeg";
import avatar7 from "./avatar7.jpeg";
import avatar8 from "./avatar8.jpeg";
import avatar9 from "./avatar9.jpeg";
import avatar10 from "./avatar10.jpeg";
import avatar11 from "./avatar11.jpeg";
import avatar12 from "./avatar12.jpeg";
import avatar13 from "./avatar13.jpeg";
import avatar14 from "./avatar14.jpeg";
import avatar15 from "./avatar15.jpeg";
import avatar16 from "./avatar16.jpeg";
import avatar17 from "./avatar17.jpeg";
import avatar18 from "./avatar18.jpeg";
import avatar19 from "./avatar19.jpeg";
import avatar20 from "./avatar20.jpeg";
import avatar21 from "./avatar21.jpeg";
import avatar22 from "./avatar22.jpeg";
import avatar23 from "./avatar23.jpeg";
import avatar24 from "./avatar24.jpeg";
import avatar25 from "./avatar25.jpeg";
import avatar26 from "./avatar26.jpeg";
import avatar27 from "./avatar27.jpeg";
import avatar28 from "./avatar28.jpeg";
import avatar29 from "./avatar29.jpeg";
import avatar30 from "./avatar30.jpeg";
import avatar31 from "./avatar31.jpeg";
import avatar32 from "./avatar32.jpeg";
import avatar33 from "./avatar33.jpeg";
import avatar34 from "./avatar34.jpeg";
import avatar35 from "./avatar35.jpeg";
import avatar36 from "./avatar36.jpeg";
import avatar37 from "./avatar37.jpeg";
import avatar38 from "./avatar38.jpeg";
import avatar39 from "./avatar39.jpeg";
import avatar40 from "./avatar40.jpeg";
import avatar41 from "./avatar41.jpeg";
import avatar42 from "./avatar42.jpeg";
import avatar43 from "./avatar43.jpeg";
import avatar44 from "./avatar44.jpeg";
import avatar45 from "./avatar45.jpeg";
import avatar46 from "./avatar46.jpeg";
import avatar47 from "./avatar47.jpeg";

export const defaultAvatars = [
  avatar0,
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
  avatar16,
  avatar17,
  avatar18,
  avatar19,
  avatar20,
  avatar21,
  avatar22,
  avatar23,
  avatar24,
  avatar25,
  avatar26,
  avatar27,
  avatar28,
  avatar29,
  avatar30,
  avatar31,
  avatar32,
  avatar33,
  avatar34,
  avatar35,
  avatar36,
  avatar37,
  avatar38,
  avatar39,
  avatar40,
  avatar41,
  avatar42,
  avatar43,
  avatar44,
  avatar45,
  avatar46,
  avatar47,
];

export const avatars = new Proxy(defaultAvatars, {
  get: function (target, name) {
    return target[name as any] || name;
  },
}) as unknown as string[] & Record<string | number, string>;
