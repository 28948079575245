import React, { useState } from "react";
import { PageLayout } from "../layout";
import { useLocation, useHistory } from "react-router-dom";
import {
  useCurrentUserCharacterList,
  useCreateCharacter,
} from "../character/useCharacter";
import { Grid, MenuItem, Select } from "@material-ui/core";
import { Button, Text } from "@arwes/core";

import { FrameContent } from "../layout/FrameContent";
import { LoadingBars } from "../components";
import { AnimatedRouteChildrenProps } from "../navigation/AnimatedRoute";
import firebase from "firebase";

export const JoinCampaignPage: React.FC<
  AnimatedRouteChildrenProps<{ inviteCode: string }>
> = (props) => {
  const { activate } = props;
  const { inviteCode } = props.match.params;

  const [selectedCharacter, setSelectedCharacter] = useState("create");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const characters = useCurrentUserCharacterList();
  const createCharacter = useCreateCharacter();

  const joinCampaign = firebase.functions().httpsCallable("joinCampaign");

  return (
    <PageLayout>
      <FrameContent
        animator={{ root: true, activate }}
        style={{ padding: "16px" }}
      >
        <Grid>
          <Text as="h4">Join Campaign</Text>
        </Grid>
        <Grid style={{ marginBottom: "16px" }}>
          <Text>
            Joining this campaign will allow all members of the campaign to view
            your character, and it will also allow the owner of the campaign to
            edit your character.
          </Text>
        </Grid>
        <Grid container spacing={2} direction="row">
          <Grid item container direction="column" xs>
            <Grid item>
              <Text>Select character to join campaign</Text>
            </Grid>
            <Grid item>
              <Select
                id="character-select"
                value={selectedCharacter}
                onChange={(event) =>
                  setSelectedCharacter(event.target.value as any)
                }
              >
                <MenuItem value="create">Create New</MenuItem>
                {characters.docs.map((characterRef) => {
                  const character = characterRef.data();
                  return (
                    <MenuItem key={characterRef.id} value={characterRef.id}>
                      {character.displayName}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <Grid item xs>
            {loading ? (
              <LoadingBars />
            ) : (
              <Button
                onClick={async () => {
                  setLoading(true);
                  try {
                    if (selectedCharacter === "create") {
                      const newCharacter = await createCharacter();
                      await joinCampaign({
                        inviteCode,
                        characterId: newCharacter.id,
                      });
                      history.push(`/characters/${newCharacter.id}`);
                    } else {
                      await joinCampaign({
                        inviteCode,
                        characterId: selectedCharacter,
                      });
                      history.push("/characters");
                    }
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                <Text>Join</Text>
              </Button>
            )}
          </Grid>
        </Grid>
      </FrameContent>
    </PageLayout>
  );
};
