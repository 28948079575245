import React, { useEffect } from "react";
import { Text } from "@arwes/core";
import { FrameContent, PageLayout } from "../layout";
import { useAuth, SuspenseWithPerf } from "reactfire";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Grid } from "@material-ui/core";

export const LogoutPage = withRouter((props: RouteComponentProps) => {
  const auth = useAuth();

  useEffect(() => {
    async function signOut() {
      await auth.signOut();
      props.history.push("/");
    }
    signOut();
  });

  return (
    <PageLayout>
      <FrameContent>
        <Grid container>
          <Text as="h3">Logging out...</Text>
          <Grid
            item
            container
            spacing={3}
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Text>Logging out...</Text>
            </Grid>
          </Grid>
        </Grid>
      </FrameContent>
    </PageLayout>
  );
});
