import React from "react";
import { Text } from "@arwes/core";
import { FrameContent, PageLayout } from "../layout";
import { useAuth, SuspenseWithPerf } from "reactfire";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import styled from "styled-components";
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { Link } from "../navigation";

export const SignInPage: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [useAuth.GoogleAuthProvider.PROVIDER_ID, "microsoft.com"],
    ...(history.location.pathname === "/signin"
      ? { signInSuccessUrl: "/" }
      : { signInSuccessUrl: history.location.pathname }),
    // callbacks: {
    //   signInSuccessWithAuthResult: () => {
    //     // props.history.push("/");
    //     return true;
    //   },
    // },
  };

  return (
    <PageLayout>
      <FrameContent>
        <Grid container>
          <Text as="h3">Sign In</Text>
          <Grid
            item
            container
            spacing={3}
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Text>Sign in with one of the providers below.</Text>
            </Grid>
            <Grid item>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
            </Grid>
            <Grid item>
              <Text>
                By signing in and using this service you are agreeing to the{" "}
                <Link href="/tos">Terms of Service</Link> and{" "}
                <Link href="/privacy">Privacy Policy</Link>.
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </FrameContent>
    </PageLayout>
  );
};
