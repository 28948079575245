import { User } from "firebase";
import { useFirestore, useFirestoreDocData, useUser } from "reactfire";
import { Account } from "./utils";

export function useAccount() {
  const currentUser = useUser<User>();
  const accountRef = useFirestore().collection("users").doc(currentUser.uid);

  const accountData = useFirestoreDocData<Account>(accountRef);

  return accountData;
}
