import { Grid, Tooltip } from "@material-ui/core";
import React from "react";
import { LoadingBars } from "../components";

export const CapacityBar: React.FC<{
  palette?: string;
  current: number;
  maximum: number;
  length: number;
}> = (props) => {
  const { palette, current, maximum, length } = props;
  return (
    <Tooltip title={`${current} / ${maximum}`} arrow>
      <span>
        <LoadingBars
          length={length}
          determinate
          palette={palette}
          progress={(current / maximum) * 100}
        />
      </span>
    </Tooltip>
  );
};
