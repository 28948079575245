import { User } from "firebase";
import { useFirestore, useFirestoreCollection, useUser } from "reactfire";

export function useSubscription() {
  const currentUser = useUser<User>();

  const subscriptionsRef = useFirestore()
    .collection("users")
    .doc(currentUser.uid)
    .collection("subscriptions");

  const subscription = (
    useFirestoreCollection(
      subscriptionsRef.where("status", "==", "active").limit(1)
    ) as any
  ).docs[0];

  return {
    hasSubscription: () => {
      return subscription && subscription.data().role === "basic";
    },
  };
}
