import { Text } from "@arwes/core";
import { FrameBox } from "@arwes/core";
import { Grid } from "@material-ui/core";
import React from "react";
import { SuspenseWithPerf } from "reactfire";

export const Clock: React.FC<{ time: number }> = (props) => {
  const { time } = props;
  const minute = Math.floor(time / 60);
  const second = time % 60;
  return (
    <FrameBox>
      <Text as="h5" key={`minute-${minute}`} style={{ marginBottom: "0" }}>
        {`${minute}`.padStart(2, "0")}
      </Text>
      <Text as="h5" style={{ marginBottom: "0" }}>
        :
      </Text>
      <Text as="h5" key={`second-${second}`} style={{ marginBottom: "0" }}>
        {`${second}`.padStart(2, "0")}
      </Text>
    </FrameBox>
  );
};
