import { firestore, User } from "firebase";
import { AxialCoordinates } from "honeycomb-grid";
import { useEffect, useState } from "react";
import {
  useFirestoreDocData,
  useFirestore,
  useUser,
  useFirestoreCollection,
} from "reactfire";
import { v4 } from "uuid";
import {
  ActionsData,
  ConditionInfo,
  LabelData,
  SituationData,
  SituationLog,
  SkillChallengeTemplate,
} from "./utils";

export class EditableSituation {
  public id: string;
  public state: SituationData;

  private db: firestore.Firestore;
  public situationRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  private situationData: SituationData;
  private setSituationUpdates: React.Dispatch<
    React.SetStateAction<SituationData>
  >;

  constructor(id: string) {
    this.id = id;
    this.db = useFirestore();
    this.situationRef = this.db.collection("situations").doc(id);
    const situationData = useFirestoreDocData<SituationData>(this.situationRef);

    const [situationUpdates, setSituationUpdates] = useState(situationData);
    useEffect(() => setSituationUpdates(situationData), [situationData]);

    this.setSituationUpdates = setSituationUpdates;
    this.situationData = situationData;

    this.state = situationUpdates;
  }
  setOwner(owner: string) {
    return this.setSituationUpdates((prevState) => ({
      ...prevState,
      owner,
    }));
  }
  setDisplayName(displayName: string) {
    return this.setSituationUpdates((prevState) => ({
      ...prevState,
      displayName,
    }));
  }
  setDescription(description: string) {
    return this.setSituationUpdates((prevState) => ({
      ...prevState,
      description,
    }));
  }
  setNotes(notes: string) {
    return this.setSituationUpdates((prevState) => ({
      ...prevState,
      notes,
    }));
  }
  setMap(mapId: string) {
    return this.setSituationUpdates((prevState) => ({
      ...prevState,
      mapId,
    }));
  }
  setTime(time: number) {
    return this.setSituationUpdates((prevState) => ({
      ...prevState,
      time,
    }));
  }
  addLog(log: SituationLog) {
    return this.situationRef.update({
      logs: [...this.situationData.logs, log],
    });
  }
  commitUpdateCharacterVisibility(id: string, visible: boolean) {
    return this.situationRef.update({
      [`characters.${id}.visible`]: visible,
    });
  }
  async commitCreateSkillChallenge(skillChallenge: SkillChallengeTemplate) {
    const id = v4();
    await this.situationRef.update({
      [`skillChallenges.${id}`]: skillChallenge,
    });
    return id;
  }
  commitCreateSkillChallengeWithId(
    id: string,
    skillChallenge: SkillChallengeTemplate
  ) {
    return this.situationRef.update({
      [`skillChallenges.${id}`]: skillChallenge,
    });
  }
  commitUpdateSkillChallenge(
    id: string,
    skillChallenge: SkillChallengeTemplate
  ) {
    return this.situationRef.update({
      [`skillChallenges.${id}`]: skillChallenge,
    });
  }
  commitDeleteSkillChallenge(id: string) {
    return this.situationRef.update({
      [`skillChallenges.${id}`]: firestore.FieldValue.delete(),
    });
  }
  async commitCreateLabel(label: LabelData) {
    const id = v4();
    await this.situationRef.update({
      [`labels.${id}`]: label,
    });
    return id;
  }
  commitUpdateLabel(id: string, label: LabelData) {
    return this.situationRef.update({
      [`labels.${id}`]: label,
    });
  }
  commitDeleteLabel(id: string) {
    return this.situationRef.update({
      [`labels.${id}`]: firestore.FieldValue.delete(),
    });
  }
  commitUpdateMelee(characterId: string, targetId: string, isMelee: boolean) {
    return this.situationRef.update({
      [`characters.${characterId}.melee.${targetId}`]: isMelee,
    });
  }
  commitUpdateDroneMelee(
    characterId: string,
    targetId: string,
    isMelee: boolean
  ) {
    return this.situationRef.update({
      [`characters.${characterId}.droneMelee.${targetId}`]: isMelee,
    });
  }
  commitUpdateCover(characterId: string, targetId: string, isCover: boolean) {
    return this.situationRef.update({
      [`characters.${characterId}.cover.${targetId}`]: isCover,
    });
  }
  commitUpdateDroneCover(
    characterId: string,
    targetId: string,
    isCover: boolean
  ) {
    return this.situationRef.update({
      [`characters.${characterId}.droneCover.${targetId}`]: isCover,
    });
  }
  commitAddCondition(characterId: string, condition: ConditionInfo) {
    return this.situationRef.update({
      [`characters.${characterId}.conditions`]:
        firestore.FieldValue.arrayUnion(condition),
    });
  }
  commitRemoveCondition(characterId: string, condition: ConditionInfo) {
    return this.situationRef.update({
      [`characters.${characterId}.conditions`]:
        firestore.FieldValue.arrayRemove(condition),
    });
  }
  // Update character position
  updateCharacterPosition(characterId: string, position: AxialCoordinates) {
    return this.situationRef.update({
      [`characters.${characterId}.coordinates`]: position,
    });
  }
  // Update drone position
  updateDronePosition(characterId: string, position: AxialCoordinates) {
    return this.situationRef.update({
      [`characters.${characterId}.droneCoordinates`]: position,
    });
  }
  commitShouldUpdateDynamicVision(shouldUpdate: boolean) {
    return this.situationRef.update({
      shouldUpdateDynamicVision: shouldUpdate,
    });
  }
  setDynamicVision(dynamicVision: boolean) {
    return this.setSituationUpdates((prevState) => ({
      ...prevState,
      dynamicVision,
    }));
  }
  commit() {
    return new Promise((resolve) => {
      this.setSituationUpdates((prevState) => {
        if (prevState.displayName !== this.situationData.displayName) {
          const campaignRef = this.db
            .collection("campaigns")
            .doc(prevState.campaignId);
          const batch = this.db.batch();
          batch.update(this.situationRef, prevState);
          batch.update(campaignRef, {
            [`situations.${this.id}`]: {
              displayName: prevState.displayName,
            },
          });

          resolve(batch.commit());
        } else {
          resolve(this.situationRef.update(prevState));
        }

        return prevState;
      });
    });
  }
  reset() {
    return this.setSituationUpdates(this.situationData);
  }
}

export function useEditableSituation(id: string): EditableSituation {
  return new EditableSituation(id);
}

export function useCreateSituation() {
  const situationsRef = useFirestore().collection("situations");
  const currentUser = useUser<User>();

  return (
    situation: Partial<Omit<SituationData, "campaignId">> & {
      campaignId: string;
    }
  ) => {
    const newSituation: SituationData = {
      owner: currentUser.uid,
      createdAt: firestore.FieldValue.serverTimestamp() as any,
      time: 0,
      displayName: "[New Situation]",
      description: "A new situation.",
      notes: "",
      skillChallenges: {},
      logs: [],
      characters: {},
      visibleArea: {},
      dynamicVision: false,
      shouldUpdateDynamicVision: false,
      ...situation,
    };
    return situationsRef.add(newSituation);
  };
}

export function useUpdateSituation() {
  const situationsRef = useFirestore().collection("situations");

  return (id: string, situation: Partial<SituationData>) =>
    situationsRef.doc(id).update(situation);
}

export function useCampaignSituationList(campaignId: string) {
  const situationsRef = useFirestore().collection("situations");

  const situations = useFirestoreCollection<void>(
    situationsRef.where("campaignId", "==", campaignId).orderBy("createdAt")
  ) as firestore.QuerySnapshot<SituationData>;

  return situations;
}

export function useDeleteSituation() {
  const situationsRef = useFirestore().collection("situations");

  return (id: string) => {
    return situationsRef.doc(id).delete();
  };
}

export function useAddCharacterToSituation() {
  const charactersRef = useFirestore().collection("characters");
  return (characterId: string, situationId: string) => {
    return charactersRef.doc(characterId).update({
      [`situations.${situationId}`]: true,
    });
  };
}

export function useRemoveCharacterFromSituation() {
  const charactersRef = useFirestore().collection("characters");
  return (characterId: string, situationId: string) => {
    return charactersRef.doc(characterId).update({
      [`situations.${situationId}`]: firestore.FieldValue.delete(),
    });
  };
}

export function useSituationActions(situationId: string) {
  const actionsRef = useFirestore()
    .collection("situations")
    .doc(situationId)
    .collection("actions");

  const actions = useFirestoreCollection<void>(
    actionsRef
  ) as firestore.QuerySnapshot<ActionsData>;

  return actions;
}

export function useCharacterActionsInSituation(
  characterId: string,
  situationId: string
) {
  const actionRef = useFirestore()
    .collection("situations")
    .doc(situationId)
    .collection("actions")
    .doc(characterId);

  const action = useFirestoreDocData<ActionsData>(actionRef);

  return action;
}

export function useSetCharacterActionsInSituation() {
  const situationsRef = useFirestore().collection("situations");

  return (characterId: string, actions: ActionsData, situationId: string) => {
    return situationsRef
      .doc(situationId)
      .collection("actions")
      .doc(characterId)
      .set({ ready: false, actions: [], lastActivations: {}, ...actions });
  };
}

export function useUpdateCharacterActionsInSituation() {
  const situationsRef = useFirestore().collection("situations");

  return (characterId: string, actions: ActionsData, situationId: string) => {
    return situationsRef
      .doc(situationId)
      .collection("actions")
      .doc(characterId)
      .update(actions)
      .catch(() =>
        situationsRef
          .doc(situationId)
          .collection("actions")
          .doc(characterId)
          .set({ ready: false, actions: [], lastActivations: {}, ...actions })
      );
  };
}
