import React, { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import styled from "styled-components";
import { AnimatedRoute, Link } from "../navigation";
import contents from "./contents";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { find } from "lodash";
import TextField from "@material-ui/core/TextField";
import { Text, Figure, Button } from "@arwes/core";
import { Container, Grid } from "@material-ui/core";
import { WideFrameLines } from "../components";
import { FrameContent } from "../layout";
import { Animator } from "@arwes/animation";

const { FrameBox } = require("@arwes/core");

const SideBarSearch = styled.div`
  margin: 16px;
`;

const StyledText = styled(Text)`
  margin-bottom: 16px;
`;

const StyledP = styled.p`
  animation: fadeIn linear 1s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const StyledFigure = styled(Figure)`
  ${(props: any) =>
    props.float
      ? props.float === "left"
        ? "padding-right: 16px;"
        : "padding-left: 16px;"
      : ""}

  width: ${(props: any) => props.width || "100%"};

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  ${(props: any) =>
    props.float
      ? props.float === "left"
        ? "float: left;"
        : "float: right;"
      : ""}

  img {
    min-width: 0px;
    min-height: 0px;
    image-rendering: pixelated;
    width: 2000px;
  }
`;

interface RulesPageProps {
  activate: boolean;
}

export const RulesPage: React.FC<RulesPageProps> = (props) => {
  const { activate } = props;
  const [search, setSearch] = useState("");
  const history = useHistory();
  return (
    <Container style={{ marginTop: "16px" }}>
      <Grid container spacing={3}>
        <Grid xs={12} md={3} item>
          <FrameContent animator={{ activate }}>
            <SideBarSearch>
              <TextField
                label="Search"
                variant="filled"
                fullWidth
                onChange={(e) => setSearch(e.target.value.toLowerCase())}
              />
            </SideBarSearch>
            <dl>
              {contents.map((chapter) => {
                const includeChapter =
                  search === "" ||
                  chapter.content?.toLowerCase().includes(search) ||
                  chapter.sections.some((section) =>
                    section.content.toLowerCase().includes(search)
                  );
                if (!includeChapter) {
                  return null;
                }
                return (
                  <>
                    <dt>
                      <Link href={`/rules/${chapter.path}`} animate>
                        {chapter.title}
                      </Link>
                    </dt>
                    {chapter.sections.map((section) => {
                      const includeSection =
                        search === "" ||
                        section.content.toLowerCase().includes(search);
                      if (!includeSection) {
                        return null;
                      }
                      return (
                        <dd>
                          <Link
                            href={`/rules/${chapter.path}#${section.path}`}
                            animate
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;- {section.title}
                          </Link>
                        </dd>
                      );
                    })}
                  </>
                );
              })}
            </dl>
          </FrameContent>
        </Grid>
        <Grid xs={12} md={9} item>
          <Route exact path="/rules">
            <Redirect to="/rules/introduction" />
          </Route>
          <Route
            path="/rules/:chapter"
            render={({ match }) => {
              const chapter = find(contents, ["path", match.params.chapter]);
              if (
                chapter?.title &&
                document.title !== `${chapter.title} - Rules`
              ) {
                document.title = `${chapter.title} - Rules`;
              }

              if (!chapter) {
                return null;
              } else {
                const chapterIndex = contents.indexOf(chapter);
                const markdownOptions = {
                  overrides: {
                    Figure: StyledFigure,
                    a: Link,
                    p: StyledP,
                    // h3: (props: any) => (
                    //   <div>
                    //     <Text animator={{ animate: false }} as="h3">
                    //       {props.children}
                    //     </Text>
                    //   </div>
                    // ),
                    // h4: (props: any) => (
                    //   <div>
                    //     <Text animator={{ animate: false }} as="h4">
                    //       {props.children}
                    //     </Text>
                    //   </div>
                    // ),
                  },
                };
                return (
                  <Animator
                    key={match.params.chapter}
                    animator={{ activate, root: true }}
                  >
                    <div>
                      <FrameContent style={{ padding: "24px" }}>
                        <div>
                          <Text as="h1">{chapter.title}</Text>
                        </div>
                        {chapter.content && (
                          <div
                            style={{
                              paddingLeft: "16px",
                              paddingRight: "16px",
                            }}
                          >
                            <Markdown options={markdownOptions}>
                              {chapter.content ?? ""}
                            </Markdown>
                          </div>
                        )}
                      </FrameContent>
                      {chapter.sections.map((section, index) => (
                        <FrameContent
                          id={section.path}
                          fillColor={
                            index % 2 === 0
                              ? /* slightly darker */ "#042025"
                              : undefined
                          }
                          style={{
                            padding: "24px",
                          }}
                        >
                          <div>
                            <Text as="h3">{section.title}</Text>
                          </div>
                          <div
                            style={{
                              paddingLeft: "16px",
                              paddingRight: "16px",
                            }}
                          >
                            <Markdown options={markdownOptions}>
                              {section.content}
                            </Markdown>
                          </div>
                          {/* <br /> */}
                        </FrameContent>
                      ))}
                      <FrameContent style={{ padding: "24px" }}>
                        <Grid justify="space-between" container>
                          <Grid item>
                            {chapterIndex > 0 && (
                              <Button
                                onClick={() => {
                                  history.push(
                                    `/rules/${contents[chapterIndex - 1].path}`
                                  );
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <Text>Previous</Text>
                              </Button>
                            )}
                          </Grid>
                          <Grid item>
                            {chapterIndex < contents.length - 1 && (
                              <Button
                                onClick={() => {
                                  history.push(
                                    `/rules/${contents[chapterIndex + 1].path}`
                                  );
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <Text>Next</Text>
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </FrameContent>
                    </div>
                  </Animator>
                );
              }
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
