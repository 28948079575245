import React, { useEffect, useState } from "react";
import { CardText, PageLayout } from "../layout";
import { useCampaignCharacterList, useEditableCharacter } from "../character";
import { Grid } from "@material-ui/core";
import { Button, Card, Text } from "@arwes/core";

import { FrameContent } from "../layout/FrameContent";
import { truncate } from "lodash";
import { SuspenseWithLoadingBars, WideFrameLines } from "../components";
import { Link } from "../navigation";
import { AnimatedRouteChildrenProps } from "../navigation/AnimatedRoute";
import { EditableCampaign, useEditableCampaign } from "../campaign";
import { avatars } from "../character/avatars";
import { SituationPlayerWrapper } from "./SituationPlayerView";

export const CampaignView: React.FC<{ campaign: EditableCampaign }> = (
  props
) => {
  const { campaign } = props;

  const [firstRenderTime] = useState(() => Date.now());
  const characters = useCampaignCharacterList(campaign.id);

  return (
    <Grid container direction="column">
      <Grid item>
        <Text as="h3">Campaign Lobby: {campaign.state.displayName}</Text>
      </Grid>
      <Grid item>
        <Text style={{ whiteSpace: "pre-wrap" }}>
          {campaign.state.description}
        </Text>
      </Grid>
      <WideFrameLines
        hideTopLines
        hideShapes
        style={{ marginBottom: "16px" }}
      />
      <Grid item>
        <Text as="h4">Players</Text>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        {characters.map((character, index) => {
          return (
            <Grid key={character.id} item xs={12} md={6}>
              <Card
                animator={{
                  duration: {
                    delay: Math.max(
                      100 * index - (Date.now() - firstRenderTime),
                      0
                    ),
                  },
                }}
                title={character.displayName}
                image={{ src: avatars[character.avatar] }}
                landscape
                options={
                  <Link href={`/characters/${character.id}/overview`}>
                    <Button>
                      <Text>View</Text>
                    </Button>
                  </Link>
                }
              >
                <CardText>{character.description}</CardText>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export const PlayerPage: React.FC<
  AnimatedRouteChildrenProps<{ id: string }>
> = (props) => {
  const { activate } = props;
  const { id } = props.match.params;
  const character = useEditableCharacter(id);
  const campaign = useEditableCampaign(character.state.campaignId!);

  useEffect(() => {
    document.title = `${character.state.displayName} - Player`;
  }, [character.state.displayName]);

  return campaign ? (
    campaign.state.charactersCurrentSituation[id] ? (
      <SuspenseWithLoadingBars traceId={"player-situation-view"}>
        <SituationPlayerWrapper
          campaign={campaign}
          character={character.state}
          situationId={campaign.state.charactersCurrentSituation[id]!}
        />
      </SuspenseWithLoadingBars>
    ) : (
      <PageLayout>
        <FrameContent
          animator={{ root: true, activate }}
          style={{ padding: "16px" }}
        >
          <Grid item container justify="space-between">
            <Grid item>
              <Text as="h2">{character.state.displayName}</Text>
            </Grid>
            <Grid item>
              <Link href={`/characters/${character.id}/overview`}>
                <Button>
                  <Text>View</Text>
                </Button>
              </Link>
            </Grid>
          </Grid>
          <SuspenseWithLoadingBars traceId={"player-campaign-view"}>
            <CampaignView campaign={campaign} />
          </SuspenseWithLoadingBars>
        </FrameContent>
      </PageLayout>
    )
  ) : null;
};
