import { firestore } from "firebase";
import { useFirestore, useFirestoreCollection } from "reactfire";

export interface Invite {
  campaignId: string;
}

export function useCreateCampaignInvite() {
  const invitesRef = useFirestore().collection("invites");

  return (campaignId: string) => invitesRef.add({ campaignId });
}

export function useDeleteInvite() {
  const invitesRef = useFirestore().collection("invites");

  return (id: string) => {
    return invitesRef.doc(id).delete();
  };
}

export function useCampaignInvite(campaignId: string) {
  const invitesRef = useFirestore().collection("invites");

  const invite = useFirestoreCollection<void>(
    invitesRef.where("campaignId", "==", campaignId).limit(1)
  ) as firestore.QuerySnapshot<{}>;

  if (invite.docs.length === 0) {
    return undefined;
  } else {
    return {
      campaignId,
      inviteCode: invite.docs[0].id,
    };
  }
}
