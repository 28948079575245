import { Button, ButtonProps, Text } from "@arwes/core";
import React, { useState } from "react";
import { LoadingBars, LoadingBarsProps } from "./LoadingBars";

export const LoadingButton: React.FC<ButtonProps & LoadingBarsProps> = (
  props
) => {
  const [isLoading, setIsLoading] = useState(false);
  return isLoading ? (
    <LoadingBars {...props} />
  ) : (
    <Button
      {...props}
      onClick={async (...args) => {
        if (props.onClick) {
          setIsLoading(true);
          await props.onClick(...args);
          setIsLoading(false);
        }
      }}
    />
  );
};
