import FastPriorityQueue from "fastpriorityqueue";
import {
  createHexPrototype,
  Grid,
  rectangle,
  hexToPoint,
  pointToCube,
  line,
  HexCoordinates,
  Hex,
  AxialCoordinates,
  spiral,
  createHex,
  ring,
  Point,
  neighborOf,
  CompassDirection,
  PartialCubeCoordinates,
  round,
} from "honeycomb-grid";
import { Terrain } from "../map/utils";

export const hexPrototype = createHexPrototype({ dimensions: 20 });

export const gridSize = 200;
export const grid = new Grid(
  hexPrototype
  // rectangle({
  //   start: { row: -100, col: -100 },
  //   width: gridSize,
  //   height: gridSize,
  // })
);

export const gridRectangle = grid.traverse(
  rectangle({
    start: { row: -100, col: -100 },
    width: gridSize,
    height: gridSize,
  })
);
// const grid = HexGrid.rectangle({ width: gridSize, height: gridSize });

export const centerHex = gridRectangle.getHex({ row: 0, col: 0 }); //grid.getHex({ row: gridSize / 2, col: gridSize / 2 })!;
export const centerPoint = hexToPoint(centerHex);

export function subtractHex(
  hex1: AxialCoordinates,
  hex2: AxialCoordinates
): AxialCoordinates {
  return {
    q: hex1.q - hex2.q,
    r: hex1.r - hex2.r,
  };
}

export function addHex(
  hex1: AxialCoordinates,
  hex2: AxialCoordinates
): AxialCoordinates {
  return {
    q: hex1.q + hex2.q,
    r: hex1.r + hex2.r,
  };
}

export function multiplyHex(
  hex: AxialCoordinates,
  multiplier: number
): AxialCoordinates {
  return {
    q: hex.q * multiplier,
    r: hex.r * multiplier,
  };
}

export function divideHex(
  hex: AxialCoordinates,
  divisor: number
): AxialCoordinates {
  return {
    q: hex.q / divisor,
    r: hex.r / divisor,
  };
}

export function roundHex(hex: AxialCoordinates): AxialCoordinates {
  return {
    q: Math.round(hex.q),
    r: Math.round(hex.r),
  };
}

export function hexToAxial(hex: Hex | AxialCoordinates): AxialCoordinates {
  return { q: hex.q, r: hex.r };
}

function isLeft(a: Point, b: Point, c: Point) {
  return (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x);
}

export function pathCoordinatesToHexes(
  path: AxialCoordinates[],
  terrain?: Terrain,
  blockingTerrain?: number
) {
  if (terrain && blockingTerrain) {
    let pathResult: Hex[] = [];
    for (let i = 0; i < path.length - 1; i++) {
      const segmentResult = pathSearch(
        grid.getHex(path[i]),
        grid.getHex(path[i + 1]),
        terrain,
        blockingTerrain
      );
      if (
        hexEquals(
          segmentResult[segmentResult.length - 1],
          grid.getHex(path[i + 1])
        )
      ) {
        pathResult = [
          ...pathResult,
          ...segmentResult.slice(
            0,
            i === path.length - 2 ? segmentResult.length : -1
          ),
        ];
      } else {
        pathResult = [...pathResult, ...segmentResult];
        break;
      }
    }
    return pathResult;
  } else {
    const hexes: Hex[] = [];
    grid
      .traverse(
        path.slice(0, path.length - 1).map((point, index) =>
          line({
            ...(index === 0 ? { start: point } : {}),
            through: path[index + 1],
          })
        )
      )
      .takeWhile(
        (hex) => !isTerrainBlocking(hexToAxial(hex), terrain, blockingTerrain)
      )
      .each((hex) => hexes.push(hex))
      .run();
    return hexes;
  }
}

export function pathSearch(
  start: Hex,
  end: Hex,
  terrain: Terrain,
  blockingTerrain: number
) {
  let closestDistance = Infinity;
  let closestHex: Hex = start;

  const gScores: Record<string, number> = {};
  const fScores: Record<string, number> = {};
  const cameFrom: Record<string, Hex> = {};

  function backtrack(end: Hex) {
    const path: Hex[] = [end];
    while (cameFrom[hexToString(end)]) {
      path.push(cameFrom[hexToString(end)]);
      end = cameFrom[hexToString(end)];
    }
    return path.reverse();
  }

  const fringe = new FastPriorityQueue<Hex>(function (a, b) {
    return fScores[hexToString(a)] < fScores[hexToString(b)];
  });
  const fringeMap: Record<string, boolean> = {};
  const seenMap: Record<string, boolean> = {};
  fringe.add(start);
  fringeMap[hexToString(start)] = true;

  gScores[hexToString(start)] = 0;
  fScores[hexToString(start)] = grid.distance(start, end);

  while (!fringe.isEmpty()) {
    if (Object.keys(seenMap).length > 1000) {
      break;
    }
    const current = fringe.poll()!;
    fringeMap[hexToString(current)] = false;
    seenMap[hexToString(current)] = true;

    if (hexEquals(current, end)) {
      return backtrack(current);
    } else {
      if (grid.distance(current, end) < closestDistance) {
        closestDistance = grid.distance(current, end);
        closestHex = current;
      }
      for (const neighbor of getNeighbors(current)) {
        if (
          !isTerrainBlocking(neighbor, terrain, blockingTerrain) &&
          !seenMap[hexToString(neighbor)]
        ) {
          const gScore = gScores[hexToString(current)] + 1;

          if (gScore < (gScores[hexToString(neighbor)] || Infinity)) {
            cameFrom[hexToString(neighbor)] = current;
            gScores[hexToString(neighbor)] = gScore;
            fScores[hexToString(neighbor)] =
              gScore + grid.distance(neighbor, end);
            if (!fringeMap[hexToString(neighbor)]) {
              fringeMap[hexToString(neighbor)] = true;
              fringe.add(neighbor);
            }
          }
        }
      }
    }
  }
  return backtrack(closestHex);
}

export function hexEquals(one: AxialCoordinates, two: AxialCoordinates) {
  return one.q === two.q && one.r === two.r;
}

export enum CoverLevel {
  none,
  partial,
  full,
}

const maxCoverDistance = 4;

export function getCoverLevel(
  start: AxialCoordinates,
  end: AxialCoordinates,
  terrain?: Terrain
): CoverLevel {
  let coverLevel = CoverLevel.none;

  const counts = {
    left: 0,
    right: 0,
  };

  const startHex = grid.getHex(start);
  const endHex = grid.getHex(end);

  const line = lineDraw(start, end);

  const path = new Set<string>(line.map((hex) => hexToString(hex)));

  for (const hex of line) {
    if (
      grid.distance(start, hex) > maxCoverDistance &&
      grid.distance(end, hex) > maxCoverDistance &&
      isTerrainBlocking(hex, terrain, 20)
    ) {
      coverLevel = CoverLevel.full;
      break;
    }
    if (
      isTerrainBlocking(hex, terrain, 20) &&
      bisectsTerrain(hex, startHex, endHex, path, counts, terrain, 20)
    ) {
      coverLevel = CoverLevel.full;
      break;
    }
    if (
      grid.distance(end, hex) <= maxCoverDistance &&
      isTerrainBlocking(hex, terrain, 15)
    ) {
      coverLevel = CoverLevel.partial;
    }
  }

  return coverLevel;
}

export function hexToString(hex: AxialCoordinates) {
  return `${hex.q},${hex.r}`;
}

function lerp(
  a: PartialCubeCoordinates,
  b: PartialCubeCoordinates,
  t: number
): AxialCoordinates {
  const q = a.q * (1 - t) + b.q * t;
  const r = a.r * (1 - t) + b.r * t;
  return { q, r };
}

function lineDraw(a: AxialCoordinates, b: AxialCoordinates) {
  const N = grid.distance(a, b);
  a = addHex(a, { q: 1e-6, r: 2e-6 });
  b = addHex(b, { q: 1e-6, r: 2e-6 });
  const results: Hex[] = [];
  for (let i = 0; i <= N; i++) {
    results.push(grid.getHex(round(lerp(a, b, (1.0 / N) * i))));
  }
  return results;
}

const memoLines: Record<string, Hex[]> = {};

function getLine(start: AxialCoordinates, end: AxialCoordinates) {
  const key = `${start.q},${start.r}-${end.q},${end.r}`;
  if (!memoLines[key]) {
    const hexes: Hex[] = [];
    grid
      .traverse(line({ start, through: end }))
      .each((hex) => hexes.push(hex))
      .run();
    memoLines[key] = hexes;
  }

  return memoLines[key];
}

export function getLineOfSight(
  start: AxialCoordinates,
  end: AxialCoordinates,
  terrain?: Terrain,
  blockingTerrain?: number,
  canHitExposed?: boolean,
  includeBlock?: boolean
) {
  //const path = new Set<string>();
  // const traversal = grid.traverse(
  //   line({
  //     start,
  //     through: end,
  //   })
  // );

  const counts = {
    left: 0,
    right: 0,
  };

  const startHex = grid.getHex(start);
  const endHex = grid.getHex(end);

  const line = lineDraw(start, end);
  const hexes: Hex[] = [];

  const path = new Set<string>(line.map((hex) => hexToString(hex)));

  for (const hex of line) {
    // path.add(hexToString(hex));

    const notBlocked =
      ((grid.distance(start, hex) <= maxCoverDistance ||
        (canHitExposed && grid.distance(end, hex) <= maxCoverDistance)) &&
        !bisectsTerrain(
          hex,
          startHex,
          endHex,
          path,
          counts,
          terrain,
          blockingTerrain
        )) ||
      !isTerrainBlocking(hex, terrain, blockingTerrain);
    if (!notBlocked) {
      if (includeBlock) {
        hexes.push(hex);
      }
      break;
    }
    hexes.push(hex);
  }

  return hexes;
}

export function bisectsTerrain(
  hex: Hex,
  start: Hex,
  end: Hex,
  path: Set<string>,
  counts: any,
  terrain?: Terrain,
  blockingTerrain?: number
) {
  // return false;
  let left = 0;
  let right = 0;
  if (isTerrainBlocking(hex, terrain, blockingTerrain)) {
    getNeighbors(hex).forEach((neighbourCoords) => {
      const neighbour = grid.getHex(neighbourCoords);
      if (
        !path.has(hexToString(neighbour)) &&
        isTerrainBlocking(neighbour, terrain, blockingTerrain)
      ) {
        const side = isLeft(neighbour.center, start.center, end.center);
        if (side > 0) {
          counts.left += 1;
          left++;
        } else if (side <= 0) {
          counts.right += 1;
          right++;
        }
      }
    });
    return counts.left !== 0 && counts.right !== 0;
  } else {
    return false;
  }
}

function getNeighbors(hex: Hex) {
  return [
    CompassDirection.NE,
    CompassDirection.E,
    CompassDirection.SE,
    CompassDirection.SW,
    CompassDirection.W,
    CompassDirection.NW,
  ].map((direction) => grid.getHex(neighborOf(hex, direction)));
}

const edgeHexes: Hex[] = [];
grid
  .traverse([
    line({ start: { row: -100, col: -100 }, until: { row: 100, col: -100 } }),
    line({ start: { row: 100, col: -100 }, until: { row: 100, col: 100 } }),
    line({ start: { row: 100, col: 100 }, until: { row: -100, col: 100 } }),
    line({ start: { row: -100, col: 100 }, until: { row: -100, col: -100 } }),
  ])
  .each((hex) => {
    edgeHexes.push(hex);
  })
  .run();

export function getVisibility(center: AxialCoordinates, terrain?: Terrain) {
  // const startTime = performance.now();
  const allHexes: Record<string, Hex> = {};
  for (const hex of edgeHexes) {
    getLineOfSight(center, hexToAxial(hex), terrain, 20, true, true).forEach(
      (hex) => {
        allHexes[hexToString(hex)] = hex;
      }
    );
  }
  return Object.values(allHexes);
}

export function getArea(
  center: AxialCoordinates,
  radius: number,
  terrain?: Terrain,
  blockingTerrain?: number,
  includeBlock?: boolean
) {
  const ringHexes: Hex[] = [];
  grid
    .traverse(
      ring({
        start: { q: center.q - radius * 2, r: center.r },
        center: center,
      })
    )
    .each((hex) => ringHexes.push(hex))
    .run();

  const allHexes: Record<string, Hex> = {};
  for (const hex of ringHexes) {
    grid
      .traverse(line({ start: center, through: hex }))
      .takeWhile((hex) => {
        if (!isTerrainBlocking(hex, terrain, blockingTerrain)) {
          return true;
        } else {
          if (includeBlock) {
            allHexes[hexToString(hex)] = hex;
          }
          return false;
        }
      })
      .each((hex) => {
        allHexes[hexToString(hex)] = hex;
      })
      .run();
  }

  return Object.values(allHexes);
}

function isTerrainBlocking(
  hex: AxialCoordinates,
  terrain?: Terrain,
  blockingTerrain?: number
) {
  // const gridHex = grid.getHex(hex);
  return (
    // gridHex.row < -gridSize / 2 ||
    // gridHex.row > gridSize / 2 ||
    // gridHex.col < -gridSize / 2 ||
    // gridHex.col > gridSize / 2 ||
    terrain &&
    blockingTerrain &&
    terrain[hex.q]?.[hex.r] !== undefined &&
    terrain[hex.q]?.[hex.r]! >= blockingTerrain
  );
}

export function getExplosion(
  target: AxialCoordinates,
  radius: number,
  source?: AxialCoordinates,
  terrain?: Terrain,
  pathBlocking?: number,
  areaBlocking?: number
) {
  let latestHex = source;
  if (latestHex) {
    getLineOfSight(latestHex, target, terrain, pathBlocking).forEach((hex) => {
      latestHex = hex;
    });
  } else {
    latestHex = target;
  }

  return getArea(latestHex, radius, terrain, areaBlocking);
}
