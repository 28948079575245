import { ArwesTheme } from "@arwes/core";
import { CSSObject } from "@emotion/react";

const rootFullStyles = {
  display: "flex",
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

const generateStyles = (
  theme: ArwesTheme,
  options: {
    animate: boolean;
    length: number;
    size: number;
    palette?: string;
    full?: boolean;
  }
): Record<string, CSSObject> => {
  const { space, outline, shadowBlur } = theme;
  const { animate, length, size, full } = options;

  const palette = options.palette
    ? theme.palette[options.palette]
    : theme.palette.primary;

  return {
    root: Object.assign(
      {
        display: "inline-block",
        padding: `0 ${space(2 * size)}px`,
      },
      full ? rootFullStyles : undefined
    ) as CSSObject,
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "auto",
      width: space(length * 2 * size),
      opacity: animate ? 0 : undefined,
    },
    item: {
      width: space(1 * size),
      height: space(5 * size),
      borderStyle: "solid",
      borderColor: palette.main,
      borderWidth: outline(1),
      boxShadow: `0 0 ${shadowBlur(1 * size)}px ${palette.main}`,
      transform: "skewX(330deg)",
      transformOrigin: "center",
    },
    itemPrimaryActive: {
      backgroundColor: palette.main,
      boxShadow: `0 0 ${shadowBlur(2 * size)}px ${palette.main}`,
    },
    itemSecondaryActive: {
      backgroundColor: palette.dark2,
      boxShadow: `0 0 ${shadowBlur(2 * size)}px ${palette.dark2}`,
    },
    itemInactive: {
      backgroundColor: "",
      boxShadow: "",
    },
  };
};

export { generateStyles };
